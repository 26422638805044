import { useLazyLoadQuery } from 'react-relay';
import taskJourneyScreenQuery, {
  taskJourneyScreenQueryTaskFragment,
} from './API/taskJourneyScreenQuery';
import { giveTagsToTasks } from '../../../../new/trackMyVehicle/helper/helper';
import { formatPlateNo, taskTypes } from '../../../../../utilities/helper';
import { optionMaker } from '../helper/helper';
import { isValueNullOrEmpty } from '../../../../../utilities/Utility';
import { usePagedTasks } from '../../../../../hooks/usePagedTasks';

const useTaskJourneyScreenData = ({ refreshedQueryOptions, variables }) => {
  const apiDataFragment = useLazyLoadQuery(
    taskJourneyScreenQuery,
    variables,
    refreshedQueryOptions,
  );

  const { tasks: findTasks } = usePagedTasks(
    taskJourneyScreenQueryTaskFragment,
    apiDataFragment,
  );

  const data = findTasks?.[0] || null;

  const t = giveTagsToTasks([data])[0];
  const parkingTag = data?.parkingTag
    ? `#${data?.parkingTag}`
    : `#${data?.parking?.parkingTag}`;
  const plateNo = formatPlateNo(data?.vehicle?.registration?.plate || '');
  const title = `${plateNo}`;
  const taskStartLocation = data?.taskStartLocation?.name || '';
  const taskEndLocation = data?.taskEndLocation?.name || '';
  let location = taskEndLocation;
  if (t?.taskType === taskTypes.recall) {
    location = taskStartLocation;
  }
  const subText =
    !isValueNullOrEmpty(data?.parkingTag) ||
    !isValueNullOrEmpty(data?.parking?.parkingTag)
      ? `${parkingTag} | ${location}`
      : `${location}`;
  return {
    data: t,
    title,
    subText,
    taskTagFE: t?.taskTagFE,
    taskType: t?.taskType,
  };
};

export default useTaskJourneyScreenData;
