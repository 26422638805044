import { graphql, fetchQuery } from 'react-relay';
import environment from '../../../../relay/relayEnvironment';

export const qrCodeScreenTaskQuery = graphql`
  query qrCodeScreenTaskQuery($taskFilter: findTaskFilterInput!) {
    ...qrCodeScreenTaskQueryTaskFragment
      @arguments(taskFilter: $taskFilter, sort: null, count: 1)
  }
`;

export const qrCodeScreenTaskQueryTaskFragment = graphql`
  fragment qrCodeScreenTaskQueryTaskFragment on Query
  @inline
  @refetchable(queryName: "qrCodeScreenTaskFragmentQuery")
  @argumentDefinitions(
    taskFilter: { type: "findTaskFilterInput!" }
    sort: { type: "SortConnectiontaskEnum" }
    count: { type: "Int" }
    cursor: { type: "String" }
  ) {
    findTasks: findTasksV2(
      filter: $taskFilter
      sort: $sort
      first: $count
      after: $cursor
    ) @connection(key: "qrCodeScreenTaskQueryTaskFragment__findTasks") {
      edges {
        node {
          _id
          vehicle {
            brand {
              name
              brandLogo
            }
            registration {
              plate
            }
            color
          }
          taskType
          taskStartLocation {
            locationId
            name
            parkingSpaces {
              floor {
                floorName
                floorId
                bay {
                  bayId
                  bayName
                }
              }
            }
          }
          taskEndLocation {
            locationId
            name
            parkingSpaces {
              floor {
                floorName
                floorId
                bay {
                  bayId
                  bayName
                }
              }
            }
          }
          campus {
            _id
            name
          }
          history {
            _id
            action
            actor
            event_at
          }
          assignedTo
          valet {
            _id
            name {
              first
              last
            }
            identityCode
            contact {
              phone {
                prefix
                number
              }
            }
          }
          user {
            name {
              first
              last
            }
            contact {
              phone {
                prefix
                number
              }
            }
          }
          ETA {
            _id
            startAt
            delayAt
            originalPredictionTime
            pendingRecall
            available
            busy
          }
          vehicleId
          ownerId
          parkingId
          parkingTag
          createdBy {
            status
          }
        }
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`;

const fetchQrCodeScreenTaskQuery = (data) => {
  const variables = { taskFilter: data };
  const request = fetchQuery(environment, qrCodeScreenTaskQuery, variables);
  return request.toPromise();
};

export default fetchQrCodeScreenTaskQuery;
