/* eslint-disable react/no-array-index-key, no-nested-ternary */
import React from 'react';
import { StyleSheet } from 'react-native';
import { FlatList } from 'react-native-gesture-handler';
import { View, Text, Card, Button, Icon } from '../../../components/new';
import theme from '../../../themes/new/theme';
import useParkingOverview from './hooks/useParkingOverview';
import DayPicker from './components/DayPicker';
import SearchParkingList from '../searchParkingList';
import OverviewTaskFilterWidget from './components/OverviewTaskFilterWidget';
import { taskType } from './constants';
import MetricsWidget from './components/MetricsWidget';
import useTaskFilter from './hooks/useTaskFilter';
import TaskInfoCard from './components/TaskInfoCard';
import { TaskDataType } from './types';

const ParkingOverviewScreenInner = ({ queryOptions }) => {
  const defaultTaskType = taskType.RECALL;
  const {
    state,
    isToday,
    tasksQueryFragment,
    parkingLocationQueryFragment,
    handleDaySelection,
    navigateToParkingOverviewScreen,
  } = useParkingOverview(queryOptions, defaultTaskType);

  const { selectedDate, dayPickerData, metricsWidgetData } = state;

  const {
    filteredTasks,
    selectedTaskFilter,
    selectedTaskType,
    noTasksMessage,
    hasMoreTasks,
    fetchMoreTasks,
    handleTaskfilterSelection,
    handleTaskTypeSelection,
  } = useTaskFilter(selectedDate, tasksQueryFragment, defaultTaskType, isToday);

  return (
    <>
      <View py="4xl" px="2xl">
        <Text
          size="3xl"
          color="primary.400"
          fontFamily={theme.fonts.headingMedium}
        >
          Overview
        </Text>
      </View>
      {/* DayPicker section */}
      {dayPickerData && (
        <DayPicker
          days={dayPickerData}
          selectedDate={selectedDate}
          onSelect={(date: string) => {
            handleDaySelection(date);
          }}
        />
      )}
      <FlatList
        data={filteredTasks}
        onEndReached={() => {
          if (hasMoreTasks) fetchMoreTasks();
        }}
        ListHeaderComponent={
          <>
            {metricsWidgetData && (
              <MetricsWidget metricsWidgetData={metricsWidgetData} />
            )}
            {isToday && (
              <View px="2xl" mb="2xl">
                <Card pt="4xl">
                  <SearchParkingList
                    findParkingLocation={parkingLocationQueryFragment}
                  />
                  <Button
                    appearance="ghost"
                    size="small"
                    status="info"
                    onPress={navigateToParkingOverviewScreen}
                    accessoryRight={() => (
                      <Icon
                        name="arrow-forward"
                        style={styles.icon}
                        size="md"
                        color="primary.10"
                      />
                    )}
                  >
                    Parking Overview
                  </Button>
                </Card>
              </View>
            )}
            <OverviewTaskFilterWidget
              taskType={selectedTaskType}
              taskFilter={selectedTaskFilter}
              onTaskFilterSelect={handleTaskfilterSelection}
              onTaskTypeSelect={handleTaskTypeSelection}
            />
          </>
        }
        ListEmptyComponent={
          <View height="40" alignItems="center" justifyContent="center">
            <Text color="primary.100">{noTasksMessage}</Text>
          </View>
        }
        renderItem={({
          item: task,
          index,
        }: {
          item: TaskDataType;
          index: any;
        }) => (
          <View key={index} px="2xl">
            <TaskInfoCard {...task} />
          </View>
        )}
      />
    </>
  );
};

export default ParkingOverviewScreenInner;

const styles = StyleSheet.create({
  icon: {
    height: 18,
    width: 18,
    marginLeft: -8,
    textAlign: 'center',
  },
});
