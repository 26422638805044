import { graphql } from 'react-relay';

/* eslint-disable import/prefer-default-export */

export const cashierHomeScreenQuery = graphql`
  query cashierHomeScreenQuery($taskFilter: findTaskFilterInput!) {
    ...cashierHomeScreenQueryTaskFragment
      @arguments(taskFilter: $taskFilter, sort: null, count: 10)
  }
`;

export const cashierHomeScreenQueryTaskFragment = graphql`
  fragment cashierHomeScreenQueryTaskFragment on Query
  @refetchable(queryName: "cashierHomeScreenTaskFragmentQuery")
  @argumentDefinitions(
    taskFilter: { type: "findTaskFilterInput!" }
    sort: { type: "SortConnectiontaskEnum" }
    count: { type: "Int" }
    cursor: { type: "String" }
  ) {
    findTasks: findTasksV2(
      filter: $taskFilter
      sort: $sort
      first: $count
      after: $cursor
    ) @connection(key: "cashierHomeScreenQueryTaskFragment__findTasks") {
      edges {
        node {
          _id
          vehicle {
            registration {
              plate
            }
          }
          taskType
          campus {
            _id
          }
          history {
            _id
            action
            actor
            event_at
          }
          isVehicleWhitelisted
          ETA {
            _id
            startAt
            delayAt
            originalPredictionTime
            pendingRecall
            available
            busy
          }
          parking {
            status
            parkingInvoice {
              timings {
                entryTime
                exitTime
                chargeTime
              }
              paymentDetails {
                isPaid
                mode
                focReason
                amount
              }
              charges {
                chargesBreakup {
                  duration
                  payable
                }
                discountAmount
                totalPayable
              }
            }
          }
          parkingId
        }
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`;
