import React, { useRef, useState, isValidElement, useEffect } from 'react';
import { useWindowDimensions, Platform, AppState } from 'react-native';
import Carousel from 'react-native-reanimated-carousel';
import { ResizeMode, Video } from 'expo-av';
import View from '../basic/View/View';
import Text from '../primitive/Typography/Text/Text';
import Pressable from '../basic/Pressable/Pressable';
import StarRating from '../composites/StarRating/StarRating';
import { StarRatingVariants } from '../composites/StarRating/helpers/helpers';
import theme, { getTheme } from '../../../themes/new/theme';
import {
  discountAndWalletTextMaker,
  dynamicHeightMaker,
  imageTransformation,
  mediaTypes,
} from '../../../utilities/helper';
import GradientBackground from './GradientBackground';
import CachedImage from './CachedImage';
import CachedImageBackground from './CachedImageBackground';
import Placeholder from './Placeholder/Placeholder';

const { space } = getTheme();
interface DetailCardProps {
  id: string;
  displayName: string;
  discountPercentage: number;
  currentBalance: number;
  tagLine: string[] | JSX.Element;
  ratingScore: number;
  coverImages: { _id: string; mediaURL: string; mediaType: mediaTypes }[];
  payBillCashback: number;
  pointsEnabled: { redeem: boolean; reward: boolean };
  statusTag?: { status: StatusType; label: string } | JSX.Element;
  onPress: (...args: any) => void;
  viewableItem?: string;
  coverVideo?: { _id: string; mediaURL: string; mediaType: mediaTypes };
  billUploadCashback: number;
}

const DetailCard = ({
  id,
  displayName,
  discountPercentage,
  currentBalance,
  statusTag,
  tagLine,
  ratingScore,
  coverImages,
  payBillCashback,
  pointsEnabled,
  onPress,
  viewableItem,
  coverVideo,
  billUploadCashback,
}: DetailCardProps) => {
  const { width } = useWindowDimensions();
  const widthProp = width - getTheme().space['7xl'];
  const carouselRef = useRef(null);

  const videoRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const gradientColors = [
    { offset: '0', color: theme.colors.primary[500], opacity: 0 },
    { offset: '1', color: theme.colors.primary[500], opacity: 1 },
  ];

  useEffect(() => {
    if (id === viewableItem && AppState.currentState === 'active') {
      videoRef?.current?.playAsync();
    } else {
      videoRef?.current?.pauseAsync();
    }
  }, [viewableItem, AppState.currentState]);

  return (
    <Pressable
      onPress={onPress}
      style={{
        borderRadius: getTheme().radii.xl,
        overflow: 'hidden',
      }}
    >
      {isValidElement(statusTag) && statusTag}
      {!statusTag && pointsEnabled?.redeem && pointsEnabled?.reward ? (
        <StatusTagContainer>
          <CachedImage
            source={require('../../../../assets/images/black_membership/black-member-logo-2.webp')}
            style={{
              width: width / 2.5 - getTheme().space['7xl'],
              height: 16,
              resizeMode: 'contain',
            }}
          />
        </StatusTagContainer>
      ) : (
        <></>
      )}

      {coverVideo &&
      Object.keys(coverVideo).length &&
      coverVideo.mediaType === mediaTypes.video &&
      Platform.OS !== 'web' ? (
        <>
          <Video
            id={coverVideo?._id}
            ref={videoRef}
            source={{ uri: coverVideo?.mediaURL }}
            videoStyle={{
              width: widthProp,
              height: dynamicHeightMaker(widthProp, 0.8),
              backgroundColor: 'transparent',
              position: 'relative',
            }}
            style={{
              width: widthProp,
              height: dynamicHeightMaker(widthProp, 0.8),
              backgroundColor: 'transparent',
              position: 'relative',
            }}
            resizeMode={ResizeMode.COVER}
            rate={1}
            key={coverVideo?._id}
            isLooping
            isMuted
          />
          <View position="absolute" bottom={0} flex={1} overflow="hidden">
            <GradientBackground
              backgroundOpacity={0.6}
              gradientColors={gradientColors}
              width={widthProp}
              height={dynamicHeightMaker(widthProp, 0.8)}
              id={id}
            />
          </View>
        </>
      ) : Platform.OS === 'web' ? (
        <>
          <CachedImage
            id={coverImages[0]?._id}
            source={{
              uri: imageTransformation(coverImages[0]?.mediaURL, widthProp),
            }}
            style={{
              width: widthProp,
              height: dynamicHeightMaker(widthProp, 0.8),
              resizeMode: 'cover',
            }}
          />
          <View position="absolute" bottom={0} flex={1} overflow="hidden">
            <GradientBackground
              backgroundOpacity={0.6}
              gradientColors={gradientColors}
              width={widthProp}
              height={dynamicHeightMaker(widthProp, 0.8)}
              id={id}
            />
          </View>
        </>
      ) : (
        <Carousel
          ref={carouselRef}
          width={widthProp}
          height={dynamicHeightMaker(widthProp, 0.8)}
          data={coverImages}
          enabled={coverImages?.length > 1}
          onSnapToItem={(index) => {
            setActiveIndex(index);
          }}
          autoPlay={coverImages?.length > 1 && viewableItem === id}
          scrollAnimationDuration={200}
          autoPlayInterval={3000}
          panGestureHandlerProps={{
            activeOffsetX: [-5, 5],
            failOffset: [-5, 5],
          }}
          onConfigurePanGesture={(gestureChain) => {
            gestureChain.activeOffsetX([-10, 10]);
          }}
          renderItem={({ index, item }) => (
            <>
              <CachedImage
                id={item?._id}
                source={{
                  uri: imageTransformation(item?.mediaURL, widthProp),
                }}
                style={{
                  width: widthProp,
                  height: dynamicHeightMaker(widthProp, 0.8),
                  resizeMode: 'cover',
                }}
              />
              <View position="absolute" bottom={0} flex={1} overflow="hidden">
                <GradientBackground
                  backgroundOpacity={0.6}
                  gradientColors={gradientColors}
                  width={widthProp}
                  height={dynamicHeightMaker(widthProp, 0.8)}
                  id={id}
                />
              </View>
            </>
          )}
          overscrollEnabled={false}
          vertical={false}
          initialNumToRender={2}
          windowSize={3}
          maxToRenderPerBatch={2}
          // minScrollDistancePerSwipe={widthProp / 3}
        />
      )}
      <View
        style={{
          position: 'absolute',
          bottom: 0,
          zIndex: 2,
          width: '100%',
        }}
      >
        <View mb="2xl" px="2xl">
          <View
            flexDirection="row"
            alignItems="flex-start"
            justifyContent="space-between"
          >
            <View flex={0.8}>
              <Text
                size="xl"
                color="primary.500"
                weight="medium"
                numberOfLines={2}
              >
                {displayName}
              </Text>
            </View>
            {ratingScore > 0 && (
              <StarRating
                ratingScore={ratingScore}
                size={StarRatingVariants.md}
              />
            )}
          </View>
          {Array.isArray(tagLine) && tagLine?.length > 0 && (
            <View mt="xs" flexDirection="row" alignItems="center">
              {tagLine.map(
                (ele, ind) =>
                  ind < 3 && (
                    <Text
                      // eslint-disable-next-line react/no-array-index-key
                      key={`${ind} ${ele}`}
                      size="sm"
                      color="primary.400"
                    >
                      {ele}
                      {ind < 2 && ind < tagLine.length - 1 ? ', ' : ''}
                    </Text>
                  ),
              )}
            </View>
          )}
          {isValidElement(tagLine) && tagLine}
        </View>
        {(currentBalance || discountPercentage || payBillCashback) &&
        pointsEnabled?.redeem &&
        pointsEnabled?.reward ? (
          <CachedImageBackground
            source={require('../../../../assets/images/gradient.png')}
            style={{
              width: '100%',
              justifyContent: 'center',
              transform: [{ translateY: Platform.OS === 'ios' ? 1 : 0 }],
              overflow: 'hidden',
              borderBottomLeftRadius: getTheme().radii.xl,
              borderBottomRightRadius: getTheme().radii.xl,
            }}
            imageStyle={{
              resizeMode: 'cover',
            }}
          >
            <Text
              size="xs"
              color="static.gold"
              px="2xl"
              py="xl"
              weight="medium"
            >
              {discountAndWalletTextMaker(
                currentBalance,
                discountPercentage,
                payBillCashback,
              )}
            </Text>
          </CachedImageBackground>
        ) : billUploadCashback &&
          !pointsEnabled?.redeem &&
          !pointsEnabled?.reward ? (
          <CachedImageBackground
            source={require('../../../../assets/images/gradient.png')}
            style={{
              width: '100%',
              justifyContent: 'center',
              transform: [{ translateY: Platform.OS === 'ios' ? 1 : 0 }],
              overflow: 'hidden',
              borderBottomLeftRadius: getTheme().radii.xl,
              borderBottomRightRadius: getTheme().radii.xl,
            }}
            imageStyle={{
              resizeMode: 'cover',
            }}
          >
            <Text
              size="xs"
              color="static.gold"
              px="2xl"
              py="xl"
              weight="medium"
            >
              {`${billUploadCashback}% Cashback`}
            </Text>
          </CachedImageBackground>
        ) : (
          <></>
        )}
      </View>
    </Pressable>
  );
};

const StatusTagContainer = ({ children, bg = 'opacity.90' }) => {
  return (
    <View position="absolute" top={0} zIndex={20} overflow="hidden">
      <View bg={bg} borderBottomRightRadius="xl" px="lg" py="sm">
        {children}
      </View>
    </View>
  );
};

export const DetailCardPlaceholder = ({ width, withRating = false }) => {
  const AR = 0.8;
  const height = AR * width;

  return (
    <Placeholder.BaseView
      width={width}
      height={height}
      borderRadius="xl"
      overflow="hidden"
    >
      <View flex={1}>
        <View style={{ width: '100%', height: '100%' }} />
        <View
          px="2xl"
          pb="2xl"
          bottom={0}
          position="absolute"
          flexDirection="row"
          width="100%"
        >
          <View>
            <Placeholder.Line width={150} mb="2xl" />
            <Placeholder.Line width={80} mb="md" />
          </View>
          {/* Rating */}
          {withRating && (
            <View flex={1} alignItems="flex-end">
              <Placeholder.Block width={56} height={36} borderRadius="md" />
            </View>
          )}
        </View>
      </View>
      <Placeholder.Line height={42} borderRadius={0} />
    </Placeholder.BaseView>
  );
};

export default DetailCard;
