/* eslint-disable camelcase */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  FlatList,
  Linking,
  Platform,
  Share,
  useWindowDimensions,
} from 'react-native';
import Constants from 'expo-constants';
import {
  useFocusEffect,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import * as Sharing from 'expo-sharing';
import { debounce } from 'lodash';
import { useActionSheet } from '@expo/react-native-action-sheet';
import { ActionSheetRef } from 'react-native-actions-sheet';
import { useLazyLoadQuery } from 'react-relay';
import { StatusBar } from 'expo-status-bar';
import moment from 'moment';
import {
  IconButton,
  TopNavigation,
  View,
  Button,
  Text,
  Pressable,
  Modal,
} from '../../../../components/new';
import DarkThemeWrapper from '../../../../screens/new/wrapperComp/DarkThemeWrapper';
import {
  INVESTMENTKEYS,
  INVESTMENT_APPLICATION_STATUS,
  navigateBack,
} from '../../../../utilities/helper';
import InvestmentHighlightsComp from './InvestmentHighlightsComp';
import VideoFrame from './VideoFrame';
import HorizontalFrame from './HorizontalFrame';
import InvestmentLocation from './InvestmentLocation';
import NearBy from './NearBy';
import InvestmentPhotoGrid from './InvestmentPhotoGrid';
import Tracker from './Tracker';
import InvestArticle from './InvestArticle';
import VideoSlider from './VideoSlider';
import InvestmentCalculator from './InvestmentCalculator';
import InvestmentSubscriptionDetails from './InvestmentSubscriptionDetails';
import RoiDetails from './RoiDetails';
import OfferDetails from './OfferDetails';
import InterestRegistered from './InterestRegistered';
import useAuthStore from '../../../../stores/authStore';
import useLoginModalStore from '../../../../stores/loginModalStore';
import useUserStore from '../../../../stores/userStore';
import { colors, space } from '../../../../themes/new/theme';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../../../../components/new/primitive/snackbar/helpers/helpers';
import { useSnackbarStore } from '../../../../stores/snackbar/snackbarStore';
import findTypeFormResponsesApi from '../../../../relay/findTypeFormResponsesApi';
import { firebaseEventLogger } from '../../../../utilities/firbaseAnalytics';
import FacebookPixelLogger from '../../../../utilities/FacebookPixelLogger';
import {
  DetailsPageQuery,
  GenerateInvestmentLead,
} from '../API/DetailsPageQuery';
import BottomSheetV2 from '../../../../components/new/composites/BottomSheet/BottomSheetV2';
import InvestmentListDataComp from './InvestmentListDataComp';
import UnlockSliderComponent from '../../../../components/new/UnlockSliderComponent';
import InvestPartners from './InvestPartners';
import InvestmentFileComponent from './InvestmentFileComponent';
import { State } from '../../../../themes/new/helper';
import { isValueNullOrEmpty } from '../../../../utilities/Utility';
import InvestmentSubscriptionComponents from './InvestmentSubscriptionComponents';
import InvestmentApplicationComp from './InvestmentApplicationComp';
import InvestmentPromise from './InvestmentPromise';
import InvestmentComparisonComp from './InvestmentComparisonComp';
import InvestmentCitySelection from './InvestmentCitySelection';
import { modifyStructuredData } from '../../../../utilities/InvestmentConstants';
import { AppTheme } from '../../../../stores/commonStore';
import InvestmentRecords from './InvestmentRecords';
import InvestmentAssociates from './InvestmentAssociates';
import InvestmentPartners from './InvestmentPartners';

const DetailsPage = ({
  propertyId,
  queryOptions,
  refresh,
  propertyNotFound,
}) => {
  const route = useRoute();
  const { width } = useWindowDimensions();
  const bottomSheetModalRef = useRef<ActionSheetRef>(null);
  const flatListRef = useRef();
  const params = route?.params;
  const utm_source = params?.utm_source ?? '';
  const utm_medium = params?.utm_medium ?? '';
  const utm_campaign = params?.utm_campaign ?? '';
  const utm_id = params?.utm_id ?? '';
  const _id = params?.propertyId ?? propertyId;
  const insets = useSafeAreaInsets();
  const navigation = useNavigation();
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  const { dispatchSnackbar } = useSnackbarStore((state) => state);
  const { contact } = useUserStore((state) => state);
  const firstName = useUserStore((state) => state?.firstName);
  const lastName = useUserStore((state) => state?.lastName);
  const userId = useUserStore((state) => state?.id);
  const investmentPaymentReceived = useUserStore(
    (state) => state?.investmentPaymentReceived,
  );
  const setInvestmentPaymentReceived = useUserStore(
    (state) => state?.setInvestmentPaymentReceived,
  );
  const userName = `${firstName}${lastName ? ` ${lastName}` : ''}`;
  const phoneNumber = contact?.phone?.number ?? '';
  let isOverApplied = false;
  const setAuthModalOpen = useLoginModalStore((state) => state.setIsOpen);
  const setCheckHomePageInvestmentLead = useUserStore(
    (state) => state?.setCheckHomePageInvestmentLead,
  );
  const setLoginScreenTitle = useLoginModalStore(
    (state) => state.setLoginScreenTitle,
  );
  const setSignUpScreenTitle = useLoginModalStore(
    (state) => state.setSignUpScreenTitle,
  );
  const setAuthModalTheme = useLoginModalStore(
    (state) => state.setAuthModalTheme,
  );
  const [interested, setInterested] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [roiData, setRoiData] = useState(null);
  const actionSheetOptions = ['Copy To Clipboard', 'Cancel'];
  const { showActionSheetWithOptions } = useActionSheet();
  const [property, setProperty] = useState(null);
  const [investmentData, setInvestmentData] = useState([]);
  const [region, setRegion] = useState('');
  const [showApply, setShowApply] = useState(false);
  const [citySelection, setCitySelection] = useState(false);
  const [showLeadGenLoading, setShowLeadGenLoading] = useState(false);
  const [leadRecord, setLeadRecord] = useState(null);
  const [showConsentSuccess, setShowConsentSuccess] = useState(false);
  const [ModalVisible, setModalVisible] = useState(false);
  const [variables, setVariables] = useState({
    _id,
    filter: {
      investmentPropertyId: _id,
    },
    sort: '_ID_DESC',
    includeFindInvLead: isLoggedIn,
  });

  useEffect(() => {
    setVariables({
      _id,
      filter: {
        investmentPropertyId: _id,
      },
      sort: '_ID_DESC',
      includeFindInvLead: isLoggedIn,
    });
  }, [isLoggedIn]);

  const DetailsPageRef = useLazyLoadQuery(
    DetailsPageQuery,
    variables,
    queryOptions,
  );

  const isFractionalOwnership =
    DetailsPageRef?.findInvestmentProperty?.investmentType ===
    INVESTMENTKEYS.FRACTIONAL_OWNERSHIP;

  const updateApiErrorHandlerInvestment = (err) => {
    setShowLeadGenLoading(false);
    setRegion('');
    dispatchSnackbar({
      msg: err?.message ?? 'Something went wrong!',
      status: SnackbarStatus.error,
      version: SnackbarVersion.v2,
    });
    firebaseEventLogger('PropertyDetails__leadGenError_Data', {
      buttonName: 'leadGenError',
      screenName: 'PropertyDetails',
      userType: 'user',
      interactionType: 'data',
      isLoggedIn,
      propertyId: _id,
      error: err?.message,
    });
  };

  useFocusEffect(
    React.useCallback(() => {
      if (investmentPaymentReceived) {
        refresh();
        setInvestmentPaymentReceived(false);
        setTimeout(() => {
          flatListRef?.current?.scrollToOffset({
            animated: true,
            offset: 0,
          });
        }, 1000);
        firebaseEventLogger('PropertyDetails__paymentSuccess_Data', {
          screenName: 'paymentSuccess',
          userType: 'user',
          interactionType: 'data',
          isLoggedIn,
          propertyId,
        });
      }
    }, [investmentPaymentReceived]),
  );

  useEffect(() => {
    if (DetailsPageRef?.findInvestmentProperty) {
      const response = DetailsPageRef;
      const findInvestmentLead = response?.findInvestmentLead?.[0];
      let showApplication = false;
      firebaseEventLogger('PropertyDetails__propertyDetails_Landing', {
        screenName: 'PropertyDetails',
        userType: 'user',
        interactionType: 'landing',
        isLoggedIn,
        propertyId,
        propertyName: response?.findInvestmentProperty?.about?.name,
      });
      if (isFractionalOwnership) {
        const investment_data =
          response?.findInvestmentProperty?.investment?.data;
        if (investment_data?.investment) {
          isOverApplied =
            investment_data?.investment?.data?.application?.applied >
            investment_data?.investment?.units?.totalUnits;
        }
        if (
          isValueNullOrEmpty(findInvestmentLead?.status) ||
          findInvestmentLead?.status ===
            INVESTMENT_APPLICATION_STATUS.REFUND_PROCESSED ||
          findInvestmentLead?.status === INVESTMENT_APPLICATION_STATUS.CREATED
        ) {
          showApplication = true;
          setShowApply(true);
        } else {
          showApplication = false;
          setShowApply(false);
        }
        if (findInvestmentLead) {
          firebaseEventLogger('PropertyDetails__applicationStatus_Data', {
            buttonName: 'applicationStatus',
            screenName: 'PropertyDetails',
            userType: 'user',
            interactionType: 'data',
            isLoggedIn,
            propertyId: _id,
            status: findInvestmentLead?.status,
          });
          setLeadRecord(findInvestmentLead);
        }
      }
      const res = [...modifyStructuredData(response, showApplication)];
      const filteredData =
        response?.findInvestmentProperty?.investmentType ===
        INVESTMENTKEYS.FRACTIONAL_OWNERSHIP
          ? res?.filter((val) => val?.isActive)
          : res?.filter((item) => !item?.isFractional && item?.isActive);
      setInvestmentData(filteredData);
      const projectData = {
        formId: response?.findInvestmentProperty?.about?.typeformId,
        title: response?.findInvestmentProperty?.about?.name,
      };
      setProperty(projectData);
      if (isLoggedIn && projectData?.formId && !isFractionalOwnership) {
        checkTypeFormConfig(projectData);
      }
    } else {
      propertyNotFound();
    }
  }, [DetailsPageRef]);

  const checkSubmitted = (res, projectData) => {
    if (projectData?.formId === res?.formId) {
      const newObj = { ...projectData };
      let fillingBeforeOneMonth = true;
      if (res?.submittedAt) {
        const currentDate = new Date();
        const typeFormDate = new Date(res.submittedAt);
        const miliseconds = currentDate - typeFormDate;
        const days = miliseconds / 1000 / 60 / 60 / 24;
        if (days > 30) {
          fillingBeforeOneMonth = false;
        } else {
          fillingBeforeOneMonth = true;
        }
      }
      newObj.isInterestRegistered = fillingBeforeOneMonth;
      setProperty(newObj);
      navigateSurvey(newObj);
    }
  };

  const checkTypeFormConfig = (projectData) => {
    if (projectData?.formId) {
      const ele = {
        typeFormfilter: {
          formId: projectData?.formId,
        },
        typeFormLimit: 1,
        typeFormSort: 'submittedAt_DESC',
      };
      findTypeFormResponsesApi(ele)
        .then(({ findTypeFormResponses }) => {
          if (findTypeFormResponses?.length) {
            checkSubmitted(findTypeFormResponses[0], projectData);
          } else {
            navigateSurvey(projectData);
          }
        })
        .catch((error) => {
          console.log(error);
          navigateSurvey(projectData);
        });
    }
  };

  useEffect(() => {
    if (
      showConsentSuccess &&
      investmentData &&
      isLoggedIn &&
      isFractionalOwnership
    ) {
      setTimeout(() => {
        flatListRef?.current?.scrollToIndex({
          animated: true,
          index: investmentData?.find(
            (i) => i?.component === INVESTMENTKEYS.APPLICATION,
          )
            ? 2
            : 1,
        });
      }, 1000);
      setShowConsentSuccess(false);
    }
  }, [investmentData, showConsentSuccess]);

  useEffect(() => {
    if (isLoggedIn && property?.formId && !isFractionalOwnership) {
      checkTypeFormConfig(property);
    }
  }, [interested, isLoggedIn]);

  const navigateSurvey = (res) => {
    if (isLoggedIn && interested && !res?.isInterestRegistered) {
      callZapierApi();
      navigation.navigate('surveyScreen', {
        phoneNumber,
        TYPEFORMID: property?.formId,
        SURVEYID: _id,
        IsInvestment: true,
      });
      setInterested(false);
    }
  };

  const callZapierApi = async () => {
    const leadData = {
      name: userName,
      phoneNumber,
      userId,
      utm_source,
      utm_medium,
      utm_campaign,
      utm_id,
      propertyId: _id,
      propertyName: DetailsPageRef?.findInvestmentProperty?.about?.name,
      createdAt: moment().toISOString(),
    };
    await fetch(`https://hooks.zapier.com/hooks/catch/18310736/2b0rgoh/`, {
      method: 'POST',
      mode: 'no-cors',
      headers: {
        'Content-Type': 'application/json', // Set content type to JSON
      },
      body: JSON.stringify({
        leadData,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json(); // Parse response JSON if request was successful
        }
        throw new Error('Failed to call Zapier API');
      })
      // eslint-disable-next-line @typescript-eslint/no-shadow
      .then((data) => {
        console.log('Zapier API call successful:', data);
      })
      .catch((error) => {
        console.error('Error calling Zapier API:', error);
      });
  };

  const handelUpdateInvestmentLeadGen = (response) => {
    setCheckHomePageInvestmentLead(true);
    setShowLeadGenLoading(false);
    setRegion('');
    setLeadRecord(response?.generateInvestmentLead?.record);
    if (response?.generateInvestmentLead?.record) {
      navigation.navigate('InvestApplyComponent', {
        data: DetailsPageRef?.findInvestmentProperty,
        leadResponse: response?.generateInvestmentLead?.record,
        isOverApplied,
      });
      firebaseEventLogger('PropertyDetails__leadGenSuccess_Data', {
        buttonName: 'leadGenSuccess',
        screenName: 'PropertyDetails',
        userType: 'user',
        interactionType: 'data',
        isLoggedIn,
        propertyId: _id,
        leadId: response?.generateInvestmentLead?.record?._id,
      });
    } else {
      dispatchSnackbar({
        msg: 'Lead not created!',
        status: SnackbarStatus.error,
        version: SnackbarVersion.v2,
      });
      firebaseEventLogger('PropertyDetails__leadGenError_Data', {
        buttonName: 'leadGenError',
        screenName: 'PropertyDetails',
        userType: 'user',
        interactionType: 'data',
        isLoggedIn,
        propertyId: _id,
        error: response?.generateInvestmentLead?.error?.message,
      });
    }
  };

  const generateInvLeadGenApi = () => {
    firebaseEventLogger('PropertyDetails__generateLead_Data', {
      buttonName: 'generateLead',
      screenName: 'PropertyDetails',
      userType: 'user',
      interactionType: 'data',
      isLoggedIn,
      propertyId: _id,
      region,
    });
    setShowLeadGenLoading(true);
    // zapier lead creation for fractional
    callZapierApi();
    // investment lead creation for fractional
    const leadGenVariables = {
      record: {
        investmentPropertyId: _id,
        leadUTM: {
          source: utm_source,
          medium: utm_medium,
        },
        trackingCode: utm_id,
        investmentDetails: {
          region,
        },
      },
    };
    GenerateInvestmentLead(
      leadGenVariables,
      handelUpdateInvestmentLeadGen,
      updateApiErrorHandlerInvestment,
    );
  };

  const onContinueButtonPress = () => {
    FacebookPixelLogger('track', 'CompleteRegistration');
    firebaseEventLogger('PropertyDetails__interestedTap_Tap', {
      buttonName: 'interestedTap',
      screenName: 'PropertyDetails',
      userType: 'user',
      interactionType: 'tap',
      isLoggedIn,
      propertyId: _id,
    });
    if (isLoggedIn) {
      callZapierApi();
      navigation.navigate('surveyScreen', {
        phoneNumber,
        TYPEFORMID: property?.formId,
        SURVEYID: _id,
        IsInvestment: true,
      });
    } else {
      firebaseEventLogger('PropertyDetails__login_Tap', {
        buttonName: 'login',
        screenName: 'PropertyDetails',
        userType: 'user',
        interactionType: 'tap',
        propertyId: _id,
      });
      setAuthModalTheme('dark');
      setLoginScreenTitle('Register your interest');
      setSignUpScreenTitle('Provide your details');
      setAuthModalOpen(true);
      setInterested(true);
    }
  };

  const onUnlockButtonPress = (slider) => {
    if (isLoggedIn) {
      setTimeout(() => {
        if (slider) {
          firebaseEventLogger('PropertyDetails__consentSlider_Slider', {
            buttonName: 'unlockNow',
            screenName: 'PropertyDetails',
            userType: 'user',
            interactionType: 'tap',
            isLoggedIn,
            propertyId: _id,
          });
        } else {
          firebaseEventLogger('PropertyDetails__lockButton_Tap', {
            buttonName: 'lockButton',
            screenName: 'PropertyDetails',
            userType: 'user',
            interactionType: 'tap',
            isLoggedIn,
            propertyId: _id,
          });
        }
        bottomSheetModalRef?.current?.show();
      }, 100);
    } else {
      firebaseEventLogger('PropertyDetails__login_Tap', {
        buttonName: 'login',
        screenName: 'PropertyDetails',
        userType: 'user',
        interactionType: 'tap',
        propertyId: _id,
      });
      setAuthModalTheme('dark');
      setLoginScreenTitle('Register your interest');
      setSignUpScreenTitle('Provide your details');
      setAuthModalOpen(true);
      setShowConsentSuccess(true);
    }
  };

  const RenderItem = ({ item, index }) => {
    const bgColor =
      index % 2 === 0 ? 'background.primary.base' : 'background.secondary.base';
    switch (item?.component) {
      case INVESTMENTKEYS.APPLICATION:
        return (
          <InvestmentApplicationComp
            data={leadRecord}
            propertyData={item?.propertyData}
            bgColor={bgColor}
          />
        );
      case INVESTMENTKEYS.INTRO:
        const res = { ...item };
        res.width = width;
        return (
          <VideoFrame
            data={res}
            index={index}
            selectedIndex={selectedIndex}
            bgColor={bgColor}
          />
        );
      case INVESTMENTKEYS.OPPORTUNITY:
        return (
          <InvestmentListDataComp
            data={item}
            horizontal={false}
            bgColor={bgColor}
          />
        );
      case INVESTMENTKEYS.COMPAREINVESTMENTS:
        return (
          <InvestmentComparisonComp
            data={item}
            bgColor={bgColor}
            consentGiven={isLoggedIn}
            onPressUnlock={() => onUnlockButtonPress(false)}
          />
        );
      case INVESTMENTKEYS.SUBSCRIPTIONV2:
        return (
          <InvestmentSubscriptionComponents
            data={item}
            openOfferDetails={() => {
              firebaseEventLogger('PropertyDetails__offerDetails_Tap', {
                buttonName: 'offerDetails',
                screenName: 'PropertyDetails',
                userType: 'user',
                interactionType: 'tap',
                isLoggedIn,
                propertyId: _id,
              });
              setTimeout(() => {
                bottomSheetModalRef?.current?.show();
              }, 100);
            }}
            consentGiven={isLoggedIn}
            openCalculator={() =>
              isLoggedIn
                ? setModalVisible(!ModalVisible)
                : onUnlockButtonPress(false)
            }
            bgColor={bgColor}
            onPressUnlock={() => onUnlockButtonPress(false)}
          />
        );
      case INVESTMENTKEYS.SUBSCRIPTION:
        return (
          <InvestmentSubscriptionDetails
            data={item}
            applied={
              DetailsPageRef?.findInvestmentProperty?.investment?.data
                ?.application?.applied ?? 0
            }
            propertyViewCount={
              DetailsPageRef?.findInvestmentProperty?.propertyViewCount ?? 0
            }
            isOverApplied={isOverApplied}
            onPressUnlock={() => onUnlockButtonPress(false)}
            consentGiven={isLoggedIn}
            bgColor={bgColor}
          />
        );
      case INVESTMENTKEYS.INVESTMENTSRECORD:
        return <InvestmentRecords bgColor={bgColor} />;
      case INVESTMENTKEYS.INVESTMENTASSOCIATES:
        return (
          <InvestmentAssociates
            bgColor={bgColor}
            openLegacy={() => {
              navigation.navigate('InvestmentAssociatesJourney');
              firebaseEventLogger('PropertyDetails__viewLegacy_Tap', {
                buttonName: 'viewLegacy',
                screenName: 'PropertyDetails',
                userType: 'user',
                interactionType: 'tap',
                propertyId: _id,
              });
            }}
          />
        );
      case INVESTMENTKEYS.INVESTMENT_PARTNERS:
        return <InvestmentPartners bgColor={bgColor} />;
      case INVESTMENTKEYS.HIGHLIGHTS:
        return (
          <InvestmentHighlightsComp
            data={item}
            index={index}
            selectedIndex={selectedIndex}
            bgColor={bgColor}
          />
        );
      case INVESTMENTKEYS.GALLERY:
        return (
          <InvestmentPhotoGrid
            data={item}
            bgColor={bgColor}
            propertyId={_id}
            navigateToGallery={(ele) =>
              navigation.navigate('Gallery', {
                ...ele,
                title: property?.title,
              })
            }
          />
        );
      case INVESTMENTKEYS.CTA:
        return <HorizontalFrame data={item} bgColor={bgColor} />;
      case INVESTMENTKEYS.LOCATION:
        return <InvestmentLocation data={item} bgColor={bgColor} />;
      case INVESTMENTKEYS.NEAR_BY:
        return <NearBy data={item} bgColor={bgColor} />;
      case INVESTMENTKEYS.PROMISE:
        return <InvestmentPromise data={item} bgColor={bgColor} />;
      case INVESTMENTKEYS.AMENITIES:
        return (
          <VideoSlider
            data={item}
            selIndex={index}
            selectedIndex={selectedIndex}
            bgColor={bgColor}
          />
        );
      case INVESTMENTKEYS.ABOUT_US:
        return (
          <VideoFrame
            data={item}
            index={index}
            selectedIndex={selectedIndex}
            showPadding
            bgColor={bgColor}
          />
        );
      case INVESTMENTKEYS.PROCESS:
        return (
          <Tracker
            data={item}
            consentGiven={isLoggedIn}
            isFractionalOwnership={isFractionalOwnership}
            bgColor={bgColor}
            onPressUnlock={() => onUnlockButtonPress(false)}
          />
        );
      case INVESTMENTKEYS.PRESS:
        return <InvestArticle data={item} bgColor={bgColor} propertyId={_id} />;
      case INVESTMENTKEYS.PARTNERS:
        return <InvestPartners isDetails data={item} bgColor={bgColor} />;
      case INVESTMENTKEYS.DOCUMENTS:
        return (
          <InvestmentFileComponent
            data={item}
            bgColor={bgColor}
            propertyId={_id}
          />
        );

      default:
        return null;
    }
  };

  const updateSelectedDebounce = debounce((i) => {
    setSelectedIndex(i);
  }, 700);

  const handleViewableItemsChanged = useCallback(
    ({ viewableItems, changed, ...other }) => {
      const index = viewableItems[1]?.index || viewableItems[0]?.index;
      if (index !== undefined) {
        updateSelectedDebounce(index);
      } else if (!isFractionalOwnership) {
        updateSelectedDebounce(1);
      }
    },
    [],
  );

  const sharePlan = async () => {
    firebaseEventLogger('PropertyDetails__shareLink_Tap', {
      buttonName: 'shareLink',
      screenName: 'PropertyDetails',
      userType: 'user',
      interactionType: 'tap',
      propertyId: _id,
    });
    const baseURL =
      Constants?.expoConfig?.extra?.APP_ENV === 'production'
        ? 'https://club.32nd.com/'
        : 'https://staging.club.32nd.com/';
    const suffixUrl = `PropertyDetails?propertyId=${_id}&utm_source=share&utm_medium=link&utm_campaign=conversion`;
    const url = baseURL + suffixUrl;
    try {
      const options =
        Platform.OS === 'android'
          ? {
              url,
              message: `${url}`,
            }
          : { url };
      await Share.share(options);
    } catch (error) {
      if (Platform.OS === 'web' && !(await Sharing.isAvailableAsync())) {
        showActionSheetWithOptions(
          {
            options: actionSheetOptions,
            cancelButtonIndex: 1,
            // destructiveButtonIndex:3,
          },
          // eslint-disable-next-line @typescript-eslint/no-shadow
          (selectedIndex: number) => {
            switch (selectedIndex) {
              case 0:
                // Save
                // eslint-disable-next-line no-case-declarations
                const shareText = `${url}`;
                // eslint-disable-next-line no-case-declarations
                const dummyInput = document.createElement('input');
                document.body.appendChild(dummyInput);
                dummyInput.value = shareText;
                dummyInput.select();
                document.execCommand('copy');
                document.body.removeChild(dummyInput);
                dispatchSnackbar({
                  msg: 'Link copied to the clipboard',
                  status: SnackbarStatus.info,
                  version: SnackbarVersion.v2,
                });
                break;
              default:
                break;
              // something
            }
          },
        );
      }
    }
  };

  const handleOnDismiss = () => {
    setRoiData(null);
  };

  const redirectWebPage = () => {
    firebaseEventLogger('PropertyDetails__termsAndConditions_Tap', {
      buttonName: 'termsAndConditions',
      screenName: 'PropertyDetails',
      userType: 'user',
      interactionType: 'tap',
      isLoggedIn,
      propertyId: _id,
    });
    bottomSheetModalRef?.current?.hide();
    const url = 'https://club.32nd.com/t&c';
    if (Platform.OS === 'web') {
      Linking.openURL(url);
    } else {
      navigation?.navigate('CustomWebview', {
        title: 'Terms and Conditions',
        url,
      });
    }
  };

  const onApplyNow = () => {
    firebaseEventLogger('PropertyDetails__applyNow_Tap', {
      buttonName: 'applyNow',
      screenName: 'PropertyDetails',
      userType: 'user',
      interactionType: 'tap',
      isLoggedIn,
      propertyId: _id,
      status: leadRecord?.status ?? 'Not Created',
    });
    if (leadRecord?.status === INVESTMENT_APPLICATION_STATUS.CREATED) {
      navigation.navigate('InvestApplyComponent', {
        data: DetailsPageRef?.findInvestmentProperty,
        leadResponse: leadRecord,
        isOverApplied,
      });
    } else {
      setCitySelection(true);
      bottomSheetModalRef?.current?.show();
    }
  };

  return (
    <DarkThemeWrapper>
      <StatusBar style={AppTheme.LIGHT} />
      <View bg="background.primary.elevation" flex={1}>
        <TopNavigation
          title={property?.title}
          appearance="ghost"
          level="1"
          IconLeft={
            <IconButton
              name="back-outline-300"
              size="sm"
              appearance="ghost"
              iconColor="primary.500"
              onPress={() => navigateBack(navigation, 'user')}
            />
          }
          IconRight={
            <IconButton
              name="share-outline-400"
              size="sm"
              appearance="ghost"
              iconColor="primary.500"
              onPress={sharePlan}
            />
          }
        />
        <FlatList
          ref={flatListRef}
          data={investmentData}
          bounces={false}
          keyExtractor={(item) => `${item?.component}_${_id}`}
          directionalLockEnabled
          ListFooterComponent={
            <View bg="background.primary.elevation">
              <Pressable
                style={{
                  paddingTop: space['4xl'],
                  paddingBottom: space['9xl'],
                }}
                onPress={redirectWebPage}
              >
                <Text size="xs" px="2xl" color="primary.300" textAlign="center">
                  *Disclaimer: All numbers are estimates. Please read all
                  <Text color="info.500">{`${' '}Terms & Conditions${' '}`}</Text>
                  before investing
                </Text>
              </Pressable>
              {property?.isInterestRegistered ||
              (isFractionalOwnership && !isLoggedIn) ? null : (
                <View
                  height={isFractionalOwnership && isLoggedIn ? 100 : 70}
                  bg="background.primary.elevation"
                />
              )}
            </View>
          }
          onViewableItemsChanged={handleViewableItemsChanged}
          viewabilityConfig={{
            itemVisiblePercentThreshold: 50,
          }}
          contentContainerStyle={{
            backgroundColor: colors.static.black,
            top: -1,
          }}
          initialNumToRender={2}
          windowSize={5}
          maxToRenderPerBatch={5}
          renderItem={RenderItem}
          showsVerticalScrollIndicator={false}
        />
        {isFractionalOwnership && !isLoggedIn ? (
          Platform.OS === 'web' ? (
            <View
              bg="background.primary.elevation"
              p="2xl"
              pb={Platform.OS === 'web' ? '2xl' : insets.bottom}
            >
              <Button
                state={State.ACTIVE}
                status="primary"
                size="md"
                onPress={() => onUnlockButtonPress(true)}
              >
                Unlock Exclusive Details
              </Button>
            </View>
          ) : (
            <View bg="background.secondary.elevation" pb={insets.bottom}>
              <UnlockSliderComponent
                onUnlockButtonPress={() => onUnlockButtonPress(true)}
                containerWidth={width - 32}
                thumbIconName="lock-filled"
                thumbIconSize="sm"
                thumbIconColor="primary.200"
                containerBorderWidth={0}
                containerBg="#121212"
              />
            </View>
          )
        ) : isFractionalOwnership && showApply ? (
          <View
            bg="background.primary.base"
            width="100%"
            position="absolute"
            bottom={0}
          >
            {isOverApplied ? (
              <View px="2xl" py="lg">
                <Text color="primary.300" size="xs">
                  {isOverApplied
                    ? `⭐️️ This property was over applied by ${
                        DetailsPageRef?.findInvestmentProperty?.investment?.data
                          ?.application?.applied /
                        DetailsPageRef?.findInvestmentProperty?.investment?.data
                          ?.investment?.units?.totalUnits
                      }x`
                    : `⭐️️ Apply now and get flat 50% off on application fee!`}
                </Text>
              </View>
            ) : null}
            <View
              bg="background.primary.elevation"
              p="2xl"
              pb={
                Platform.OS === 'web' || Platform.OS === 'android'
                  ? '2xl'
                  : insets.bottom
              }
            >
              <Button
                state={showLeadGenLoading ? State.DISABLED : State.ACTIVE}
                status="primary"
                size="md"
                onPress={onApplyNow}
                loading={showLeadGenLoading}
              >
                Apply Now
              </Button>
            </View>
          </View>
        ) : property?.isInterestRegistered && !isFractionalOwnership ? (
          <InterestRegistered />
        ) : !isFractionalOwnership ? (
          <View
            bg="background.primary.elevation"
            width="100%"
            position="absolute"
            bottom={0}
            p="2xl"
            pb={
              Platform.OS === 'web' || Platform.OS === 'android'
                ? '2xl'
                : insets.bottom
            }
          >
            <Button
              state={property?.formId ? State.ACTIVE : State.DISABLED}
              status="primary"
              size="md"
              onPress={onContinueButtonPress}
            >
              I’m Interested
            </Button>
          </View>
        ) : null}
        <BottomSheetV2
          ref={bottomSheetModalRef}
          onClose={handleOnDismiss}
          insets
        >
          {citySelection ? (
            <InvestmentCitySelection
              region={region}
              selectCity={(val) => setRegion(val)}
              proceedSelection={() => {
                setCitySelection(false);
                generateInvLeadGenApi();
                bottomSheetModalRef?.current?.hide();
              }}
            />
          ) : roiData ? (
            <RoiDetails data={roiData} />
          ) : (
            <OfferDetails
              data={investmentData?.find(
                (val) => val?.component === INVESTMENTKEYS.SUBSCRIPTIONV2,
              )}
            />
          )}
        </BottomSheetV2>
        <Modal
          visible={ModalVisible}
          onBackdropPress={() => {
            // setModalVisible(!ModalVisible);
          }}
          onRequestClose={() => {
            setModalVisible(!ModalVisible);
          }}
        >
          <InvestmentCalculator
            data={{
              data: DetailsPageRef?.findInvestmentProperty?.investment?.data,
            }}
            openRoiDetails={(details) => {
              setModalVisible(!ModalVisible);
              setTimeout(() => {
                firebaseEventLogger('PropertyDetails__roiDetails_Tap', {
                  buttonName: 'roiDetails',
                  screenName: 'PropertyDetails',
                  userType: 'user',
                  interactionType: 'tap',
                  isLoggedIn,
                  propertyId: _id,
                });
                setRoiData(details);
                setTimeout(() => {
                  bottomSheetModalRef?.current?.show();
                }, 100);
              }, 500);
            }}
            onPressUnlock={() => {
              setModalVisible(!ModalVisible);
              setTimeout(() => {
                onUnlockButtonPress(false);
              }, 1000);
            }}
            handleOnPress={() => setModalVisible(!ModalVisible)}
            consentGiven={isLoggedIn}
            bgColor="background.secondary.base"
          />
        </Modal>
      </View>
    </DarkThemeWrapper>
  );
};

export default DetailsPage;
