import React, { useEffect, useState } from 'react';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { Modal, Text, View } from '../../../../../components/new';
import CouponBox from '../../CouponBox';
import { getSourceDetails } from '../../PaymentScreen';
import CouponApplied from '../../CouponApplied';
import useLoginModalStore from '../../../../../stores/loginModalStore';
import { usePayBillStore } from '../hooks/usePayBillStore';
import { GQLPayBillQueryVariables } from '../types';
import { paymentOrderTypeEnum } from '../../../../../utilities/helper';
import payBillAnalytics from '../analytics';

const CouponWidget = ({
  queryVariables,
  onApplyCoupon,
}: {
  queryVariables: GQLPayBillQueryVariables;
  onApplyCoupon: (couponCode: string) => void;
}) => {
  const navigation = useNavigation();
  const isFocused = useIsFocused();
  const [showCouponApplied, setShowCouponApplied] = useState(false);
  const { appliedCoupon, setAppliedCoupon } = useLoginModalStore(
    (state) => state,
  );
  const { title, orderId, orderType, rawQueryData, priceDetails } =
    usePayBillStore((state) => state);
  const { couponAmount, couponCashbackAmount } = priceDetails;
  const couponRes = rawQueryData?.getNetPrice?.extras?.coupons;

  const applyCoupon = () => {
    if (appliedCoupon?.couponCode) {
      onApplyCoupon(appliedCoupon?.couponCode);
      setShowCouponApplied(true);
    }
  };

  const handleRemoveCoupon = () => {
    payBillAnalytics.couponRemoved(title, orderType, orderId);
    setAppliedCoupon(null);
    onApplyCoupon(null);
  };

  const navigateToCouponScreen = () => {
    payBillAnalytics.allCouponsViewed(title, orderType, orderId);
    navigation.navigate('CouponsComponent', {
      refId: getSourceDetails(orderType, rawQueryData?.getNetPrice)?.refId,
      netPriceVariables: { ...queryVariables },
      orderType,
    });
  };

  useEffect(() => {
    if (isFocused) {
      applyCoupon();
    }
  }, [isFocused]);

  useEffect(() => {
    if (appliedCoupon?.couponCode) {
      const res = {
        couponCode: appliedCoupon?.couponCode,
        savedAmount: couponAmount ?? 0,
        cashbackAmount: couponCashbackAmount ?? 0,
      };
      setAppliedCoupon(res);
    }
  }, [couponAmount, couponCashbackAmount]);

  if (orderType === paymentOrderTypeEnum.INVESTMENT_LEAD) return null;

  return (
    <View mb="4xl">
      <Text size="md" color="primary.300" weight="medium" mb="2xl">
        Offers & Benefits
      </Text>
      <CouponBox
        appliedCoupon={appliedCoupon}
        couponResData={couponRes}
        removeCoupon={handleRemoveCoupon}
        handleCoupons={navigateToCouponScreen}
      />
      <Modal
        bg="blur"
        visible={showCouponApplied}
        onBackdropPress={() => {
          setShowCouponApplied(false);
        }}
        onRequestClose={() => {
          setShowCouponApplied(false);
        }}
        showBottomCloseButton
        handleClose={() => setShowCouponApplied(false)}
      >
        <CouponApplied
          appliedCoupon={appliedCoupon}
          closeModal={() => setShowCouponApplied(false)}
        />
      </Modal>
    </View>
  );
};

export default CouponWidget;
