import React, { useEffect, useState } from 'react';
import * as Linking from 'expo-linking';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Platform, StyleSheet, TouchableOpacity } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { Button, IconButton, Layout, Text, View } from '../new';
import NewOtpInput from '../new/primitive/NewOtpInput/NewOtpInput';
import { InputStates } from '../new/primitive/OTPInput/helpers';
import { firebaseEventLogger } from '../../utilities/firbaseAnalytics';
import { addUser, generateOTP, loginUser } from '../../relay/authApi';
import useLoginFlowStore from '../../stores/loginFlowStore';
import findCampusLocation from '../../relay/campusLocationApi';
import useUserStore from '../../stores/userStore';
import useAuthStore from '../../stores/authStore';
import useCommonStore from '../../stores/commonStore';
import CustomPressable from '../new/primitive/CustomPressable/CustomPressable';
import useLoginModalStore from '../../stores/loginModalStore';
import VideoMomentsStore from '../../stores/VideoMomentsStore';
import AppConstants from '../../utilities/AppConstants';
import { navigationRef } from '../../navigation/RootNavigation';

const EnterOtp = () => {
  const loginFlowStore = useLoginFlowStore((state) => state);
  const source = navigationRef?.current?.getCurrentRoute()?.name;
  const navigation = useNavigation();

  const [otpError, setOtpError] = useState('');
  const [resendOTPCount, setResendOTPCount] = useState(1);
  const [resendButtonTime, setResendButtonTime] = useState(30);
  const [otpCode, setOtpCode] = useState<string>('');
  const [prevOtpCode, setPrevOtpCode] = useState<string>('');
  const [isPinReady, setIsPinReady] = useState<boolean>(false);

  const handleSetOTPCode = (code: string) => {
    setPrevOtpCode(otpCode);
    setOtpCode(code);
  };

  const handlePinReady = (ready: boolean) => {
    setIsPinReady(ready);
    if (ready && prevOtpCode === '') {
      // auto detect otp
      submitOTP();
    }
  };

  const setUserDetails = useUserStore((state) => state.setUserDetails);
  const setLoginDetails = useAuthStore((state) => state.setLoginDetails);
  const setCampusId = useCommonStore((state) => state.setCampusId);
  const loginModalStore = useLoginModalStore((state) => state);
  const commonStore = useCommonStore((state) => state);
  const setScannedS3Imagekey = VideoMomentsStore(
    (state) => state.setScannedS3Imagekey,
  );

  const handleLoginUser = (response, error, data) => {
    firebaseEventLogger('otp__verifyOtp_Data', {
      buttonName: 'verifyOtp',
      screenName: 'otp',
      userType: 'user',
      interactionType: 'data',
      success: 'true',
      source,
    });
    if (response?.loginUser?.record?.photoMomentsSearchImage) {
      setScannedS3Imagekey(response.loginUser.record.photoMomentsSearchImage);
    }
    setUserDetails(
      response?.loginUser?.record?.name?.first,
      response?.loginUser?.record?.name?.last,
      response?.loginUser?.record?.role?.name,
      response?.loginUser?.record?.role?.permission,
      response?.loginUser?.record?._id,
      response?.loginUser?.record?.contact,
      response?.loginUser?.record?.role?.name,
      response?.loginUser?.record?.role?.secondaryRole,
      response?.loginUser?.record?.profileImage,
    );
    setLoginDetails(
      true,
      response?.loginUser?.record?.token,
      response?.loginUser?.record?.refreshToken,
    );
    if (response.loginUser !== null) {
      const { name, permission } = response.loginUser.record.role;
      const { first } = response.loginUser.record.name;
      const { token } = response.loginUser.record;
      const { refreshToken } = response.loginUser.record;
      const { _id } = response.loginUser.record;
      const campusID = response.loginUser.record.role.meta?.campus?._id;
      if (name !== 'admin') {
        const obj = {
          user: name,
          userToken: token,
          refreshToken,
          userId: _id,
          userName: first,
          permission,
        };
        if (name !== 'user') {
          if (campusID) {
            setCampusId(campusID);
          } else {
            setOtpError('User not associated to any campus.');
            return;
          }
        }
        if (name === 'user') {
          if (
            commonStore.wasSignupFlowActive &&
            Platform.OS !== 'web' &&
            source !== 'PropertyDetails'
          ) {
            loginModalStore.setShowWelcomeModal(true);
            commonStore.setWasSignupFlowActive(false);
          }
          findCampusLocation({})
            .then((res) => {
              setCampusId(res?.findCampusLocation[0]?._id);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      } else {
        setOtpError('Please login with correct user type.');
      }
    } else if (error !== null) {
      setOtpError(error[0].message);
    } else {
      setOtpError('Please enter correct OTP.');
    }
  };

  const handleAddUser = (response, error, data) => {
    if (response.addUser !== null) {
      loginUser(data, handleLoginUser, OTPErrorCallback);
    } else if (error !== null) {
      setOtpError(error[0].message);
    } else {
      setOtpError('Please enter correct OTP.');
    }
  };

  const OTPErrorCallback = (err) => {
    firebaseEventLogger('otp__verifyOtp_Data', {
      buttonName: 'verifyOtp',
      screenName: 'otp',
      userType: 'user',
      interactionType: 'data',
      success: 'false',
      source,
    });
    setOtpError('Entered OTP was wrong');
  };

  const handleReset = () => {
    startResendTimer();
    setOtpError('');
    setOtpCode('');
    setPrevOtpCode('');
  };

  const submitOTP = () => {
    firebaseEventLogger('otp__verifyOtp_Tap', {
      buttonName: 'verifyOtp',
      screenName: 'otp',
      userType: 'user',
      interactionType: 'tap',
    });
    const data = {
      phone: {
        prefix: `+${loginFlowStore.countryCallingCode}`,
        number: loginFlowStore.mobileNumber,
      },
      name: loginFlowStore?.signupData?.name || '',
      pin: '',
    };
    const otp = otpCode;
    if (otp.length === 4) {
      data.pin = otp;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (data?.name && data?.name?.first !== '') {
        if (loginFlowStore.secondarySignup) {
          loginUser(data, handleLoginUser, OTPErrorCallback);
        } else {
          addUser(data, handleAddUser, OTPErrorCallback);
        }
      } else {
        loginUser(data, handleLoginUser, OTPErrorCallback);
      }
    } else {
      setOtpError('Please enter correct OTP.');
    }
  };

  const startResendTimer = () => {
    setResendButtonTime(30);
    const interval = setInterval(() => {
      setResendButtonTime((val) => {
        if (val === 1) {
          clearInterval(interval);
        }
        return val - 1;
      });
    }, 1000);
  };

  useEffect(() => {
    startResendTimer();
  }, []);

  const whatsAppLogin = async () => {
    try {
      if (Platform.OS !== 'web') {
        const url = `https://32nd.authlink.me/?redirectUri=${AppConstants.SCHEME}://login`;
        await Linking.openURL(url);
      } else {
        const url = `https://32nd.authlink.me/?redirectUri=${AppConstants.WEB_URL}/login`;
        await Linking.openURL(url);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleOnBackPress = () => {
    if (commonStore.wasSignupFlowActive) {
      navigation.goBack();
    } else {
      navigation.navigate('enterPhone');
    }
  };

  return (
    <Layout level={2} style={styles.mainWrapper}>
      <SafeAreaView style={{ flex: 1 }} edges={['top', 'bottom']}>
        <View
          pb="2xl"
          pt="2xl"
          style={{
            paddingHorizontal: 16,
            borderBottomWidth: 1,
            borderBottomColor: '#d2d2d2',
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'center',
              position: 'relative',
            }}
          >
            <TouchableOpacity
              // @ts-ignore
              style={{
                position: 'absolute',
                left: 0,
                top: 0,
                transform: [{ translateY: -9 }],
              }}
              onPress={() => {}}
            >
              <IconButton
                name="back-outline-300"
                size="md"
                appearance="ghost"
                iconColor="primary.500"
                onPress={handleOnBackPress}
                shape={undefined}
                bg={undefined}
                state={undefined}
                iconSize={undefined}
                text={undefined}
              />
            </TouchableOpacity>
            <Text size="md" color="primary.400">
              32nd
            </Text>
          </View>
        </View>
        <View style={styles.container}>
          <View>
            <Text size="3xl" color="primary.200" mb="8" mt="24">
              Verify with OTP
            </Text>
            <Text size="sm" color="primary.200" mt="sm" mb="42">
              Enter the 4 digit OTP sent on{' '}
              <Text size="md" fontWeight="500" color="primary.400">
                +{loginFlowStore.countryCallingCode}-
                {loginFlowStore.mobileNumber}
              </Text>
            </Text>
            <NewOtpInput
              label="OTP"
              length={4}
              code={otpCode}
              setCode={handleSetOTPCode}
              setIsPinReady={handlePinReady}
              otpError={otpError}
              setOtpError={setOtpError}
            />
            <View style={{ marginTop: 40 }}>
              <Button
                size="lg"
                status="primary"
                state={!isPinReady ? InputStates.disabled : InputStates.active}
                onPress={submitOTP}
              >
                Verify
              </Button>
            </View>
            <View
              px="lg"
              py="lg"
              my="lg"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <View
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
              >
                <Text size="sm" color="primary.100">
                  {`Haven’t received a SMS? `}
                </Text>
                {resendButtonTime > 0 ? (
                  <Text status="primary" state="active" size="sm" py={8}>
                    Resend OTP{' '}
                    <Text size="sm">{`(00:${
                      resendButtonTime > 9
                        ? resendButtonTime
                        : `0${resendButtonTime}`
                    })`}</Text>
                  </Text>
                ) : (
                  <Button
                    status="info"
                    appearance="ghost"
                    state="active"
                    size="md"
                    onPress={() => {
                      const payload = {
                        phone: {
                          prefix: '+91',
                          number: loginFlowStore.mobileNumber,
                        },
                        resendOTPCount,
                      };
                      setResendOTPCount((prevState) => prevState + 1);
                      firebaseEventLogger('otp__resendOtp_Tap', {
                        buttonName: 'resendOtp',
                        screenName: 'otp',
                        userType: 'user',
                        interactionType: 'tap',
                      });
                      generateOTP(payload, (res) => {
                        handleReset();
                      });
                    }}
                  >
                    Resend OTP
                  </Button>
                )}
              </View>

              {Platform.OS === 'android' && (
                <>
                  <View
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                    mt="md"
                  >
                    <View width={85} height={1} backgroundColor="primary.50">
                      {}
                    </View>
                    <View mx="2xl">
                      <Text weight="medium" size="xs" color="primary.50">
                        OR
                      </Text>
                    </View>
                    <View width={85} height={1} backgroundColor="primary.50">
                      {}
                    </View>
                  </View>
                  <View
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <CustomPressable
                      appearance="ghost"
                      size="lg"
                      textSize="sm"
                      iconSize="sm"
                      leftStyle={{ width: 20, height: 20 }}
                      state="active"
                      status="primary"
                      leftContent={require('../../../assets/whatsapp.png')}
                      onPress={() => {
                        whatsAppLogin();
                      }}
                    >
                      Continue Via WhatsApp
                    </CustomPressable>
                  </View>
                </>
              )}
            </View>
          </View>
        </View>
      </SafeAreaView>
    </Layout>
  );
};

const styles = StyleSheet.create({
  mainWrapper: {
    flex: 1,
  },
  container: {
    paddingHorizontal: 16,
    flex: 1,
  },
});
export default EnterOtp;
