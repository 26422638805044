import React from 'react';
import { Card, Divider, Pressable, Text, View } from '../../../components/new';
import PaymentsImage from '../../../../assets/images/payments.webp';
import { paymentOrderTypeEnum, toRupees } from '../../../utilities/helper';
import { getTheme, themeModes } from '../../../themes/new/theme';
import CachedImage from '../../../components/new/custom/CachedImage';

const darkTheme = getTheme(themeModes.dark);

interface BillingCardProps {
  paid?: boolean;
  orderType: paymentOrderTypeEnum;
  appliedCoupon?: {
    savedAmount: number;
    couponCode: string;
  };
  discountPromo?: {
    text: string;
    cb: () => void;
  };
  priceDetails: {
    billAmount: number;
    netAmount: number;
    tipAmount?: number;
    charityAmount?: number;
    discountAmount?: number;
    usedPointsAmount?: number;
    blackMembershipAmount?: number;
  };
  onViewBreakUp?: (...args: any) => void;
  onEdit?: (...args) => void;
}

const BillingCard = ({
  orderType,
  paid = false,
  priceDetails,
  discountPromo,
  appliedCoupon,
  onEdit,
  onViewBreakUp,
}: BillingCardProps) => {
  const {
    billAmount = 0,
    netAmount = 0,
    tipAmount = 0,
    charityAmount = 0,
    discountAmount = 0,
    usedPointsAmount = 0,
    blackMembershipAmount = 0,
  } = priceDetails ?? {};

  return (
    <Card withBorder>
      <View
        flexDirection="row"
        mb="2xl"
        alignItems="center"
        justifyContent="space-between"
      >
        <View flexDirection="row" alignItems="center">
          <Text size="sm" color="primary.400">
            Bill Amount
          </Text>
          {typeof onViewBreakUp === 'function' && (
            <Pressable onPress={onViewBreakUp}>
              <Text ml="lg" size="xs" color="info.500">
                View breakup
              </Text>
            </Pressable>
          )}
          {typeof onEdit === 'function' && (
            <Pressable onPress={onEdit}>
              <Text ml="lg" size="xs" color="info.500">
                Edit
              </Text>
            </Pressable>
          )}
        </View>
        <Text size="sm" color="primary.400">
          ₹{billAmount}
        </Text>
      </View>
      {typeof discountPromo?.text === 'string' && (
        <View
          flexDirection="row"
          mb="2xl"
          alignItems="center"
          justifyContent="space-between"
        >
          <View flexDirection="row" alignItems="center">
            <Text size="sm" color="primary.400">
              32nd Plus discount
            </Text>
          </View>
          <Pressable onPress={() => discountPromo?.cb()}>
            <Text size="sm" color="info.500">
              {discountPromo?.text}
            </Text>
          </Pressable>
        </View>
      )}
      {!paid && blackMembershipAmount > 0 && (
        <View
          flexDirection="row"
          mb="2xl"
          alignItems="center"
          justifyContent="space-between"
        >
          <Text size="sm" color="primary.400">
            32nd Plus membership
          </Text>
          <View flexDirection="row">
            <Text
              mr="lg"
              size="sm"
              color={darkTheme.colors.primary[100]}
              style={{
                textDecorationLine: 'line-through',
                textDecorationStyle: 'solid',
              }}
            >
              1999
            </Text>
            <Text size="sm" color="primary.400">
              {toRupees(blackMembershipAmount)}
            </Text>
          </View>
        </View>
      )}
      {tipAmount > 0 && (
        <View
          flexDirection="row"
          mb="2xl"
          alignItems="center"
          justifyContent="space-between"
        >
          <Text size="sm" color="primary.400">
            {orderType === paymentOrderTypeEnum.PARKING ? 'Valet Tip' : 'Tip'}
          </Text>
          <Text size="sm" color="primary.400">
            {toRupees(tipAmount)}
          </Text>
        </View>
      )}
      {charityAmount > 0 && (
        <View
          flexDirection="row"
          mb="2xl"
          alignItems="center"
          justifyContent="space-between"
        >
          <Text size="sm" color="primary.400">
            Donation Amount
          </Text>
          <Text size="sm" color="primary.400">
            {toRupees(charityAmount)}
          </Text>
        </View>
      )}
      {paid && blackMembershipAmount > 0 && (
        <View
          flexDirection="row"
          mb="2xl"
          alignItems="center"
          justifyContent="space-between"
        >
          <Text size="sm" color="primary.400">
            32nd Plus Membership
          </Text>
          <Text size="sm" color="primary.400">
            {toRupees(blackMembershipAmount)}
          </Text>
        </View>
      )}
      {discountAmount > 0 && (
        <View
          flexDirection="row"
          mb="2xl"
          alignItems="center"
          justifyContent="space-between"
        >
          <Text size="sm" color="success.500">
            32nd Plus Savings
          </Text>
          <Text size="sm" color="success.500">
            -{toRupees(discountAmount)}
          </Text>
        </View>
      )}
      {usedPointsAmount > 0 && (
        <View flexDirection="row" mb="2xl">
          <View flexDirection="row">
            <Text size="sm" color="success.500">
              Savings via 32nd
            </Text>
            <View ml="sm">
              <CachedImage
                source={PaymentsImage}
                style={{ height: 24, width: 24 }}
              />
            </View>
          </View>
          <View flex={1} alignItems="flex-end">
            <Text size="sm" color="success.500">
              -{toRupees(usedPointsAmount)}
            </Text>
          </View>
        </View>
      )}
      {appliedCoupon && appliedCoupon?.savedAmount > 0 ? (
        <View flexDirection="row" mb="2xl">
          <Text size="sm" color="success.500">
            {`Coupon (${appliedCoupon?.couponCode})`}
          </Text>
          <View flex={1} alignItems="flex-end">
            <Text size="sm" color="success.500">
              {`-₹${appliedCoupon?.savedAmount}`}
            </Text>
          </View>
        </View>
      ) : null}
      <Divider />
      <View flexDirection="row" mt="lg">
        <View>
          <Text size="sm" color="primary.500" weight="medium">
            You {paid ? 'Paid' : 'Pay'}
          </Text>
        </View>
        <View flex={1} alignItems="flex-end">
          <Text size="sm" color="primary.500" weight="medium">
            {toRupees(netAmount)}
          </Text>
        </View>
      </View>
    </Card>
  );
};

export default BillingCard;
