import React, { useCallback, useRef, useState } from 'react';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { ScrollView, Dimensions } from 'react-native';
import { OneSignal } from 'react-native-onesignal';
import { removeUser, deleteUser } from '../../relay/userApi';
import {
  camelCaseToSeparatedWords,
  navigateBack,
} from '../../utilities/helper';
import {
  Divider,
  IconButton,
  Layout,
  Text,
  TopNavigation,
  Button,
  View,
  Pressable,
  Toggle,
} from '../../components/new';
import CustomPressable from '../../components/new/primitive/CustomPressable/CustomPressable';
import useAuthStore from '../../stores/authStore';
import useUserStore, { UserRole } from '../../stores/userStore';
import useCommonStore, { AppTheme } from '../../stores/commonStore';
import { removeTokenMapping } from '../../relay/notificationTokenMappingApis';
import useNotificationModalStore from '../../stores/notificationModalStore';
import SwitchRoleBottomSheet from '../DS/SwitchRoleBottomSheet';
import AddWorkLog from '../../relay/valetMyTasksApi';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../../components/new/primitive/snackbar/helpers/helpers';
import { useSnackbarStore } from '../../stores/snackbar/snackbarStore';
import LoyaltyModal from '../restaurantPartner/LoyaltyModal';
import useRestaurantPartnerStore from '../../stores/restaurantpartner/restaurantPartnerStore';
import { updateRestaurantConfig } from '../restaurantPartner/API/restaurantPartnerApi';
import NotLoggedInPlaceholderV2 from '../../components/new/custom/NotLoggedInPlaceholderV2';
import BottomSheetV2 from '../../components/new/composites/BottomSheet/BottomSheetV2';
import VideoMomentsStore from '../../stores/VideoMomentsStore';
import useLoginModalStore from '../../stores/loginModalStore';
import ToggleComponent from '../../components/new/switch.component';
import { firebaseEventLogger } from '../../utilities/firbaseAnalytics';
// import useBoundStore from '../../stores';
// import {Toggle} from '../../components/new/primitive/Toggle/Toggle';

const UserSettingScreen = ({ navigation, route }) => {
  const { isLoyaltyEnabled, setLoyalty, restaurantId } =
    useRestaurantPartnerStore((state) => state);
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  const userRole = useUserStore((state) => state.role);
  const setRole = useUserStore((state) => state.setRole);
  const primaryRole = useUserStore((state) => state.primaryRole);
  const secondaryRole = useUserStore((state) => state.secondaryRole);
  const setPrimaryRole = useUserStore((state) => state.setPrimaryRole);
  const emptyUserDetails = useUserStore((state) => state.emptyUserDetails);
  const emptyMomentsStore = VideoMomentsStore(
    (state) => state.emptyMomentsStore,
  );

  const windowWidth = Dimensions.get('window').width;
  const [bottomSheetAction, setBottomSheetAction] = useState('logout');
  const bottomSheetModalRef = useRef(null);
  const { theme, setAppTheme } = useCommonStore((state) => state);
  const insets = useSafeAreaInsets();
  const [showDeletModal, setShowDeleteModal] = useState(false);
  const [openRoleSheet, setOpenRoleSheet] = useState(false);
  const { dispatchSnackbar } = useSnackbarStore((state) => state);
  const [modalVisible, setModalVisible] = useState(false);
  const blackMembership = useUserStore((state) => state.blackMembership);
  const commonStore = useCommonStore((state) => state);

  const handlePresentModalPress = useCallback(() => {
    if (bottomSheetModalRef.current) {
      bottomSheetModalRef.current?.show();
    }
  }, []);

  const handleOnDismiss = useCallback(() => {
    bottomSheetModalRef.current?.hide();
  }, []);

  const handleRemoveUser = (res, error) => {
    if (error && error.length) {
      // setShowDeleteModal(false);
      handleOnDismiss();
    }
    if (res && res.removeUser && Object.keys(res.removeUser).length) {
      // setShowDeleteModal(false);
      handleOnDismiss();
      handleRemoveToken();
      setLoginDetails(false, '', '');
      // setUserDetails('', '', '', null, '', null);
      emptyUserDetails();
      emptyMomentsStore();
    }
  };

  const handleDeleteUser = (res, error) => {
    if (error && error.length) {
      // setShowDeleteModal(false);
      handleOnDismiss();
    }
    if (res && res.deleteUser && Object.keys(res.deleteUser).length) {
      // setShowDeleteModal(false);
      handleOnDismiss();
      handleRemoveToken();
      setLoginDetails(false, '', '');
      // setUserDetails('', '', '', null, '', null);
      emptyUserDetails();
      emptyMomentsStore();
    }
  };

  const userId = useUserStore((state) => state.id);
  const role = useUserStore((state) => state.role);
  const loginModalStore = useLoginModalStore((state) => state);

  const modalDeactivateCallBack = async () => {
    await handleRemoveToken();
    await removeUser(userId, handleRemoveUser);
    handleLogout();
  };

  const modalDeleteCallBack = async () => {
    await handleRemoveToken();
    await deleteUser(userId, handleDeleteUser);
    handleLogout();
  };

  const setLoginDetails = useAuthStore((state) => state.setLoginDetails);
  const { setScannedS3Imagekey, setMomentsData } = VideoMomentsStore(
    (state) => state,
  );
  // const setUserDetails = useUserStore((state) => state.setUserDetails);
  const expoToken = useNotificationModalStore((state) => state.expoToken);

  const handleRemoveToken = () => {
    if (expoToken) {
      removeTokenMapping(expoToken)
        .then((res) => {
          console.log('--', res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const punchOutFn = (type = 'punch_out') => {
    return AddWorkLog({
      date: new Date().toISOString(),
      valetID: userId,
      workLogHistory: [
        {
          time: new Date().toISOString(),
          type,
        },
      ],
    })
      .then(() => {})
      .catch((error) => {});
  };

  const handleLogout = async () => {
    await punchOutFn();
    loginModalStore.resetStore();
    setLoginDetails(false, '', '');
    setScannedS3Imagekey('');
    setMomentsData('');
    emptyUserDetails();
    emptyMomentsStore();
    OneSignal.logout();
    commonStore.setAppTheme(AppTheme.LIGHT);
    navigateBack(navigation, null);
  };

  if (!isLoggedIn) {
    return <NotLoggedInPlaceholderV2 header="Settings" showLoginModal />;
  }

  return (
    <Layout level="2">
      <TopNavigation
        title="Settings"
        appearance="default"
        level="1"
        textSize="md"
        textColor="primary.500"
        IconLeft={
          <IconButton
            name="back-outline-300"
            size="md"
            appearance="ghost"
            iconColor="primary.500"
            onPress={() => {
              navigateBack(navigation, userRole);
              // navigateBack(navigation, null);
            }}
          />
        }
      />
      <ScrollView style={{ paddingTop: 24, paddingHorizontal: 16 }}>
        <View style={{ flex: 1 }}>
          {blackMembership?.active && (
            <View>
              <View
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                mb="2xl"
              >
                <Text size="sm" color="primary.400">
                  Dark Theme
                </Text>
                <ToggleComponent />
              </View>
              <Divider />
            </View>
          )}
          {/* <View>
            <CustomPressable
              appearance="ghost"
              size="md"
              textSize="md"
              iconSize="md"
              shadow="md"
              state="active"
              status="primary"
              rounded="lg"
              iconColor="primary.400"
              leftContent={null}
              textColor="primary.500"
              rightContent={
                <Toggle
                  isOn={themeContext.theme === 'dark'}
                  onToggle={toggleHandler}
                />
              }
              onPress={toggleHandler}
            >
              Dark Theme
            </CustomPressable>
            <Divider />
          </View> */}
          {role === UserRole.RESTAURANT_PARTNER ? (
            <View>
              <Pressable
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginVertical: 16,
                }}
              >
                <Text size="sm" color="primary.400">
                  Loyalty Program
                </Text>
                <Toggle
                  isOn={isLoyaltyEnabled}
                  onToggle={() => {
                    if (isLoyaltyEnabled) {
                      setModalVisible(true);
                    } else {
                      const data = {
                        restaurantId,
                        loyalityPoints: true,
                      };
                      updateRestaurantConfig(
                        data,
                        (res, err) => {
                          if (
                            res &&
                            res?.updateRestaurantConfig &&
                            res?.updateRestaurantConfig?.pointsEnabled &&
                            res?.updateRestaurantConfig?.pointsEnabled
                              ?.reward &&
                            res?.updateRestaurantConfig?.pointsEnabled?.redeem
                          ) {
                            setLoyalty(
                              res?.updateRestaurantConfig?.pointsEnabled
                                ?.reward &&
                                res?.updateRestaurantConfig?.pointsEnabled
                                  ?.redeem,
                            );
                            dispatchSnackbar({
                              msg: `Loyalty Enabled Successfully!`,
                              status: SnackbarStatus.success,
                              version: SnackbarVersion.v2,
                            });
                          }
                        },
                        (err) => {
                          console.log('err', err);
                          dispatchSnackbar({
                            msg: `Something Went Wrong!`,
                            status: SnackbarStatus.error,
                            version: SnackbarVersion.v2,
                          });
                        },
                      );
                    }
                  }}
                />
              </Pressable>
              <Divider />
            </View>
          ) : (
            <></>
          )}

          {!!(
            isLoggedIn &&
            // userRole === UserRole.USER &&
            // userRole !== primaryRole &&
            secondaryRole &&
            secondaryRole.length
          ) && (
            <View>
              <CustomPressable
                appearance="ghost"
                size="md"
                textSize="sm"
                iconSize="2xl"
                shadow="md"
                state="active"
                status="primary"
                rounded="lg"
                // leftStyle={{ width: 28, height: 28 }}
                leftContent={null}
                rightContent="edit-outline-300"
                textColor="primary.400"
                // mb="2xl"
                onPress={() => {
                  setOpenRoleSheet(!openRoleSheet);
                }}
              >
                Change Role:{' '}
                {role === UserRole.USER
                  ? '32nd Customer'
                  : camelCaseToSeparatedWords(role)}
              </CustomPressable>
              <Divider />
            </View>
          )}
          {isLoggedIn && (
            <View>
              <CustomPressable
                appearance="ghost"
                size="md"
                textSize="sm"
                iconSize="2xl"
                shadow="md"
                state="active"
                status="primary"
                rounded="lg"
                // leftStyle={{ width: 28, height: 28 }}
                iconColor="error.400"
                leftContent={null}
                rightContent="delete-400"
                textColor="primary.400"
                // mb="2xl"
                onPress={() => {
                  firebaseEventLogger('settings__deleteccount_Tap', {
                    buttonName: 'deleteccoun',
                    screenName: 'settings',
                    userType: 'user',
                    interactionType: 'tap',
                  });
                  setBottomSheetAction('deleteaccount');
                  handlePresentModalPress();
                }}
              >
                Delete Account
              </CustomPressable>
              <Divider />
            </View>
          )}

          {isLoggedIn && (
            <View>
              <CustomPressable
                appearance="ghost"
                size="md"
                textSize="sm"
                iconSize="2xl"
                shadow="md"
                state="active"
                status="primary"
                rounded="lg"
                // leftStyle={{ width: 28, height: 28 }}
                iconColor=""
                leftContent={null}
                rightContent="logout-400"
                textColor="primary.400"
                // mb="2xl"
                onPress={() => {
                  firebaseEventLogger('settings__logOut_Tap', {
                    buttonName: 'logOut',
                    screenName: 'settings',
                    userType: 'user',
                    interactionType: 'tap',
                  });
                  setBottomSheetAction('logout');
                  handlePresentModalPress();
                }}
              >
                Logout
              </CustomPressable>
              <Divider />
            </View>
          )}

          {isLoggedIn && (
            <BottomSheetV2 ref={bottomSheetModalRef} onClose={handleOnDismiss}>
              {bottomSheetAction === 'logout' ? (
                <View>
                  <View justifyContent="center" px="2xl" pt="2xl">
                    <Text size="xl" color="primary.200">
                      Are you sure want to logout?
                    </Text>
                    <View mt="xl">
                      <Divider level={1} />
                    </View>
                    <Text size="xs" color="primary.200" mt="3xl">
                      You will need to log in again to use the app. Are you sure
                      you want to proceed?
                    </Text>
                    <View px="2xl" my="4xl">
                      <Divider level={1} />
                    </View>
                    <View flexDirection="row" alignItems="center">
                      <Button
                        style={{
                          width: (windowWidth - 3 * 16) / 2,
                          marginRight: 16,
                        }}
                        state="active"
                        status="error"
                        appearance="filled"
                        size="lg"
                        onPress={async () => {
                          // clearOrderDetails({});
                          // clearCart();
                          await handleRemoveToken();
                          handleLogout();
                          handleOnDismiss();
                        }}
                      >
                        Logout
                      </Button>
                      <Button
                        style={{ width: (windowWidth - 3 * 16) / 2 }}
                        state="active"
                        status="primary"
                        appearance="filled"
                        size="lg"
                        onPress={() => {
                          handleOnDismiss();
                        }}
                      >
                        Cancel
                      </Button>
                    </View>
                  </View>
                </View>
              ) : (
                <View>
                  <View justifyContent="center" px="2xl" pt="2xl">
                    <Text size="xl" color="primary.200">
                      Are you sure you want to delete your account?
                    </Text>
                    <View mt="xl">
                      <Divider level={1} />
                    </View>
                    <Text size="xs" color="primary.200" mt="3xl">
                      By deleting your account, you will lose all your data, and
                      settings associated with the account. Why don’t you
                      deactivate your account instead.
                    </Text>
                    <View px="2xl" my="4xl">
                      <Divider level={1} />
                    </View>
                    <View flexDirection="row" alignItems="center">
                      <Button
                        style={{
                          width: (windowWidth - 3 * 16) / 2,
                          marginRight: 16,
                        }}
                        state="active"
                        status="error"
                        appearance="filled"
                        size="lg"
                        onPress={() => {
                          firebaseEventLogger('settings__deleteAccount_Tap', {
                            buttonName: 'deleteAccount',
                            screenName: 'settings',
                            userType: 'user',
                            interactionType: 'tap',
                          });
                          modalDeleteCallBack();
                        }}
                      >
                        Delete
                      </Button>
                      <Button
                        style={{ width: (windowWidth - 3 * 16) / 2 }}
                        state="active"
                        status="primary"
                        appearance="filled"
                        size="lg"
                        onPress={() => {
                          firebaseEventLogger(
                            'settings__deactivateAccount_Tap',
                            {
                              buttonName: 'deactivateAccount',
                              screenName: 'settings',
                              userType: 'user',
                              interactionType: 'tap',
                            },
                          );
                          modalDeactivateCallBack();
                        }}
                      >
                        Deactivate
                      </Button>
                    </View>
                  </View>
                </View>
              )}
            </BottomSheetV2>
          )}
        </View>
      </ScrollView>
      <SwitchRoleBottomSheet isOpen={openRoleSheet} navigation={navigation} />
      <LoyaltyModal
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
      />
    </Layout>
  );
};

export default UserSettingScreen;
