import React, {
  useState,
  useCallback,
  Suspense,
  useRef,
  useEffect,
} from 'react';
import { useLazyLoadQuery } from 'react-relay';
import { ScrollView } from 'react-native';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import {
  Layout,
  View,
  IconButton,
  TopNavigation,
  Button,
  Icon,
  Text,
} from '../../../components/new';
import Loading from '../../../components/loading.component';
import TaskCard from '../../../components/new/primitive/TaskCard/TaskCard';
import DisconnectedDropover from '../../../components/disconnectedpopover.component';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../../utilities/NewErrorBoundary';
import NewErrorView from '../../../utilities/NewErrorView';
import useCommonStore from '../../../stores/commonStore';
import useUserStore, { UserRole } from '../../../stores/userStore';
import valetTaskUpdatedSubscriptionApi from './API/valetTaskUpdatedSubscriptionApi';
import {
  findTaskHistoryLastAction,
  formatPlateNo,
  formatTimeToHrsMinSec,
  taskHistoryActionTypes,
  taskTypes,
} from '../../../utilities/helper';
import ValetQrCodeCard from '../../../components/new/custom/ValetQrCodeCard';
import {
  submitKeyScreenQuery,
  submitKeyScreenTaskFragment,
} from './API/submitKeyScreenQuery';
import {
  InteractionType,
  firebaseEventLogger,
} from '../../../utilities/firbaseAnalytics';
import AppConstants from '../../../utilities/AppConstants';
import CustomLottieView from '../../../components/new/custom/CustomLottieView';
import { usePagedTasks } from '../../../hooks/usePagedTasks';

interface SubmitKeyScreenProps {
  queryOptions: any;
  variables: any;
  fetchKey: any;
}

const SubmitKeyScreen = ({
  queryOptions,
  variables,
  fetchKey,
}: SubmitKeyScreenProps) => {
  const navigation = useNavigation<any>();
  const [submitKeyButtonDisabled, setSubmitKeyButtonDisabled] = useState(true);
  const { IMAGEKIT_PROD_URI } = AppConstants;
  const campusID = useCommonStore((state) => state.campusId);
  const animation = useRef(null);
  const insets = useSafeAreaInsets();
  const userId = useUserStore((state) => state.id);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const findTasksFragmentRef = useLazyLoadQuery(
    submitKeyScreenQuery,
    variables,
    {
      ...queryOptions,
      networkCacheConfig: { force: true },
      fetchPolicy: 'store-and-network',
    },
  );

  const { tasks: findTasks } = usePagedTasks(
    submitKeyScreenTaskFragment,
    findTasksFragmentRef,
  );

  const { vehicle, _id, history, valet, parkingETA, taskEndLocation } =
    findTasks[0] ?? {};

  const ETA =
    parkingETA &&
    parkingETA?.parkingTimePrediction &&
    parkingETA?.parkingTimePrediction?.length &&
    parkingETA?.parkingTimePrediction?.filter(
      (item) => item?.locationId === taskEndLocation?.locationId,
    );

  // ------ current time ------
  const currentTime = new Date().valueOf();
  const delayAt = new Date(ETA[0]?.startToCompleteTime).valueOf(); // delay time
  const isDelayed = currentTime > delayAt; // check if current time breached delay time

  const taskSubscriptionFilter = {
    history: [
      taskHistoryActionTypes.cancelled,
      taskHistoryActionTypes.completed,
      taskHistoryActionTypes.incomplete,
      taskHistoryActionTypes.EXITED,
    ],
  };

  const handleTaskUpdateSubscription = (res) => {
    // ------ task updated subscription ------
    const taskUpdated = res?.taskUpdated;
    const lastAction = findTaskHistoryLastAction(taskUpdated);
    if (taskUpdated) {
      // ------ check if tasktype is park or repark ------
      if (
        taskUpdated?.taskType === taskTypes.park ||
        taskUpdated?.taskType === taskTypes.repark
      ) {
        /* ------ if task marked as completed, incomplete, exited,
        cancelled then navigate to task completion screen */
        if (
          (lastAction === taskHistoryActionTypes.completed ||
            lastAction === taskHistoryActionTypes.incomplete ||
            lastAction === taskHistoryActionTypes.cancelled ||
            lastAction === taskHistoryActionTypes.EXITED) &&
          taskUpdated?.assignedTo === userId
        ) {
          firebaseEventLogger('submitKey__valetQrCode_Data', {
            buttonName: 'valetQrCode',
            screenName: 'submitKey',
            userType: `${UserRole.VALET}`,
            interactionType: InteractionType.DATA,
            taskId: `${_id}`,
            valetId: `${userId}`,
          });
          navigation.navigate('EndTaskScreen', {
            taskId: taskUpdated?._id,
          });
        }
      }
    }
  };

  const handleTaskUpdationSubRef = useRef(handleTaskUpdateSubscription);

  useFocusEffect(
    useCallback(() => {
      // handleTaskUpdationSubRef.current = handleTaskUpdateSubscription;
      let cancelSubscriptionUpdateData = {
        dispose: () => {},
      };
      const cancelUpdateTaskSubscription = (disposable) => {
        cancelSubscriptionUpdateData = disposable;
      };
      const taskUpdatedDataReq = {
        campusId: campusID,
        userId,
        history: taskSubscriptionFilter.history,
        event_at: new Date().toISOString(),
        taskType: [taskTypes.park, taskTypes.recall, taskTypes.repark],
      };
      valetTaskUpdatedSubscriptionApi(
        taskUpdatedDataReq,
        (data) => handleTaskUpdationSubRef.current(data),
        cancelUpdateTaskSubscription,
      );

      return () => {
        // Useful for cleanup functions
        cancelSubscriptionUpdateData.dispose();
      };
    }, []),
  );

  // ------ vehicle number ------
  const formattedVehicleNumber = formatPlateNo(vehicle?.registration?.plate);
  const heading = formattedVehicleNumber;

  // ------ finding accepted status from history ------
  const acceptedStatus =
    history &&
    history?.length &&
    history?.filter((ele) => ele?.action === taskHistoryActionTypes.accepted);

  // ------ getting time from accepted status ------
  const taskAcceptedTime =
    acceptedStatus && acceptedStatus?.length
      ? new Date(acceptedStatus[0]?.event_at).valueOf()
      : new Date(history[0]?.event_at).valueOf();

  // ------ difference between current time and task accepted time ------
  const difference = currentTime - taskAcceptedTime;

  const timerObj = {
    title1: 'Wait Time',
    title2: formatTimeToHrsMinSec(difference / 1000),
    title2Color: 'primary.500',
  };
  if (difference / 1000 > 120 && difference / 1000 < 300) {
    timerObj.title2Color = 'warning.500';
  }
  if (difference / 1000 > 300) {
    timerObj.title2Color = 'error.500';
  }

  // ------ difference between current time and delayAt -------
  const delayedDifferenceInMins = (delayAt - currentTime) / 60 / 1000;

  const [delayedTimer, setDelayedTimer] = useState(
    Math.floor(delayedDifferenceInMins),
  ); // 1 min timer for for waiting timer in task card

  // ------ updating timer after every 1 min for timer in task card ------
  useEffect(() => {
    const delayedInterval = 1000 * 60;
    const delayedTimerInterval = setInterval(() => {
      if (delayedTimer > 0) {
        setDelayedTimer(delayedTimer - 1);
      }
    }, delayedInterval);
    return () => {
      // Useful for cleanup functions
      clearInterval(delayedTimerInterval);
    };
  }, [delayedTimer]);

  const topRightCornerComponent = () => {
    return (
      <View
        flexDirection="row"
        borderRadius="md"
        borderWidth="xs"
        borderColor="primary.50"
        bg="primary.10"
        p="sm+md"
        alignItems="center"
      >
        <Icon
          name="timer-outline-300"
          size="xl"
          color={isDelayed || delayedTimer < 1 ? 'warning.500' : 'success.500'}
        />
        <Text ml="lg" size="md" weight="medium" color="primary.300">
          {delayedTimer < 1 || isDelayed ? 'Delayed' : `${delayedTimer} min`}
        </Text>
      </View>
    );
  };

  const bottomLeftCTAComponent = () => {
    return (
      <Button
        size="lg"
        appearance="filled"
        status="primary"
        shadow="sm"
        state={submitKeyButtonDisabled ? 'disabled' : 'active'}
        mb={insets.bottom}
      >
        <CustomLottieView
          autoPlay
          ref={animation}
          loop
          source={require('../../../../assets/lottie/loading-bubble.json')}
          style={{ height: 22, width: 56 }}
          containerWidth={56}
        />
      </Button>
    );
  };

  const { name, identityCode, profileImage } = valet ?? {};

  const fullName = `${name?.first} ${name?.last !== null ? name?.last : ''}`;
  const imageLink = profileImage
    ? IMAGEKIT_PROD_URI + profileImage
    : 'https://ik.imagekit.io/32ndhub/assets/nouser.png';

  return (
    <View flex={1}>
      <ScrollView contentContainerStyle={{ flex: 1 }}>
        <View px="2xl" mt="4xl">
          {valet && Object.keys(valet).length ? (
            <ValetQrCodeCard
              fullName={fullName}
              imageLink={imageLink}
              _id={valet?._id}
              identityCode={identityCode}
              qrCodeAspectRatio={0.5}
            />
          ) : (
            <></>
          )}
        </View>
        <View height={200} />
      </ScrollView>
      <View position="absolute" bottom={0} width="100%">
        <TaskCard
          heading={heading}
          topRightCorner={topRightCornerComponent()}
          shadow="lg"
          bottomLeftCTA={bottomLeftCTAComponent()}
          alwaysOpened
        />
      </View>
    </View>
  );
};

const SubmitKeyScreenWrapper = ({ navigation, route }) => {
  const { taskId } = route?.params ?? {};
  const campusID = useCommonStore((state) => state.campusId);
  const { role } = useUserStore((state) => state);
  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'network-only',
    });
  const [netStatus, setNetStatus] = useState(true);

  const refresh = useCallback(
    () => {
      setRefreshedQueryOptions((prev) => ({
        fetchKey: (prev?.fetchKey ?? 0) + 1,
        fetchPolicy: 'network-only',
      }));
    },
    [
      /* ... */
    ],
  );

  const filter = {
    campus: { _id: campusID },
    _id: taskId,
  };

  return (
    <Layout level={2}>
      <TopNavigation
        appearance="ghost"
        level="1"
        IconLeft={
          <IconButton
            name="back-outline-300"
            size="md"
            appearance="ghost"
            iconColor="primary.500"
            onPress={() => navigation.navigate(role)}
          />
        }
        title="Submit Key"
      />
      <NewErrorBoundary
        fetchKey={refreshedQueryOptions.fetchKey}
        fallback={
          <NewErrorView
            errorMsg="Sorry something went wrong"
            reload={refresh}
          />
        }
      >
        <Suspense fallback={<Loading />}>
          <SubmitKeyScreen
            variables={{
              filter,
            }}
            fetchKey={refreshedQueryOptions?.fetchKey}
            queryOptions={refreshedQueryOptions}
            refresh={refresh}
          />
        </Suspense>
      </NewErrorBoundary>
      <DisconnectedDropover
        setNetStatus={setNetStatus}
        text="No Internet Connection"
        icon="wifi-off-outline"
      />
    </Layout>
  );
};

export default SubmitKeyScreenWrapper;
