import React from 'react';
import { usePayBillStore } from '../hooks/usePayBillStore';
import AmountSavedComp from '../../AmountSavedComp';
import { View } from '../../../../../components/new';
import { toRupees } from '../../../../../utilities/helper';

const SavingsStrip = () => {
  const priceDetails = usePayBillStore((state) => state?.priceDetails);
  const { totalSavingsAmount } = priceDetails;

  if (totalSavingsAmount <= 0) return null;

  return (
    <View width="100%" mb="2xl">
      <AmountSavedComp
        text={`You’re saving ${toRupees(totalSavingsAmount)} on this bill`}
      />
    </View>
  );
};

export default SavingsStrip;
