import React from 'react';
import { ViewStyle } from 'react-native';
import styled from 'styled-components/native';
import { border, space, SpaceProps, BorderRadiusProps } from 'styled-system';
import { PlaceholderLine } from 'rn-placeholder';
import { getTheme } from '../../../../themes/new/theme';
import useCommonStore from '../../../../stores/commonStore';

const StyledPlaceholderLine = styled(PlaceholderLine)`
  ${space}
  ${border}
`;

interface BlockPlaceholderProps extends SpaceProps, BorderRadiusProps {
  width?: number;
  height?: number;
  style?: ViewStyle;
}

const BlockPlaceholder = ({
  width,
  height,
  style = {},
  ...restProps
}: BlockPlaceholderProps) => {
  const theme = useCommonStore((state) => state.theme);

  return (
    <StyledPlaceholderLine
      {...restProps}
      noMargin
      style={[
        {
          width: width ?? '100%',
          height: height ?? width ?? 80,
        },
        style,
        {
          backgroundColor: getTheme(theme).colors.primary[30],
        },
      ]}
    />
  );
};

export default BlockPlaceholder;
