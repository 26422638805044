/**
 * @generated SignedSource<<a2f8239bbadcdeae67fc2092ecfd9488>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EnumInvestmentPropertyUserConsentTCStatus = "GIVEN" | "REVOKED" | "%future added value";
export type InvestmentPageQueryFindInvestmentUserConsentsQuery$variables = {};
export type InvestmentPageQueryFindInvestmentUserConsentsQuery$data = {
  readonly findInvestmentUserConsents: {
    readonly propertyId: any | null;
    readonly status: EnumInvestmentPropertyUserConsentTCStatus | null;
  } | null;
};
export type InvestmentPageQueryFindInvestmentUserConsentsQuery = {
  response: InvestmentPageQueryFindInvestmentUserConsentsQuery$data;
  variables: InvestmentPageQueryFindInvestmentUserConsentsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "InvestmentPropertyUserConsentTC",
    "kind": "LinkedField",
    "name": "findInvestmentUserConsents",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "propertyId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "InvestmentPageQueryFindInvestmentUserConsentsQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "InvestmentPageQueryFindInvestmentUserConsentsQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "921ee5ecd5c3b3ab5b32b7fa4f694652",
    "id": null,
    "metadata": {},
    "name": "InvestmentPageQueryFindInvestmentUserConsentsQuery",
    "operationKind": "query",
    "text": "query InvestmentPageQueryFindInvestmentUserConsentsQuery {\n  findInvestmentUserConsents {\n    propertyId\n    status\n  }\n}\n"
  }
};
})();

(node as any).hash = "1d3b7af6869992532b5a0d97a3261539";

export default node;
