/* eslint-disable import/prefer-default-export */
import { graphql, fetchQuery } from 'react-relay';
import environment from '../../../../relay/relayEnvironment';

const defaultQuery = graphql`
  query findTaskQuery($filter: findTaskFilterInput!) {
    ...findTaskQueryTaskFragment @arguments(taskFilter: $filter, count: 1)
  }
`;

export const findTasksQueryFragment = graphql`
  fragment findTaskQueryTaskFragment on Query
  @inline
  @refetchable(queryName: "findTaskQueryTaskFragmentQuery")
  @argumentDefinitions(
    taskFilter: { type: "findTaskFilterInput!" }
    count: { type: "Int" }
    cursor: { type: "String" }
  ) {
    findTasks: findTasksV2(filter: $taskFilter, first: $count, after: $cursor)
      @connection(key: "findTaskQueryTaskFragment__findTasks") {
      edges {
        node {
          _id
          vehicle {
            brand {
              name
              brandLogo
            }
            registration {
              plate
            }
            color
          }
          user {
            _id
          }
        }
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`;

export const findTaskQuery = (variables, query = defaultQuery) => {
  return fetchQuery(environment, query, variables).toPromise();
};
