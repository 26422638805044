import React, { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import Carousel from 'react-native-reanimated-carousel';
import { Dimensions, Linking, Platform } from 'react-native';
import { useFragment } from 'react-relay';
import View from '../../basic/View/View';
import CarouselProgressTiles from './CarouselProgressTiles';
import Pressable from '../../basic/Pressable/Pressable';
import { getBaseUrlForDeepLink } from '../../../../utilities/Utility';
import { firebaseEventLogger } from '../../../../utilities/firbaseAnalytics';
import CachedImage from '../CachedImage';
import { homeScreenBannerFragment } from '../../../../screens/new/BannerHomeScreen/API/homeScreenLogOutQuery';
import { Text } from '../../index';

const DEEPLINK_BASE_URL = getBaseUrlForDeepLink();

const HomePageBannerCarousel = ({ fragmentRef }) => {
  const { width } = Dimensions.get('window');
  const aspectRatio = 642 / 1492;
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [carouselData, setCarouselData] = useState<
    { bannerImage: any; deeplink: string }[]
  >([]);

  const data = useFragment(homeScreenBannerFragment, fragmentRef);

  useEffect(() => {
    const modifiedData: { bannerImage: any; deeplink: string }[] = data.map(
      (ele) => {
        return {
          bannerImage: ele?.imageLink,
          deeplink: `${DEEPLINK_BASE_URL}${ele?.deepLink}`,
        };
      },
    );
    setCarouselData(modifiedData);
  }, [data]);

  if (carouselData.length === 0) {
    return null;
  }

  if (carouselData.length === 1) {
    return (
      <View
        borderRadius="xl"
        overflow="hidden"
        backgroundColor="black"
        width={width - 32}
        height={(width - 32) * aspectRatio}
      >
        <Pressable
          style={{
            flex: 1,
            flexDirection: 'column',
            alignItems: 'center',
          }}
          onPress={async () => {
            try {
              firebaseEventLogger('home_page_banner_clicked', {
                deeplink: carouselData[0].deeplink,
              });
              const link = carouselData[0].deeplink;
              await Linking.openURL(link);
            } catch (e) {
              console.log(e);
            }
          }}
        >
          <CachedImage
            source={carouselData[0]?.bannerImage}
            style={{
              flex: 1,
              width: width - 32,
              height: (width - 32) * aspectRatio,
              resizeMode: 'contain',
            }}
          />
        </Pressable>
      </View>
    );
  }

  if (Platform.OS === 'web') {
    return (
      <View borderRadius="xl" overflow="hidden">
        <Carousel
          loop
          width={width - 32}
          height={(width - 32) * aspectRatio}
          data={carouselData}
          scrollAnimationDuration={100}
          autoPlayInterval={5000}
          onSnapToItem={(index) => {
            setActiveIndex(index);
          }}
          renderItem={({ index, item }) => (
            <Pressable
              style={{
                flex: 1,
                flexDirection: 'column',
                alignItems: 'center',
              }}
              onPress={async () => {
                try {
                  firebaseEventLogger('home_page_banner_clicked', {
                    deeplink: carouselData[activeIndex].deeplink,
                  });
                  const link = carouselData[activeIndex].deeplink;
                  await Linking.openURL(link);
                } catch (e) {
                  console.log(e);
                }
              }}
            >
              <CachedImage
                source={item?.bannerImage}
                style={{
                  flex: 1,
                  width: width - 32,
                  height: (width - 32) * aspectRatio,
                  resizeMode: 'cover',
                }}
              />
            </Pressable>
          )}
          autoPlay
        />
        <View
          style={{
            position: 'absolute',
            marginHorizontal: 16,
            bottom: 16,
            width: width - 64,
          }}
        >
          <CarouselProgressTiles
            time={5000}
            numberOfBars={carouselData.length}
            activeIndex={activeIndex}
          />
        </View>
      </View>
    );
  }
  return (
    <View borderRadius="xl" overflow="hidden">
      <Carousel
        loop
        width={width - 32}
        height={(width - 32) * aspectRatio}
        data={carouselData}
        scrollAnimationDuration={100}
        autoPlayInterval={5000}
        onSnapToItem={(index) => {
          setActiveIndex(index);
        }}
        renderItem={({ index, item }) => (
          <Pressable
            style={{
              flex: 1,
              flexDirection: 'column',
              alignItems: 'center',
            }}
            onPress={async () => {
              try {
                firebaseEventLogger('home_page_banner_clicked', {
                  deeplink: carouselData[activeIndex].deeplink,
                });
                const link = carouselData[activeIndex].deeplink;
                await Linking.openURL(link);
              } catch (e) {
                console.log(e);
              }
            }}
          >
            <CachedImage
              source={item?.bannerImage}
              style={{
                flex: 1,
                width: width - 32,
                height: (width - 32) * aspectRatio,
                resizeMode: 'cover',
              }}
            />
          </Pressable>
        )}
        autoPlay
        panGestureHandlerProps={{
          activeOffsetX: [-10, 10],
        }}
      />
      <View
        style={{
          position: 'absolute',
          marginHorizontal: 16,
          bottom: 16,
          width: width - 64,
        }}
      >
        <CarouselProgressTiles
          time={5000}
          numberOfBars={carouselData.length}
          activeIndex={activeIndex}
        />
      </View>
    </View>
  );
};

export default HomePageBannerCarousel;
