import React from 'react';
import { FlatList } from 'react-native';
import { View } from '../index';
import { Appearance, State } from '../../../themes/new/helper';
import Chip from '../primitive/Chip/Chip';
import { EventsFilterDataType } from '../../../screens/new/eventsHome/helpers/EventsFilterData';

interface ListingFiltersProps {
  data: EventsFilterDataType[];
  onPress: (data: EventsFilterDataType) => void;
  isVisible: boolean;
}
const ListingFilters = (props: ListingFiltersProps) => {
  if (!props.isVisible) {
    return null;
  }
  return (
    <FlatList
      data={props.data}
      horizontal
      showsHorizontalScrollIndicator={false}
      renderItem={({ item, index }) => {
        return (
          <View ml={index === 0 ? '2xl' : 0} mr="lg">
            <Chip
              state={item.active ? State.SELECTED : State.ACTIVE}
              // @ts-ignore
              onPress={() => {
                props.onPress(item);
              }}
              size="xs"
              appearance={Appearance.FILLED}
              iconRight={item.isMultiSelect ? 'down-outline-300' : ''}
            >
              {!item.isMultiSelect
                ? `${item.name}`
                : item.itemsSelected > 0
                ? `${item.name} (${item.itemsSelected})`
                : `${item.name}`}
            </Chip>
          </View>
        );
      }}
    />
  );
};

export default ListingFilters;
