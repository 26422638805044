/* eslint-disable import/prefer-default-export */
/**
 * Used with pagination in case when actual data wrapped in `edges.node` is needed.
 * @param edges - edges from paged data
 */
export const unwrapPagedData = (
  edges: Array<{ node: Record<string, any> }>,
) => {
  return edges.map((edge) => edge.node);
};
