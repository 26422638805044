import type { StateType, ActionType } from './types';

export const actionTypes: Record<string, string> = {
  SET_SELECTED_DATE: 'SET_SELECTED_DATE',
  SET_DAYPICKER_DATA: 'SET_DAYPICKER_DATA',
  SET_METRICS_WIDGET_DATA: 'SET_METRICS_WIDGET_DATA',
  SET_METRICS_DATA: 'SET_METRICS_DATA',
};

export const initialState: StateType = {
  selectedDate: null,
  dayPickerData: null,
  metricsWidgetData: null,
  metricsData: null,
};

export const parkingOverviewReducer = (
  state: StateType,
  action: ActionType,
) => {
  switch (action.type) {
    case actionTypes.SET_SELECTED_DATE: {
      return {
        ...state,
        selectedDate: action.payload,
      };
    }

    case actionTypes.SET_DAYPICKER_DATA: {
      return {
        ...state,
        dayPickerData: action.payload,
      };
    }
    case actionTypes.SET_METRICS_DATA: {
      return {
        ...state,
        metricsData: action.payload,
      };
    }
    case actionTypes.SET_METRICS_WIDGET_DATA: {
      return {
        ...state,
        metricsWidgetData: {
          ...action.payload,
        },
      };
    }
    default:
      return state;
  }
};
