import React from 'react';
import { View } from '../../../../../components/new';
import CancellationInfoBanner from '../../CancellationInfoBanner';
import { usePayBillStore } from '../hooks/usePayBillStore';
import { paymentOrderTypeEnum } from '../../../../../utilities/helper';
import { PAYBILL_BS_MODES } from '../helpers';
import payBillAnalytics from '../analytics';

interface EventCancelltationWidgetProps {
  showBottomSheet: (mode: PAYBILL_BS_MODES) => void;
}
const EventCancelltationWidget = ({
  showBottomSheet,
}: EventCancelltationWidgetProps) => {
  const { title, event, orderId, orderType } = usePayBillStore(
    (state) => state,
  );
  const { eventDateF, isRefundableIfCancelled } = event ?? {};

  if (orderType !== paymentOrderTypeEnum.EVENT) return null;
  if (!isRefundableIfCancelled) return null;

  return (
    <View mb="4xl">
      <CancellationInfoBanner
        onPress={() => {
          payBillAnalytics.freeCancellationBannerClicked(
            title,
            orderType,
            orderId,
          );
          showBottomSheet(PAYBILL_BS_MODES.EVENT_CANCELLATION_INFO);
        }}
        title="Free Cancellation"
        message={`Get full refund if cancelled before ${eventDateF}`}
      />
    </View>
  );
};

export default EventCancelltationWidget;
