/* eslint-disable import/prefer-default-export */
import {
  BlackMembershipStatus,
  MembershipTypes,
  paymentOrderTypeEnum,
} from '../../../utilities/helper';
import { DeepReadonly } from '../../../utilities/types';

type Customer = {
  memberships: {
    membershipAddonId: string;
    status: BlackMembershipStatus;
  }[];
};

type BlackMembershipAddon = {
  _id: string;
  type: string;
  additionalDiscount: {
    refType: string;
    discount: {
      value: number;
      type: string;
    };
  }[];
  additionalCashback: {
    refType: string;
    cashback: {
      value: number;
      type: string;
    };
  }[];
};

type BlackMembershipAddonWithTypeAndId = Pick<
  BlackMembershipAddon,
  '_id' | 'type'
>;

export const isCustomerBlackMember = (
  customer: Customer,
  addon:
    | BlackMembershipAddonWithTypeAndId
    | BlackMembershipAddonWithTypeAndId[],
) => {
  let membershipAddon: BlackMembershipAddonWithTypeAndId;
  if (Array.isArray(addon)) {
    membershipAddon = extractBlackMembershipAddonConfig(addon);
  } else {
    membershipAddon = addon;
  }
  if (membershipAddon?.type !== MembershipTypes.PLUS_MEMBERSHIP) return false;
  const blackMembership = getActiveBlackMembershipFromUser(
    customer,
    membershipAddon?._id,
  );
  if (!blackMembership) return false;
  if (blackMembership?.status === BlackMembershipStatus.ACTIVE) return true;
  return false;
};

export const getActiveBlackMembershipId = (
  customer: Customer,
  addon:
    | BlackMembershipAddonWithTypeAndId
    | BlackMembershipAddonWithTypeAndId[],
): string | null => {
  let membershipAddon: BlackMembershipAddonWithTypeAndId;
  if (Array.isArray(addon)) {
    membershipAddon = extractBlackMembershipAddonConfig(addon);
  } else {
    membershipAddon = addon;
  }
  if (membershipAddon?.type !== MembershipTypes.PLUS_MEMBERSHIP) return null;

  const blackMembership = getActiveBlackMembershipFromUser(
    customer,
    membershipAddon?._id,
  );
  if (!blackMembership) return null;
  if (blackMembership?.status === BlackMembershipStatus.ACTIVE)
    return blackMembership?.membershipAddonId;
  return null;
};

export const getActiveBlackMembershipFromUser = (
  user: Customer,
  id: string,
) => {
  if (Array.isArray(user?.memberships) && user?.memberships?.length > 0) {
    const membership = user?.memberships?.find((m) => {
      if (
        m?.membershipAddonId === id &&
        m?.status === BlackMembershipStatus.ACTIVE
      )
        return true;
      return false;
    });
    return membership ?? null;
  }
  return null;
};

export const extractBlackMembershipAddonConfig = <
  T extends DeepReadonly<Pick<BlackMembershipAddon, 'type'>>,
>(
  addons: ReadonlyArray<T>,
): T | null => {
  if (addons?.length) {
    const addon = addons?.find(
      (adn) => adn?.type === MembershipTypes.PLUS_MEMBERSHIP,
    );
    return addon;
  }
  return null;
};

export const isStandaloneBlackMembershipBeignPurchased = (
  orderType: paymentOrderTypeEnum,
  orderId: string, // must be the addonId from addon config
  addons: BlackMembershipAddonWithTypeAndId[],
) => {
  if (orderType !== paymentOrderTypeEnum.ADDONS) return false;
  const bmAddon = extractBlackMembershipAddonConfig(addons);
  if (bmAddon) {
    return (
      bmAddon?.type === MembershipTypes.PLUS_MEMBERSHIP &&
      bmAddon?._id === orderId
    );
  }
  return false;
};

export const getParkingDiscountPercentage = (
  membershipAddon: Pick<BlackMembershipAddon, 'additionalDiscount'>,
): number => {
  const parkingDiscountConf = membershipAddon?.additionalDiscount?.find(
    (item) => item?.refType === paymentOrderTypeEnum.PARKING,
  );
  if (parkingDiscountConf?.discount?.type !== 'PERCENTAGE') return 0;
  return parkingDiscountConf?.discount?.value ?? 0;
};

export const getEventDiscountPercentage = (
  membershipAddon: Pick<BlackMembershipAddon, 'additionalDiscount'>,
): number => {
  const eventDiscountConfig = membershipAddon?.additionalDiscount?.find(
    (item) => item?.refType === paymentOrderTypeEnum.EVENT,
  );
  if (eventDiscountConfig?.discount?.type !== 'PERCENTAGE') return 0;
  return eventDiscountConfig?.discount?.value ?? 0;
};

export const getEventCashbackPercentage = (
  membershipAddon: Pick<BlackMembershipAddon, 'additionalCashback'>,
): number => {
  const eventCashbackConfig = membershipAddon?.additionalCashback?.find(
    (item) => item?.refType === paymentOrderTypeEnum.EVENT,
  );
  if (eventCashbackConfig?.cashback?.type !== 'PERCENTAGE') return 0;
  return eventCashbackConfig?.cashback?.value ?? 0;
};
