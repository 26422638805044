/* eslint-disable no-nested-ternary */
import React, { Suspense, useCallback, useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useLazyLoadQuery, usePaginationFragment } from 'react-relay';
import { ScrollView } from 'react-native';
import {
  Button,
  Icon,
  IconButton,
  Layout,
  Text,
  TopNavigation,
  View,
} from '../../components/new';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../utilities/NewErrorBoundary';
import Loading from '../../components/loading.component';
import useCommonStore from '../../stores/commonStore';
import useUserStore, { UserRole } from '../../stores/userStore';
import DisconnectedDropover from '../../components/disconnectedpopover.component';
import {
  assignTaskQuery,
  assignTaskQueryFragment,
  assignTaskQueryTaskFragment,
} from './API/assignTaskQuery';
import {
  formatPlateNo,
  formatTimeToHrsMinSec,
  navigateBack,
  taskHistoryActionTypes,
} from '../../utilities/helper';
import { useSnackbarStore } from '../../stores/snackbar/snackbarStore';
import TaskCard from '../../components/new/primitive/TaskCard/TaskCard';
import RectangularTimer from '../../components/new/primitive/Timer/RectangularTimer';
import NewErrorView from '../../utilities/NewErrorView';
import { updateTaskForSupervisor } from '../../relay/taskApi';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../../components/new/primitive/snackbar/helpers/helpers';
import { unwrapPagedData } from '../../utilities/paginationUtilities';
import { Appearance, State, Status } from '../../themes/new/helper';
import { usePagedTasks } from '../../hooks/usePagedTasks';

const AssignTask = (props) => {
  const navigation = useNavigation<any>();
  const { role } = useUserStore((state) => state);
  const { queryOptions, variables, fetchKey } = props ?? {};
  const { dispatchSnackbar } = useSnackbarStore((state) => state);
  const [assignTaskDisabled, setAssignTaskDisabled] = useState(false);
  const insets = useSafeAreaInsets();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const findTasksAndSearchValetRef = useLazyLoadQuery(
    assignTaskQuery,
    variables,
    {
      ...queryOptions,
      networkCacheConfig: { force: true },
      fetchPolicy: 'network-only',
    },
  );

  const {
    data: paginatedData,
    loadNext: fetchMoreUserList,
    hasNext: hasMoreUsers,
  } = usePaginationFragment(
    assignTaskQueryFragment,
    findTasksAndSearchValetRef,
  );
  const searchValet = unwrapPagedData(paginatedData?.searchValetV2?.edges);
  const { tasks: findTasks } = usePagedTasks(
    assignTaskQueryTaskFragment,
    findTasksAndSearchValetRef,
  );

  const { vehicle, _id, history, taskType, assignedTo } = findTasks[0] ?? {};

  const valet = (searchValet && searchValet?.length && searchValet[0]) ?? {};

  const formattedVehicleNumber = formatPlateNo(vehicle?.registration?.plate);
  const heading = formattedVehicleNumber;

  const taskCreatedTime = new Date(history[0]?.event_at).valueOf();
  const currentTime = new Date().valueOf();
  const difference = currentTime - taskCreatedTime;

  const timerObj = {
    title1: 'Wait Time',
    title2: formatTimeToHrsMinSec(difference / 1000),
    title2Color: 'primary.500',
  };
  if (difference / 1000 > 120 && difference / 1000 < 300) {
    timerObj.title2Color = 'warning.500';
  }
  if (difference / 1000 > 300) {
    timerObj.title2Color = 'error.500';
  }

  const topRightCornerComponent = () => {
    return (
      <View
        borderRadius="full"
        borderWidth="xs"
        borderColor="primary.50"
        p="sm"
      >
        <Icon size="xl" name="car-filled-400" color="primary.100" />
      </View>
    );
  };

  const bottomLeftCornerComponent = () => (
    <RectangularTimer
      title1={timerObj?.title1}
      title2={timerObj?.title2}
      title2Color={timerObj?.title2Color}
    />
  );

  const bottomLeftCTAComponent = () => {
    const handleAssignTask = () => {
      setAssignTaskDisabled(true);
      const data = {
        _id,
        record: {
          history: [
            { action: taskHistoryActionTypes.assigned },
            { action: taskHistoryActionTypes.accepted },
          ],
          assignedTo: valet?._id,
        },
      };
      updateTaskForSupervisor(
        data,
        (res) => {
          if (res) {
            dispatchSnackbar({
              msg: `Task Assigned To Valet`,
              status: SnackbarStatus.success,
              version: SnackbarVersion.v2,
            });
            setAssignTaskDisabled(false);
            navigation.popToTop();
          }
        },
        () => {
          dispatchSnackbar({
            msg: `Something went wrong!`,
            status: SnackbarStatus.error,
            version: SnackbarVersion.v2,
            position: 'top',
          });
          setAssignTaskDisabled(false);
        },
      );
    };
    return (
      <Button
        size="lg"
        appearance={Appearance.FILLED}
        state={assignTaskDisabled ? State.DISABLED : State.ACTIVE}
        status={Status.PRIMARY}
        shadow="sm"
        mb={insets.bottom}
        onPress={handleAssignTask}
        loading={assignTaskDisabled}
      >
        Assign Task
      </Button>
    );
  };

  const RenderUserDetails = ({
    title,
    text,
    isVerified,
    showNotVerifiedText,
    iconName,
  }) => {
    return (
      <View>
        <Text size="sm" color="primary.200">
          {title}
        </Text>
        <View
          mt="lg"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Text
            size="lg"
            weight="medium"
            color={isVerified ? 'primary.500' : 'primary.200'}
          >
            {text}
          </Text>
          {isVerified && iconName?.length ? (
            <View>
              <Icon name={iconName} size="2xl" color="primary.500" />
            </View>
          ) : showNotVerifiedText ? (
            <Text size="xs" color="primary.100">
              Not Verified
            </Text>
          ) : (
            <></>
          )}
        </View>
      </View>
    );
  };

  return (
    <View flex={1}>
      <ScrollView>
        <View px="2xl" mt="4xl" justifyContent="space-between">
          <View>
            <View
              mb="9xl"
              borderRadius="lg"
              borderColor="primary.50"
              borderWidth="xs"
              px="2xl"
              py="2xl"
            >
              <View>
                <RenderUserDetails
                  title="Name"
                  text={`${valet?.name?.first || ''} ${
                    valet?.name?.last || ''
                  } (${valet?.identityCode})`}
                  isVerified
                  iconName="check-filled-400"
                />
              </View>
            </View>
          </View>
        </View>
        <View height={200} />
      </ScrollView>
      <View position="absolute" bottom={0} width="100%" bg="primary.10">
        <TaskCard
          heading={heading}
          topRightCorner={topRightCornerComponent()}
          bottomLeftCorner={bottomLeftCornerComponent()}
          bottomLeftCTA={bottomLeftCTAComponent()}
          shadow="lg"
          alwaysOpened
        />
      </View>
    </View>
  );
};

const AssignTaskWrapper = ({ navigation, route }) => {
  const campusID = useCommonStore((state) => state.campusId);
  const { role } = useUserStore((state) => state);
  const { taskId, phoneNumber } = route?.params ?? {};

  const taskFilter = {
    campus: { _id: campusID },
    _id: taskId,
    history: [{ action: taskHistoryActionTypes.created }],
  };

  const searchFilter = {
    search: { nameOrPhoneOrCode: phoneNumber },
    role: { name: UserRole.VALET, meta: { campus: { _id: campusID } } },
  };

  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'network-only',
    });
  const [netStatus, setNetStatus] = useState(true);

  const refresh = useCallback(
    () => {
      setRefreshedQueryOptions((prev) => ({
        fetchKey: (prev?.fetchKey ?? 0) + 1,
        fetchPolicy: 'network-only',
      }));
    },
    [
      /* ... */
    ],
  );

  return (
    <Layout level={2}>
      <TopNavigation
        appearance="ghost"
        level="1"
        IconLeft={
          <IconButton
            name="back-outline-300"
            size="md"
            appearance="ghost"
            iconColor="primary.500"
            onPress={() => navigateBack(navigation, role)}
          />
        }
        title="Assign Task"
      />
      <NewErrorBoundary
        fetchKey={refreshedQueryOptions.fetchKey}
        fallback={
          <NewErrorView
            errorMsg="Sorry something went wrong"
            reload={refresh}
          />
        }
      >
        <Suspense fallback={<Loading />}>
          <AssignTask
            variables={{
              count: 1,
              taskFilter,
              searchFilter,
            }}
            fetchKey={refreshedQueryOptions?.fetchKey}
            queryOptions={refreshedQueryOptions}
            refresh={refresh}
          />
        </Suspense>
      </NewErrorBoundary>
      <DisconnectedDropover
        setNetStatus={setNetStatus}
        text="No Internet Connection"
        icon="wifi-off-outline"
      />
    </Layout>
  );
};

export default AssignTaskWrapper;
