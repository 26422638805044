import React from 'react';
import { StyleSheet, useWindowDimensions, View } from 'react-native';
import NewProgressBar from './NewProgressBar';
import theme from '../../../../themes/new/theme';

interface CarouselProgressTilesProps {
  time: number;
  numberOfBars: number;
  activeIndex: number;
  availableWidth?: number;
  activeBarColor?: string;
  inactiveBarColor?: string;
  borderRadius?: number;
  height?: number;
  paddingHorizontal?: number;
}

const CarouselProgressTiles = ({
  time,
  numberOfBars,
  activeIndex,
  availableWidth,
  activeBarColor,
  inactiveBarColor,
  borderRadius,
  height,
  paddingHorizontal,
}: CarouselProgressTilesProps) => {
  let { width } = useWindowDimensions();
  if (availableWidth) {
    width = availableWidth;
  }
  const totalGap = 16;
  const singleGap = Math.floor(totalGap / (numberOfBars - 1)); // Gap between each bar
  const barWidth = Math.floor((width - 64 - 16) / numberOfBars);

  const renderTile = (index: number) => {
    const isLastBar = index === numberOfBars - 1;
    // Adjust width to include gap for all but the last bar
    const marginRight = isLastBar ? 0 : singleGap; // Add gap to right if not the last bar

    if (index === activeIndex) {
      return (
        <View
          key={index}
          style={{
            marginRight,
            borderRadius: borderRadius ?? 0,
            overflow: 'hidden',
          }}
        >
          <NewProgressBar
            width={barWidth}
            animationTime={time}
            activeColor={activeBarColor}
            inactiveColor={inactiveBarColor}
            height={height ?? 2}
          />
        </View>
      );
    }
    return (
      <View
        key={index}
        style={{
          width: barWidth,
          marginRight,
          height: height ?? 2,
          backgroundColor: inactiveBarColor ?? theme.colors.primary['300'],
          borderRadius: borderRadius ?? 0,
        }}
      />
    );
  };

  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        height: height ?? 2,
        paddingHorizontal: paddingHorizontal ?? 0,
      }}
    >
      {Array.from({ length: numberOfBars }, (_, index) => renderTile(index))}
    </View>
  );
};

export default CarouselProgressTiles;
