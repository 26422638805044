/* eslint-disable no-nested-ternary */
import React, {
  useState,
  useCallback,
  Suspense,
  useRef,
  useEffect,
} from 'react';
import { useLazyLoadQuery, readInlineData } from 'react-relay';
import { FlatList } from 'react-native';
import { debounce } from 'lodash';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import {
  Layout,
  View,
  IconButton,
  TopNavigation,
  Button,
  Text,
  Icon,
} from '../../../components/new';
import Loading from '../../../components/loading.component';
import DisconnectedDropover from '../../../components/disconnectedpopover.component';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../../utilities/NewErrorBoundary';
import NewErrorView from '../../../utilities/NewErrorView';
import useCommonStore from '../../../stores/commonStore';
import useUserStore, { UserRole } from '../../../stores/userStore';
import { searchUserScreenQuery } from './API/searchUserScreenQuery';
import UserCard from '../../../components/new/custom/UserCard';
import { getTheme } from '../../../themes/new/theme';
import SearchInput from '../../../components/new/composites/SearchInput/SearchInput';
import { findTaskQuery, findTasksQueryFragment } from './API/findTaskQuery';
import { updateTaskMutation } from './API/updateTaskMutation';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../../../components/new/primitive/snackbar/helpers/helpers';
import { useSnackbarStore } from '../../../stores/snackbar/snackbarStore';
import { findVehicleQuery } from './API/findVehicleQuery';
import { addVehicleMutation } from './API/addVehicleMutation';
import { navigateBack } from '../../../utilities/helper';
import {
  InteractionType,
  firebaseEventLogger,
} from '../../../utilities/firbaseAnalytics';
import AppConstants from '../../../utilities/AppConstants';
import { unwrapPagedData } from '../../../utilities/paginationUtilities';

interface SearchUserScreenProps {
  queryOptions: any;
  variables: any;
  taskId: string;
  setFilter: () => void;
  filter: any;
  fetchKey: any;
  partnerPayment: number;
}

const SearchUserScreen = ({
  queryOptions,
  variables,
  taskId,
  setFilter,
  filter,
  fetchKey,
  partnerPayment,
}: SearchUserScreenProps) => {
  const navigation = useNavigation<any>();
  const { role } = useUserStore((state) => state);
  const inputRef = useRef(null);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { searchUsers } = useLazyLoadQuery(searchUserScreenQuery, variables, {
    ...queryOptions,
    networkCacheConfig: { force: true },
  });

  useEffect(() => {
    setTimeout(() => {
      inputRef.current.focus();
    }, 50);
  }, [searchUsers]);

  const { dispatchSnackbar } = useSnackbarStore((state) => state);
  const campusID = useCommonStore((state) => state.campusId);

  const [searchValue, setSearchValue] = useState('');
  const [searchCardDisabled, setSearchCardDisabled] = useState(false);

  const { IMAGEKIT_PROD_URI } = AppConstants;

  const renderItem = ({ item, index }) => {
    const fullName = `${item?.name?.first} ${
      item?.name?.last !== null ? item?.name?.last : ''
    }`;
    const verified = item?.contact?.phone?.verified || false;
    const imageLink = item?.profileImage
      ? IMAGEKIT_PROD_URI + item?.profileImage
      : 'https://ik.imagekit.io/32ndhub/assets/nouser.png';
    const contactNumber = `${item?.contact?.phone?.prefix} ${item?.contact?.phone?.number}`;

    const handleCardPress = async () => {
      firebaseEventLogger('searchUser__searchNumber_Tap', {
        buttonName: 'searchNumber',
        screenName: 'searchUser',
        userType: `${role}`,
        interactionType: InteractionType.TAP,
        taskId: `${taskId}`,
        customerName: `${fullName}`,
        visitCount: `${item?.parkingVisitCount}`,
        phoneNo: `${contactNumber}`,
      });
      setSearchCardDisabled(true);
      try {
        if (role === UserRole.PARTNER) {
          navigation.navigate('PartnerCashPaymentScreen', {
            partnerPayment,
            contact: {
              prefix: item?.contact?.phone?.prefix,
              number: item?.contact?.phone?.number,
            },
          });
          setSearchCardDisabled(false);
        } else {
          // ------ step-1 -> finding task on basis of taskId and campusId ------
          const taskRef = await findTaskQuery({
            filter: { _id: taskId, campus: { _id: campusID } },
          });
          const { findTasks } = readInlineData(findTasksQueryFragment, taskRef);
          let task = {
            findTasks: unwrapPagedData(findTasks.edges),
          };
          task = task?.findTasks[0];

          // ------ step-2 finding vehicle with vehicle number and ownerId ------
          let vehicles = await findVehicleQuery({
            filter: {
              registration: { plate: task?.vehicle?.registration?.plate },
              ownerID: item?._id,
            },
          });
          vehicles = vehicles?.findVehicles;

          // ------ if vehicle with this owner id does not exist make new ------
          if (!vehicles?.length) {
            let vehicle = await addVehicleMutation({
              record: {
                brand: task?.vehicle?.brand,
                registration: task?.vehicle?.registration,
                color: task?.vehicle?.color,
                ownerID: item?._id,
              },
            });
            vehicle = vehicle?.addVehicle?.record;

            // ------ step-3 update task for vehicleId and ownerId ------
            await updateTaskMutation({
              _id: taskId,
              record: { vehicleId: vehicle?._id, ownerId: item?._id },
            });
          } else {
            // ------ again step-3 update task for vehicleId and ownerId ------
            await updateTaskMutation({
              _id: taskId,
              record: { vehicleId: task?.vehicleId, ownerId: item?._id },
            });
          }
          setSearchCardDisabled(false);
          // ------ step-4 navigate to start ride screen ------
          navigation.navigate('StartRideScreen', {
            taskId,
          });
        }
      } catch (error) {
        setSearchCardDisabled(false);
        dispatchSnackbar({
          msg: error?.res?.errors[0]?.message || 'Something went wrong!',
          status: SnackbarStatus.error,
          version: SnackbarVersion.v2,
        });
      }
    };

    return (
      <View mt={index !== 0 ? '2xl' : 0}>
        <UserCard
          number={verified ? fullName : ''}
          fullName={contactNumber}
          isVerified={item?.contact?.phone?.verified}
          onPress={handleCardPress}
          imageLink={imageLink}
          parkingCount={
            item?.parkingVisitCount < 9
              ? item?.parkingVisitCount <= 0
                ? 0
                : `0${item?.parkingVisitCount}`
              : item?.parkingVisitCount
          }
          disabled={searchCardDisabled}
        />
      </View>
    );
  };

  // ------ on press cross icon in input ------
  const onRightSearchIconPress = () => {
    if (searchValue?.length > 0) {
      setSearchValue('');
      setFilter({});
    }
  };

  // ------ handle text change for input ------
  const handleOnTextChange = async (num) => {
    setSearchValue(num);
    const number = { number: num.toString() };
    if (num?.length >= 10) {
      setFilter({ contact: { phone: number } });
    }
  };

  return (
    <View flex={1}>
      <View px="2xl" mt="4xl">
        <SearchInput
          inputRef={inputRef}
          rightIconName={
            searchValue?.length ? 'close-outline-300' : 'search-outline-400'
          }
          placeholder="91xxxxxxxx"
          onRightIconClick={onRightSearchIconPress}
          value={searchValue}
          onChangeText={handleOnTextChange}
          leftIconName=""
          inputMode="numeric"
          keyboardType="numeric"
          autoFocus
          maxLength={15}
        />
      </View>
      {searchUsers && searchUsers?.length ? (
        <FlatList
          style={{
            marginTop: getTheme().space['4xl'],
            // paddingHorizontal: getTheme().space['2xl'],
          }}
          data={searchUsers}
          renderItem={renderItem}
        />
      ) : searchValue?.length >= 10 && !searchUsers?.length ? (
        <View flex={1} alignItems="center" mt="9xl">
          <Icon size="3xl+3xl" color="primary.100" name="search-outline-400" />
          <Text
            size="xl"
            color="primary.100"
            weight="medium"
            textAlign="center"
            mt="2xl"
          >
            No Results for “{filter?.contact?.phone?.number}”
          </Text>
          <Button
            size="lg"
            appearance="ghost"
            status="info"
            mt="sm"
            onPress={() =>
              navigation.navigate('AddNewCustomerScreen', {
                taskId,
                contact: filter?.contact,
                partnerPayment,
              })
            }
          >
            + Add New Customer
          </Button>
        </View>
      ) : (
        <></>
      )}
    </View>
  );
};

const SearchUserScreenWrapper = ({ navigation, route }) => {
  const { taskId, partnerPayment } = route?.params ?? {};
  const [filter, setFilter] = useState({});
  const { role } = useUserStore((state) => state);
  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'network-only',
    });
  const [netStatus, setNetStatus] = useState(true);

  const refresh = useCallback(
    () => {
      setRefreshedQueryOptions((prev) => ({
        fetchKey: (prev?.fetchKey ?? 0) + 1,
        fetchPolicy: 'network-only',
      }));
    },
    [
      /* ... */
    ],
  );

  useFocusEffect(
    useCallback(() => {
      refresh();
      return () => {
        // Useful for cleanup functions
      };
    }, []),
  );

  return (
    <Layout level={2}>
      <TopNavigation
        appearance="ghost"
        level="1"
        IconLeft={
          <IconButton
            name="back-outline-300"
            size="md"
            appearance="ghost"
            iconColor="primary.500"
            onPress={() => navigateBack(navigation, role)}
          />
        }
        title="Search"
      />
      <NewErrorBoundary
        fetchKey={refreshedQueryOptions.fetchKey}
        fallback={
          <NewErrorView
            errorMsg="Sorry something went wrong"
            reload={refresh}
          />
        }
      >
        <Suspense fallback={<Loading />}>
          <SearchUserScreen
            variables={{
              filter,
            }}
            fetchKey={refreshedQueryOptions?.fetchKey}
            queryOptions={refreshedQueryOptions}
            refresh={refresh}
            taskId={taskId}
            setFilter={setFilter}
            filter={filter}
            partnerPayment={partnerPayment}
          />
        </Suspense>
      </NewErrorBoundary>
      <DisconnectedDropover
        setNetStatus={setNetStatus}
        text="No Internet Connection"
        icon="wifi-off-outline"
      />
    </Layout>
  );
};

export default SearchUserScreenWrapper;
