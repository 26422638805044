import React from 'react';
import { StyleSheet } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { View } from '../../../../../../components/new';
import TopSearchBarPlaceholder from '../../common/TopSearchBarPlaceholder';
import TopBannerPlaceholder from './components/TopBannerPlaceholder';
import RestaurantOfferPlaceholder from './components/RestaurantsOfferPlaceholder';
import MomentsWidgetPlaceholder from './components/MomentsWidgetPlaceholder';
import QuickAccessWidgetPlaceholder from './components/QuickAccessWidgetPlaceholder';
import Placeholder from '../../../../../../components/new/custom/Placeholder/Placeholder';

const HomePagePlaceholder = () => {
  const { top } = useSafeAreaInsets();

  return (
    <View paddingTop={top}>
      <Placeholder>
        <View px="2xl">
          <TopSearchBarPlaceholder />
          <TopBannerPlaceholder />
          <View>
            <RestaurantOfferPlaceholder />
          </View>
          <View mt="9xl">
            <MomentsWidgetPlaceholder />
          </View>
          <View mt="4xl">
            <QuickAccessWidgetPlaceholder />
          </View>
        </View>
      </Placeholder>
    </View>
  );
};

export default HomePagePlaceholder;

const styles = StyleSheet.create({
  avatar: {
    position: 'absolute',
    width: 100,
    height: 100,
    borderRadius: 100,
    top: -50,
    left: '50%',
    transform: [{ translateX: -50 }],
  },
});
