import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { Platform } from 'react-native';
import { getMinutesBetweenDates } from '../utilities/helper';

export enum LoginModalScreenSteps {
  ENTER_PHONE_NUMBER = 'enter_phone_number',
  ENTER_OTP = 'enter_otp',
  SIGN_UP = 'sign_up',
}

export interface SignUpPayload {
  name: {
    first: string;
    last: string;
  };
}

export interface LoginModalStoreInterface {
  isOpen: boolean;
  currentScreen: LoginModalScreenSteps;
  lastStateUpdatedAt: Date | null;
  mobileNumber: string;
  signupData: SignUpPayload;
  whatsappLoginEnabled: boolean;
  loginScreenTitle: string;
  signUpScreenTitle: string;
  showWelcomeModal: boolean;
  secondarySignup: boolean;
  showBackIcon: boolean;
  authModalTheme: string;
  appliedCoupon: any;
  isLoginWhatsappActive: boolean;
  waId: string;
  countryCallingCode: string;
  setIsOpen: (open: boolean) => void;
  setLoginModalState: (open: boolean) => void;
  setSignupPayload: (payload: SignUpPayload) => void;
  setMobileNumber: (mobileNumber: string) => void;
  setCurrentScreen: (currentScreen: LoginModalScreenSteps) => void;
  setWhatsappLoginEnabled: (enabled: boolean) => void;
  setLoginScreenTitle: (title: string) => void;
  setSignUpScreenTitle: (title: string) => void;
  setShowWelcomeModal: (open: boolean) => void;
  setSecondarySignup: (enabled: boolean) => void;
  setShowBackIcon: (showBackIcon: boolean) => void;
  setAuthModalTheme: (title: string) => void;
  setAppliedCoupon: (appliedCoupon: any) => void;
  setIsLoginWhatsappActive: (active: boolean) => void;
  setWaId: (waId: string) => void;
  resetStore: () => void;
  setCountryCallingCode: (callingCode: string) => void;
}

const useLoginModalStore = create<LoginModalStoreInterface>()(
  devtools(
    (set, get) => ({
      isOpen: false,
      currentScreen: LoginModalScreenSteps.ENTER_PHONE_NUMBER,
      lastStateUpdatedAt: null,
      mobileNumber: '',
      signupData: { name: { first: '', last: '' } },
      whatsappLoginEnabled: Platform.OS === 'android',
      loginScreenTitle: 'Login or Sign Up',
      signUpScreenTitle: 'Sign Up',
      showWelcomeModal: false,
      secondarySignup: false,
      showBackIcon: true,
      authModalTheme: '',
      appliedCoupon: null,
      isLoginWhatsappActive: false,
      waId: '',
      countryCallingCode: '91',
      setLoginModalState: (open: boolean) =>
        set(() => ({
          isOpen: open,
          currentScreen: LoginModalScreenSteps.ENTER_OTP,
        })),
      setShowBackIcon: (showBackIcon: boolean) =>
        set(() => ({
          showBackIcon,
        })),
      setIsOpen: (open: boolean) =>
        set((state) => {
          if (open && state.lastStateUpdatedAt) {
            const diff = getMinutesBetweenDates(
              new Date(state.lastStateUpdatedAt),
              new Date(),
            );
            if (diff > 5) {
              return {
                isOpen: true,
                currentScreen: LoginModalScreenSteps.ENTER_PHONE_NUMBER,
                lastStateUpdatedAt: null,
              };
            }
          }
          if (
            !open &&
            state.currentScreen === LoginModalScreenSteps.ENTER_PHONE_NUMBER
          ) {
            return {
              isOpen: false,
              currentScreen: LoginModalScreenSteps.ENTER_PHONE_NUMBER,
            };
          }
          return {
            isOpen: open,
            currentScreen: LoginModalScreenSteps.ENTER_PHONE_NUMBER,
          };
        }),
      setMobileNumber: (mobileNumber: string) =>
        set((state) => ({
          mobileNumber,
          lastStateUpdatedAt: new Date(),
        })),
      setCurrentScreen: (currentScreen: LoginModalScreenSteps) =>
        set((state) => ({
          currentScreen,
        })),
      setSignupPayload: (payload: SignUpPayload) =>
        set((state) => ({
          signupData: payload,
        })),
      setWhatsappLoginEnabled: (enabled: boolean) => {
        const isEnabled = enabled && Platform.OS === 'android';
        set(() => ({
          whatsappLoginEnabled: isEnabled,
        }));
      },
      setLoginScreenTitle: (title: string) =>
        set((state) => ({
          loginScreenTitle: title,
        })),
      setSignUpScreenTitle: (title: string) =>
        set((state) => ({
          signUpScreenTitle: title,
        })),
      setShowWelcomeModal: (open: boolean) =>
        set((state) => ({
          showWelcomeModal: open,
        })),
      setSecondarySignup: (enabled: boolean) =>
        set((state) => ({
          secondarySignup: enabled,
        })),
      setAuthModalTheme: (authModalTheme: string) =>
        set((state) => ({
          authModalTheme,
        })),
      setAppliedCoupon: (appliedCoupon: any) =>
        set((state) => {
          return {
            appliedCoupon,
          };
        }),
      setIsLoginWhatsappActive: (active: boolean) =>
        set(() => ({
          isLoginWhatsappActive: active,
        })),
      setWaId: (waId: string) =>
        set(() => ({
          waId,
        })),
      resetStore: () =>
        set(() => ({
          isOpen: false,
          currentScreen: LoginModalScreenSteps.ENTER_PHONE_NUMBER,
          lastStateUpdatedAt: null,
          mobileNumber: '',
          signupData: { name: { first: '', last: '' } },
          whatsappLoginEnabled: Platform.OS === 'android',
          loginScreenTitle: 'Login or Sign Up',
          signUpScreenTitle: 'Sign Up',
          showWelcomeModal: false,
          secondarySignup: false,
          showBackIcon: true,
          authModalTheme: '',
          appliedCoupon: null,
          isLoginWhatsappActive: false,
          waId: '',
          countryCallingCode: '',
        })),
      setCountryCallingCode: (callingCode: string) =>
        set(() => ({
          countryCallingCode: callingCode,
        })),
    }),
    {
      name: 'LoginModalStore',
    },
  ),
);

export default useLoginModalStore;
