import React, { PropsWithChildren } from 'react';
import { Fade, Placeholder as RnPlaceholder } from 'rn-placeholder';
import useCommonStore from '../../../../stores/commonStore';
import BlockPlaceholder from './BlockPlaceholder';
import LinePlaceholder from './LinePlaceholder';
import PlaceholderBaseView from './PlaceholderBaseView';
import { getTheme } from '../../../../themes/new/theme';

type PlaceholderProps = {
  animatedColorLight?: string;
  animatedColorDark?: string;
};

const Placeholder = ({ children }: PropsWithChildren<PlaceholderProps>) => {
  const theme = useCommonStore((state) => state.theme);

  return (
    <RnPlaceholder
      Animation={(props) => (
        <Fade
          {...props}
          style={{
            backgroundColor: getTheme(theme).colors.grey[20],
          }}
        />
      )}
    >
      {children}
    </RnPlaceholder>
  );
};

Placeholder.BaseView = PlaceholderBaseView;
Placeholder.Block = BlockPlaceholder;
Placeholder.Line = LinePlaceholder;

export default Placeholder;
