/**
 * @generated SignedSource<<a80bff7523e781571b4a2de94263856c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EnumeventCategory = "ART" | "CRAFTS" | "DANCE_MUSIC" | "FOOD_DRINKS" | "HEALTH_WELLNESS" | "SENSORY" | "%future added value";
export type EnumeventMediaCoverMediaType = "image" | "video" | "%future added value";
export type Referer = "ADDONS" | "EVENT" | "FOOD_ORDER" | "INVESTMENT_LEAD" | "PARKING" | "POPUP_ORDER" | "POPUP_STORE" | "RESTAURANT" | "STORE" | "%future added value";
export type findEventInput = {
  _id?: any | null;
  campus: FilterFindManyeventCampusInput;
  category?: ReadonlyArray<EnumeventCategory | null> | null;
  eventTiming?: ReadonlyArray<FilterFindManyeventEventTimingInput | null> | null;
  host?: FilterFindManyeventHostInput | null;
  isActive: boolean;
  priorityEventId?: string | null;
  title?: string | null;
  upcomingEvent?: boolean | null;
  venue?: string | null;
};
export type FilterFindManyeventCampusInput = {
  _id: any;
};
export type FilterFindManyeventHostInput = {
  name?: string | null;
};
export type FilterFindManyeventEventTimingInput = {
  _id?: any | null;
  date?: any | null;
  slot?: FilterFindManyeventEventTimingSlotInput | null;
};
export type FilterFindManyeventEventTimingSlotInput = {
  _id?: any | null;
  availableTickets?: ReadonlyArray<FilterFindManyeventEventTimingSlotAvailableTicketsInput | null> | null;
  endTime?: any | null;
  isActive?: boolean | null;
  slotSpace?: number | null;
  slotTag?: string | null;
  startTime?: any | null;
};
export type FilterFindManyeventEventTimingSlotAvailableTicketsInput = {
  _id?: any | null;
  isActive?: boolean | null;
  maxSpace?: number | null;
  name?: string | null;
  personAllowed?: number | null;
  price?: number | null;
};
export type invoiceWorkshopDetailsInput = {
  slotId: any;
  tickets?: ReadonlyArray<invoiceWorkshopDetailsTicketsInput | null> | null;
};
export type invoiceWorkshopDetailsTicketsInput = {
  spaces: number;
  ticketId: any;
};
export type reservationConfirmationScreenQuery$variables = {
  filter: findEventInput;
  includeGetNetPrice: boolean;
  orderId: string;
  orderType: Referer;
  useRewardPoints: boolean;
  workshopDetails?: invoiceWorkshopDetailsInput | null;
};
export type reservationConfirmationScreenQuery$data = {
  readonly findEvents: ReadonlyArray<{
    readonly _id: any;
    readonly campus: {
      readonly _id: any;
    };
    readonly description: string;
    readonly eventTiming: ReadonlyArray<{
      readonly _id: any | null;
      readonly date: any;
      readonly slot: {
        readonly _id: any | null;
        readonly endTime: any;
        readonly slotSpace: number;
        readonly slotTag: string;
        readonly slotsLeft: number | null;
        readonly startTime: any;
      };
    } | null>;
    readonly faqs: ReadonlyArray<{
      readonly answer: string;
      readonly question: string;
    } | null> | null;
    readonly host: {
      readonly description: string;
      readonly media: ReadonlyArray<{
        readonly cover: boolean | null;
        readonly displayOrder: number;
        readonly mediaType: EnumeventMediaCoverMediaType;
        readonly mediaURL: string;
      } | null> | null;
      readonly name: string;
    } | null;
    readonly media: {
      readonly cover: ReadonlyArray<{
        readonly cover: boolean | null;
        readonly displayOrder: number;
        readonly mediaType: EnumeventMediaCoverMediaType;
        readonly mediaURL: string;
      } | null> | null;
      readonly past: ReadonlyArray<{
        readonly cover: boolean | null;
        readonly displayOrder: number;
        readonly mediaType: EnumeventMediaCoverMediaType;
        readonly mediaURL: string;
      } | null> | null;
      readonly testimonials: ReadonlyArray<{
        readonly cover: boolean | null;
        readonly displayOrder: number;
        readonly mediaType: EnumeventMediaCoverMediaType;
        readonly mediaURL: string;
      } | null> | null;
    } | null;
    readonly mediaCoverages: ReadonlyArray<{
      readonly hyperLink: string | null;
      readonly imageUrl: string;
    } | null> | null;
    readonly motivation: ReadonlyArray<{
      readonly logo: string;
      readonly title: string;
    } | null> | null;
    readonly timeline: ReadonlyArray<{
      readonly duration: number | null;
      readonly logo: string;
      readonly media: ReadonlyArray<{
        readonly cover: boolean | null;
        readonly displayOrder: number;
        readonly mediaType: EnumeventMediaCoverMediaType;
        readonly mediaURL: string;
      } | null> | null;
      readonly title: string;
    } | null> | null;
    readonly title: string;
    readonly venue: string;
  }>;
  readonly getNetPrice?: {
    readonly cashbackAmount: number | null;
    readonly cashbackPercentage: number | null;
    readonly discountPercentage: number | null;
    readonly priceDetails: {
      readonly discountGiven: number;
      readonly grossAmount: number;
      readonly netAmount: number;
      readonly rewardsUsed: number;
    };
  } | null;
};
export type reservationConfirmationScreenQuery = {
  response: reservationConfirmationScreenQuery$data;
  variables: reservationConfirmationScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "includeGetNetPrice"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderType"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "useRewardPoints"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "workshopDetails"
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v9 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "mediaURL",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "mediaType",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "cover",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "displayOrder",
    "storageKey": null
  }
],
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "eventMediaCover",
  "kind": "LinkedField",
  "name": "media",
  "plural": true,
  "selections": (v9/*: any*/),
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "logo",
  "storageKey": null
},
v12 = [
  {
    "condition": "includeGetNetPrice",
    "kind": "Condition",
    "passingValue": true,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "orderId",
            "variableName": "orderId"
          },
          {
            "kind": "Variable",
            "name": "orderType",
            "variableName": "orderType"
          },
          {
            "kind": "Variable",
            "name": "useRewardPoints",
            "variableName": "useRewardPoints"
          },
          {
            "kind": "Variable",
            "name": "workshopDetails",
            "variableName": "workshopDetails"
          }
        ],
        "concreteType": "invoiceModelPriceExtendedTC",
        "kind": "LinkedField",
        "name": "getNetPrice",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "invoicePriceDetails",
            "kind": "LinkedField",
            "name": "priceDetails",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "grossAmount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "discountGiven",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "rewardsUsed",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "netAmount",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "discountPercentage",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cashbackPercentage",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cashbackAmount",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "filter"
      }
    ],
    "concreteType": "event",
    "kind": "LinkedField",
    "name": "findEvents",
    "plural": true,
    "selections": [
      (v6/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "eventCampus",
        "kind": "LinkedField",
        "name": "campus",
        "plural": false,
        "selections": [
          (v6/*: any*/)
        ],
        "storageKey": null
      },
      (v7/*: any*/),
      (v8/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "venue",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "eventMedia",
        "kind": "LinkedField",
        "name": "media",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "eventMediaCover",
            "kind": "LinkedField",
            "name": "cover",
            "plural": true,
            "selections": (v9/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "eventMediaCover",
            "kind": "LinkedField",
            "name": "past",
            "plural": true,
            "selections": (v9/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "eventMediaCover",
            "kind": "LinkedField",
            "name": "testimonials",
            "plural": true,
            "selections": (v9/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "eventMediaCoverages",
        "kind": "LinkedField",
        "name": "mediaCoverages",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "imageUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hyperLink",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "eventFaqs",
        "kind": "LinkedField",
        "name": "faqs",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "question",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "answer",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "eventHost",
        "kind": "LinkedField",
        "name": "host",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          (v8/*: any*/),
          (v10/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "eventMotivation",
        "kind": "LinkedField",
        "name": "motivation",
        "plural": true,
        "selections": [
          (v7/*: any*/),
          (v11/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "eventTimeline",
        "kind": "LinkedField",
        "name": "timeline",
        "plural": true,
        "selections": [
          (v7/*: any*/),
          (v11/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "duration",
            "storageKey": null
          },
          (v10/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "eventEventTiming",
        "kind": "LinkedField",
        "name": "eventTiming",
        "plural": true,
        "selections": [
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "date",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "eventEventTimingSlot",
            "kind": "LinkedField",
            "name": "slot",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startTime",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endTime",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "slotSpace",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "slotsLeft",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "slotTag",
                "storageKey": null
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "reservationConfirmationScreenQuery",
    "selections": (v12/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Operation",
    "name": "reservationConfirmationScreenQuery",
    "selections": (v12/*: any*/)
  },
  "params": {
    "cacheID": "0f8a94b9591083652083ff01f5a3d143",
    "id": null,
    "metadata": {},
    "name": "reservationConfirmationScreenQuery",
    "operationKind": "query",
    "text": "query reservationConfirmationScreenQuery(\n  $useRewardPoints: Boolean!\n  $orderId: ID!\n  $orderType: Referer!\n  $filter: findEventInput!\n  $includeGetNetPrice: Boolean!\n  $workshopDetails: invoiceWorkshopDetailsInput\n) {\n  getNetPrice(useRewardPoints: $useRewardPoints, orderId: $orderId, orderType: $orderType, workshopDetails: $workshopDetails) @include(if: $includeGetNetPrice) {\n    priceDetails {\n      grossAmount\n      discountGiven\n      rewardsUsed\n      netAmount\n    }\n    discountPercentage\n    cashbackPercentage\n    cashbackAmount\n  }\n  findEvents(filter: $filter) {\n    _id\n    campus {\n      _id\n    }\n    title\n    description\n    venue\n    media {\n      cover {\n        mediaURL\n        mediaType\n        cover\n        displayOrder\n      }\n      past {\n        mediaURL\n        mediaType\n        cover\n        displayOrder\n      }\n      testimonials {\n        mediaURL\n        mediaType\n        cover\n        displayOrder\n      }\n    }\n    mediaCoverages {\n      imageUrl\n      hyperLink\n    }\n    faqs {\n      question\n      answer\n    }\n    host {\n      name\n      description\n      media {\n        mediaURL\n        mediaType\n        cover\n        displayOrder\n      }\n    }\n    motivation {\n      title\n      logo\n    }\n    timeline {\n      title\n      logo\n      duration\n      media {\n        mediaURL\n        mediaType\n        cover\n        displayOrder\n      }\n    }\n    eventTiming {\n      _id\n      date\n      slot {\n        startTime\n        endTime\n        slotSpace\n        slotsLeft\n        slotTag\n        _id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "722453d1c75550bdafe582e142fd2598";

export default node;
