/* eslint-disable no-nested-ternary */
import React from 'react';
import { View, Text, ChipGroup } from '../../../../components/new';
import TaskTypeDropDown from './TaskTypeDropDown';
import { filterComponentConfig } from '../constants';

const OverviewTaskFilterWidget = ({
  taskType,
  taskFilter,
  onTaskTypeSelect,
  onTaskFilterSelect,
}) => {
  return (
    <View>
      <View px="2xl" py="lg" flexDirection="row" justifyContent="space-between">
        <Text size="md" weight="medium">
          Task Overview
        </Text>
        <View>
          <TaskTypeDropDown
            taskSelected={taskType}
            onSelect={onTaskTypeSelect}
          />
        </View>
      </View>
      <View py="2xl">
        <ChipGroup
          data={filterComponentConfig}
          selectedIndex={
            filterComponentConfig.find((f) => f.name === taskFilter)?.id
          }
          setSelectedIndex={onTaskFilterSelect}
        />
      </View>
    </View>
  );
};

export default OverviewTaskFilterWidget;
