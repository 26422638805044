import { graphql } from 'react-relay';

/* eslint-disable import/prefer-default-export */

export const finishTaskScreenQuery = graphql`
  query finishTaskScreenQuery($filter: findTaskFilterInput!) {
    ...finishTaskScreenQueryTaskFragment
      @arguments(taskFilter: $filter, sort: null, count: 1)
  }
`;

export const finishTaskScreenQueryTaskFragment = graphql`
  fragment finishTaskScreenQueryTaskFragment on Query
  @refetchable(queryName: "finishTaskScreenTaskQuery")
  @argumentDefinitions(
    taskFilter: { type: "findTaskFilterInput!" }
    sort: { type: "SortConnectiontaskEnum" }
    count: { type: "Int" }
    cursor: { type: "String" }
  ) {
    findTasks: findTasksV2(
      filter: $taskFilter
      sort: $sort
      first: $count
      after: $cursor
    ) @connection(key: "finishTaskScreenQueryTaskFragment__findTasks") {
      edges {
        node {
          _id
          vehicle {
            brand {
              name
              brandLogo
            }
            registration {
              plate
            }
            color
          }
          taskType
          taskStartLocation {
            locationId
            name
            parkingSpaces {
              floor {
                floorName
                floorId
                bay {
                  bayId
                  bayName
                }
              }
            }
          }
          taskEndLocation {
            locationId
            name
            parkingSpaces {
              floor {
                floorName
                floorId
                bay {
                  bayId
                  bayName
                }
              }
            }
          }
          campus {
            _id
            name
          }
          history {
            _id
            action
            actor
            event_at
          }
          assignedTo
          valet {
            _id
            name {
              first
              last
            }
            identityCode
            contact {
              phone {
                prefix
                number
              }
            }
          }
          user {
            name {
              first
              last
            }
            contact {
              phone {
                prefix
                number
              }
            }
          }
          ETA {
            _id
            startAt
            delayAt
            originalPredictionTime
            pendingRecall
            available
            busy
          }
          vehicleId
          ownerId
          parkingId
          parkingTag
          createdBy {
            status
          }
        }
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`;
