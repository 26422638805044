import React from 'react';
import { Image } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Icon, Text, View } from '../components/new';
import Pressable from '../components/new/basic/Pressable/Pressable';
import TrackingFooterComponent from '../components/new/custom/TrackingFooter.component';
import EventBus, { EventBusEventsEnum } from '../utilities/EventBus';
import { firebaseEventLogger } from '../utilities/firbaseAnalytics';
import { space } from '../themes/new/theme';
import useCommonStore from '../stores/commonStore';

interface BottomTabRoutes {
  title: string;
  activeIcon: string;
  inactiveIcon: string;
  navigateTo: string;
  showNewTag: boolean;
}

const RoutesArr: BottomTabRoutes[] = [
  {
    title: 'Home',
    activeIcon: 'home-filled-300',
    inactiveIcon: 'home-outline-300',
    navigateTo: 'home',
    showNewTag: false,
  },
  {
    title: 'Dine-In',
    activeIcon: 'dining-filled',
    inactiveIcon: 'dining-outline',
    navigateTo: 'dineIn',
    showNewTag: false,
  },
  {
    title: 'Invest',
    activeIcon: 'transaction-filled',
    inactiveIcon: 'transaction-outline',
    navigateTo: 'invest',
    showNewTag: true,
  },
  {
    title: 'Experiences',
    activeIcon: 'calendar-filled-300',
    inactiveIcon: 'calendar-outline-300',
    navigateTo: 'events',
    showNewTag: false,
  },
  {
    title: 'Shop',
    activeIcon: 'shop-filled-400',
    inactiveIcon: 'shop-400',
    navigateTo: 'store',
    showNewTag: false,
  },
];

const BottomTabBarNew = ({ navigation, state }) => {
  const selectedState = state.index;
  const { showInvestNewTag, setShowInvestNewTag } = useCommonStore(
    (storeState) => storeState,
  );
  // const isLoggedIn = useAuthStore((authState) => authState.isLoggedIn);

  return (
    <View
      bg="background.primary.base"
      borderTopColor="primary.50"
      borderTopWidth="xs"
    >
      {/* {isLoggedIn && ( */}
      <TrackingFooterComponent routeName={RoutesArr?.[selectedState]?.title} />
      {/* )} */}
      <SafeAreaView edges={['bottom']}>
        <View
          style={{ flexDirection: 'row', justifyContent: 'space-around' }}
          px="2xl"
          py="sm+md"
        >
          {RoutesArr.map((route, index) => {
            return (
              <Pressable
                style={{ flex: 1, alignItems: 'center' }}
                key={route.title}
                onPress={() => {
                  let { title } = route;
                  if (title === 'Dine-In') {
                    title = 'dineIn';
                  }
                  if (title === 'Events') {
                    title = 'events';
                  }
                  if (title === 'Invest' && showInvestNewTag) {
                    setShowInvestNewTag(false);
                  }
                  const eventName = `bottomTabBar__${title}_Tap`;
                  firebaseEventLogger(eventName, {
                    buttonName: title,
                    screenName: 'bottomTabBar',
                    userType: 'user',
                    interactionType: 'tap',
                  });
                  if (index === selectedState) {
                    // scroll to top
                    switch (index) {
                      case 0:
                        EventBus.emitEvent(
                          EventBusEventsEnum.SCROLL_TO_TOP_TAB_1,
                          null,
                        );
                        break;
                      case 1:
                        EventBus.emitEvent(
                          EventBusEventsEnum.SCROLL_TO_TOP_TAB_2,
                          null,
                        );
                        break;
                      case 2:
                        EventBus.emitEvent(
                          EventBusEventsEnum.SCROLL_TO_TOP_TAB_3,
                          null,
                        );
                        break;
                      case 3:
                        EventBus.emitEvent(
                          EventBusEventsEnum.SCROLL_TO_TOP_TAB_4,
                          null,
                        );
                        break;
                      case 4:
                        EventBus.emitEvent(
                          EventBusEventsEnum.SCROLL_TO_TOP_TAB_5,
                          null,
                        );
                        break;
                      default:
                        break;
                    }
                  }
                  navigation.navigate(route.navigateTo);
                }}
              >
                <View p="xs">
                  <View alignItems="center">
                    <Icon
                      size="2xl"
                      shape="square"
                      color={
                        index === selectedState ? 'primary.500' : 'primary.100'
                      }
                      name={
                        index === selectedState
                          ? route.activeIcon
                          : route.inactiveIcon
                      }
                    />
                    {route?.showNewTag && showInvestNewTag ? (
                      <View position="absolute" top={-4} right={-18}>
                        <Image
                          source={require('../../assets/images/new-tag.webp')}
                          style={{
                            height: space['4xl'],
                            width: space['6xl'],
                            resizeMode: 'contain',
                          }}
                        />
                      </View>
                    ) : null}
                  </View>
                  <Text
                    style={{ textAlign: 'center' }}
                    color={
                      index === selectedState ? 'primary.500' : 'primary.100'
                    }
                    size="2xs"
                  >
                    {route.title}
                  </Text>
                </View>
              </Pressable>
            );
          })}
        </View>
      </SafeAreaView>
    </View>
  );
};

export default BottomTabBarNew;
