/* eslint-disable import/prefer-default-export */

import { useMemo } from 'react';
import * as payBillHelper from '../helpers';
import {
  GQLGetNetPriceData,
  GQLPayBillConfig,
  PayBillRouteParams,
  GQLAddonConfig,
  StoreInitialData,
} from '../types';
import { paymentOrderTypeEnum } from '../../../../../utilities/helper';
import {
  extractBlackMembershipAddonConfig,
  isStandaloneBlackMembershipBeignPurchased,
} from '../../../plusMembership/helpers';
import { normalizeToNumberOrZero } from '../../../../../utilities/Utility';

export const usePayBillStoreDataBuilder = (
  routeParams: PayBillRouteParams,
  payBillConfig: GQLPayBillConfig,
  getNetPrice: GQLGetNetPriceData,
  getNetPriceWithMembership: GQLGetNetPriceData,
): StoreInitialData => {
  const initialStoreData: StoreInitialData = useMemo(() => {
    const { orderId, orderType } = routeParams;
    const priceDetails = payBillHelper.buildStorePriceData(getNetPrice);
    const priceDetailsWithPlusMembership = getNetPriceWithMembership
      ? payBillHelper.buildStorePriceData(getNetPriceWithMembership)
      : null;
    const charityConfig = payBillHelper.getEnabledCharity(
      payBillConfig?.findCharities,
    );
    const blackMembershipConfig =
      extractBlackMembershipAddonConfig<GQLAddonConfig>(
        payBillConfig.findAddons,
      );
    const eventsData =
      orderType === paymentOrderTypeEnum.EVENT
        ? payBillHelper.build32ndEventsData(routeParams)
        : null;
    const pageTitle = payBillHelper.resolvePageTitle(
      orderType,
      orderId,
      getNetPrice,
      blackMembershipConfig,
    );

    return {
      title: pageTitle,
      orderId: routeParams?.orderId,
      orderType: routeParams?.orderType,
      paymentMethod: routeParams?.paymentmethod,
      billAmount: normalizeToNumberOrZero(routeParams?.amount),
      isPlusMembershipPurchased: isStandaloneBlackMembershipBeignPurchased(
        orderType,
        orderId,
        [blackMembershipConfig],
      ),
      config: {
        tipEnabled: payBillHelper.isTipEnabled(
          orderType,
          payBillConfig.findConfig,
        ),
        charityEnabled: Boolean(charityConfig && charityConfig?._id),
        billPayEnabled: payBillHelper.isBillPayEnabled(orderType, getNetPrice),
        pointsEnabled: payBillHelper.isPointsEnabled(orderType, getNetPrice),
        blackMembershipConfig,
        charityConfig,
      },
      priceDetails,
      priceDetailsWithPlusMembership,
      rawQueryData: {
        getNetPrice,
        getNetPriceWithMembership,
      },
      event: eventsData,
    };
  }, [routeParams, getNetPrice, getNetPriceWithMembership, payBillConfig]);

  return initialStoreData;
};
