import React, { useState } from 'react';
import { ThemeProvider, ThemeConsumer } from 'styled-components/native';
import { Platform } from 'react-native';
import * as Linking from 'expo-linking';
import { useNavigation } from '@react-navigation/native';
import { Text, View } from '../new';
import SelectLoginMethodCarousel from '../new/custom/Carousel/SelectLoginMethodCarousel';
import CustomPressable from '../new/primitive/CustomPressable/CustomPressable';
import { firebaseEventLogger } from '../../utilities/firbaseAnalytics';
import useLoginFlowStore, {
  LoginFlowScreenSteps,
} from '../../stores/loginFlowStore';
import { getTheme, themeModes } from '../../themes/new/theme';
import AppConstants from '../../utilities/AppConstants';

function randomBoolean(): boolean {
  return Math.random() < 0.5;
}

const ChooseLoginMethod = () => {
  const loginFlowStore = useLoginFlowStore((state) => state);
  const navigation = useNavigation();
  const [isWhatsappFirst] = useState<boolean>(randomBoolean());

  const redirectWebPage = (type) => {
    const url =
      type === 1
        ? 'https://club.32nd.com/t&c'
        : 'https://club.32nd.com/privacy-policy';
    if (Platform.OS === 'web') {
      window.open(url);
    } else {
      navigation?.navigate('CustomWebview', {
        title: type === 1 ? 'Terms and Conditions' : 'Privacy policy',
        url,
      });
    }
  };

  const whatsAppLogin = async () => {
    try {
      if (Platform.OS !== 'web') {
        const url = `https://32nd.authlink.me/?redirectUri=${AppConstants.SCHEME}://login`;
        await Linking.openURL(url);
      } else {
        const url = `https://32nd.authlink.me/?redirectUri=${AppConstants.WEB_URL}/login`;
        await Linking.openURL(url);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const whatsAppLoginButton = (
    <CustomPressable
      appearance="filled"
      size="md"
      textSize="lg"
      iconSize="md"
      shadow="md"
      state="active"
      status="primary"
      rounded="lg"
      leftStyle={{ width: 28, height: 28 }}
      iconColor="primary.400"
      leftContent={require('../../../assets/whatsapp.png')}
      rightContent="forward-outline-300"
      // mb={isWhatsappFirst ? '2xl' : 0}
      onPress={whatsAppLogin}
    >
      Login Via WhatsApp
    </CustomPressable>
  );

  const phoneLoginButton = (
    <CustomPressable
      appearance="filled"
      size="md"
      textSize="lg"
      iconSize="md"
      shadow="md"
      state="active"
      status="primary"
      rounded="lg"
      leftStyle={{ width: 28, height: 28 }}
      iconColor="primary.400"
      leftContent={require('../../../assets/mobile.png')}
      rightContent="forward-outline-300"
      // mb={isWhatsappFirst ? 0 : '2xl'}
      mb="2xl"
      onPress={() => {
        loginFlowStore.setCurrentScreen(
          LoginFlowScreenSteps.ENTER_PHONE_NUMBER,
        );
        navigation.navigate('enterPhone');
      }}
    >
      Login Via Mobile Number
    </CustomPressable>
  );

  return (
    <View mt="2xl" style={{ flex: 1 }}>
      <View style={{ flex: 1 }}>
        <SelectLoginMethodCarousel />
      </View>
      <View justifyContent="center">
        <ThemeConsumer>
          {(theme) => (
            <ThemeProvider
              theme={
                theme.currentThemeMode === themeModes.light
                  ? getTheme(themeModes.dark)
                  : getTheme(themeModes.light)
              }
            >
              {phoneLoginButton}
              {/* {isWhatsappFirst ? whatsAppLoginButton : phoneLoginButton} */}
            </ThemeProvider>
          )}
        </ThemeConsumer>
        {/* {isWhatsappFirst ? phoneLoginButton : whatsAppLoginButton} */}
        {Platform.OS === 'android' ? whatsAppLoginButton : null}
        <Text
          m="2xl"
          style={{ textAlign: 'center' }}
          size="xs"
          color="primary.200"
        >
          By Signing up, you agree to our{' '}
          <Text
            color="info.500"
            onPress={() => {
              firebaseEventLogger('loginSignupHome__termsAndConditions_Tap', {
                buttonName: 'termsAndConditions',
                screenName: 'loginSignupHome',
                userType: 'user',
                interactionType: 'tap',
              });
              redirectWebPage(1);
            }}
          >
            Terms and Conditions
          </Text>{' '}
          and{' '}
          <Text
            color="info.500"
            onPress={() => {
              firebaseEventLogger('loginSignupHome__privacyPolicy_Tap', {
                buttonName: 'privacyPolicy',
                screenName: 'loginSignupHome',
                userType: 'user',
                interactionType: 'tap',
              });
              redirectWebPage(2);
            }}
          >
            Privacy policy
          </Text>
        </Text>
      </View>
    </View>
  );
};

export default ChooseLoginMethod;
