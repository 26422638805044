import React, { Suspense, useCallback, useState } from 'react';
import { useFocusEffect } from '@react-navigation/native';
import ParkingOverviewScreenInner from './ParkingOverviewScreenInner';
import { Layout } from '../../../components/new';
import { Loading } from '../../../components';
import { RefreshQueryOptions } from './types';
import NewErrorBoundary from '../../../utilities/NewErrorBoundary';
import NewErrorView from '../../../utilities/NewErrorView';

const ParkingOverviewScreen = () => {
  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<RefreshQueryOptions>({
      fetchKey: 0,
    });

  const refresh = () => {
    setRefreshedQueryOptions((prev: RefreshQueryOptions) => ({
      ...prev,
      fetchKey: (prev?.fetchKey || 0) + 1,
    }));
  };

  useFocusEffect(
    useCallback(() => {
      (async () => {
        refresh();
      })();
    }, []),
  );

  return (
    <Layout level="1" edges={['top']}>
      <NewErrorBoundary
        fetchKey={refreshedQueryOptions.fetchKey}
        fallback={
          <NewErrorView
            errorMsg="Sorry something went wrong"
            reload={refresh}
          />
        }
      >
        <Suspense fallback={<Loading />}>
          <ParkingOverviewScreenInner queryOptions={refreshedQueryOptions} />
        </Suspense>
      </NewErrorBoundary>
    </Layout>
  );
};

export default ParkingOverviewScreen;
