import { Linking, Platform } from 'react-native';
import { Link } from '@react-navigation/native';

export const handlePhone = (phone) => {
  phone = 'tel:'.concat(phone);
  Linking.openURL(phone);
};

export const handleDirections = (lat, lng) => {
  const addr = lat.concat(',', lng);
  const googleMapsScheme = 'comgooglemaps://';

  const urlApple = 'http://maps.apple.com/?ll='.concat(addr);
  const urlGoogle = 'https://www.google.com/maps/search/?api=1&query='.concat(
    addr,
  );

  if (Platform.OS === 'web') {
    window.open(urlGoogle, '_blank');
  } else {
    Linking.canOpenURL(googleMapsScheme.concat(`?${addr}`))
      .then((res) => {
        if (res) {
          Linking.openURL(urlGoogle);
        } else {
          throw 'Cannot open url';
        }
      })
      .catch((err) => {
        console.log(err);
        if (Platform.OS === 'ios') {
          Linking.openURL(urlApple);
        } else {
          Linking.openURL(urlGoogle);
        }
      });
  }
};
