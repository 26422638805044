import { graphql } from 'react-relay';

/* eslint-disable import/prefer-default-export */
export const assignTaskQuery = graphql`
  query assignTaskQuery(
    $taskFilter: findTaskFilterInput!
    $searchFilter: searchValetFilterInput
    $count: Int
    $cursor: String
  ) {
    ...assignTaskQueryTaskFragment
      @arguments(taskFilter: $taskFilter, sort: null, count: 1)
    ...assignTaskQueryFragment
      @arguments(filter: $searchFilter, count: $count, cursor: $cursor)
  }
`;

export const assignTaskQueryFragment = graphql`
  fragment assignTaskQueryFragment on Query
  @refetchable(queryName: "assignTaskFragmentQuery")
  @argumentDefinitions(
    filter: { type: "searchValetFilterInput" }
    count: { type: "Int" }
    cursor: { type: "String" }
  ) {
    searchValetV2(filter: $filter, first: $count, after: $cursor)
      @connection(key: "assignTaskQueryFragment_searchValetV2") {
      count
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          _id
          name {
            first
            last
          }
          role {
            name
          }
          contact {
            phone {
              prefix
              number
            }
          }
          identityCode
          workLogStatus {
            status
            time
            lastStatus
            taskCount
          }
        }
      }
    }
  }
`;

export const assignTaskQueryTaskFragment = graphql`
  fragment assignTaskQueryTaskFragment on Query
  @refetchable(queryName: "assignTaskQueryTaskFragmentQuery")
  @argumentDefinitions(
    taskFilter: { type: "findTaskFilterInput!" }
    sort: { type: "SortConnectiontaskEnum" }
    count: { type: "Int" }
    cursor: { type: "String" }
  ) {
    findTasks: findTasksV2(
      filter: $taskFilter
      sort: $sort
      first: $count
      after: $cursor
    ) @connection(key: "assignTaskQueryTaskFragment__findTasks") {
      edges {
        node {
          _id
          vehicle {
            brand {
              name
              brandLogo
            }
            registration {
              plate
            }
            color
          }
          taskType

          campus {
            _id
            name
          }
          history {
            _id
            action
            actor
            event_at
          }
          assignedTo
          valet {
            name {
              first
              last
            }
            identityCode
            contact {
              phone {
                prefix
                number
              }
            }
          }
          user {
            name {
              first
              last
            }
            contact {
              phone {
                prefix
                number
              }
            }
          }
          vehicleId
          ownerId
          parkingId
          parkingTag
          parkingETA {
            parkingTimePrediction {
              locationId
              acceptedToCompleteTime
            }
          }
        }
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`;
