/* eslint-disable import/prefer-default-export */
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { IPayBillStore, IPayBillStoreState, StoreInitialData } from '../types';
import { WidgetConfig } from '../helpers';
import { PaymentGatewayType } from '../../../../../utilities/helper';

const initialState: IPayBillStoreState = {
  title: '',
  paymentMethod: PaymentGatewayType.JUSPAY,
  orderType: '', // orderType from routes
  orderId: '', // orderId from routes
  billAmount: 0, // amount which is passed to paybill screen in route params
  rawQueryData: {
    getNetPrice: null,
    getNetPriceWithMembership: null,
  },
  config: {
    billPayEnabled: false,
    charityEnabled: false,
    pointsEnabled: false,
    tipEnabled: false,
    charityConfig: null,
    blackMembershipConfig: null,
  },
  blackMembershipAdded: false, // if "Plus Membership" is added in cart along with the main purchase
  charityApplied: WidgetConfig.CHARITY_APPLIED, // is charity applied in the current payment
  pointsApplied: false, // is points applied in the current payment
  isPlusMembershipPurchased: false, // true if standalone "Plus Membership" is purchased
  priceDetails: {
    billAmount: 0,
    cashbackAmount: 0,
    cashbackPercentage: 0,
    charityAmount: 0,
    couponAmount: 0,
    couponCashbackAmount: 0,
    discountAmount: 0,
    discountPercentage: 0,
    grossAmount: 0,
    membershipAmount: 0,
    netAmount: 0,
    tipAmount: 0,
    totalCashbackAmount: 0,
    totalSavingsAmount: 0,
    usablePointsAmount: 0,
    usedPointsAmount: 0,
  },
  priceDetailsWithPlusMembership: null, // With Plus Membership
  event: {
    ticketId: '',
    slotId: '',
    noOfSlots: 0, // no. of slots selected while reservation
    eventDate: null,
    eventDateF: '',
    /** CANCELLATION INFO */
    isRefundableIfCancelled: false,
    refundLastDate: null,
    refundLastDateF1: '', // formated
    refundLastDateF2: '', // formated
  },
};

export const usePayBillStore = create<IPayBillStore>()(
  devtools((set) => ({
    ...initialState,
    setIntialData(initialData: StoreInitialData) {
      set(() => {
        return {
          title: initialData.title,
          orderType: initialData.orderType,
          orderId: initialData.orderId,
          config: initialData.config,
          billAmount: initialData.billAmount,
          paymentMethod: initialData.paymentMethod,
          event: initialData.event,
          priceDetails: initialData.priceDetails,
          priceDetailsWithPlusMembership:
            initialData.priceDetailsWithPlusMembership,
          rawQueryData: initialData.rawQueryData,
          isPlusMembershipPurchased: initialData.isPlusMembershipPurchased,
        };
      });
    },
    resetStore: () => {
      set(() => {
        return {
          ...initialState,
        };
      });
    },
    setCharityApplied: (applied: boolean) => {
      set(() => {
        return {
          charityApplied: applied,
        };
      });
    },
    setPointsApplied: (applied: boolean) => {
      set(() => ({
        pointsApplied: applied,
      }));
    },
    setBlackMembershipAdded: (added: boolean) => {
      set(() => {
        return {
          blackMembershipAdded: added,
        };
      });
    },
  })),
);
