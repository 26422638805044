/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-no-comment-textnodes */
import React from 'react';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import {
  createStackNavigator,
  CardStyleInterpolators,
} from '@react-navigation/stack';
import { Easing, Platform } from 'react-native';
import NewSearchScreen from '../screens/new/Search/NewSearchScreen';
import RestaurantScreenGallery from '../screens/new/resturants/RestaurantScreenGallery';
import BottomTabBarNew from './BottomTabBarNew';
import SlotMachineScreen from '../screens/new/games/slotMachine/SlotMachineScreen';
import UserSettingScreen from '../screens/user/userSettingsSceen';
import ChangeUserScreen from '../screens/auth/changeNumberScreen';
import TrackMyVehicleScreenWrapper from '../components/hocs/TrackMyVehicleScreenWrapper';
import PointsScreenWrapper from '../components/hocs/PointsScreenWrapper';
import UserAccountScreenWrapper from '../components/hocs/UserAccountScreenWrapper';
import SurveyScreen from '../screens/new/survey/SurveyScreen';
import LoginTrackComponent from '../screens/auth/LoginTrackComponent';
import RedirectToPlatformStore from '../screens/new/RedirectToPlatformStore';
import PaymentScreen from '../screens/new/payment/PaymentScreen';
import CouponsComponent from '../screens/new/payment/CouponsComponent';
import AfterPaymentScreen from '../screens/new/payment/AfterPaymentScreen';
import TransactionScreen from '../screens/new/payment/transactionScreen/TransactionScreen';
import TransactionsScreen from '../screens/new/payment/transactionsScreen/TransactionsScreen';
import FaqScreen from '../screens/new/faq/FaqScreen';
import WifiLogin from '../screens/wifilogin/WifiLogin';
import Login from '../components/NewLoginFlow/Login';
import useCommonStore from '../stores/commonStore';
import EnterPhoneNumber from '../components/NewLoginFlow/EnterPhoneNumber';
import EnterOtp from '../components/NewLoginFlow/EnterOtp';
import Signup from '../components/NewLoginFlow/Signup';
import EmailEditorScreen from '../screens/auth/EmailEditorScreen';
import EmailOtpVerificationScreen from '../screens/auth/EmailOtpVerificationScreen';
import BannerHomeScreen from '../screens/new/BannerHomeScreen/BannerHomeScreen';
import ReservationsScreenWrapper from '../screens/new/eventsHome/ReservationsScreen';
import ReservationConfirmationScreenWrapper from '../screens/new/eventsHome/ReservationConfirmationScreen';
import EventTicketScreenWrapper from '../screens/new/eventsHome/EventTicketScreen';
import EventsHomeWrapper from '../screens/new/eventsHome/EventsHome';
import InvestScreenWrapper from '../screens/new/investHome/InvestScreen';
import PropertyDetailsWrapper from '../screens/new/investHome/PropertyDetails';
import InvestmentAssociatesJourney from '../screens/new/investHome/components/InvestmentAssociatesJourney';
import InvestApplyComponent from '../screens/new/investHome/InvestApplyComponent';
import ThankYouScreen from '../screens/new/investHome/ThankYouScreen';
import TaskJourneyScreenWrapper from '../screens/valetSupervisor/new/taskJourney/TaskJourney';
import InstagramSharePage from '../screens/photomoments/InstagramSharePage';
import StoreList from '../screens/new/store/storeList';
import TicketTnCScreen from '../screens/new/TicketTnCScreen';
import EditProfileScreen from '../screens/auth/editProfileScreen';
import OTPInputScreen from '../screens/auth/OTPInputScreen';
import PhotoMoments from '../screens/PhotoMoments';
import PhotoViewer from '../screens/photomoments/PhotoViewer';
import UserFeedbackScreen from '../screens/user/userFeedbackScreen';
import MenuScreen from '../screens/new/menu/menuScreen';
import OrderScreen from '../screens/new/menu/orderScreen';
import StoresScreen from '../screens/new/stores';
import RestaurantPage from '../screens/new/resturants/restaurantScreen1';
import StorePage from '../screens/new/storeScreen';
import OrderSummary from '../screens/new/menu/orderSummary';
import PastOrders from '../screens/new/menu/pastOrders';
import MyBookingScreen from '../screens/new/MyBookingScreen';
import StaffScanReedemScreen from '../screens/user/staffScan&ReedemScreen';
import StaffBarcodeScannerScreen from '../screens/user/staffBarcodeScannerScreen';
import StaffAddManuallyBarcodeScreen from '../screens/user/staffAddManuallyBarcodeScreen';
import StaffRewardScreen from '../screens/user/staffRewardScreen';
import CustomWebview from '../screens/new/CustomWebview';
import RewardsScreen from '../screens/new/RewardsScreen';
import TimeGuaranteeScreen from '../screens/new/TimeGuaranteeScreen';
import FreeParkingScreen from '../screens/new/FreeParkingScreen';
import FreeParkingScreenNew from '../screens/new/FreeParkingScreenNew';
import ParkingFeedbackScreen from '../screens/new/trackYourCar/ParkingFeedbackScreen';
import RewardPointHistoryScreen from '../screens/new/RewardPointHistoryScreen';
import NotLoggedInRewardsScreen from '../screens/new/NotLoggedInRewardsScreen';
import About from '../screens/new/About';
import DiscountsAndOfferScreen from '../screens/new/DiscountsAndOfferScreen';
import useLoginFlowStore from '../stores/loginFlowStore';
import RestaurantListing from '../screens/new/resturants/restaurantListing';
import EventsListing from '../screens/new/eventsHome/EventsListing';
import RecallSuccessScreen from '../screens/new/trackYourCar/RecallSuccessScreen';
import PlusMemberProfileScreen from '../screens/new/plusMembership/screens/PlusMembershipProfileScreen';
import Gallery from '../components/new/Gallery';
import EventCancellationScreen from '../screens/new/eventsHome/EventCancellationScreen/EventCancellationScreen';
import EventConfirmCancellationScreen from '../screens/new/eventsHome/EventConfirmCancellationScreen/EventConfirmCancellationScreen';
import EventCancellationStatusScreen from '../screens/new/eventsHome/EventConfirmCancellationScreen/CancellationStatusScreen';
import ScanBillScreen from '../screens/new/scanBill/ScanBillScreen';
import AfterSubmitScreen from '../screens/new/scanBill/AfterSubmitScreen';
import PayBillScreen from '../screens/new/payment/PayBillScreen/PayBillScreen';

const HomeTabs = () => {
  const { Navigator, Screen } = createBottomTabNavigator();
  return (
    <Navigator
      // @ts-expect-error TS(2769): No overload matches this call.
      keyboardHidesTabBar="true"
      initialRouteName="home"
      screenOptions={{ headerShown: false }}
      tabBar={(props) => <BottomTabBarNew {...props} />}
    >
      <Screen name="home" component={BannerHomeScreen} />
      <Screen name="dineIn" component={RestaurantListing} />
      <Screen name="invest" component={InvestScreenWrapper} />
      <Screen name="events" component={EventsListing} />
      <Screen name="store" component={StoreList} />
    </Navigator>
  );
};
const BottomTabNavigator = () => {
  const Stack = createStackNavigator();
  const hasLoginSkipped = useCommonStore((state) => state.hasLoginSkipped);
  let initialRouteName =
    hasLoginSkipped || Platform.OS === 'web' ? 'user' : 'login';
  const initialRouteFromStore = useLoginFlowStore(
    (state) => state.initialRoute,
  );

  if (initialRouteFromStore?.name?.length) {
    initialRouteName = initialRouteFromStore?.name;
  }

  return (
    <Stack.Navigator
      screenOptions={{ headerShown: false }}
      initialRouteName={initialRouteName}
    >
      <Stack.Screen name="login" component={Login} />
      <Stack.Screen name="enterPhone" component={EnterPhoneNumber} />
      <Stack.Screen name="enterOtp" component={EnterOtp} />
      <Stack.Screen name="signUp" component={Signup} />

      <Stack.Screen name="user" component={HomeTabs} />
      <Stack.Screen name="usersettings" component={UserSettingScreen} />

      <Stack.Screen
        name="editProfile"
        options={{ gestureEnabled: false }}
        component={EditProfileScreen}
      />
      <Stack.Screen name="changeNumber" component={ChangeUserScreen} />
      <Stack.Screen name="editEmail" component={EmailEditorScreen} />
      <Stack.Screen
        name="verifyEmailOtp"
        component={EmailOtpVerificationScreen}
      />
      <Stack.Screen name="track" component={TrackMyVehicleScreenWrapper} />
      <Stack.Screen name="phoneVerify" component={OTPInputScreen} />
      <Stack.Screen name="slotMachineScreen" component={SlotMachineScreen} />
      <Stack.Screen name="newSearchScreen" component={NewSearchScreen} />
      <Stack.Screen name="surveyScreen" component={SurveyScreen} />
      <Stack.Screen name="PhotoMoments" component={PhotoMoments} />
      <Stack.Screen name="PhotoViewer" component={PhotoViewer} />
      <Stack.Screen name="feedback" component={UserFeedbackScreen} />
      <Stack.Screen name="taskJourney" component={TaskJourneyScreenWrapper} />
      <Stack.Screen name="menu" component={MenuScreen} />
      <Stack.Screen name="order" component={OrderScreen} />
      <Stack.Screen name="RestaurantsList" component={RestaurantListing} />
      <Stack.Screen name="StoresList" component={StoresScreen} />
      <Stack.Screen
        name="RestaurantScreen"
        component={RestaurantPage}
        initialParams={
          initialRouteName === 'RestaurantScreen'
            ? initialRouteFromStore?.params ?? {}
            : {}
        }
      />
      <Stack.Screen name="Storescreen" component={StorePage} />
      <Stack.Screen name="orderSummary" component={OrderSummary} />
      <Stack.Screen name="pastOrders" component={PastOrders} />
      <Stack.Screen name="myBookings" component={MyBookingScreen} />
      <Stack.Screen
        name="StaffScanRedeemScreen"
        component={StaffScanReedemScreen}
      />
      <Stack.Screen
        name="StaffBarcodeScannerScreen"
        component={StaffBarcodeScannerScreen}
      />
      <Stack.Screen
        name="StaffAddManuallyBarcodeScreen"
        component={StaffAddManuallyBarcodeScreen}
      />
      <Stack.Screen name="StaffRewardScreen" component={StaffRewardScreen} />
      <Stack.Screen
        name="RestaurantScreenGallery"
        component={RestaurantScreenGallery}
      />
      <Stack.Screen
        name="DicountAndOffer"
        component={DiscountsAndOfferScreen}
      />
      <Stack.Screen
        name="CustomWebview"
        component={CustomWebview}
        options={{
          animationTypeForReplace: 'push',
          animationEnabled: true,
          transitionSpec: {
            open: {
              animation: 'timing',
              config: {
                duration: 300,
                easing: Easing.inOut(Easing.ease),
              },
            },
            close: {
              animation: 'timing',
              config: {
                duration: 100,
                easing: Easing.inOut(Easing.ease),
              },
            },
          },
          cardStyleInterpolator:
            Platform.OS === 'android'
              ? CardStyleInterpolators.forRevealFromBottomAndroid
              : CardStyleInterpolators.forVerticalIOS,
        }}
      />
      <Stack.Screen name="RewardsScreen" component={RewardsScreen} />
      <Stack.Screen name="pointsScreen" component={PointsScreenWrapper} />
      <Stack.Screen
        name="TimeGuaranteeScreen"
        component={TimeGuaranteeScreen}
      />
      <Stack.Screen name="FreeParkingScreen" component={FreeParkingScreen} />
      <Stack.Screen
        name="FreeParkingScreenNew"
        component={FreeParkingScreenNew}
      />
      <Stack.Screen name="parkingFeedback" component={ParkingFeedbackScreen} />
      <Stack.Screen name="loginTrack" component={LoginTrackComponent} />
      <Stack.Screen
        name="RewardPointHistoryScreen"
        component={RewardPointHistoryScreen}
      />
      <Stack.Screen
        name="NotLoggedInRewardsScreen"
        component={NotLoggedInRewardsScreen}
      />
      <Stack.Screen name="About" component={About} />
      <Stack.Screen name="faq" component={FaqScreen} />
      <Stack.Screen
        name="redirectToPlatformStore"
        component={RedirectToPlatformStore}
      />
      <Stack.Screen name="paymentScreen" component={PaymentScreen} />
      <Stack.Screen name="paymentAmountScreen" component={PayBillScreen} />
      <Stack.Screen name="CouponsComponent" component={CouponsComponent} />
      <Stack.Screen name="afterPaymentScreen" component={AfterPaymentScreen} />
      <Stack.Screen name="transactionScreen" component={TransactionScreen} />
      <Stack.Screen name="transactionsScreen" component={TransactionsScreen} />
      {/* <Stack.Screen name="loginwifi" component={WifiLogin} /> */}
      <Stack.Screen name="eventsHome" component={EventsHomeWrapper} />
      <Stack.Screen
        name="eventsReservations"
        component={ReservationsScreenWrapper}
      />
      <Stack.Screen
        name="eventsReservationsConfirmation"
        component={ReservationConfirmationScreenWrapper}
      />
      <Stack.Screen name="eventTicket" component={EventTicketScreenWrapper} />
      <Stack.Screen name="ticketTnC" component={TicketTnCScreen} />
      <Stack.Screen name="account" component={UserAccountScreenWrapper} />
      <Stack.Screen name="instagramShare" component={InstagramSharePage} />
      <Stack.Screen name="PropertyDetails" component={PropertyDetailsWrapper} />
      <Stack.Screen
        name="InvestmentAssociatesJourney"
        component={InvestmentAssociatesJourney}
      />
      <Stack.Screen name="ThankYouScreen" component={ThankYouScreen} />
      <Stack.Screen
        name="InvestApplyComponent"
        component={InvestApplyComponent}
      />
      <Stack.Screen
        name="RecallSuccessScreen"
        component={RecallSuccessScreen}
      />
      <Stack.Screen
        name="plusMembershipScreen"
        component={PlusMemberProfileScreen}
      />
      <Stack.Screen name="Gallery" component={Gallery} />
      <Stack.Screen
        name="eventCancellation"
        component={EventCancellationScreen}
      />
      <Stack.Screen
        name="eventConfirmCancellation"
        component={EventConfirmCancellationScreen}
      />
      <Stack.Screen
        name="eventCancellationStatus"
        component={EventCancellationStatusScreen}
      />
      <Stack.Screen name="ScanBillScreen" component={ScanBillScreen} />
      <Stack.Screen name="AfterSubmitScreen" component={AfterSubmitScreen} />
    </Stack.Navigator>
  );
};
export default BottomTabNavigator;
