import React, { useCallback, useEffect, useState } from 'react';
import { useWindowDimensions, FlatList } from 'react-native';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { View, Text, Button, Pressable } from '../../../components/new';
import { radii, space } from '../../../themes/new/theme';
import VideoPlayer from '../../../components/new/custom/VideoPlayer';
import { State, Status } from '../../../themes/new/helper';
import DarkThemeWrapper from '../wrapperComp/DarkThemeWrapper';
import useUserStore from '../../../stores/userStore';
import {
  InvestCarousalData,
  videoWidgetData,
} from '../../../utilities/InvestmentConstants';
import {
  INVESTMENT_APPLICATION_STATUS,
  dynamicHeightMaker,
} from '../../../utilities/helper';
import CarouselProgressTiles from '../../../components/new/custom/Carousel/CarouselProgressTiles';
import { firebaseEventLogger } from '../../../utilities/firbaseAnalytics';

const InvestmentWidget = ({
  isCarousalWidget = false,
  investmentLead = [],
  reloadComp = () => {},
}) => {
  const navigation = useNavigation();
  const { checkHomePageInvestmentLead, setCheckHomePageInvestmentLead } =
    useUserStore((state) => state);
  const investmentWidgetCount = useUserStore(
    (state) => state?.investmentWidgetCount,
  );
  const setInvestmentWidgetCount = useUserStore(
    (state) => state?.setInvestmentWidgetCount,
  );

  const { width } = useWindowDimensions();

  const [play, setPlay] = useState(false);
  const [carousalData, setCarousalData] = useState(InvestCarousalData);
  const [activeIndex, setActiveIndex] = useState(0);

  const updateVideCount = async () => {
    await setInvestmentWidgetCount(investmentWidgetCount + 1);
  };

  useEffect(() => {
    if (investmentWidgetCount < 5) {
      updateVideCount();
    }
  }, []);

  const checkSliderData = useCallback(() => {
    const carousalDataRef = [...InvestCarousalData];
    if (investmentLead?.length) {
      const receivedOrCreatedData = investmentLead.filter(
        (item) =>
          item?.status === INVESTMENT_APPLICATION_STATUS.APPLICATION_RECEIVED ||
          item?.status === INVESTMENT_APPLICATION_STATUS.CREATED,
      );
      const result = carousalDataRef.filter(
        (o) =>
          !receivedOrCreatedData.find(
            (x) => x?.investmentPropertyId === o?.propertyId,
          ),
      );
      setCarousalData(result);
    }
  }, [investmentLead]);

  useFocusEffect(
    useCallback(() => {
      setPlay(true);
      if (checkHomePageInvestmentLead) {
        setCheckHomePageInvestmentLead(false);
        reloadComp();
      }
      if (isCarousalWidget) {
        checkSliderData();
      }
      return () => {
        setPlay(false);
      };
    }, [checkHomePageInvestmentLead, investmentLead]),
  );

  useFocusEffect(
    useCallback(() => {
      setPlay(true);
    }, []),
  );

  const handleScroll = (e) => {
    const { contentOffset } = e.nativeEvent;
    const viewSize = e.nativeEvent.layoutMeasurement;

    // Divide the horizontal offset by the width of the view to see which page is visible
    const pageNum = Math.floor(contentOffset.x / viewSize.width);
    if (activeIndex !== pageNum) {
      setActiveIndex(pageNum);
    }
  };

  const investmentCarousal = () => (
    <View
      mt="2xl"
      borderRadius="xl"
      overflow="hidden"
      bg="background.primary.elevation"
    >
      <FlatList
        data={carousalData}
        pagingEnabled
        onScroll={handleScroll}
        renderItem={({ item }) => (
          <View
            width={width - 32}
            height={dynamicHeightMaker(width - 32, 1.33)}
          >
            <Pressable
              onPress={() => {
                firebaseEventLogger('Home__selectSlide_Tap', {
                  propertyId: item?.propertyId,
                });
                navigation.navigate('PropertyDetails', {
                  propertyId: item?.propertyId,
                });
              }}
              style={{
                width: width - 32,
                height: dynamicHeightMaker(width - 32, 1.33),
                zIndex: 99,
                position: 'absolute',
              }}
            />
            <VideoPlayer
              id={`Video_id_inv_widget_Carousal${item?.id}`}
              url={item?.url}
              aspectRatio={1.33}
              width={width - 32}
              play={play}
              setPlay={setPlay}
              showPlayPause
              isLooping
              hideControls
              footerTitle={item?.title}
              footerSubTitle={item?.subTitle}
              footerSubTitleStyle={{ pb: '3xl' }}
            />
          </View>
        )}
        keyExtractor={(item) => item?.id}
        horizontal
        showsHorizontalScrollIndicator={false}
      />
      {carousalData?.length > 1 ? (
        <View
          style={{
            position: 'absolute',
            marginHorizontal: 16,
            bottom: 16,
            width: width - 64,
          }}
        >
          <CarouselProgressTiles
            time={10}
            numberOfBars={carousalData?.length}
            activeIndex={activeIndex}
          />
        </View>
      ) : null}
    </View>
  );

  const videoWidgetBanner = () => (
    <View mt="2xl" borderRadius="lg" overflow="hidden">
      <VideoPlayer
        id="Video_id_inv_home_new"
        url={videoWidgetData?.url}
        aspectRatio={videoWidgetData?.aspectRatio}
        width={width - 32}
        play={play}
        setPlay={setPlay}
        showPlayPause
        isLooping
        hideControls
      />
      <View
        bg="background.primary.elevation"
        width="100%"
        opacity={0.5}
        position="absolute"
        bottom={0}
        height={space['8xl+8xl']}
        style={{
          borderBottomRightRadius: radii.lg,
          borderBottomLeftRadius: radii.lg,
        }}
      />
      <View position="absolute" bottom={0} width="100%">
        <Button
          size="md"
          status={Status.PRIMARY}
          state={State.ACTIVE}
          m="2xl"
          onPress={() => {
            setInvestmentWidgetCount(5);
            navigation.navigate('PropertyDetails', {
              propertyId: videoWidgetData?.propertyId,
            });
            firebaseEventLogger('Home__unlockAccess_Tap', {
              propertyId: videoWidgetData?.propertyId,
            });
          }}
        >
          {videoWidgetData?.title}
        </Button>
      </View>
    </View>
  );

  if (investmentWidgetCount < 5 && !isCarousalWidget) {
    return (
      <View mx="2xl" mt="4xl">
        <Text size="lg" weight="medium" color="primary.400">
          32nd Investments
        </Text>
        <DarkThemeWrapper>{videoWidgetBanner()}</DarkThemeWrapper>
      </View>
    );
  }

  if (investmentWidgetCount === 5 && isCarousalWidget && carousalData?.length) {
    return (
      <View mx="2xl" mt="4xl">
        <Text size="lg" weight="medium" color="primary.400">
          32nd Investments
        </Text>
        <DarkThemeWrapper>{investmentCarousal()}</DarkThemeWrapper>
      </View>
    );
  }
};

export default InvestmentWidget;
