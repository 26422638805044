import React from 'react';
import { View } from '../../../../components/new';
import MetricsCard from './MetricsCard';

const MetricsWidget = ({ metricsWidgetData }) => {
  const onboarding = metricsWidgetData?.onboarding;
  const parking = metricsWidgetData?.parking;
  const assignment = metricsWidgetData?.assignment;
  const delivery = metricsWidgetData?.delivery;

  return (
    <View px="lg" py="4xl">
      <View flexDirection="row" mb="2xl">
        <MetricsCard
          title={onboarding.label}
          status={onboarding.status}
          score={onboarding.score}
          time={onboarding.time}
        />
        <MetricsCard
          title={parking.label}
          status={parking.status}
          score={parking.score}
          time={parking.time}
        />
      </View>
      <View flexDirection="row">
        <MetricsCard
          title={assignment.label}
          status={assignment.status}
          score={assignment.score}
          time={assignment.time}
        />
        <MetricsCard
          title={delivery.label}
          status={delivery.status}
          score={delivery.score}
          time={delivery.time}
        />
      </View>
    </View>
  );
};

export default MetricsWidget;
