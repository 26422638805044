import { graphql } from 'react-relay';

/* eslint-disable import/prefer-default-export */

export const editAmountScreenQuery = graphql`
  query editAmountScreenQuery($taskFilter: findTaskFilterInput!) {
    ...editAmountScreenQueryTaskFragment
      @arguments(taskFilter: $taskFilter, sort: null, count: 1)
  }
`;

export const editAmountScreenQueryTaskFragment = graphql`
  fragment editAmountScreenQueryTaskFragment on Query
  @refetchable(queryName: "editAmountScreenTasksQuery")
  @argumentDefinitions(
    taskFilter: { type: "findTaskFilterInput!" }
    sort: { type: "SortConnectiontaskEnum" }
    count: { type: "Int" }
    cursor: { type: "String" }
  ) {
    findTasks: findTasksV2(
      filter: $taskFilter
      sort: $sort
      first: $count
      after: $cursor
    ) @connection(key: "editAmountScreenQueryTaskFragment__findTasks") {
      edges {
        node {
          _id
          parking {
            _id
            status
            parkingTag
            parkingInvoice {
              timings {
                entryTime
                exitTime
                chargeTime
              }
              paymentDetails {
                isPaid
                mode
                focReason
                amount
              }
              charges {
                chargesBreakup {
                  duration
                  payable
                }
                discountAmount
                totalPayable
              }
            }
          }
        }
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`;
