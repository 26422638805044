import { ActionSheetProps } from 'react-native-actions-sheet';
import { getTheme } from '../../../../themes/new/theme';
import { normalizeToNumberOrZero } from '../../../../utilities/Utility';
import {
  DateFormatsEnum,
  getFormatedDate,
  paymentOrderTypeEnum,
} from '../../../../utilities/helper';
import {
  IPayBillStore,
  GQLAddonConfig,
  GQLCharityConfig,
  GQLGetNetPriceData,
  GQLTipConfig,
  TipWidgetConfig,
  GQLPayBillConfig,
  GQLPayBillQueryVariables,
  PayBillRouteParams,
} from './types';
import {
  extractBlackMembershipAddonConfig,
  isStandaloneBlackMembershipBeignPurchased,
} from '../../plusMembership/helpers';
import { getSourceDetails } from '../PaymentScreen';
import { getEventCancellationInfo } from '../../eventsHome/helpers/helpers';

export enum PAYBILL_BS_MODES {
  NONE,
  BREAK_UP,
  CHARITY_INFO,
  BLACK_MEMBER_BENEFITS,
  BLACK_MEMBER_INVITATION_ON_PAY,
  DOWNLOAD_APP_PROMT_IF_POINTS_USED_ON_WEB,
  EVENT_CANCELLATION_INFO,
  BLACK_MEMBER_PROMPT,
  DOWNLOAD_APP_PROMT_IF_BLACK_MEMBER_PAYS_ON_WEB,
}

export const WidgetConfig = {
  CHARITY_APPLIED: false,
};

const DARK_BOTTOM_SHEET_WHITELIST = [
  PAYBILL_BS_MODES.BLACK_MEMBER_BENEFITS,
  PAYBILL_BS_MODES.BLACK_MEMBER_INVITATION_ON_PAY,
  PAYBILL_BS_MODES.DOWNLOAD_APP_PROMT_IF_POINTS_USED_ON_WEB,
  PAYBILL_BS_MODES.DOWNLOAD_APP_PROMT_IF_BLACK_MEMBER_PAYS_ON_WEB,
];

export const buildPayBillQueryVariables = (data: {
  isCustomerBlackMember: boolean;
  config: GQLPayBillConfig;
  routeParams: PayBillRouteParams;
}): GQLPayBillQueryVariables => {
  const { config, routeParams, isCustomerBlackMember } = data;
  const {
    orderType,
    orderId,
    amount: billAmount,
    eventSlotId,
    eventTicketId,
    noOfSlots,
  } = routeParams;

  const blackMembershipConfig = extractBlackMembershipAddonConfig(
    config.findAddons,
  );
  const standaloneMembershipPurchased =
    isStandaloneBlackMembershipBeignPurchased(
      orderType,
      orderId,
      config.findAddons as any,
    );
  /** Charity related set up */
  const charityConfig = getEnabledCharity(config?.findCharities);
  const charityEnabled = Boolean(charityConfig && charityConfig?._id); // is enabled from backend

  /** Tip Related set up */
  const tipEnabled = isTipEnabled(orderType, config.findConfig); // is enabled from backend
  const defaultTipAmount = getDefaultTip(orderType);

  const qVars: GQLPayBillQueryVariables = {
    orderId,
    orderType: orderType as GQLPayBillQueryVariables['orderType'],
    amount: normalizeToNumberOrZero(billAmount),
    useRewardPoints: false, // default
    fetchPreAppliedAddon: false, // default
    csrContributionId: '',
  };
  if (charityEnabled && WidgetConfig.CHARITY_APPLIED) {
    qVars.csrContributionId = charityConfig?._id;
  }
  if (tipEnabled) {
    qVars.tipAmount = defaultTipAmount;
  }
  if (!standaloneMembershipPurchased && !isCustomerBlackMember) {
    qVars.fetchPreAppliedAddon = true;
    qVars.preAppliedAddon = { orderId: blackMembershipConfig?._id };
  }
  // if plus membership is purchased standalone
  if (standaloneMembershipPurchased) {
    /**
     * NOTE: -------------------------------------------------------------------------------------------
     * amount = 1 is just a workaround to prevent API error on backend as it has to be +ve float value.
     * In this case amount = null or amount= undefined is not possible to pass in relay query definition
     * `paymentAmountScreenQuery`due to complicated query. This needs to be handled elegantly in future.
     */
    qVars.amount = 1;
  }
  // if workshop/event is being purchased
  if (orderType === paymentOrderTypeEnum.EVENT) {
    qVars.workshopDetails = {
      slotId: eventSlotId,
      tickets: [
        {
          ticketId: eventTicketId,
          spaces: normalizeToNumberOrZero(noOfSlots),
        },
      ],
    };
  }

  return qVars;
};

export const resolvePageTitle = (
  orderType: paymentOrderTypeEnum,
  orderId: string,
  apiData: GQLGetNetPriceData,
  addonConfig: GQLAddonConfig,
): string => {
  try {
    if (!orderType || !orderId || !apiData || !addonConfig) return '';
    if (orderType === paymentOrderTypeEnum.INVESTMENT_LEAD) {
      return 'Payment';
    }
    if (
      isStandaloneBlackMembershipBeignPurchased(orderType, orderId, [
        addonConfig,
      ])
    ) {
      return 'Black Membership';
    }
    return (
      (getSourceDetails(orderType, apiData) as { title: string })?.title ??
      'Payment'
    );
  } catch (e) {
    return 'Payment';
  }
};

export const getPayBillBottomSheetStyles = (
  bsMode: PAYBILL_BS_MODES,
): Pick<ActionSheetProps, 'containerStyle' | 'indicatorStyle'> => {
  if (DARK_BOTTOM_SHEET_WHITELIST.includes(bsMode)) {
    // dark styles
    return {
      containerStyle: {
        backgroundColor: 'black',
        paddingBottom: 0,
        overflow: 'hidden',
      },
      indicatorStyle: {
        margin: 10,
        zIndex: 10,
        position: 'absolute',
        backgroundColor: getTheme()?.colors?.primary[100],
      },
    };
  }

  return {
    indicatorStyle: {
      marginTop: 10,
    },
  };
};

export const buildStorePriceData = (
  priceData: GQLGetNetPriceData,
): IPayBillStore['priceDetails'] => {
  const { priceDetails } = priceData ?? {};

  const pd: IPayBillStore['priceDetails'] = {
    netAmount: normalizeToNumberOrZero(priceDetails?.netAmount),
    billAmount: normalizeToNumberOrZero(priceDetails?.grossAmount),
    tipAmount: normalizeToNumberOrZero(priceDetails?.tipAmount),
    charityAmount: normalizeToNumberOrZero(priceDetails?.csrAmount),
    membershipAmount: normalizeToNumberOrZero(priceDetails?.addonAmount),
    discountAmount: normalizeToNumberOrZero(priceDetails?.discountGiven),
    discountPercentage: normalizeToNumberOrZero(priceData?.discountPercentage),
    usedPointsAmount: normalizeToNumberOrZero(priceDetails?.rewardsUsed),
    usablePointsAmount: normalizeToNumberOrZero(priceDetails?.rewardsUsable),
    couponAmount: normalizeToNumberOrZero(priceDetails?.couponAmount),
    cashbackAmount: normalizeToNumberOrZero(priceData?.cashbackAmount),
    cashbackPercentage: normalizeToNumberOrZero(priceData?.cashbackPercentage),
    couponCashbackAmount: normalizeToNumberOrZero(
      priceDetails?.couponCashbackAmount,
    ),
    grossAmount: 0,
    totalSavingsAmount: 0,
    totalCashbackAmount: 0,
  };

  pd.grossAmount =
    pd.billAmount + pd.tipAmount + pd.charityAmount + pd.membershipAmount;
  pd.totalSavingsAmount =
    pd.discountAmount + pd.usedPointsAmount + pd.couponAmount;
  pd.totalCashbackAmount = pd.cashbackAmount + pd.couponCashbackAmount;

  return pd;
};

/** Set Event related data in store */
export const build32ndEventsData = (routeParams: PayBillRouteParams) => {
  const { eventDate, eventSlotId, eventTicketId, noOfSlots } = routeParams;
  const noOfSlotsNum = normalizeToNumberOrZero(noOfSlots);
  if (
    eventSlotId?.length &&
    eventTicketId?.length &&
    eventDate?.length &&
    noOfSlotsNum > 0
  ) {
    const eventDateObj = new Date(eventDate);
    const ci = getEventCancellationInfo(eventDateObj);
    const eventInfo = {
      noOfSlots: Number(noOfSlots),
      ticketId: eventTicketId,
      slotId: eventSlotId,
      eventDate: new Date(eventDate),
      eventDateF: getFormatedDate(
        eventDateObj,
        DateFormatsEnum.DateMonthYear,
      ) as string,
      isRefundableIfCancelled: ci.isRefundableIfCancelled,
      refundLastDate: ci.refundLastDate.date,
      refundLastDateF1: ci.refundLastDate.formatOne,
      refundLastDateF2: ci.refundLastDate.formatTwo,
    };
    return eventInfo;
  }
  return null;
};

export const getEnabledCharity = (
  charityConfig: ReadonlyArray<GQLCharityConfig>,
): GQLCharityConfig | null => {
  let enabledCharityCount = 0;
  let charity = null;
  charityConfig.forEach((c) => {
    if (c?.isActive) {
      charity = c;
      enabledCharityCount += 1;
    }
  });
  if (enabledCharityCount === 1) return charity;
  return null;
};

export const isTipEnabled = (
  orderType: paymentOrderTypeEnum,
  config: GQLTipConfig,
): boolean => {
  const tipConfig = config?.payBillConfig?.tipStatus ?? [];
  const matchedConfig = tipConfig.find((c) => c?.refType === orderType);
  if (typeof matchedConfig !== 'undefined') {
    return matchedConfig?.isTipEnabled;
  }
  return false;
};

export const isPointsEnabled = (
  orderType: paymentOrderTypeEnum,
  apiData: GQLGetNetPriceData,
): boolean => {
  return orderType === paymentOrderTypeEnum.restaurant
    ? apiData?.sourceDetails?.restaurant?.payBill?.pointsEnabled?.redeem
    : true;
};

export const isBillPayEnabled = (
  orderType: paymentOrderTypeEnum,
  apiData: GQLGetNetPriceData,
): boolean => {
  return orderType === paymentOrderTypeEnum.restaurant
    ? apiData?.sourceDetails?.restaurant?.payBill?.payEnabled ?? false
    : true;
};

export const resolveTipWidgetConfig = (
  orderType: string,
): TipWidgetConfig | undefined => {
  switch (orderType) {
    case paymentOrderTypeEnum.PARKING: {
      return {
        defaultIdx: 0,
        label: 'Tip Your Valet Driver',
        values: [
          { price: 0 },
          { price: 10 },
          { price: 30 },
          { price: 50 },
          { price: 100 },
          { price: 500 },
        ],
      };
    }
    case paymentOrderTypeEnum.restaurant: {
      return {
        defaultIdx: 0,
        label: 'Pay Tip',
        values: [
          { price: 0 },
          { price: 10 },
          { price: 30 },
          { price: 50 },
          { price: 100 },
          { price: 500 },
        ],
      };
    }
    default: {
      return {
        defaultIdx: 0,
        label: 'Pay Tip',
        values: [
          { price: 0 },
          { price: 10 },
          { price: 30 },
          { price: 50 },
          { price: 100 },
          { price: 500 },
        ],
      };
    }
  }
};

export const getDefaultTip = (orderType: paymentOrderTypeEnum): number => {
  const tipWidgetConfig = resolveTipWidgetConfig(orderType);
  const defaultTipAmount =
    tipWidgetConfig.values[tipWidgetConfig.defaultIdx].price;
  return defaultTipAmount;
};
