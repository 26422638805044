import { useNavigation } from '@react-navigation/native';
import { useLazyLoadQuery, useFragment } from 'react-relay';
import React, { useMemo, useRef } from 'react';
import { Platform, ScrollView } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { View } from '../../../../components/new';
import { RestaurantCollectionCategory } from '../../../../utilities/helper';
import {
  homeScreenLogoutCollectionsFragment,
  homeScreenLogOutQuery,
} from '../API/homeScreenLogOutQuery';
import RestaurantCollectionView from './RestaurantCollectionView';
import { buildRestaurantCollectionViewData } from '../utils';
import Header from '../../../../components/new/custom/Header';
import FeatureTiles from './FeatureTiles';
import DownloadCard from '../../trackMyVehicle/DownloadCard';
import PhotoMomentWidget from '../../../../components/PhotoMomentWidget';
import DidYouKnowCarouselNew from '../../../../components/new/custom/Carousel/DidYouKnowCarouselNew';
import HomePageBannerCarousel from '../../../../components/new/custom/Carousel/HomePageBannerCarousel';
import useCommonStore from '../../../../stores/commonStore';
import RestaurantsWithOffers from './RestaurantsWithOffers';
import InvestmentWidget from '../../investHome/InvestmentWidget';

const filterCollection = (
  collection: any[],
  category: RestaurantCollectionCategory,
) => {
  return collection
    .filter((c) => c.title === category && c?.restaurants?.length)
    .map((cafe) => cafe.restaurants)[0];
};

const HomePageNonLoggedIn = ({ queryOptions }) => {
  const navigation = useNavigation();
  const { top } = useSafeAreaInsets();
  const campusId = useCommonStore((state) => state.campusId);

  const variables = {
    filter: {
      title: [
        RestaurantCollectionCategory.OUTDOOR_SEATING,
        RestaurantCollectionCategory.FINE_DINING,
        RestaurantCollectionCategory.CAFES,
        RestaurantCollectionCategory.SERVES_ALCOHOL,
      ],
    },
    restaurantsOfferFilter: {
      campus: {
        _id: campusId,
      },
      type: 'RESTAURANT',
      withOffer: true,
    },
  };

  const {
    findCollections: findCollectionsRef,
    offerRestaurants: offerRestaurantsRef,
    getAllHomePageBanners: getAllHomePageBannersRef,
  } = useLazyLoadQuery(homeScreenLogOutQuery, variables, {
    fetchKey: queryOptions.fetchKey,
    fetchPolicy: 'store-or-network',
  });

  const collection = useFragment(
    homeScreenLogoutCollectionsFragment,
    findCollectionsRef,
  );

  const {
    alcoholCollection,
    outDoorCollection,
    fineDiningCollection,
    cafesCollection,
  } = useMemo(() => {
    const outdoor = filterCollection(
      collection,
      RestaurantCollectionCategory.OUTDOOR_SEATING,
    );
    const alcohol = filterCollection(
      collection,
      RestaurantCollectionCategory.SERVES_ALCOHOL,
    );
    const fineDining = filterCollection(
      collection,
      RestaurantCollectionCategory.FINE_DINING,
    );
    const cafes = filterCollection(
      collection,
      RestaurantCollectionCategory.CAFES,
    );

    return {
      alcoholCollection: buildRestaurantCollectionViewData(alcohol),
      outDoorCollection: buildRestaurantCollectionViewData(outdoor),
      fineDiningCollection: buildRestaurantCollectionViewData(fineDining),
      cafesCollection: buildRestaurantCollectionViewData(cafes),
    };
  }, [collection]);

  const scrollViewRef = useRef(null);

  return (
    <View flex={1}>
      <View position="absolute" top={0} left={0} right={0} zIndex={1}>
        <Header navigation={navigation} />
      </View>
      <ScrollView
        showsVerticalScrollIndicator={false}
        ref={scrollViewRef}
        contentContainerStyle={{ paddingTop: 60 + top }}
      >
        <View mt="2xl">
          <View px="2xl" mt="xl">
            <HomePageBannerCarousel fragmentRef={getAllHomePageBannersRef} />
          </View>
          <InvestmentWidget />
          <View px="2xl">
            <RestaurantsWithOffers fragmentRef={offerRestaurantsRef} />
          </View>
          <View mt="4xl">
            <PhotoMomentWidget />
          </View>
          <FeatureTiles />
          <InvestmentWidget isCarousalWidget investmentLead={[]} />
          {Platform.OS === 'web' && (
            <View mt="4xl" px="2xl">
              <DownloadCard />
            </View>
          )}
          <View mt="2xl" />
          <RestaurantCollectionView
            title="Places to party at 32ND"
            category={RestaurantCollectionCategory.SERVES_ALCOHOL}
            collection={alcoholCollection}
          />
          <RestaurantCollectionView
            title="Cafes With Outdoor Seating"
            category={RestaurantCollectionCategory.OUTDOOR_SEATING}
            collection={outDoorCollection}
          />
          <RestaurantCollectionView
            title="Fine Dining at 32ND"
            category={RestaurantCollectionCategory.FINE_DINING}
            collection={fineDiningCollection}
          />
          <RestaurantCollectionView
            title="Cafes at 32ND"
            category={RestaurantCollectionCategory.CAFES}
            collection={cafesCollection}
          />
          <View px="2xl" mt="2xl" mb="4xl">
            <DidYouKnowCarouselNew />
          </View>
        </View>
      </ScrollView>
    </View>
  );
};

export default HomePageNonLoggedIn;
