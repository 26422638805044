import { graphql } from 'react-relay';

/* eslint-disable import/prefer-default-export */

export const selectParkingLocationScreenQuery = graphql`
  query selectParkingLocationScreenQuery($filter: findTaskFilterInput!) {
    ...selectParkingLocationScreenQueryTaskFragment
      @arguments(taskFilter: $filter, count: 1)
  }
`;

export const selectParkingLocationScreenTaskFragment = graphql`
  fragment selectParkingLocationScreenQueryTaskFragment on Query
  @refetchable(queryName: "selectParkingLocationScreenTaskFragmentQuery")
  @argumentDefinitions(
    taskFilter: { type: "findTaskFilterInput!" }
    count: { type: "Int" }
    cursor: { type: "String" }
  ) {
    findTasks: findTasksV2(filter: $taskFilter, first: $count, after: $cursor)
      @connection(
        key: "selectParkingLocationScreenQueryTaskFragment__findTasks"
      ) {
      edges {
        node {
          _id
          vehicle {
            registration {
              plate
            }
          }
          taskStartLocation {
            locationId
            name
            parkingSpaces {
              floor {
                floorName
                floorId
                bay {
                  bayId
                  bayName
                }
              }
            }
          }
          taskEndLocation {
            locationId
            name
            parkingSpaces {
              floor {
                floorName
                floorId
                bay {
                  bayId
                  bayName
                }
              }
            }
          }
          history {
            _id
            action
            actor
            event_at
          }
          taskType
          campus {
            _id
          }
          parkingETA {
            parkingTimePrediction {
              locationId
              name
              parkingTimeThreshold
              acceptedToCompleteTime
              startToCompleteTime
            }
          }
        }
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`;
