/**
 * @generated SignedSource<<3e2028180aa431e9259195bf22193af2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EnumtaskHistoryAction = "abort" | "accepted" | "arrived" | "assigned" | "cancelled" | "completed" | "created" | "exited" | "incomplete" | "onboarded" | "started" | "%future added value";
export type EnumtaskTaskType = "park" | "recall" | "repark" | "%future added value";
export type SortConnectiontaskEnum = "CREATED_AT_DESC" | "DELAY_AT_ASC" | "DELAY_AT_DESC" | "_ID_ASC" | "_ID_DESC" | "%future added value";
export type findParkingLocationFilterInput = {
  campus: FilterFindManyparkingLocationCampusInput;
};
export type FilterFindManyparkingLocationCampusInput = {
  _id: any;
};
export type findTaskFilterInput = {
  _id?: any | null;
  assignedTo?: any | null;
  campus: FilterFindManytaskCampusInput;
  created_at?: string | null;
  history?: ReadonlyArray<FilterFindManytaskHistoryInput | null> | null;
  metrics?: FilterFindManytaskMetricsInput | null;
  ownerId?: any | null;
  search?: findTaskFilterInputSearch | null;
  taskEndLocation?: FilterFindManytaskTaskEndLocationInput | null;
  taskStartLocation?: FilterFindManytaskTaskStartLocationInput | null;
  taskType?: EnumtaskTaskType | null;
};
export type FilterFindManytaskHistoryInput = {
  action?: EnumtaskHistoryAction | null;
};
export type FilterFindManytaskTaskStartLocationInput = {
  _id?: any | null;
  locationId: any;
};
export type FilterFindManytaskTaskEndLocationInput = {
  _id?: any | null;
  locationId: any;
};
export type FilterFindManytaskCampusInput = {
  _id: any;
};
export type FilterFindManytaskMetricsInput = {
  _id?: any | null;
  acceptedIn?: number | null;
  assignedIn?: number | null;
  createToArrivedIn?: number | null;
  createdAt?: any | null;
  isAcceptedDelayed?: boolean | null;
  isAssignmentDelayed?: boolean | null;
  isOnbardingDelayed?: boolean | null;
  isParkingDelayed?: boolean | null;
  isWithInCustomerETA?: boolean | null;
  isWithinETA?: boolean | null;
  startToCompleteIn?: number | null;
  taskCompletionIn?: number | null;
  taskOnboardeIn?: number | null;
  updatedAt?: any | null;
  valetTookInCompletion?: number | null;
};
export type findTaskFilterInputSearch = {
  vehicleNo: string;
};
export type supervisorOverviewParentQuery$variables = {
  endTimeStamp: number;
  parkingLocationFilter: findParkingLocationFilterInput;
  sortTasks?: SortConnectiontaskEnum | null;
  startTimeStamp: number;
  taskFilter: findTaskFilterInput;
};
export type supervisorOverviewParentQuery$data = {
  readonly findParkingLocation: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"searchParkingList_findParkingLocation">;
  }>;
  readonly getTaskMetricsDateWise: {
    readonly metrics: ReadonlyArray<{
      readonly assignmentScore: number | null;
      readonly assignmentTime: number | null;
      readonly date: string | null;
      readonly deliveryScore: number | null;
      readonly deliveryTime: number | null;
      readonly onboardingScore: number | null;
      readonly onboardingTime: number | null;
      readonly parkingScore: number | null;
      readonly parkingTime: number | null;
    } | null> | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"supervisorOverviewTasksFragment">;
};
export type supervisorOverviewParentQuery = {
  response: supervisorOverviewParentQuery$data;
  variables: supervisorOverviewParentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endTimeStamp"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "parkingLocationFilter"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sortTasks"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startTimeStamp"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "taskFilter"
},
v5 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "endTimeStamp",
      "variableName": "endTimeStamp"
    },
    {
      "kind": "Variable",
      "name": "startTimeStamp",
      "variableName": "startTimeStamp"
    }
  ],
  "concreteType": "taskMetricsDayWiseTC",
  "kind": "LinkedField",
  "name": "getTaskMetricsDateWise",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "taskMetricsDateTC",
      "kind": "LinkedField",
      "name": "metrics",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "date",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "assignmentTime",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "assignmentScore",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "deliveryTime",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "deliveryScore",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "parkingTime",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "parkingScore",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "onboardingTime",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "onboardingScore",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = [
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "parkingLocationFilter"
  }
],
v7 = {
  "kind": "Variable",
  "name": "sort",
  "variableName": "sortTasks"
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v10 = [
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "taskFilter"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 15
  },
  (v7/*: any*/)
],
v11 = [
  (v9/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "supervisorOverviewParentQuery",
    "selections": [
      (v5/*: any*/),
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "parkingLocation",
        "kind": "LinkedField",
        "name": "findParkingLocation",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "searchParkingList_findParkingLocation"
          }
        ],
        "storageKey": null
      },
      {
        "args": [
          {
            "kind": "Literal",
            "name": "count",
            "value": 15
          },
          (v7/*: any*/),
          {
            "kind": "Variable",
            "name": "taskFilter",
            "variableName": "taskFilter"
          }
        ],
        "kind": "FragmentSpread",
        "name": "supervisorOverviewTasksFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v4/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "supervisorOverviewParentQuery",
    "selections": [
      (v5/*: any*/),
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "parkingLocation",
        "kind": "LinkedField",
        "name": "findParkingLocation",
        "plural": true,
        "selections": [
          (v8/*: any*/),
          (v9/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "parkingLocationAddress",
            "kind": "LinkedField",
            "name": "address",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "addressLine1",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "parkingLocationParkingSpaces",
            "kind": "LinkedField",
            "name": "parkingSpaces",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "parkingLocationParkingSpacesFloor",
                "kind": "LinkedField",
                "name": "floor",
                "plural": true,
                "selections": [
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "floorName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "parkingLocationParkingSpacesFloorBay",
                    "kind": "LinkedField",
                    "name": "bay",
                    "plural": true,
                    "selections": [
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "bayName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "parkingSpots",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "parkingOccupied",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "parkingVacant",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "findTasks",
        "args": (v10/*: any*/),
        "concreteType": "taskConnection",
        "kind": "LinkedField",
        "name": "findTasksV2",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "taskEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "task",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "taskType",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "taskVehicle",
                    "kind": "LinkedField",
                    "name": "vehicle",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "taskVehicleBrand",
                        "kind": "LinkedField",
                        "name": "brand",
                        "plural": false,
                        "selections": (v11/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "taskVehicleRegistration",
                        "kind": "LinkedField",
                        "name": "registration",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "plate",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "taskHistory",
                    "kind": "LinkedField",
                    "name": "history",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "action",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "event_at",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "valet",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "identityCode",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "taskMetrics",
                    "kind": "LinkedField",
                    "name": "metrics",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isWithinETA",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isParkingDelayed",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isOnbardingDelayed",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isAssignmentDelayed",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "assignedIn",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "taskOnboardeIn",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "taskCompletionIn",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "valetTookInCompletion",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "taskTaskStartLocation",
                    "kind": "LinkedField",
                    "name": "taskStartLocation",
                    "plural": false,
                    "selections": (v11/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "taskTaskEndLocation",
                    "kind": "LinkedField",
                    "name": "taskEndLocation",
                    "plural": false,
                    "selections": (v11/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "parkingTag",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "findTasks",
        "args": (v10/*: any*/),
        "filters": [
          "filter",
          "sort"
        ],
        "handle": "connection",
        "key": "SupervisorOverviewTaskFragment_findTasks",
        "kind": "LinkedHandle",
        "name": "findTasksV2"
      }
    ]
  },
  "params": {
    "cacheID": "3f22a3fd8d28a05c605e5a77c0c1d522",
    "id": null,
    "metadata": {},
    "name": "supervisorOverviewParentQuery",
    "operationKind": "query",
    "text": "query supervisorOverviewParentQuery(\n  $startTimeStamp: Int!\n  $endTimeStamp: Int!\n  $parkingLocationFilter: findParkingLocationFilterInput!\n  $taskFilter: findTaskFilterInput!\n  $sortTasks: SortConnectiontaskEnum\n) {\n  getTaskMetricsDateWise(startTimeStamp: $startTimeStamp, endTimeStamp: $endTimeStamp) {\n    metrics {\n      date\n      assignmentTime\n      assignmentScore\n      deliveryTime\n      deliveryScore\n      parkingTime\n      parkingScore\n      onboardingTime\n      onboardingScore\n    }\n  }\n  findParkingLocation(filter: $parkingLocationFilter) {\n    ...searchParkingList_findParkingLocation\n  }\n  ...supervisorOverviewTasksFragment_Pxh5F\n}\n\nfragment searchParkingList_findParkingLocation on parkingLocation {\n  _id\n  name\n  address {\n    addressLine1\n  }\n  parkingSpaces {\n    floor {\n      _id\n      floorName\n      bay {\n        _id\n        bayName\n        parkingSpots\n        parkingOccupied\n        parkingVacant\n      }\n    }\n  }\n}\n\nfragment supervisorOverviewTasksFragment_Pxh5F on Query {\n  findTasks: findTasksV2(filter: $taskFilter, sort: $sortTasks, first: 15) {\n    edges {\n      node {\n        taskType\n        vehicle {\n          brand {\n            name\n          }\n          registration {\n            plate\n          }\n        }\n        history {\n          action\n          event_at\n        }\n        valet {\n          identityCode\n        }\n        metrics {\n          isWithinETA\n          isParkingDelayed\n          isOnbardingDelayed\n          isAssignmentDelayed\n          assignedIn\n          taskOnboardeIn\n          taskCompletionIn\n          valetTookInCompletion\n        }\n        taskStartLocation {\n          name\n        }\n        taskEndLocation {\n          name\n        }\n        parkingTag\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4edb87103b0c50f2caa34f51af7b38ed";

export default node;
