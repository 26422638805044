/**
 * @generated SignedSource<<c65a6e1b08efc264fbdae4a234be58c2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type EnumInvestmentPropertyLeadsGenerateModelStatus = "APPLICATION_RECEIVED" | "CREATED" | "REFUND_INITIATED" | "REFUND_PROCESSED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type homeScreenQueryFindInvestmentLeadFragment$data = ReadonlyArray<{
  readonly investmentPropertyId: any;
  readonly status: EnumInvestmentPropertyLeadsGenerateModelStatus | null;
  readonly " $fragmentType": "homeScreenQueryFindInvestmentLeadFragment";
}>;
export type homeScreenQueryFindInvestmentLeadFragment$key = ReadonlyArray<{
  readonly " $data"?: homeScreenQueryFindInvestmentLeadFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"homeScreenQueryFindInvestmentLeadFragment">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "homeScreenQueryFindInvestmentLeadFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "investmentPropertyId",
      "storageKey": null
    }
  ],
  "type": "InvestmentPropertyLeadsGenerateModel",
  "abstractKey": null
};

(node as any).hash = "91d20af8e24d139eb4a2dd1bf910c034";

export default node;
