import React, { Suspense, useCallback, useEffect, useState } from 'react';
import { useQueryLoader } from 'react-relay';
import { useIsFocused } from '@react-navigation/native';
import {
  IconButton,
  Layout,
  TopNavigation,
  View,
} from '../../../../components/new';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../../../utilities/NewErrorBoundary';
import NewErrorView from '../../../../utilities/NewErrorView';
import { Loading } from '../../../../components';
import PayBillScreenInner from './PayBillScreenInner';
import {
  MembershipTypes,
  applyEllipsis,
  navigateBack,
} from '../../../../utilities/helper';
import useUserStore from '../../../../stores/userStore';
import useAuthStore from '../../../../stores/authStore';
import { configQuery } from './API/fetchConfig';
import { usePayBillStore } from './hooks/usePayBillStore';

const PayBillScreen = ({ navigation }) => {
  const userId = useUserStore((state) => state.id);
  const isFocused = useIsFocused();
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  const pageTitle = usePayBillStore((state) => state.title);
  const [configQueryRef, loadConfigQuery] = useQueryLoader(configQuery);
  const userRole = useUserStore((state) => state.role);
  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'network-only',
    });

  const refresh = useCallback(async () => {
    setRefreshedQueryOptions((prev) => ({
      ...prev,
      fetchKey: (prev?.fetchKey || 0) + 1,
    }));
  }, []);

  const goBack = () => {
    navigateBack(navigation, userRole);
  };

  useEffect(() => {
    /**
     * dependent on `isFocused` because often times paybill screen is returned from payment gateway
     * and other screen. So the fresh api call is needed.
     */
    if (!isFocused) return;
    const variables = {
      addonsFilter: {
        type: MembershipTypes.PLUS_MEMBERSHIP,
      },
      userId: isLoggedIn ? userId : '',
      isLoggedIn,
    };
    loadConfigQuery(variables, { fetchPolicy: 'network-only' });
  }, [isLoggedIn, userId, isFocused]);

  return (
    <Layout level={2}>
      <View zIndex={1}>
        <TopNavigation
          title={applyEllipsis(pageTitle, 25)}
          level=""
          textSize="md"
          textColor="primary.400"
          IconLeft={
            <IconButton
              name="back-outline-300"
              size="md"
              appearance="ghost"
              iconColor="primary.500"
              onPress={goBack}
            />
          }
        />
      </View>
      <View flex={1}>
        <NewErrorBoundary
          fetchKey={refreshedQueryOptions.fetchKey}
          fallback={
            <NewErrorView
              errorMsg="Sorry something went wrong"
              reload={refresh}
            />
          }
        >
          <Suspense
            fallback={
              <View mt="8xl">
                <Loading />
              </View>
            }
          >
            {configQueryRef ? (
              <View flex={1}>
                <PayBillScreenInner
                  configQueryRef={configQueryRef}
                  queryOptions={refreshedQueryOptions}
                />
              </View>
            ) : null}
          </Suspense>
        </NewErrorBoundary>
      </View>
    </Layout>
  );
};

export default PayBillScreen;
