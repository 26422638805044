/* eslint-disable no-nested-ternary */
import React, { useState, useCallback, Suspense } from 'react';
import { useLazyLoadQuery } from 'react-relay';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { ScrollView } from 'react-native';
import {
  Layout,
  View,
  Text,
  IconButton,
  TopNavigation,
  Button,
  Icon,
} from '../../../components/new';
import Loading from '../../../components/loading.component';
import TaskCard from '../../../components/new/primitive/TaskCard/TaskCard';
import DisconnectedDropover from '../../../components/disconnectedpopover.component';
import { useSnackbarStore } from '../../../stores/snackbar/snackbarStore';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../../utilities/NewErrorBoundary';
import NewErrorView from '../../../utilities/NewErrorView';
import useCommonStore from '../../../stores/commonStore';
import useUserStore, { UserRole } from '../../../stores/userStore';
import RadioChipGroup from '../../../components/new/composites/RadioChipGroup/RadioChipGroup';
import {
  formatPlateNo,
  formatTimeToHrsMinSec,
  maskedNumber,
  navigateBack,
  taskHistoryActionTypes,
  taskTypes,
} from '../../../utilities/helper';
import RectangularTimer from '../../../components/new/primitive/Timer/RectangularTimer';
import {
  startRideScreenQuery,
  startRideScreenTaskFragment,
} from './API/startRideScreenQuery';
import { updateTaskForSupervisor } from '../../../relay/taskApi';
import useCampusLocation from '../../../stores/campusLocation';
import { reparkQuery } from '../../valetSupervisor/API/reparkQuery';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../../../components/new/primitive/snackbar/helpers/helpers';
import {
  InteractionType,
  firebaseEventLogger,
} from '../../../utilities/firbaseAnalytics';
import { Appearance, State, Status } from '../../../themes/new/helper';
import { usePagedTasks } from '../../../hooks/usePagedTasks';

interface StartRideScreenProps {
  queryOptions: any;
  variables: any;
  taskTypeFromParams: string;
  fetchKey: any;
}

const StartRideScreen = ({
  queryOptions,
  variables,
  taskTypeFromParams,
  fetchKey,
}: StartRideScreenProps) => {
  const navigation = useNavigation<any>();
  const { campusLocations } = useCampusLocation((state) => state);
  const { role } = useUserStore((state) => state);
  const { dispatchSnackbar } = useSnackbarStore((state) => state);
  const [startRideDisabled, setStartRideDisabled] = useState(false);
  const insets = useSafeAreaInsets();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const findTasksFragmentRef = useLazyLoadQuery(
    startRideScreenQuery,
    variables,
    {
      ...queryOptions,
      networkCacheConfig: { force: true },
      fetchPolicy: 'network-only',
    },
  );

  const [campusLocationsData, setCampusLocationsData] =
    useState(campusLocations);

  const { tasks: findTasks } = usePagedTasks(
    startRideScreenTaskFragment,
    findTasksFragmentRef,
  );

  const { vehicle, _id, history, user, valet, parkingRecommendation } =
    findTasks[0] ?? {};
  const recommendedParkingId =
    parkingRecommendation?.taskEndLocation?.locationId;

  const onboardedStatus =
    history &&
    history?.length &&
    history?.filter((ele) => ele?.action === taskHistoryActionTypes.onboarded);

  const formattedVehicleNumber = formatPlateNo(vehicle?.registration?.plate);
  const heading = formattedVehicleNumber;
  const acceptedStatus =
    history &&
    history?.length &&
    history?.filter((ele) => ele?.action === taskHistoryActionTypes.accepted);

  const taskAcceptedTime =
    acceptedStatus && acceptedStatus?.length
      ? new Date(acceptedStatus[0]?.event_at).valueOf()
      : new Date(history[0]?.event_at).valueOf();
  const currentTime = new Date().valueOf();
  const difference = currentTime - taskAcceptedTime;

  const timerObj = {
    title1: 'Wait Time',
    title2: formatTimeToHrsMinSec(difference / 1000),
    title2Color: 'primary.500',
  };
  if (difference / 1000 > 120 && difference / 1000 < 300) {
    timerObj.title2Color = 'warning.500';
  }
  if (difference / 1000 > 300) {
    timerObj.title2Color = 'error.500';
  }

  const topRightCornerComponent = () => {
    return valet?.identityCode ? (
      <View
        borderRadius="full"
        borderWidth="xs"
        borderColor="primary.100"
        py="sm"
        px="md"
        bg="primary.50"
      >
        <Text size="sm" color="primary.500">
          {valet?.identityCode}
        </Text>
      </View>
    ) : (
      <View
        borderRadius="full"
        borderWidth="xs"
        borderColor="primary.50"
        p="sm"
      >
        <Icon size="xl" name="car-filled-400" color="primary.100" />
      </View>
    );
  };

  const bottomLeftCornerComponent = () => (
    <RectangularTimer
      title1={timerObj?.title1}
      title2={timerObj?.title2}
      title2Color={timerObj?.title2Color}
    />
  );

  const RenderUserDetails = ({
    title,
    text,
    isVerified,
    showNotVerifiedText,
    iconName,
  }) => {
    return (
      <View>
        <Text size="sm" color="primary.200">
          {title}
        </Text>
        <View
          mt="lg"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Text
            size="lg"
            weight="medium"
            color={isVerified ? 'primary.500' : 'primary.200'}
          >
            {text}
          </Text>
          {isVerified && iconName?.length ? (
            <View>
              <Icon name={iconName} size="2xl" color="primary.500" />
            </View>
          ) : showNotVerifiedText ? (
            <Text size="xs" color="primary.100">
              Not Verified
            </Text>
          ) : (
            <></>
          )}
        </View>
      </View>
    );
  };

  /* ------ finding matched parking location from campusLocationsData
    with taskStartLocation ------  */
  const parkingLocation =
    campusLocationsData &&
    campusLocationsData?.length &&
    campusLocationsData?.map((item) => {
      return item?.name === '32nd'
        ? {
            id: item?._id,
            name: item?.name,
            selected: false,
            state: 'disabled',
          }
        : {
            id: item?._id,
            name: item?.name,
            selected: item?._id === recommendedParkingId,
          };
    });

  const [parkingLocationData, setParkingLocationData] =
    useState(parkingLocation);

  // ------ finding selected parking location ------
  const findSelectedLocation =
    parkingLocationData &&
    parkingLocationData?.length &&
    parkingLocationData?.filter((item) => item?.selected);

  // ------ handle on selecting particular parking location ------
  const onPressParkingLocation = (id) => {
    firebaseEventLogger('startRide__selectLocation_Tap', {
      buttonName: 'selectLocation',
      screenName: 'startRide',
      userType: `${UserRole.VALET}`,
      interactionType: InteractionType.TAP,
      taskId: `${_id}`,
      locationId: `${id}`,
    });
    const data = parkingLocationData.map((item) => {
      if (item.id === id) {
        return { ...item, selected: true };
      }
      return { ...item, selected: false };
    });
    setParkingLocationData(data);
  };

  const navigateScreen = (response, error) => {
    if (error) {
      // console.log(error);
      setStartRideDisabled(false);
    }
    if (response) {
      // console.log('Customer Onboarded');
      setStartRideDisabled(false);
      if (role === UserRole.VALET) {
        navigation.navigate('SelectParkingLocationScreen', { taskId: _id });
      } else {
        navigation.navigate(UserRole.VALET_SUPERVISOR);
      }
    }
  };

  const handleRepark = () => {
    const data = {
      valetId: valet?._id,
      taskId: _id,
      taskEndLocationId: findSelectedLocation[0]?.id,
    };
    reparkQuery(data)
      .then((res) => {
        setStartRideDisabled(false);
        navigation.navigate('SelectParkingLocationScreen', {
          taskId: res?.reparkTask?._id,
        });
      })
      .catch((err) => {
        setStartRideDisabled(false);
        if (err?.res?.errors[0]?.extensions?.code === 104) {
          navigation.popToTop();
        }
        dispatchSnackbar({
          msg: err?.res?.errors[0]?.message || 'Something went wrong!',
          status: SnackbarStatus.error,
          version: SnackbarVersion.v2,
          position: 'top',
        });
      });
  };

  const handleStartRide = () => {
    // ------ if task type from params is repark then do handleRepark ------
    if (taskTypeFromParams === taskTypes.repark) {
      handleRepark();
      return;
    }
    setStartRideDisabled(true);
    const taskData = {};
    (taskData as any)._id = _id;
    (taskData as any).record = {};
    (taskData as any).record.taskEndLocation = {};
    (taskData as any).record.taskEndLocation.locationId =
      findSelectedLocation[0]?.id;
    (taskData as any).record.history = [];
    (taskData as any).record.history =
      onboardedStatus && onboardedStatus?.length
        ? [{ action: taskHistoryActionTypes.started }]
        : [
            { action: taskHistoryActionTypes.onboarded },
            { action: taskHistoryActionTypes.started },
          ];
    updateTaskForSupervisor(taskData, navigateScreen, (err) => {
      // console.log(err);
      let msg = err?.res?.errors[0]?.message;
      if (
        err?.res?.errors[0]?.message ===
        "Cannot read properties of undefined (reading 'map')"
      ) {
        msg = 'Task is corrupted. Cancel this and create a new one!';
      }
      dispatchSnackbar({
        msg: msg || 'Something went wrong!',
        status: SnackbarStatus.error,
        version: SnackbarVersion.v2,
      });
      setStartRideDisabled(false);
    });
  };

  const handleFinishOnboarding = () => {
    setStartRideDisabled(true);
    const taskData = {};
    (taskData as any)._id = _id;
    (taskData as any).record = {};
    (taskData as any).record.history = [];
    (taskData as any).record.history = [
      { action: taskHistoryActionTypes.onboarded },
    ];
    updateTaskForSupervisor(taskData, navigateScreen, (err) => {
      let msg = err?.res?.errors[0]?.message;
      if (
        err?.res?.errors[0]?.message ===
        "Cannot read properties of undefined (reading 'map')"
      ) {
        msg = 'Task is corrupted. Cancel this and create a new one!';
      }
      dispatchSnackbar({
        msg: msg || 'Something went wrong!',
        status: SnackbarStatus.error,
        version: SnackbarVersion.v2,
        position: 'bottom',
      });
      setStartRideDisabled(false);
    });
  };

  const bottomLeftCTAComponent = () => {
    return (
      <Button
        size="lg"
        appearance={Appearance.FILLED}
        state={
          (findSelectedLocation?.length && !startRideDisabled) ||
          (role !== UserRole.VALET && !startRideDisabled)
            ? State.ACTIVE
            : State.DISABLED
        }
        status={Status.PRIMARY}
        shadow="sm"
        loading={startRideDisabled}
        mb={insets.bottom}
        onPress={() => {
          if (role === UserRole.VALET) {
            firebaseEventLogger('startRide__startRide_Tap', {
              buttonName: 'startRide',
              screenName: 'startRide',
              userType: `${UserRole.VALET}`,
              interactionType: InteractionType.TAP,
              taskId: `${_id}`,
              locationId: `${findSelectedLocation[0]?.id}`,
            });
            handleStartRide();
          } else {
            firebaseEventLogger('startRide__finishTask_Tap', {
              buttonName: 'finishTask',
              screenName: 'startRide',
              userType: `${role}`,
              interactionType: InteractionType.TAP,
              taskId: `${_id}`,
              locationId: `${findSelectedLocation[0]?.id}`,
            });
            handleFinishOnboarding();
          }
        }}
      >
        {role === UserRole.VALET ? 'Start Ride' : 'Finish Onboarding'}
      </Button>
    );
  };

  return (
    <View flex={1}>
      <ScrollView scrollIndicatorInsets={{ right: 0 }}>
        <View px="2xl" mt="4xl" justifyContent="space-between">
          <View>
            <View
              mb="9xl"
              borderRadius="lg"
              borderColor="primary.50"
              borderWidth="xs"
              px="2xl"
              py="2xl"
            >
              <View>
                <RenderUserDetails
                  title="Mobile Number"
                  text={`${user?.contact?.phone?.prefix} ${maskedNumber(
                    user?.contact?.phone?.number,
                  )}`}
                  isVerified={user?.contact?.phone?.verified}
                  showNotVerifiedText
                  iconName="check-filled-400"
                />
              </View>
              <View mt="4xl">
                <RenderUserDetails
                  title="Name"
                  text={`${user?.name?.first || ''} ${user?.name?.last || ''}`}
                  isVerified={user?.contact?.phone?.verified}
                  iconName="check-filled-400"
                />
              </View>
              <View mt="4xl">
                <RenderUserDetails
                  title="Total Visits"
                  text={user?.parkingVisitCount}
                  isVerified={user?.contact?.phone?.verified}
                />
              </View>
            </View>
          </View>
          {role === UserRole.VALET ? (
            <View mt="9xl">
              <RadioChipGroup
                data={parkingLocationData}
                onPress={(id) => onPressParkingLocation(id)}
                title="Select Parking Location"
              />
            </View>
          ) : (
            <></>
          )}
        </View>
        <View height={200} />
      </ScrollView>
      <View position="absolute" bottom={0} width="100%" bg="primary.10">
        <TaskCard
          heading={heading}
          topRightCorner={topRightCornerComponent()}
          bottomLeftCorner={bottomLeftCornerComponent()}
          bottomLeftCTA={bottomLeftCTAComponent()}
          shadow="lg"
          alwaysOpened
        />
      </View>
    </View>
  );
};

const StartRideScreenWrapper = ({ navigation, route }) => {
  const { taskId, taskType } = route?.params ?? {};
  const campusID = useCommonStore((state) => state.campusId);
  const { role } = useUserStore((state) => state);
  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'network-only',
    });
  const [netStatus, setNetStatus] = useState(true);

  const refresh = useCallback(
    () => {
      setRefreshedQueryOptions((prev) => ({
        fetchKey: (prev?.fetchKey ?? 0) + 1,
        fetchPolicy: 'network-only',
      }));
    },
    [
      /* ... */
    ],
  );

  const filter = {
    campus: { _id: campusID },
    _id: taskId,
  };

  useFocusEffect(
    useCallback(() => {
      refresh();
    }, []),
  );

  return (
    <Layout level={2}>
      <TopNavigation
        appearance="ghost"
        level="1"
        IconLeft={
          <IconButton
            name="back-outline-300"
            size="md"
            appearance="ghost"
            iconColor="primary.500"
            onPress={() => navigateBack(navigation, role)}
          />
        }
        title="Review Details"
      />
      <NewErrorBoundary
        fetchKey={refreshedQueryOptions.fetchKey}
        fallback={
          <NewErrorView
            errorMsg="Sorry something went wrong"
            reload={refresh}
          />
        }
      >
        <Suspense fallback={<Loading />}>
          <StartRideScreen
            variables={{
              filter,
              // searchFilter,
            }}
            fetchKey={refreshedQueryOptions?.fetchKey}
            queryOptions={refreshedQueryOptions}
            refresh={refresh}
            taskTypeFromParams={taskType}
          />
        </Suspense>
      </NewErrorBoundary>
      <DisconnectedDropover
        setNetStatus={setNetStatus}
        text="No Internet Connection"
        icon="wifi-off-outline"
      />
    </Layout>
  );
};

export default StartRideScreenWrapper;
