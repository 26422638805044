import React, {
  useState,
  useEffect,
  useCallback,
  Suspense,
  useRef,
} from 'react';
import { useLazyLoadQuery } from 'react-relay';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { ScrollView } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import {
  Layout,
  View,
  Text,
  IconButton,
  TopNavigation,
  Button,
  Icon,
  RadioGroup,
  Divider,
} from '../../../components/new';
import Loading from '../../../components/loading.component';
import TaskCard from '../../../components/new/primitive/TaskCard/TaskCard';
import DisconnectedDropover from '../../../components/disconnectedpopover.component';
import { useSnackbarStore } from '../../../stores/snackbar/snackbarStore';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../../utilities/NewErrorBoundary';
import NewErrorView from '../../../utilities/NewErrorView';
import useCommonStore from '../../../stores/commonStore';
import useUserStore, { UserRole } from '../../../stores/userStore';
import {
  selectParkingLocationScreenQuery,
  selectParkingLocationScreenTaskFragment,
} from './API/selectParkingLocationScreenQuery';
import RadioChipGroup from '../../../components/new/composites/RadioChipGroup/RadioChipGroup';
import {
  formatPlateNo,
  taskHistoryActionTypes,
} from '../../../utilities/helper';
import useCampusLocation from '../../../stores/campusLocation';
import { updateTaskForSupervisor } from '../../../relay/taskApi';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../../../components/new/primitive/snackbar/helpers/helpers';
import {
  InteractionType,
  firebaseEventLogger,
} from '../../../utilities/firbaseAnalytics';
import BottomSheetV2 from '../../../components/new/composites/BottomSheet/BottomSheetV2';
import { Appearance, State, Status } from '../../../themes/new/helper';
import { usePagedTasks } from '../../../hooks/usePagedTasks';

interface SelectParkingLocationScreenProps {
  queryOptions: any;
  variables: any;
  fetchKey: any;
}

const SelectParkingLocationScreen = ({
  queryOptions,
  variables,
  fetchKey,
}: SelectParkingLocationScreenProps) => {
  const navigation = useNavigation<any>();
  const { campusLocations } = useCampusLocation((state) => state);
  const [submitKeyButtonDisabled, setSubmitKeyButtonDisabled] = useState(false);
  const [parkingLocationData, setParkingLocationData] = useState([]);
  const [parkingSpotData, setParkingSpotData] = useState([]);
  const [parkingBayData, setParkingBayData] = useState([]);
  const { dispatchSnackbar } = useSnackbarStore((state) => state);
  const bottomSheetModalRef = useRef(null);

  const handleOnDismiss = React.useCallback(() => {
    bottomSheetModalRef?.current?.hide();
  }, []);

  const handlePresentModalPress = React.useCallback(() => {
    bottomSheetModalRef?.current?.show();
  }, []);
  const insets = useSafeAreaInsets();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const findTasksFragmentRef = useLazyLoadQuery(
    selectParkingLocationScreenQuery,
    variables,
    {
      ...queryOptions,
      networkCacheConfig: { force: true },
    },
  );

  const { tasks: findTasks } = usePagedTasks(
    selectParkingLocationScreenTaskFragment,
    findTasksFragmentRef,
  );
  const { taskEndLocation, vehicle, _id, history, parkingETA } =
    findTasks[0] ?? {};

  const filteredCampusLocations = campusLocations.filter(
    (item) => item?.name !== '32nd',
  );

  const [campusLocationsData, setCampusLocationsData] = useState(
    filteredCampusLocations,
  );

  const setCampusLocationFunc = () => {
    const data = campusLocationsData.map((item) => {
      return { ...item, checked: item?._id === taskEndLocation?.locationId };
    });
    setCampusLocationsData(data);
  };

  const setParkingLocationsFunc = (locationId) => {
    /* ------ finding matched parking location from findParkingLocation
  with taskStartLocation ------  */
    const parkingLocation =
      campusLocationsData &&
      campusLocationsData?.length &&
      campusLocationsData
        ?.filter((item) => item?._id === locationId)
        .map((item) => {
          return {
            id: item?._id,
            selected: true,
            parkingSpaces: item?.parkingSpaces,
            name: item?.name,
          };
        });

    setParkingLocationData(parkingLocation);
  };

  const setParkingBayFunc = (id = '') => {
    let index = 0;
    let isSpotDisabled = false;
    /* ------ finding matched parking bay from parkingLocation
  found above with taskStartLocation ------ */
    const parkingBay =
      id && id !== ''
        ? parkingBayData.map((item, index1) => {
            if (item.id === id) {
              index = index1;
              return { ...item, selected: true };
            }
            return { ...item, selected: false };
          })
        : parkingLocationData &&
          parkingLocationData?.length &&
          parkingLocationData[0]?.parkingSpaces?.floor?.map((item) => {
            return {
              id: item?._id,
              name: item?.floorName,
              selected: false,
              bay: item?.bay,
            };
          });
    setParkingBayData(parkingBay);
    if (parkingBay?.length === 1) {
      isSpotDisabled = false;
    } else if (id !== '') {
      isSpotDisabled = false;
    } else {
      isSpotDisabled = true;
    }
    const parkingSpot = parkingBay[index]?.bay?.map((item) => {
      return {
        id: item?._id,
        name: item?.bayName,
        selected: false,
        state: isSpotDisabled ? 'disabled' : 'active',
      };
    });
    setParkingSpotData(parkingSpot);
  };

  useEffect(() => {
    setCampusLocationFunc();
    return () => {
      setCampusLocationsData(campusLocations);
      setParkingLocationData([]);
      setParkingBayData([]);
      setParkingSpotData([]);
    };
  }, []);

  useEffect(() => {
    setParkingLocationsFunc(taskEndLocation?.locationId);
  }, [taskEndLocation]);

  useEffect(() => {
    setParkingBayFunc();
  }, [parkingLocationData]);

  // ------ find if there is selected location ------
  const findSelectedLocation =
    parkingLocationData &&
    parkingLocationData?.length &&
    parkingLocationData?.filter((item) => item?.selected);

  const ETA =
    findSelectedLocation &&
    findSelectedLocation?.length &&
    parkingETA?.parkingTimePrediction?.filter(
      (item) => item?.locationId === findSelectedLocation[0]?.id,
    );

  const delayAt = new Date(ETA[0]?.startToCompleteTime).valueOf(); // delay time
  const currentTime = new Date().valueOf(); // current time
  const isDelayed = currentTime > delayAt; // check if current time breached delay time

  // ------ difference between current time and delayAt -------
  const delayedDifferenceInMins = (delayAt - currentTime) / 60 / 1000;

  // ------ finding started status from task history ------
  const startedStatus =
    history &&
    history?.length &&
    history?.filter((ele) => ele.action === taskHistoryActionTypes.started);

  // ------ difference between started status time and current time in seconds ------
  const startedWithCurrentDiff =
    startedStatus &&
    startedStatus?.length &&
    (new Date().valueOf() - new Date(startedStatus[0]?.event_at).valueOf()) /
      1000;

  const [timerValue, setTimerValue] = useState(
    startedWithCurrentDiff <= 30 ? 30 - startedWithCurrentDiff : 0,
  ); // 30 seconds timer after ride started

  const [delayedTimer, setDelayedTimer] = useState(
    Math.floor(delayedDifferenceInMins),
  ); // 1 min timer for for waiting timer in task card

  useEffect(() => {
    setDelayedTimer(Math.floor(delayedDifferenceInMins));
  }, [delayedDifferenceInMins, findSelectedLocation]);

  // ------ updating timer after every 1 sec for submit key button ------
  useEffect(() => {
    const interval = 1000;
    const timer = setInterval(() => {
      if (timerValue > 0) {
        setTimerValue(timerValue - 1);
      }
    }, interval);
    return () => {
      // Useful for cleanup functions
      clearInterval(timer);
    };
  }, [timerValue]);

  // ------ updating timer after every 1 min for timer in task card ------
  useEffect(() => {
    const delayedInterval = 1000 * 60;
    const delayedTimerInterval = setInterval(() => {
      if (delayedTimer > 0) {
        setDelayedTimer(delayedTimer - 1);
      }
    }, delayedInterval);
    return () => {
      // Useful for cleanup functions
      clearInterval(delayedTimerInterval);
    };
  }, [delayedTimer]);

  const formattedVehicleNumber = formatPlateNo(vehicle?.registration?.plate);
  const heading = formattedVehicleNumber;

  const topRightCornerComponent = () => {
    return (
      <View
        flexDirection="row"
        borderRadius="md"
        borderWidth="xs"
        borderColor="primary.50"
        bg="primary.10"
        p="sm+md"
        alignItems="center"
      >
        <Icon
          name="timer-outline-300"
          size="xl"
          color={isDelayed || delayedTimer < 1 ? 'warning.500' : 'success.500'}
        />
        <Text ml="lg" size="md" weight="medium" color="primary.300">
          {delayedTimer < 1 || isDelayed ? 'Delayed' : `${delayedTimer} min`}
        </Text>
      </View>
    );
  };

  // ------ find if there is selected parking bay ------
  const findSelectedBay =
    parkingBayData &&
    parkingBayData?.length &&
    parkingBayData?.filter((item) => item?.selected);

  // ------ find if there is selected parking spot ------
  const findSelectedSpot =
    parkingSpotData &&
    parkingSpotData?.length &&
    parkingSpotData?.filter((item) => item?.selected);

  // ------ handle on selecting particular parking location ------
  const onPressParkingFloor = (id) => {
    setParkingBayFunc(id);
  };

  // ------ handle on selecting particular parking location ------
  const onPressParkingSpot = (id) => {
    const data = parkingSpotData.map((item) => {
      if (item?.id === id) {
        return { ...item, selected: true };
      }
      return { ...item, selected: false };
    });
    setParkingSpotData(data);
  };

  // ------ navigate to submit key screen ------
  const navigateToSubmitKeyScreen = (response, error) => {
    if (error) {
      // console.log(error);
      setSubmitKeyButtonDisabled(false);
      dispatchSnackbar({
        msg: error?.res?.errors[0]?.message || 'Something went wrong!',
        status: SnackbarStatus.error,
        version: SnackbarVersion.v2,
      });
    }
    if (response) {
      // console.log('Customer Onboarded');
      setSubmitKeyButtonDisabled(false);
      //   navigation.navigate('valetSupervisor');
      navigation.navigate('SubmitKeyScreen', { taskId: _id });
    }
  };

  // ------ on press submit key button ------
  const handleSubmitKey = () => {
    setSubmitKeyButtonDisabled(true);

    // ------ taskEndLocation containing locationId, floorId, bayId ------
    const taskEndLocationObj = {
      locationId: findSelectedLocation[0]?.id,
      parkingSpaces: {
        floor: {
          floorId: findSelectedBay[0]?.id,
          bay: {
            bayId: findSelectedSpot[0]?.id,
          },
        },
      },
    };

    firebaseEventLogger('selectParking__submitKey_Tap', {
      buttonName: 'submitKey',
      screenName: 'selectParking',
      userType: `${UserRole.VALET}`,
      interactionType: InteractionType.TAP,
      taskId: `${_id}`,
      locationId: `${findSelectedLocation[0]?.id}`,
      floorId: `${findSelectedBay[0]?.id}`,
      bayId: `${findSelectedSpot[0]?.id}`,
    });
    const taskData = {};
    (taskData as any)._id = _id;
    (taskData as any).record = {};
    (taskData as any).record.taskEndLocation = {};
    (taskData as any).record.taskEndLocation = { ...taskEndLocationObj };
    (taskData as any).record.history = [];
    (taskData as any).record.history = [
      { action: taskHistoryActionTypes.arrived },
    ];
    // eslint-disable-next-line max-len
    updateTaskForSupervisor(taskData, navigateToSubmitKeyScreen, (err) => {
      // console.log(err);
      dispatchSnackbar({
        msg: err?.res?.errors[0]?.message || 'Something went wrong!',
        status: SnackbarStatus.error,
        version: SnackbarVersion.v2,
      });
      setSubmitKeyButtonDisabled(false);
    });
  };

  const bottomLeftCTAComponent = () => {
    return (
      <Button
        size="lg"
        appearance={Appearance.FILLED}
        status={Status.PRIMARY}
        state={
          timerValue > 0 ||
          findSelectedLocation?.length < 1 ||
          findSelectedBay?.length < 1 ||
          findSelectedSpot?.length < 1 ||
          submitKeyButtonDisabled
            ? State.DISABLED
            : State.ACTIVE
        }
        onPress={handleSubmitKey}
        shadow="sm"
        mb={insets.bottom}
        loading={submitKeyButtonDisabled}
      >
        {timerValue <= 0
          ? 'Submit Key'
          : `Submit Key (${Math.floor(timerValue)}s)`}
      </Button>
    );
  };

  const findSelectedLocationFromBottomSheet = campusLocationsData?.filter(
    (item) => item.checked,
  );

  // ------ on press update location inside bottom sheet ------
  const handleUpdateLocation = () => {
    setParkingLocationsFunc(findSelectedLocationFromBottomSheet[0]?._id);
    handleOnDismiss();
  };

  return (
    <View flex={1}>
      <ScrollView
        contentContainerStyle={{
          flex: 1,
        }}
      >
        <View px="2xl" mt="4xl">
          <RadioChipGroup
            data={parkingLocationData}
            onPress={(id) => {}}
            title="Parking Location"
            rightIcon="edit-outline-300"
            rightIconColor="info.500"
            onPressRightIcon={handlePresentModalPress}
          />
          {parkingBayData && parkingBayData?.length ? (
            <RadioChipGroup
              data={parkingBayData}
              onPress={(id) => onPressParkingFloor(id)}
              title="Parking Bay"
            />
          ) : (
            <></>
          )}
          {parkingSpotData && parkingSpotData?.length ? (
            <RadioChipGroup
              data={parkingSpotData}
              onPress={(id) => onPressParkingSpot(id)}
              title="Parking Spot"
            />
          ) : (
            <></>
          )}
        </View>
        <View height={200} />
      </ScrollView>
      <View position="absolute" bottom={0} width="100%">
        <TaskCard
          heading={heading}
          topRightCorner={topRightCornerComponent()}
          shadow="lg"
          bottomLeftCTA={bottomLeftCTAComponent()}
          alwaysOpened
        />
      </View>
      <BottomSheetV2
        ref={bottomSheetModalRef}
        onClose={handleOnDismiss}
        insets={false}
      >
        <View>
          <View px="2xl">
            <Text size="xl" color="primary.200">
              Select Location
            </Text>
          </View>
          <View px="2xl" mt="lg">
            <Divider level={1} />
          </View>
          <View my="2xl" px="2xl">
            <RadioGroup
              radio={campusLocationsData}
              setRadio={(arr) => setCampusLocationsData(arr)}
              title=""
              isRequired
              size="lg"
              icon="right"
              collapsedCount={5}
            />
          </View>
          <View px="2xl" mt="xl">
            <Button
              size="lg"
              appearance="filled"
              state={
                findSelectedLocationFromBottomSheet[0]?._id ===
                parkingLocationData[0]?.id
                  ? 'disabled'
                  : 'active'
              }
              onPress={handleUpdateLocation}
            >
              Update Location
            </Button>
          </View>
        </View>
      </BottomSheetV2>
    </View>
  );
};

const SelectParkingLocationScreenWrapper = ({ navigation, route }) => {
  const { taskId } = route?.params ?? {};
  const campusID = useCommonStore((state) => state.campusId);
  const { role } = useUserStore((state) => state);
  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'network-only',
    });
  const [netStatus, setNetStatus] = useState(true);

  const refresh = useCallback(
    () => {
      setRefreshedQueryOptions((prev) => ({
        fetchKey: (prev?.fetchKey ?? 0) + 1,
        fetchPolicy: 'network-only',
      }));
    },
    [
      /* ... */
    ],
  );

  const filter = {
    campus: { _id: campusID },
    _id: taskId,
  };

  return (
    <Layout level={2}>
      <TopNavigation
        appearance="ghost"
        level="1"
        IconLeft={
          <IconButton
            name="back-outline-300"
            size="md"
            appearance="ghost"
            iconColor="primary.500"
            onPress={() => navigation.navigate(role)}
          />
        }
      />
      <NewErrorBoundary
        fetchKey={refreshedQueryOptions.fetchKey}
        fallback={
          <NewErrorView
            errorMsg="Sorry something went wrong"
            reload={refresh}
          />
        }
      >
        <Suspense fallback={<Loading />}>
          <SelectParkingLocationScreen
            variables={{
              filter,
            }}
            fetchKey={refreshedQueryOptions?.fetchKey}
            queryOptions={refreshedQueryOptions}
            refresh={refresh}
          />
        </Suspense>
      </NewErrorBoundary>
      <DisconnectedDropover
        setNetStatus={setNetStatus}
        text="No Internet Connection"
        icon="wifi-off-outline"
      />
    </Layout>
  );
};

export default SelectParkingLocationScreenWrapper;
