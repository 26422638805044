import React, { useState, useCallback, Suspense, useRef } from 'react';
import { useLazyLoadQuery } from 'react-relay';
import { ScrollView, useWindowDimensions } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import {
  Layout,
  View,
  Text,
  IconButton,
  TopNavigation,
  Icon,
  Button,
} from '../../../components/new';
import Loading from '../../../components/loading.component';
import DisconnectedDropover from '../../../components/disconnectedpopover.component';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../../utilities/NewErrorBoundary';
import NewErrorView from '../../../utilities/NewErrorView';
import useCommonStore from '../../../stores/commonStore';
import {
  finishTaskScreenQuery,
  finishTaskScreenQueryTaskFragment,
} from './API/finishTaskScreenQuery';
import useUserStore from '../../../stores/userStore';
import {
  ParkingStatus,
  dynamicHeightMaker,
  formatPlateNo,
  taskHistoryActionTypes,
  taskTypes,
} from '../../../utilities/helper';
import TaskCard from '../../../components/new/primitive/TaskCard/TaskCard';
import { handlePhone } from '../../../utilities/handleLinking';
import { updateTaskForSupervisor } from '../../../relay/taskApi';
import { updateParking } from '../../../relay/parkingApi';
import { getTheme } from '../../../themes/new/theme';
import ReparkConfirmationBottomSheet from './ReparkConfirmationBottomSheet';
import CountDownModal from './CountDownModal';
import { useSnackbarStore } from '../../../stores/snackbar/snackbarStore';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../../../components/new/primitive/snackbar/helpers/helpers';
import CustomLottieView from '../../../components/new/custom/CustomLottieView';
import { Appearance, State, Status } from '../../../themes/new/helper';
import { usePagedTasks } from '../../../hooks/usePagedTasks';

const FinishTaskScreen = (props) => {
  const { queryOptions, variables, refresh, navigation } = props;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const findTasksFragment = useLazyLoadQuery(finishTaskScreenQuery, variables, {
    ...queryOptions,
    networkCacheConfig: { force: true },
  });
  const { dispatchSnackbar } = useSnackbarStore((state) => state);

  const { tasks: findTasks } = usePagedTasks(
    finishTaskScreenQueryTaskFragment,
    findTasksFragment,
  );

  const animation = useRef(null);
  const { width } = useWindowDimensions();
  const [finishTaskDisabled, setFinishTaskDisabled] = useState(false);
  const [showTimer, setShowTimer] = useState(false);
  const bottomSheetModalRef = useRef(null);

  const handleOnDismiss = React.useCallback(() => {
    bottomSheetModalRef.current?.hide();
  }, []);

  const { vehicle, parkingTag, ETA, user, _id, history } = findTasks[0] ?? {};
  const minutesThresshold = 5 * 60; // thresshold for waiting timer in seconds
  const insets = useSafeAreaInsets();

  const formattedVehicleNumber = formatPlateNo(vehicle?.registration?.plate);
  const heading = formattedVehicleNumber;
  const subHeading = `#${parkingTag}`;
  // ------ finding arrived status from history ------
  const arrivedStatus =
    history &&
    history?.length &&
    history?.filter((ele) => ele?.action === taskHistoryActionTypes.arrived);

  const delayAt = new Date(ETA?.acceptedToArrivedTime).valueOf(); // delay time
  const currentTime = new Date(arrivedStatus[0]?.event_at).valueOf(); // current time
  const isDelayed = currentTime > delayAt; // check if current time breached delay time

  const topRightCornerComponent = () => {
    return (
      <View
        flexDirection="row"
        borderRadius="md"
        borderWidth="xs"
        borderColor="primary.50"
        bg="primary.10"
        p="sm+md"
        alignItems="center"
      >
        <Icon
          name="timer-outline-300"
          size="xl"
          color={isDelayed ? 'warning.500' : 'success.500'}
        />
        <Text ml="lg" size="md" weight="medium" color="primary.300">
          {isDelayed ? 'Delayed' : 'On-Time'}
        </Text>
      </View>
    );
  };

  // ------ handle for park again option ------
  const handlePresentModalPress = useCallback(() => {
    bottomSheetModalRef.current?.show();
  }, []);

  const bottomLeftCTAComponent = () => {
    // ------ function to handle call ------
    const onPressCallCustomer = () => {
      handlePhone(
        // eslint-disable-next-line no-unsafe-optional-chaining
        user?.contact?.phone?.prefix +
          // eslint-disable-next-line no-unsafe-optional-chaining
          user?.contact?.phone?.number,
      );
    };

    // ------ function to show error on alerts ------
    const ApiErrorHandler = (err) => {
      // eslint-disable-next-line no-alert
      if (err?.res?.errors[0]?.extensions?.code === 104) {
        navigation.popToTop();
      }
      dispatchSnackbar({
        msg: err?.res?.errors[0]?.message || 'Something went wrong!',
        status: SnackbarStatus.error,
        version: SnackbarVersion.v2,
        position: 'top',
      });
      setFinishTaskDisabled(false);
    };

    /* ------ function to handle navigation to task completion screen
    after getting response from API ------
    */
    const handleNavigation = () => {
      setFinishTaskDisabled(false);
      navigation.navigate('EndTaskScreen', {
        taskId: _id,
      });
    };

    // ------ update parking to finish recall task ------
    const handelFinishRecall = (res) => {
      const data = {
        id: res.updateTask.record.parkingId,
        record: {
          status: ParkingStatus.OUT,
        },
      };
      updateParking(data, handleNavigation, ApiErrorHandler);
    };

    // ------ update task as completed and then finish recall task from callback ------
    const onPressFinishTask = () => {
      setFinishTaskDisabled(true);
      const data = {
        _id,
        record: {
          history: [{ action: taskHistoryActionTypes.completed }],
        },
      };
      updateTaskForSupervisor(data, handelFinishRecall, ApiErrorHandler);
    };

    return (
      <View pb={insets.bottom}>
        <View flexDirection="row">
          <IconButton
            name="call-filled-300"
            size="md"
            iconSize="2xl"
            onPress={onPressCallCustomer}
          />
          <View ml="lg" flex={1}>
            <Button
              size="lg"
              appearance={Appearance.FILLED}
              status={Status.PRIMARY}
              state={finishTaskDisabled ? State.DISABLED : State.ACTIVE}
              onPress={onPressFinishTask}
              shadow="sm"
              loading={finishTaskDisabled}
            >
              Finish Task
            </Button>
          </View>
        </View>
        <View mt="2xl">
          <Button
            size="lg"
            appearance="ghost"
            status="info"
            onPress={handlePresentModalPress}
            shadow="sm"
          >
            Park Again
          </Button>
        </View>
      </View>
    );
  };

  return (
    <View flex={1}>
      <ScrollView
        contentContainerStyle={{
          flex: 0.65,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CustomLottieView
          loop
          autoPlay
          ref={animation}
          source={require('../../../../assets/lottie/waiting-person.json')}
          style={{
            // marginTop: getTheme().space['4xl'],
            width: width - 4 * getTheme().space['2xl'],
            height: dynamicHeightMaker(width - 4 * getTheme().space['2xl'], 1),
          }}
          containerWidth={width - 4 * getTheme().space['2xl']}
        />
      </ScrollView>
      <View position="absolute" bottom={0} width="100%">
        <TaskCard
          heading={heading}
          subHeading={subHeading}
          topRightCorner={topRightCornerComponent()}
          shadow="lg"
          bottomLeftCTA={bottomLeftCTAComponent()}
          alwaysOpened
        />
      </View>
      <ReparkConfirmationBottomSheet
        setShowTimer={setShowTimer}
        timeOfArrival={arrivedStatus[0]?.event_at}
        minutesThresshold={minutesThresshold}
        handleReparkTask={() => {
          navigation.navigate('StartRideScreen', {
            taskId: findTasks[0]?._id,
            taskType: taskTypes.repark,
          });
        }}
        bottomSheetModalRef={bottomSheetModalRef}
        handleOnDismiss={handleOnDismiss}
      />
      <CountDownModal
        show={showTimer}
        setShow={setShowTimer}
        handleReparkTask={() => {
          navigation.navigate('addCustomer', {
            data: { ...findTasks[0], taskType: 'repark' },
          });
        }}
        timeOfArrival={arrivedStatus[0]?.event_at}
      />
    </View>
  );
};

const FinishTaskScreenWrapper = ({ navigation, route }) => {
  const { taskId } = route?.params ?? {};
  const campusID = useCommonStore((state) => state.campusId);
  const { role } = useUserStore((state) => state);

  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'network-only',
    });
  const [netStatus, setNetStatus] = useState(true);

  const refresh = useCallback(
    () => {
      // Trigger a re-render of useLazyLoadQuery with the same variables,
      // but an updated fetchKey and fetchPolicy.
      // The new fetchKey will ensure that the query is fully
      // re-evaluated and refetched.
      // The fetchPolicy ensures that we always fetch from the network
      // and skip the local data cache.
      setRefreshedQueryOptions((prev) => ({
        fetchKey: (prev?.fetchKey ?? 0) + 1,
        fetchPolicy: 'network-only',
      }));
    },
    [
      /* ... */
    ],
  );

  const filter = {
    campus: { _id: campusID },
    _id: taskId,
  };

  return (
    <Layout level={2}>
      <TopNavigation
        appearance="ghost"
        level="1"
        IconLeft={
          <IconButton
            name="back-outline-300"
            size="md"
            appearance="ghost"
            iconColor="primary.500"
            onPress={() => navigation.navigate(role)}
          />
        }
      />
      <NewErrorBoundary
        fetchKey={refreshedQueryOptions.fetchKey}
        fallback={
          <NewErrorView
            errorMsg="Sorry something went wrong"
            reload={refresh}
          />
        }
      >
        <Suspense fallback={<Loading />}>
          <FinishTaskScreen
            variables={{ filter }}
            fetchKey={refreshedQueryOptions?.fetchKey}
            queryOptions={refreshedQueryOptions}
            refresh={refresh}
            navigation={navigation}
          />
        </Suspense>
      </NewErrorBoundary>
      <DisconnectedDropover
        setNetStatus={setNetStatus}
        text="No Internet Connection"
        icon="wifi-off-outline"
      />
    </Layout>
  );
};

export default FinishTaskScreenWrapper;
