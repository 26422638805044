/**
 * @generated SignedSource<<459e93c899bd567582e10b39dd93ecde>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type EnumtaskHistoryAction = "abort" | "accepted" | "arrived" | "assigned" | "cancelled" | "completed" | "created" | "exited" | "incomplete" | "onboarded" | "started" | "%future added value";
export type EnumtaskTaskType = "park" | "recall" | "repark" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type taskScreenQueryTaskFragment$data = {
  readonly findTasks: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly ETA: {
          readonly _id: any | null;
          readonly available: number | null;
          readonly busy: number | null;
          readonly delayAt: any | null;
          readonly originalPredictionTime: number | null;
          readonly pendingRecall: number | null;
          readonly startAt: any | null;
        } | null;
        readonly _id: any;
        readonly assignedTo: any | null;
        readonly campus: {
          readonly _id: any;
          readonly name: string;
        } | null;
        readonly createdBy: {
          readonly status: string | null;
        } | null;
        readonly history: ReadonlyArray<{
          readonly _id: any | null;
          readonly action: EnumtaskHistoryAction;
          readonly actor: any | null;
          readonly event_at: any | null;
        } | null>;
        readonly ownerId: any | null;
        readonly parkingId: any | null;
        readonly parkingTag: string | null;
        readonly taskEndLocation: {
          readonly locationId: any;
          readonly name: string | null;
          readonly parkingSpaces: {
            readonly floor: {
              readonly bay: {
                readonly bayId: any;
                readonly bayName: string | null;
              } | null;
              readonly floorId: any;
              readonly floorName: string | null;
            } | null;
          } | null;
        } | null;
        readonly taskStartLocation: {
          readonly locationId: any;
          readonly name: string | null;
          readonly parkingSpaces: {
            readonly floor: {
              readonly bay: {
                readonly bayId: any;
                readonly bayName: string | null;
              } | null;
              readonly floorId: any;
              readonly floorName: string | null;
            } | null;
          } | null;
        } | null;
        readonly taskType: EnumtaskTaskType;
        readonly user: {
          readonly contact: {
            readonly phone: {
              readonly number: string;
              readonly prefix: string;
            };
          };
          readonly name: {
            readonly first: string;
            readonly last: string | null;
          };
        } | null;
        readonly valet: {
          readonly _id: any;
          readonly contact: {
            readonly phone: {
              readonly number: string;
              readonly prefix: string;
            };
          };
          readonly identityCode: string | null;
          readonly name: {
            readonly first: string;
            readonly last: string | null;
          };
        } | null;
        readonly vehicle: {
          readonly _id: any | null;
          readonly brand: {
            readonly brandLogo: string | null;
            readonly name: string | null;
          } | null;
          readonly color: string | null;
          readonly registration: {
            readonly plate: string;
          } | null;
        } | null;
        readonly vehicleId: any | null;
      };
    }>;
    readonly pageInfo: {
      readonly hasNextPage: boolean;
    };
  } | null;
  readonly " $fragmentType": "taskScreenQueryTaskFragment";
};
export type taskScreenQueryTaskFragment$key = {
  readonly " $data"?: taskScreenQueryTaskFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"taskScreenQueryTaskFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "findTasks"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "locationId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "floorName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "floorId",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "bayId",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "bayName",
    "storageKey": null
  }
],
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "UserName",
  "kind": "LinkedField",
  "name": "name",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "first",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "last",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "UserContact",
  "kind": "LinkedField",
  "name": "contact",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserContactPhone",
      "kind": "LinkedField",
      "name": "phone",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "prefix",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "number",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "sort"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "taskFilter"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./taskScreenTaskFragmentQuery.graphql')
    }
  },
  "name": "taskScreenQueryTaskFragment",
  "selections": [
    {
      "alias": "findTasks",
      "args": [
        {
          "kind": "Variable",
          "name": "filter",
          "variableName": "taskFilter"
        },
        {
          "kind": "Variable",
          "name": "sort",
          "variableName": "sort"
        }
      ],
      "concreteType": "taskConnection",
      "kind": "LinkedField",
      "name": "__taskScreenQueryTaskFragment__findTasks_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "taskEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "task",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "taskVehicle",
                  "kind": "LinkedField",
                  "name": "vehicle",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "taskVehicleBrand",
                      "kind": "LinkedField",
                      "name": "brand",
                      "plural": false,
                      "selections": [
                        (v2/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "brandLogo",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "taskVehicleRegistration",
                      "kind": "LinkedField",
                      "name": "registration",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plate",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "color",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "taskType",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "taskTaskStartLocation",
                  "kind": "LinkedField",
                  "name": "taskStartLocation",
                  "plural": false,
                  "selections": [
                    (v3/*: any*/),
                    (v2/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "taskTaskStartLocationParkingSpaces",
                      "kind": "LinkedField",
                      "name": "parkingSpaces",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "taskTaskStartLocationParkingSpacesFloor",
                          "kind": "LinkedField",
                          "name": "floor",
                          "plural": false,
                          "selections": [
                            (v4/*: any*/),
                            (v5/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "taskTaskStartLocationParkingSpacesFloorBay",
                              "kind": "LinkedField",
                              "name": "bay",
                              "plural": false,
                              "selections": (v6/*: any*/),
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "taskTaskEndLocation",
                  "kind": "LinkedField",
                  "name": "taskEndLocation",
                  "plural": false,
                  "selections": [
                    (v3/*: any*/),
                    (v2/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "taskTaskEndLocationParkingSpaces",
                      "kind": "LinkedField",
                      "name": "parkingSpaces",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "taskTaskEndLocationParkingSpacesFloor",
                          "kind": "LinkedField",
                          "name": "floor",
                          "plural": false,
                          "selections": [
                            (v4/*: any*/),
                            (v5/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "taskTaskEndLocationParkingSpacesFloorBay",
                              "kind": "LinkedField",
                              "name": "bay",
                              "plural": false,
                              "selections": (v6/*: any*/),
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "campusLocation",
                  "kind": "LinkedField",
                  "name": "campus",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    (v2/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "taskHistory",
                  "kind": "LinkedField",
                  "name": "history",
                  "plural": true,
                  "selections": [
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "action",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "actor",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "event_at",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "assignedTo",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "User",
                  "kind": "LinkedField",
                  "name": "valet",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    (v7/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "identityCode",
                      "storageKey": null
                    },
                    (v8/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "User",
                  "kind": "LinkedField",
                  "name": "user",
                  "plural": false,
                  "selections": [
                    (v7/*: any*/),
                    (v8/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "taskETA",
                  "kind": "LinkedField",
                  "name": "ETA",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "startAt",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "delayAt",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "originalPredictionTime",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "pendingRecall",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "available",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "busy",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "vehicleId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "ownerId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "parkingId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "parkingTag",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "createdBy",
                  "kind": "LinkedField",
                  "name": "createdBy",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "status",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "338a6c81a02dafa114459120a52a56c5";

export default node;
