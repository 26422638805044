/* eslint-disable import/prefer-default-export */
import { useCallback, useEffect, useState } from 'react';
import { usePreloadedQuery, useLazyLoadQuery, useFragment } from 'react-relay';
import { Platform } from 'react-native';
import { useRoute } from '@react-navigation/native';
import { NewErrorBoundaryParentState } from '../../../../../utilities/NewErrorBoundary';
import { PAYBILL_BS_MODES } from '../helpers';
import * as payBillHelper from '../helpers';
import { usePayBillStore } from './usePayBillStore';
import { paymentOrderTypeEnum } from '../../../../../utilities/helper';
import { usePayBillFooter } from './usePayBillFooter';
import useUserStore from '../../../../../stores/userStore';
import {
  GQLGetNetPriceData,
  GQLPayBillConfig,
  GQLPayBillQueryVariables,
  PayBillRouteParams,
} from '../types';
import { configQuery } from '../API/fetchConfig';
import {
  getNetPriceFragment,
  payBillScreenQuery,
} from '../API/payBillScreenQuery';
import { usePayBillBottomSheet } from './usePayBillBottomSheet';
import useLoginModalStore from '../../../../../stores/loginModalStore';
import payBillAnalytics from '../analytics';
import { usePayBillStoreDataBuilder } from './usePayBillStoreDataBuilder';

export const usePayBill = (
  configQueryRef: any,
  queryOptions: NewErrorBoundaryParentState,
) => {
  const route = useRoute();
  const routeParams = (route?.params ?? {}) as PayBillRouteParams;
  const { orderId, orderType } = routeParams;
  const isCustomerAlreadyBlackMember = useUserStore(
    (state) => state.blackMembership.active,
  );
  const setAppliedCoupon = useLoginModalStore(
    (state) => state.setAppliedCoupon,
  );
  const { pay, payDisabled } = usePayBillFooter();
  const { bsRef, bsMode, hideBottomSheet, showBottomSheet, handleBsClose } =
    usePayBillBottomSheet();

  /**
   * ============================================================================
   *                         MAIN QUERY SETUP - query variables, fragments etc
   * ============================================================================
   */
  const payBillConfig: GQLPayBillConfig = usePreloadedQuery(
    configQuery,
    configQueryRef,
  );

  const [queryVariables, setQueryVariables] =
    useState<GQLPayBillQueryVariables>(
      payBillHelper.buildPayBillQueryVariables({
        config: payBillConfig,
        routeParams,
        isCustomerBlackMember: isCustomerAlreadyBlackMember,
      }),
    );

  const {
    getNetPrice: getNetPriceRef,
    getNetPriceWithMembership: getNetPriceWithMembershipRef,
  } = useLazyLoadQuery(payBillScreenQuery, queryVariables, queryOptions);

  const getNetPrice: GQLGetNetPriceData = useFragment(
    getNetPriceFragment,
    getNetPriceRef,
  );
  const getNetPriceWithMembership: GQLGetNetPriceData = useFragment(
    getNetPriceFragment,
    getNetPriceWithMembershipRef,
  );

  /**
   * =====================================================================
   *                  PAYBILL STORE SETUP & ACCESS
   * =====================================================================
   */
  const initialStoreData = usePayBillStoreDataBuilder(
    routeParams,
    payBillConfig,
    getNetPrice,
    getNetPriceWithMembership,
  );

  const blackMembershipAdded = usePayBillStore(
    (state) => state.blackMembershipAdded,
  );
  const setBlackMembershipAdded = usePayBillStore(
    (state) => state.setBlackMembershipAdded,
  );
  const resetStore = usePayBillStore((state) => state.resetStore);
  const setIntialData = usePayBillStore((state) => state.setIntialData);

  /**
   * =====================================================================
   *                          EFFECTS & CLEANUP
   * =====================================================================
   */

  useEffect(() => {
    /**
     * setting initial data in store used immediately by widgets.
     * this data is built from the API response and other stuff
     */
    setIntialData(initialStoreData);
  }, [initialStoreData]);

  useEffect(() => {
    if (initialStoreData.title) {
      payBillAnalytics.landed(initialStoreData.title, orderType, orderId);
    }
  }, [initialStoreData.title]);

  useEffect(() => {
    /**
     * Perform cleanup on unmount
     */
    return () => {
      resetStore();
      setAppliedCoupon(null);
    };
  }, []);

  /**
   * =====================================================================
   *                               HANDLERS
   * =====================================================================
   */

  const handleTipSelection = useCallback((tipAmount: number) => {
    setQueryVariables((prevVars) => {
      return {
        ...prevVars,
        tipAmount,
      };
    });
  }, []);

  const handlePointsWidgetToggle = useCallback(
    (usePoints: boolean, toggleDisabled: boolean) => {
      if (toggleDisabled) {
        if (Platform.OS === 'web') {
          payBillAnalytics.payWithPointsToggled(
            initialStoreData.title,
            orderType,
            orderId,
            false,
          );
          showBottomSheet(
            PAYBILL_BS_MODES.DOWNLOAD_APP_PROMT_IF_POINTS_USED_ON_WEB,
          );
        }
      } else {
        payBillAnalytics.payWithPointsToggled(
          initialStoreData.title,
          orderType,
          orderId,
          usePoints,
        );
        setQueryVariables((prevVars) => {
          return {
            ...prevVars,
            useRewardPoints: usePoints,
          };
        });
      }
    },
    [initialStoreData.title, orderType, orderId],
  );

  const addBlackMembershipToCart = useCallback((membershipId: string) => {
    setQueryVariables((prevVars) => {
      return {
        ...prevVars,
        addons: {
          orderId: membershipId,
        },
      };
    });
    setBlackMembershipAdded(true);
  }, []);

  const removeBlackMembershipFromCart = useCallback(() => {
    setQueryVariables((prevVars) => {
      return {
        ...prevVars,
        addons: null,
      };
    });
    setBlackMembershipAdded(false);
  }, []);

  const handleCharitySelection = useCallback(
    (isBeingApplied: boolean, charityId = '') => {
      setQueryVariables((prevVars) => {
        return {
          ...prevVars,
          csrContributionId: isBeingApplied ? charityId : '',
        };
      });
    },
    [],
  );

  const handleCouponApplication = useCallback((couponCode: string) => {
    setQueryVariables((prevVars) => {
      return {
        ...prevVars,
        couponCode,
      };
    });
  }, []);

  /**
   * Main Pay Action handler
   */
  const handlePayNow = (skipChecks: boolean | (() => boolean) = false) => {
    if (skipChecks) {
      return pay();
    }
    if (typeof skipChecks === 'function') {
      if (skipChecks()) {
        return pay();
      }
      return;
    }

    // pay now checks
    if (
      orderType === paymentOrderTypeEnum.INVESTMENT_LEAD ||
      orderType === paymentOrderTypeEnum.ADDONS
    ) {
      pay();
      return;
    }
    if (isCustomerAlreadyBlackMember && Platform.OS === 'web') {
      showBottomSheet(
        PAYBILL_BS_MODES.DOWNLOAD_APP_PROMT_IF_BLACK_MEMBER_PAYS_ON_WEB,
      );
      return;
    }
    if (!isCustomerAlreadyBlackMember && !blackMembershipAdded) {
      showBottomSheet(PAYBILL_BS_MODES.BLACK_MEMBER_INVITATION_ON_PAY);
      return;
    }
    pay();
  };

  return {
    bsRef,
    bsMode,
    pageTitle: initialStoreData.title,
    isBillPayEnabled: initialStoreData.config.billPayEnabled,
    queryVariables,
    payDisabled,
    getNetPrice,
    getNetPriceWithMembership,
    showBottomSheet,
    hideBottomSheet,
    addBlackMembershipToCart,
    removeBlackMembershipFromCart,
    handleBsClose,
    handleTipSelection,
    handlePointsWidgetToggle,
    handleCharitySelection,
    handleCouponApplication,
    handlePayNow,
  };
};
