/**
 * @generated SignedSource<<f2b25ea621712b18b360474f55c84648>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type payBillScreenQuery_getNetPriceFragment$data = {
  readonly cashbackAmount: number | null;
  readonly cashbackPercentage: number | null;
  readonly discountPercentage: number | null;
  readonly extras: {
    readonly coupons: {
      readonly isApplied: boolean | null;
      readonly message: string | null;
    } | null;
  } | null;
  readonly priceDetails: {
    readonly addonAmount: number | null;
    readonly billSummary: {
      readonly additions: number;
      readonly deductions: number;
      readonly grossAmount: number;
      readonly netAmount: number;
    } | null;
    readonly couponAmount: number;
    readonly couponCashbackAmount: number;
    readonly couponId: any | null;
    readonly csrAmount: number;
    readonly discountGiven: number;
    readonly discountPercentage: number;
    readonly grossAmount: number;
    readonly netAmount: number;
    readonly rewardsUsable: number | null;
    readonly rewardsUsed: number;
    readonly tipAmount: number | null;
  };
  readonly sourceDetails: {
    readonly event: {
      readonly _id: any;
      readonly eventTiming: ReadonlyArray<{
        readonly _id: any | null;
        readonly slot: {
          readonly availableTickets: ReadonlyArray<{
            readonly _id: any | null;
            readonly basePrice: number;
            readonly personAllowed: number;
            readonly price: number;
          } | null>;
        };
      } | null>;
      readonly title: string;
    } | null;
    readonly parking: {
      readonly _id: any;
      readonly parkingInvoice: {
        readonly charges: {
          readonly chargesBreakup: ReadonlyArray<{
            readonly duration: number | null;
            readonly payable: number | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
    readonly popUpStore: {
      readonly _id: any;
      readonly displayName: string;
    } | null;
    readonly restaurant: {
      readonly _id: any;
      readonly displayName: string;
      readonly payBill: {
        readonly payEnabled: boolean | null;
        readonly pointsEnabled: {
          readonly redeem: boolean | null;
        } | null;
      } | null;
    } | null;
    readonly store: {
      readonly _id: any;
      readonly displayName: string;
    } | null;
  } | null;
  readonly " $fragmentType": "payBillScreenQuery_getNetPriceFragment";
};
export type payBillScreenQuery_getNetPriceFragment$key = {
  readonly " $data"?: payBillScreenQuery_getNetPriceFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"payBillScreenQuery_getNetPriceFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "grossAmount",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "netAmount",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "discountPercentage",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v5 = [
  (v3/*: any*/),
  (v4/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "payBillScreenQuery_getNetPriceFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "invoicePriceDetails",
      "kind": "LinkedField",
      "name": "priceDetails",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "discountGiven",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rewardsUsed",
          "storageKey": null
        },
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tipAmount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "csrAmount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rewardsUsable",
          "storageKey": null
        },
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "couponId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "couponAmount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "couponCashbackAmount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "GNPBIllSummary",
          "kind": "LinkedField",
          "name": "billSummary",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "deductions",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "additions",
              "storageKey": null
            },
            (v1/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "addonAmount",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cashbackPercentage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cashbackAmount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "InvoiceSourceDetailsTC",
      "kind": "LinkedField",
      "name": "sourceDetails",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "restaurants2",
          "kind": "LinkedField",
          "name": "restaurant",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "restaurants2PayBill",
              "kind": "LinkedField",
              "name": "payBill",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "payEnabled",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "restaurants2PayBillPointsEnabled",
                  "kind": "LinkedField",
                  "name": "pointsEnabled",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "redeem",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            (v4/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "event",
          "kind": "LinkedField",
          "name": "event",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            },
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "eventEventTiming",
              "kind": "LinkedField",
              "name": "eventTiming",
              "plural": true,
              "selections": [
                (v4/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "eventEventTimingSlot",
                  "kind": "LinkedField",
                  "name": "slot",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "eventEventTimingSlotAvailableTickets",
                      "kind": "LinkedField",
                      "name": "availableTickets",
                      "plural": true,
                      "selections": [
                        (v4/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "price",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "basePrice",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "personAllowed",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "stores",
          "kind": "LinkedField",
          "name": "store",
          "plural": false,
          "selections": (v5/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "popUpStore",
          "kind": "LinkedField",
          "name": "popUpStore",
          "plural": false,
          "selections": (v5/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Parking",
          "kind": "LinkedField",
          "name": "parking",
          "plural": false,
          "selections": [
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "ParkingParkingInvoice",
              "kind": "LinkedField",
              "name": "parkingInvoice",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ParkingParkingInvoiceCharges",
                  "kind": "LinkedField",
                  "name": "charges",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ParkingParkingInvoiceChargesChargesBreakup",
                      "kind": "LinkedField",
                      "name": "chargesBreakup",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "duration",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "payable",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "invoiceExtrasDetailsTC",
      "kind": "LinkedField",
      "name": "extras",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "invoiceExtrasCouponDetailsTC",
          "kind": "LinkedField",
          "name": "coupons",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isApplied",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "message",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "invoiceModelPriceExtendedTC",
  "abstractKey": null
};
})();

(node as any).hash = "0250d90e92c4dbb4a8edd2b5fde3699c";

export default node;
