import React, { useMemo } from 'react';
import { StyleSheet } from 'react-native';
import LottieView from 'lottie-react-native';
import { BlurView } from 'expo-blur';
import { Text, View } from '../../../../../components/new';
import GoldButton from '../../../plusMembership/components/GoldButton';
import { FontWeights } from '../../../../../themes/new/helper';
import CachedImageBackground from '../../../../../components/new/custom/CachedImageBackground';
import CachedImage from '../../../../../components/new/custom/CachedImage';
import { usePayBillStore } from '../hooks/usePayBillStore';
import { HideBottomSheetFn, IPayBillStore } from '../types';
import payBillAnalytics from '../analytics';

const MESH_BG = require('../../../../../../assets/images/black_membership/mesh-bg.png');
const LOGO = require('../../../../../../assets/images/black_membership/black-member-logo-2.webp');
const PERCENT_LOTTIE = require('../../../../../../assets/images/black_membership/lottie/percent-gold.json');
const PARKING_LOTTIE = require('../../../../../../assets/images/black_membership/lottie/parking.json');
const FOOD_LOTTIE = require('../../../../../../assets/images/black_membership/lottie/food.json');
const WINE_GLASS_LOTTIE = require('../../../../../../assets/images/black_membership/lottie/wine-glasses.json');

const BORDER_CLR = 'gold.500';

type BlackMembershipConfig = IPayBillStore['config']['blackMembershipConfig'];

interface BlackMembershipBenefitsBsProps {
  hideBottomSheet: HideBottomSheetFn;
  onAddToCart: (membershipId: string) => void;
}

const BlackMembershipBenefitsBs = ({
  hideBottomSheet,
  onAddToCart,
}: BlackMembershipBenefitsBsProps) => {
  const { orderType, orderId, config, blackMembershipAdded, title } =
    usePayBillStore((state) => state);
  const { blackMembershipConfig: bmConfig } = config ?? {};

  const labels = useMemo(
    () => generateMembershipBenefitsData(bmConfig),
    [bmConfig],
  );

  const handleBtnClick = () => {
    if (blackMembershipAdded) {
      payBillAnalytics.blackMembershipBenefitsAwesomeClicked(
        title,
        orderType,
        orderId,
      );
    }
    const afterBsDismissCb = () => {
      payBillAnalytics.blackMembershipAddedToCart(title, orderType, orderId);
      onAddToCart(config?.blackMembershipConfig?._id);
    };
    hideBottomSheet(!blackMembershipAdded ? afterBsDismissCb : null);
  };

  return (
    <View alignItems="center" justifyContent="center">
      <CachedImageBackground
        source={MESH_BG}
        style={styles.imageContainer}
        imageStyle={styles.image}
      >
        <View px="2xl" mt="8xl" mb="2xl">
          <BlurView intensity={20} width="100%">
            <View
              pt="2xl"
              alignItems="center"
              borderWidth="xs"
              borderRadius="lg"
              borderColor="#4f4318"
              width="100%"
              overflow="hidden"
            >
              <CachedImage source={LOGO} style={{ width: 286, height: 44 }} />
              <Text mt="lg" size="sm" color="primary.300">
                --- Membership Benefits ---
              </Text>
              <View width="100%" px="2xl" mt="9xl">
                <View
                  flexDirection="row"
                  borderBottomWidth="xs"
                  borderColor={BORDER_CLR}
                >
                  <BenefitView
                    lottieImg={PERCENT_LOTTIE}
                    label={labels[0] ?? ''}
                    border
                  />
                  <BenefitView
                    lottieImg={PARKING_LOTTIE}
                    label={labels[1] ?? ''}
                  />
                </View>
                <View flexDirection="row">
                  <BenefitView
                    lottieImg={FOOD_LOTTIE}
                    label={labels[2] ?? ''}
                    border
                  />
                  <BenefitView
                    lottieImg={WINE_GLASS_LOTTIE}
                    label={labels[3] ?? ''}
                  />
                </View>
              </View>
              <View py="4xl">
                <Text size="md" color="gold.50">
                  + 1 complimentary coffee monthly
                </Text>
              </View>
            </View>
          </BlurView>
        </View>
        <View mb="4xl" px="2xl">
          <GoldButton
            onPress={handleBtnClick}
            text={blackMembershipAdded ? 'Awesome' : 'Add To Cart'}
          />
        </View>
      </CachedImageBackground>
    </View>
  );
};

const BenefitView = ({
  lottieImg,
  label,
  border = false,
}: {
  lottieImg: any;
  label: string;
  border?: boolean;
}) => (
  <View
    flex={1}
    p="3xl"
    alignItems="center"
    justifyContent="center"
    borderRightWidth={border ? 'xs' : 0}
    borderColor={BORDER_CLR}
  >
    <View width={60} height={60}>
      <LottieView
        autoPlay
        loop
        style={{
          height: 60,
          width: 60,
        }}
        source={lottieImg}
      />
    </View>
    <Text
      mt="2xl"
      mb="3xl"
      textAlign="center"
      size="md"
      weight={FontWeights.MEDIUM}
      color="gold.50"
    >
      {label}
    </Text>
  </View>
);

const generateMembershipBenefitsData = (addonConfig: BlackMembershipConfig) => {
  const labels = [];
  let cashbackPerc = null;

  if (addonConfig?.additionalCashback?.length) {
    if (addonConfig?.additionalCashback[0]?.cashback?.type === 'PERCENTAGE') {
      cashbackPerc =
        addonConfig?.additionalCashback[0]?.cashback?.value ?? null;
    }
  }

  const event = addonConfig?.additionalDiscount?.find(
    (cb) => cb?.refType === 'EVENT',
  );

  const parking = addonConfig?.additionalDiscount?.find(
    (cb) => cb?.refType === 'PARKING',
  );

  if (event && event?.discount?.type === 'PERCENTAGE') {
    labels.push(`Flat ${event?.discount?.value}% off on event bookings`);
  } else {
    labels.push('');
  }

  if (parking && parking?.discount?.type === 'PERCENTAGE') {
    labels.push(`Flat ${parking?.discount?.value}% off on parking fee`);
  } else {
    labels.push('');
  }

  if (cashbackPerc) {
    labels.push(`Flat ${cashbackPerc}% cashback + offers`);
  } else {
    labels.push('');
  }

  labels.push('1 complimentary coffee monthly');

  return labels;
};

const styles = StyleSheet.create({
  imageContainer: {
    justifyContent: 'center',
    resizeMode: 'cover',
    width: '100%',
  },
  image: {
    width: '100%',
    height: '100%',
  },
});

export default BlackMembershipBenefitsBs;
