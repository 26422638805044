import React from 'react';
import * as Device from 'expo-device';
import { Linking } from 'react-native';
import { Text, View } from '../../../../../components/new';
import { usePayBillStore } from '../hooks/usePayBillStore';
import { BlackMembershipGenericPopUp } from '../../../plusMembership';
import {
  handleStorePress,
  paymentOrderTypeEnum,
  toRupees,
} from '../../../../../utilities/helper';
import { GQLGetNetPriceData, HideBottomSheetFn } from '../types';
import { FontWeights } from '../../../../../themes/new/helper';
import ParkingBillBreakUp from '../../PaymentBreakUp/ParkingBillBreakUp';
import EventBillBreakUp from '../../PaymentBreakUp/EventBillBreakUp';
import payBillAnalytics from '../analytics';
import { DeepWritable } from '../../../../../utilities/types';

export const CharityInfoBs = () => {
  const config = usePayBillStore((state) => state?.config);
  const { charityConfig } = config ?? {};
  const handleCharityLink = async (webLink: string) => {
    try {
      Linking.openURL(webLink);
    } catch (e) {
      console.log(e);
    }
  };

  if (!config?.charityEnabled) return null;
  return (
    <View p="2xl">
      <View mb="2xl" borderBottomWidth="xs" borderBottomColor="grey.100">
        <Text mb="lg" size="md" weight="medium" color="primary.300">
          {`'${charityConfig?.title}'`}- Donation Details
        </Text>
      </View>
      <View mb="4xl">
        <Text size="sm" color="primary.300">
          {charityConfig?.description}
        </Text>
      </View>
      {charityConfig?.finePrint?.details?.length && (
        <View flexDirection="row">
          <Text size="sm" color="primary.300">
            you can check more about them here -{' '}
            <Text
              onPress={() =>
                handleCharityLink(charityConfig?.finePrint?.details[0])
              }
              size="sm"
              color="info.500"
            >
              {charityConfig?.finePrint?.details[0]}
            </Text>
          </Text>
        </View>
      )}
    </View>
  );
};

export const DownloadAppIfPointsUsedOnWebBs = ({
  hideBottomSheet,
}: {
  hideBottomSheet: HideBottomSheetFn;
}) => {
  const { title, orderId, orderType, priceDetails } = usePayBillStore(
    (state) => state,
  );
  return (
    <BlackMembershipGenericPopUp
      text={`You can use your ${toRupees(
        priceDetails.usablePointsAmount,
      )} points only if you pay via 32nd Club app!`}
      btnOneText="Pay With 32nd Club App"
      btnTwoText="Pay Without 32nd Club App"
      onPressOne={() => {
        payBillAnalytics.downloadNowClicked(title, orderType, orderId);
        hideBottomSheet(() => {
          handleStorePress(Device.osName);
        });
      }}
      onPressTwo={() => {
        payBillAnalytics.continueWithoutPointsClicked(
          title,
          orderType,
          orderId,
        );
        hideBottomSheet();
      }}
    />
  );
};

export const EventCancellationInfoBs = () => {
  const { event, priceDetails } = usePayBillStore((state) => state);
  const { refundLastDateF2 } = event ?? {};
  const { grossAmount } = priceDetails ?? {};
  return (
    <View p="2xl" bg="background.secondary.elevation">
      <View mb="2xl" pb="lg" borderBottomWidth="xs" borderColor="grey.50">
        <Text size="md" color="primary.300" weight={FontWeights.MEDIUM}>
          Cancellation & Refund
        </Text>
      </View>
      <View mb="2xl" flexDirection="row" justifyContent="space-between">
        <Text size="sm" color="primary.500">
          Untill {refundLastDateF2}
        </Text>
        <Text size="xs" color="success.500">
          (Full refund){' '}
          <Text color="primary.500" size="sm">
            {toRupees(grossAmount)}
          </Text>
        </Text>
      </View>
      <View flexDirection="row" justifyContent="space-between">
        <Text size="sm" color="primary.500">
          Post {refundLastDateF2}
        </Text>
        <Text size="xs" color="primary.200">
          Non-refundable
        </Text>
      </View>
    </View>
  );
};

export const BlackMemberInvitationOnPayBs = ({
  onAddBMembership,
  hideBottomSheet,
  onPayWithoutMembership,
}: {
  onAddBMembership: (membershipId: string) => void;
  hideBottomSheet: HideBottomSheetFn;
  onPayWithoutMembership: (skipChecks?: boolean) => void;
}) => {
  const {
    title,
    orderType,
    orderId,
    config,
    blackMembershipAdded,
    priceDetailsWithPlusMembership,
  } = usePayBillStore((state) => state);
  const { discountAmount, cashbackAmount } = priceDetailsWithPlusMembership;

  return (
    <BlackMembershipGenericPopUp
      text={
        discountAmount
          ? `Do you want to save ${toRupees(discountAmount)} and get ${toRupees(
              cashbackAmount,
            )} cashback?`
          : `Do you want to get ${toRupees(cashbackAmount)} cashback?`
      }
      btnOneText="Yes, Add Membership"
      btnTwoText="No, Pay Without Membership"
      onPressOne={() => {
        // purchase membership and let the user review the details without continuing "pay now" action
        payBillAnalytics.blackMembershipAddedToCart(title, orderType, orderId);
        hideBottomSheet(
          !blackMembershipAdded
            ? () => {
                onAddBMembership(config?.blackMembershipConfig?._id);
              }
            : null,
        );
      }}
      onPressTwo={() => {
        // continue to paynow action without purchasing membership
        payBillAnalytics.payWithoutMembershipClicked(title, orderType, orderId);
        hideBottomSheet(() => onPayWithoutMembership(true));
      }}
    />
  );
};

export const DownloadAppPromtIfBlackMemberPaysOnWebBs = ({
  hideBottomSheet,
  onPayWithoutApp,
}: {
  onPayWithoutApp: (skipChecks?: boolean) => void;
  hideBottomSheet: HideBottomSheetFn;
}) => {
  return (
    <BlackMembershipGenericPopUp
      text="As a member you can save upto 50% if you pay via 32nd Club app!"
      btnOneText="Pay With 32nd Club App"
      btnTwoText="Pay Without 32nd Club App"
      onPressOne={() => {
        hideBottomSheet(() => handleStorePress(Device.osName));
      }}
      onPressTwo={() => {
        // continue to paynow action on web without availing black membership benefits
        hideBottomSheet(() => onPayWithoutApp(true));
      }}
    />
  );
};

export const ViewBreakupBs = () => {
  const { orderType, rawQueryData, priceDetails, event } = usePayBillStore(
    (state) => state,
  );
  const { noOfSlots, slotId, ticketId } = event;
  const { billAmount } = priceDetails;
  const { getNetPrice } = rawQueryData ?? {};
  const sourceDetails = getNetPrice?.sourceDetails as DeepWritable<
    GQLGetNetPriceData['sourceDetails']
  >;

  return (
    <View mt="xl" px="2xl">
      {orderType === paymentOrderTypeEnum.PARKING && (
        <ParkingBillBreakUp
          billAmount={billAmount}
          sourceDetails={sourceDetails}
        />
      )}
      {orderType === paymentOrderTypeEnum.EVENT && (
        <EventBillBreakUp
          billAmount={billAmount}
          slotId={slotId}
          ticketId={ticketId}
          noOfSlots={noOfSlots}
          sourceDetails={sourceDetails}
        />
      )}
    </View>
  );
};
