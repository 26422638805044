/* eslint-disable no-nested-ternary */
import React, { memo, useContext, useEffect, useRef, useState } from 'react';
import {
  ScrollView,
  StyleSheet,
  Animated,
  Platform,
  FlatList,
} from 'react-native';
import { ThemeContext, ThemeProvider } from 'styled-components/native';
import {
  dynamicHeightMaker,
  imageTransformation,
} from '../../../utilities/helper';
import { Button, Text, View } from '../index';
import Pressable from '../basic/Pressable/Pressable';
import theme, { getTheme } from '../../../themes/new/theme';
import GradientBackground from './GradientBackground';
import WebCarousel from './WebCarousel';
import VideoPlayer from './VideoPlayer';
import CachedImage from './CachedImage';

const areEqual = (prevProps, nextProps) => {
  if (prevProps?.data?.length !== nextProps?.data?.length) return false;
  return true;
};

const ImageCarousel = ({
  data,
  status,
  onPress,
  windowWidth,
  borderRadiusRequired,
  fullBorderRadiusRequired,
  aspectRatio,
  showVideo,
  dotsFromBottom,
  showNavigationCta,
  showTitleAndDescription,
}) => {
  const [images, setImages] = useState(
    !showVideo && data?.length
      ? data.filter((item) => item?.mediaType !== 'video')
      : data
      ? JSON.parse(JSON.stringify(data))
      : [],
  );
  const [scrollViewWidth, setScrollViewWidth] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const scrollX = useRef(new Animated.Value(0)).current;

  const activeDotColor = theme.colors.singletone.white;
  const inActiveDotColor = theme.colors.opacity[50];

  const scrollRef = useRef(null);

  useEffect(() => {
    if (activeIndex > 1 && activeIndex < images.length - 1 - 1) {
      scrollRef.current.scrollTo({
        x: 16 * (activeIndex - 2),
        y: 0,
        animated: true,
      });
    }
  }, [activeIndex]);

  const gradientColors = [
    { offset: '0', color: theme.colors.primary[500], opacity: 0 },
    { offset: '1', color: theme.colors.primary[500], opacity: 0.8 },
  ];

  const themeContext = useContext(ThemeContext);

  return (
    <Animated.View>
      {status && status.length !== 0 && images?.length ? (
        <Pressable
          onPress={onPress}
          style={{
            position: 'absolute',
            top: theme.space['2xl'],
            left: theme.space['2xl'],
            zIndex: 1,
          }}
        >
          <View backgroundColor="opacity.80" borderRadius="sm" px="lg" py="sm">
            <View flexDirection="row" alignItems="center">
              <View
                width="3xs"
                //   p="xs"
                height="3xs"
                borderRadius="sm"
                borderWidth="xs"
                borderColor="success.500"
                justifyContent="center"
                alignItems="center"
              >
                <View
                  backgroundColor="success.500"
                  width="5xs"
                  height="5xs"
                  borderRadius="xs"
                />
              </View>
              <Text size="xs" weight="medium" color="success.500" pl="sm">
                {status}
              </Text>
            </View>
          </View>
        </Pressable>
      ) : (
        <></>
      )}
      {Platform.OS !== 'web' && (
        <>
          <View
            borderTopLeftRadius={
              borderRadiusRequired || fullBorderRadiusRequired
                ? theme.radii.md
                : 0
            }
            borderTopRightRadius={
              borderRadiusRequired || fullBorderRadiusRequired
                ? theme.radii.md
                : 0
            }
            borderRadius={fullBorderRadiusRequired ? theme.radii.md : 0}
            overflow="hidden"
            backgroundColor="background.secondary.base"
          >
            <FlatList
              data={images}
              onLayout={(e) => {
                setScrollViewWidth(e.nativeEvent.layout.width);
              }}
              keyExtractor={(item) => item?._id}
              style={{
                backgroundColor: theme.colors.background.secondary.base,
              }}
              renderItem={({ item, index }) => {
                return (
                  <Pressable onPress={onPress} key={item?._id}>
                    <Animated.View
                      style={{
                        flexDirection: 'row',
                      }}
                    >
                      <View>
                        {item?.mediaType !== 'video' ? (
                          <View
                            position="absolute"
                            bottom={0}
                            zIndex={2}
                            height={
                              dotsFromBottom && dotsFromBottom !== 0
                                ? dotsFromBottom + 80
                                : 80
                            }
                            width={windowWidth}
                          >
                            <GradientBackground
                              backgroundOpacity={0.6}
                              gradientColors={gradientColors}
                              flex={1}
                              id={item?._id}
                            />
                          </View>
                        ) : (
                          <></>
                        )}
                        {item?.mediaType !== 'video' ? (
                          <CachedImage
                            style={{
                              width: windowWidth,
                              height: dynamicHeightMaker(
                                windowWidth,
                                aspectRatio,
                              ),
                              resizeMode: 'cover',
                            }}
                            key={item?._id}
                            source={{
                              uri: imageTransformation(
                                item?.mediaURL,
                                windowWidth,
                                `${windowWidth}-${windowWidth * aspectRatio}`,
                              ),
                              cacheKey: item?._id,
                            }}
                          />
                        ) : (
                          <VideoPlayer
                            // muted={muted}
                            // setMuted={setMuted}
                            id={item?._id}
                            url={item?.mediaURL}
                            aspectRatio={aspectRatio}
                            play={index === activeIndex}
                            showPlayPause
                            isLooping
                            bottom={dotsFromBottom}
                          />
                        )}

                        {images?.length > 1 &&
                        index === images.length - 1 &&
                        showNavigationCta ? (
                          <ThemeProvider
                            theme={
                              themeContext.currentThemeMode === 'light'
                                ? getTheme('dark')
                                : getTheme('light')
                            }
                          >
                            <View
                              position="absolute"
                              height="100%"
                              width="100%"
                              alignItems="center"
                              justifyContent="center"
                              backgroundColor="opacity.80"
                            >
                              <Button
                                size="md"
                                appearance="filled"
                                iconLeft="photolibrary-outline-400"
                                status="primary"
                                onPress={onPress}
                              >
                                See All Photos
                              </Button>
                            </View>
                          </ThemeProvider>
                        ) : (
                          <></>
                        )}
                        {showTitleAndDescription ? (
                          <View
                            position="absolute"
                            height="100%"
                            width="100%"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Text
                              size="2xl"
                              weight="medium"
                              color="primary.10"
                              textAlign="center"
                              numberOfLines={1}
                            >
                              {item?.title}
                            </Text>
                            <Text
                              mt="xs"
                              size="md"
                              color="grey.50"
                              textAlign="center"
                              numberOfLines={1}
                            >
                              {item?.description}
                            </Text>
                          </View>
                        ) : (
                          <></>
                        )}
                      </View>
                    </Animated.View>
                  </Pressable>
                );
              }}
              ListEmptyComponent={() => (
                <Pressable onPress={onPress}>
                  <Animated.View
                    style={{
                      flexDirection: 'row',
                    }}
                  >
                    <View>
                      <CachedImage
                        style={{
                          width: windowWidth,
                          height: dynamicHeightMaker(windowWidth, aspectRatio),
                          resizeMode: 'cover',
                        }}
                        source={require('../../../../assets/images/Noimage.png')}
                      />
                    </View>
                  </Animated.View>
                </Pressable>
              )}
              horizontal
              directionalLockEnabled
              showsHorizontalScrollIndicator={false}
              scrollEnabled
              disableIntervalMomentum
              removeClippedSubviews
              overScrollMode="never"
              viewabilityConfig={{
                waitForInteraction: true,
                viewAreaCoveragePercentThreshold: 95,
              }}
              pagingEnabled
              onScroll={Animated.event(
                [{ nativeEvent: { contentOffset: { x: scrollX } } }],
                {
                  useNativeDriver: false,
                  listener: (e) => {
                    setActiveIndex(
                      Math.round(e.nativeEvent.contentOffset.x / windowWidth),
                    );
                  },
                },
              )}
              onMomentumScrollEnd={({ nativeEvent }) => {
                const index = Math.round(
                  nativeEvent.contentOffset.x / windowWidth,
                );
                setActiveIndex(index);
              }}
              initialNumToRender={2}
              windowSize={3}
              maxToRenderPerBatch={2}
              scrollEventThrottle={16}
            />
          </View>
          <Pressable onPress={onPress}>
            <View position="absolute" bottom={0} zIndex={2} width={windowWidth}>
              <ScrollView
                horizontal
                scrollEnabled={false}
                showsHorizontalScrollIndicator={false}
                bounces={false}
                contentContainerStyle={{
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                style={{
                  position: 'absolute',
                  bottom:
                    dotsFromBottom && dotsFromBottom !== 0
                      ? dotsFromBottom + theme.space['2xl']
                      : theme.space['2xl'],
                  alignSelf: 'center',
                  zIndex: 3,
                  width: images?.length > 2 ? 80 : 40,
                }}
                ref={scrollRef}
              >
                {images &&
                  images?.length > 1 &&
                  images.map((_ele, index) => {
                    const inputRange = [
                      (index - 2) * scrollViewWidth,
                      (index - 1) * scrollViewWidth,
                      index * scrollViewWidth,
                      (index + 1) * scrollViewWidth,
                      (index + 2) * scrollViewWidth,
                    ];

                    const colour = scrollX.interpolate({
                      inputRange,
                      outputRange: [
                        inActiveDotColor,
                        inActiveDotColor,
                        activeDotColor,
                        inActiveDotColor,
                        inActiveDotColor,
                      ],
                      extrapolate: 'clamp',
                    });
                    const scale = scrollX.interpolate({
                      inputRange,
                      outputRange: [0.5, 1, 1.25, 1, 0.5],
                      extrapolate: 'clamp',
                    });
                    return (
                      <Animated.View
                        key={_ele?._id}
                        style={[
                          styles.indiactor,
                          {
                            transform: [{ scale }],
                            backgroundColor: colour,
                          },
                        ]}
                      />
                    );
                  })}
              </ScrollView>
            </View>
          </Pressable>
        </>
      )}
      <Pressable onPress={onPress}>
        {Platform.OS === 'web' && (
          <View
            borderTopLeftRadius={
              borderRadiusRequired || fullBorderRadiusRequired
                ? theme.radii.md
                : 0
            }
            borderTopRightRadius={
              borderRadiusRequired || fullBorderRadiusRequired
                ? theme.radii.md
                : 0
            }
            borderRadius={fullBorderRadiusRequired ? theme.radii.md : 0}
            overflow="hidden"
            backgroundColor="background.secondary.base"
            height={dynamicHeightMaker(windowWidth, aspectRatio)}
          >
            <WebCarousel
              data={images}
              dotsFromBottom={dotsFromBottom}
              aspectRatio={aspectRatio}
              seeAllPhotoHandle={onPress}
              windowWidth={windowWidth}
              showNavigationCta={showNavigationCta}
              showTitleAndDescription={showTitleAndDescription}
            />
          </View>
        )}
      </Pressable>
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  indiactor: {
    width: theme.sizes['3xs'],
    height: theme.sizes['3xs'],
    borderRadius: theme.radii.md,
    margin: theme.space.sm,
  },
});

export default memo(ImageCarousel, areEqual);
