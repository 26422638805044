import React, { Suspense, useCallback, useRef, useState } from 'react';
import {
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  useWindowDimensions,
} from 'react-native';
import { useLazyLoadQuery } from 'react-relay';
import { useTheme } from 'styled-components/native';
import { get } from 'lodash';
import { useFocusEffect } from '@react-navigation/native';
import {
  Card,
  IconButton,
  Input,
  Layout,
  Text,
  TopNavigation,
  View,
  Button,
} from '../../../components/new';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../../utilities/NewErrorBoundary';
import NewErrorView from '../../../utilities/NewErrorView';
import Loading from '../../../components/loading.component';
import {
  navigateBack,
  PaymentGatewayType,
  paymentOrderTypeEnum,
} from '../../../utilities/helper';
import useUserStore from '../../../stores/userStore';
import baseTheme, { getTheme } from '../../../themes/new/theme';

import { InputStates } from '../../../components/new/primitive/Input/helpers';
import { paymentScreenQuery } from './api/paymentScreenQuery';
import { firebaseEventLogger } from '../../../utilities/firbaseAnalytics';
import DarkThemeWrapper from '../wrapperComp/DarkThemeWrapper';
import CachedImage from '../../../components/new/custom/CachedImage';

const BLACK_LOGO = require('../../../../assets/images/black_membership/black-member-logo-3.webp');

export const getSourceDetails = (orderType, data) => {
  let title = 'Payment';
  let refId = '';
  switch (orderType) {
    case paymentOrderTypeEnum.restaurant:
      title = data?.sourceDetails?.restaurant?.displayName;
      refId = data?.sourceDetails?.restaurant?._id;
      return {
        title,
        refId,
      };
    case paymentOrderTypeEnum.popupStore:
      title = data?.sourceDetails?.popUpStore?.displayName;
      refId = data?.sourceDetails?.popUpStore?._id;
      return {
        title,
        refId,
      };
    case paymentOrderTypeEnum.store:
      title = data?.sourceDetails?.store?.displayName;
      refId = data?.sourceDetails?.store?._id;
      return {
        title,
        refId,
      };
    case paymentOrderTypeEnum.EVENT:
      title = data?.sourceDetails?.event?.title;
      refId = data?.sourceDetails?.event?._id;
      return {
        title,
        refId,
      };
    case paymentOrderTypeEnum.PARKING:
      refId = data?.sourceDetails?.event?._id;
      return {
        title,
        refId,
      };
    default:
      return {
        title: '',
        refId: '',
      };
  }
};

const dataMaker = (discountP: number, cashbackP: number) => {
  return [
    {
      title: `Flat ${discountP}% Off`,
      subText: `On bill payments via the 32nd Club app`,
    },
    {
      title: `Earn ${cashbackP}% cashback`,
      subText: `Receive 32nd points worth ${cashbackP}% of bill amount`,
    },
  ];
};

const CommonCard = ({ title = '', subText = '', widthProp }) => {
  return (
    <DarkThemeWrapper>
      <View width={widthProp}>
        <Card appearance="filled" bg="background.secondary.base" withBorder>
          <View flexDirection="row" alignItems="center">
            <View mr="sm+md" pt="sm" justifyContent="center">
              <CachedImage
                source={BLACK_LOGO}
                style={{ width: 65, height: 35 }}
              />
            </View>
            <View flex={1}>
              <Text size="lg" mb="sm">
                {title}
              </Text>
              <Text size="xs" color="primary.200" numberOfLines={1}>
                {subText}
              </Text>
            </View>
          </View>
        </Card>
      </View>
    </DarkThemeWrapper>
  );
};

const PaymentScreen = ({ queryOptions, navigation, route, variables }) => {
  const theme = useTheme();
  const { width } = useWindowDimensions();
  const inputRef = useRef(null);
  const { orderId, orderType, amount, paymentMethod } = route.params ?? {};
  const [inputValue, setInputValue] = useState(amount || '');
  const isCustomerAlreadyBlackMember = useUserStore(
    (state) => state?.blackMembership?.active,
  );

  const { getNetPrice: data, findConfig } = useLazyLoadQuery(
    paymentScreenQuery,
    variables,
    queryOptions,
  );
  const bmDiscountPercentage = data?.priceDetails?.discountPercentage;
  const bmCashbackPercentage = data?.cashbackPercentage;

  useFocusEffect(
    useCallback(() => {
      // setInputValue('');
      setTimeout(() => {
        inputRef?.current?.focus();
      }, 200);
    }, []),
  );

  const d = dataMaker(bmDiscountPercentage, bmCashbackPercentage);
  const isBillPayEnabled = data?.sourceDetails?.restaurant?.payBill?.payEnabled;
  const isDiscountsScrollable = !!(
    bmDiscountPercentage && bmCashbackPercentage
  );
  const commonCardWidth = isDiscountsScrollable
    ? width - 3 * getTheme().space['2xl']
    : width - 2 * getTheme().space['2xl'];

  if (
    !isBillPayEnabled &&
    orderType === paymentOrderTypeEnum.restaurant &&
    paymentMethod !== PaymentGatewayType.BILL_UPLOAD
  ) {
    return (
      <View flex={1} justifyContent="center" alignItems="center">
        <Text>Access Denied</Text>
      </View>
    );
  }

  return (
    <>
      <ScrollView
        style={{ flex: 1 }}
        contentContainerStyle={{ flex: 1 }}
        keyboardShouldPersistTaps="handled"
        // bounces={false}
      >
        <View bg="primary.10">
          {isCustomerAlreadyBlackMember &&
            paymentMethod !== PaymentGatewayType.BILL_UPLOAD && (
              <>
                {!!(bmDiscountPercentage && bmCashbackPercentage) && (
                  <View>
                    <ScrollView
                      horizontal
                      showsHorizontalScrollIndicator={false}
                      showsVerticalScrollIndicator={false}
                      scrollEnabled={isDiscountsScrollable}
                    >
                      <View mb="7xl" mt="4xl" px="2xl">
                        <View flexDirection="row">
                          {bmDiscountPercentage ? (
                            <CommonCard
                              title={d[0]?.title}
                              subText={d[0]?.subText}
                              widthProp={commonCardWidth}
                            />
                          ) : null}
                          {isDiscountsScrollable ? (
                            <>
                              <View mx="sm" justifyContent="center">
                                <Text color="primary.100" size="4xl">
                                  +
                                </Text>
                              </View>
                            </>
                          ) : (
                            <></>
                          )}
                          {bmCashbackPercentage ? (
                            <>
                              <CommonCard
                                title={d[1]?.title}
                                subText={d[1]?.subText}
                                widthProp={commonCardWidth}
                              />
                            </>
                          ) : null}
                        </View>
                      </View>
                    </ScrollView>
                  </View>
                )}
              </>
            )}
          {!(bmDiscountPercentage && bmCashbackPercentage) && <View mb="9xl" />}
          <View mx="2xl">
            <Card appearance="filled" bg="background.primary.elevation">
              <Text size="lg" color="primary.300" textAlign="center" mb="2xl">
                Enter Bill Amount (₹)
              </Text>
              <View justifyContent="center">
                {/* this is overwritten input here */}
                <Input
                  inputRef={inputRef}
                  autoFocus
                  caretHidden
                  style={{
                    textAlign: 'center',
                    fontSize: baseTheme.fontSizes['5xl'],
                    lineHeight: baseTheme.lineHeights['4xl'],
                    fontFamily: baseTheme.fonts.bodyMedium,
                    backgroundColor: get(
                      theme.colors,
                      'background.primary.elevation',
                    ),
                    borderColor: get(
                      theme.colors,
                      'background.primary.elevation',
                    ),
                    caretColor: 'transparent',
                  }}
                  placeholderTextColor="grey.200"
                  state={InputStates.unstyled}
                  placeholder="₹0"
                  value={inputValue}
                  maxLength={8}
                  onChangeText={(i) => {
                    const modifiedInput = i.replace(/[^0-9]/g, '');
                    setInputValue(modifiedInput);
                  }}
                  inputMode="numeric"
                  keyboardType="numeric"
                />
              </View>
            </Card>
          </View>
        </View>
      </ScrollView>

      <View
        bottom={baseTheme.space['4xl']}
        position="absolute"
        left={0}
        right={0}
        mx="2xl"
      >
        <View>
          <View>
            <View>
              <Button
                size="lg"
                onPress={() => {
                  firebaseEventLogger('continue', {
                    orderType,
                    orderId,
                    paymentMethod,
                  });
                  if (paymentMethod === PaymentGatewayType.BILL_UPLOAD) {
                    navigation.navigate('ScanBillScreen', {
                      amount: amount ?? inputValue,
                      orderId,
                      orderType,
                      cashbackPercentage:
                        findConfig?.billUploadConfig?.cashbackPercentage,
                    });
                  } else {
                    navigation.navigate('paymentAmountScreen', {
                      amount: amount ?? inputValue,
                      orderId,
                      orderType,
                      paymentMethod,
                    });
                  }
                }}
                state={Number(inputValue) <= 0 ? 'disabled' : ''}
              >
                {paymentMethod === PaymentGatewayType.BILL_UPLOAD
                  ? `Upload Bill & Get ${findConfig?.billUploadConfig?.cashbackPercentage}% Cashback`
                  : 'Continue'}
              </Button>
            </View>
          </View>
        </View>
      </View>
    </>
  );
};
const PaymentScreenWrapper = ({ navigation, route }) => {
  const [title, setTitle] = useState('');
  const params = JSON.parse(JSON.stringify(route?.params || {}));
  const userRole = useUserStore((state) => state.role);
  const { orderId, orderType, amount } = route.params ?? {};

  // ===================
  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'network-only',
    });

  const refresh = () => {
    setRefreshedQueryOptions((prev) => ({
      ...prev,
      fetchKey: (prev?.fetchKey || 0) + 1,
    }));
  };

  useFocusEffect(
    React.useCallback(() => {
      firebaseEventLogger('enter_bill_amount', {
        ...params,
      });
    }, []),
  );

  const variables = {
    amount: Number(amount) || 100,
    useRewardPoints: false,
    orderId,
    orderType,
  };
  // ===================

  const backActionHandler = () => {
    navigateBack(navigation, userRole);
  };

  return (
    <Layout level={2} edges={['bottom']}>
      <KeyboardAvoidingView
        style={{ flex: 1 }}
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      >
        <View zIndex={1}>
          <TopNavigation
            title={title}
            // appearance="ghost"
            level=""
            textSize="md"
            textColor="primary.400"
            IconLeft={
              <IconButton
                name="back-outline-300"
                size="md"
                appearance="ghost"
                iconColor="primary.500"
                onPress={backActionHandler}
              />
            }
          />
        </View>
        <View flex={1}>
          <NewErrorBoundary
            fetchKey={refreshedQueryOptions.fetchKey}
            fallback={
              <NewErrorView
                errorMsg="Sorry something went wrong"
                reload={refresh}
              />
            }
          >
            <Suspense
              fallback={
                <View mt="8xl">
                  <Loading />
                </View>
              }
            >
              <View flex={1}>
                <View flex={1}>
                  <PaymentScreen
                    queryOptions={refreshedQueryOptions}
                    variables={variables}
                    navigation={navigation}
                    route={route}
                    retry={refresh}
                    setTitle={setTitle}
                  />
                </View>
              </View>
            </Suspense>
          </NewErrorBoundary>
        </View>
      </KeyboardAvoidingView>
    </Layout>
  );
};

export default PaymentScreenWrapper;
