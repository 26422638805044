/**
 * @generated SignedSource<<bcd2086799f0a974cf3ed02af8e031ef>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type homeScreenLogOutQuery_homeScreenBannerFragment$data = ReadonlyArray<{
  readonly deepLink: string;
  readonly imageLink: string;
  readonly " $fragmentType": "homeScreenLogOutQuery_homeScreenBannerFragment";
}>;
export type homeScreenLogOutQuery_homeScreenBannerFragment$key = ReadonlyArray<{
  readonly " $data"?: homeScreenLogOutQuery_homeScreenBannerFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"homeScreenLogOutQuery_homeScreenBannerFragment">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "homeScreenLogOutQuery_homeScreenBannerFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "imageLink",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deepLink",
      "storageKey": null
    }
  ],
  "type": "HomePageBanner",
  "abstractKey": null
};

(node as any).hash = "7cfc64dc21d07c9aa013fa64abc908f9";

export default node;
