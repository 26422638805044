import React from 'react';
import styled from 'styled-components/native';
import { space, border, SpaceProps, BorderRadiusProps } from 'styled-system';
import { ViewStyle } from 'react-native';
import { PlaceholderLine } from 'rn-placeholder';
import { getTheme } from '../../../../themes/new/theme';
import useCommonStore from '../../../../stores/commonStore';

const StyledLinePlaceholder = styled(PlaceholderLine)`
  ${space}
  ${border}
`;

interface LinePlaceholderProps extends SpaceProps, BorderRadiusProps {
  width?: number;
  height?: number;
  style?: ViewStyle;
}

const LinePlaceholder = ({
  width,
  height = 12,
  style = {},
  ...restProps
}: LinePlaceholderProps) => {
  const theme = useCommonStore((state) => state.theme);

  return (
    <StyledLinePlaceholder
      {...restProps}
      noMargin
      style={[
        {
          width: width ?? '100%',
          height,
        },
        style,
        {
          backgroundColor: getTheme(theme).colors.primary[30],
        },
      ]}
    />
  );
};

export default LinePlaceholder;
