/**
 * @generated SignedSource<<a943fec842b73abd43478ffe2bdc1ad7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type EnumtaskHistoryAction = "abort" | "accepted" | "arrived" | "assigned" | "cancelled" | "completed" | "created" | "exited" | "incomplete" | "onboarded" | "started" | "%future added value";
export type EnumtaskTaskType = "park" | "recall" | "repark" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type qrCodeScreenTaskQueryTaskFragment$data = {
  readonly findTasks: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly ETA: {
          readonly _id: any | null;
          readonly available: number | null;
          readonly busy: number | null;
          readonly delayAt: any | null;
          readonly originalPredictionTime: number | null;
          readonly pendingRecall: number | null;
          readonly startAt: any | null;
        } | null;
        readonly _id: any;
        readonly assignedTo: any | null;
        readonly campus: {
          readonly _id: any;
          readonly name: string;
        } | null;
        readonly createdBy: {
          readonly status: string | null;
        } | null;
        readonly history: ReadonlyArray<{
          readonly _id: any | null;
          readonly action: EnumtaskHistoryAction;
          readonly actor: any | null;
          readonly event_at: any | null;
        } | null>;
        readonly ownerId: any | null;
        readonly parkingId: any | null;
        readonly parkingTag: string | null;
        readonly taskEndLocation: {
          readonly locationId: any;
          readonly name: string | null;
          readonly parkingSpaces: {
            readonly floor: {
              readonly bay: {
                readonly bayId: any;
                readonly bayName: string | null;
              } | null;
              readonly floorId: any;
              readonly floorName: string | null;
            } | null;
          } | null;
        } | null;
        readonly taskStartLocation: {
          readonly locationId: any;
          readonly name: string | null;
          readonly parkingSpaces: {
            readonly floor: {
              readonly bay: {
                readonly bayId: any;
                readonly bayName: string | null;
              } | null;
              readonly floorId: any;
              readonly floorName: string | null;
            } | null;
          } | null;
        } | null;
        readonly taskType: EnumtaskTaskType;
        readonly user: {
          readonly contact: {
            readonly phone: {
              readonly number: string;
              readonly prefix: string;
            };
          };
          readonly name: {
            readonly first: string;
            readonly last: string | null;
          };
        } | null;
        readonly valet: {
          readonly _id: any;
          readonly contact: {
            readonly phone: {
              readonly number: string;
              readonly prefix: string;
            };
          };
          readonly identityCode: string | null;
          readonly name: {
            readonly first: string;
            readonly last: string | null;
          };
        } | null;
        readonly vehicle: {
          readonly brand: {
            readonly brandLogo: string | null;
            readonly name: string | null;
          } | null;
          readonly color: string | null;
          readonly registration: {
            readonly plate: string;
          } | null;
        } | null;
        readonly vehicleId: any | null;
      };
    }>;
    readonly pageInfo: {
      readonly hasNextPage: boolean;
    };
  } | null;
  readonly " $fragmentType": "qrCodeScreenTaskQueryTaskFragment";
};
export type qrCodeScreenTaskQueryTaskFragment$key = {
  readonly " $data"?: qrCodeScreenTaskQueryTaskFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"qrCodeScreenTaskQueryTaskFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "qrCodeScreenTaskQueryTaskFragment"
};

(node as any).hash = "d5bafa5c565450cac977efdd2fba2fdf";

export default node;
