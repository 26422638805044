/**
 * @generated SignedSource<<a72277e95009d64ab6eac8983b9e9af8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type EnumtaskHistoryAction = "abort" | "accepted" | "arrived" | "assigned" | "cancelled" | "completed" | "created" | "exited" | "incomplete" | "onboarded" | "started" | "%future added value";
export type EnumtaskTaskType = "park" | "recall" | "repark" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type selectParkingLocationScreenQueryTaskFragment$data = {
  readonly findTasks: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly _id: any;
        readonly campus: {
          readonly _id: any;
        } | null;
        readonly history: ReadonlyArray<{
          readonly _id: any | null;
          readonly action: EnumtaskHistoryAction;
          readonly actor: any | null;
          readonly event_at: any | null;
        } | null>;
        readonly parkingETA: {
          readonly parkingTimePrediction: ReadonlyArray<{
            readonly acceptedToCompleteTime: any | null;
            readonly locationId: any | null;
            readonly name: string;
            readonly parkingTimeThreshold: any;
            readonly startToCompleteTime: any | null;
          } | null> | null;
        } | null;
        readonly taskEndLocation: {
          readonly locationId: any;
          readonly name: string | null;
          readonly parkingSpaces: {
            readonly floor: {
              readonly bay: {
                readonly bayId: any;
                readonly bayName: string | null;
              } | null;
              readonly floorId: any;
              readonly floorName: string | null;
            } | null;
          } | null;
        } | null;
        readonly taskStartLocation: {
          readonly locationId: any;
          readonly name: string | null;
          readonly parkingSpaces: {
            readonly floor: {
              readonly bay: {
                readonly bayId: any;
                readonly bayName: string | null;
              } | null;
              readonly floorId: any;
              readonly floorName: string | null;
            } | null;
          } | null;
        } | null;
        readonly taskType: EnumtaskTaskType;
        readonly vehicle: {
          readonly registration: {
            readonly plate: string;
          } | null;
        } | null;
      };
    }>;
    readonly pageInfo: {
      readonly hasNextPage: boolean;
    };
  } | null;
  readonly " $fragmentType": "selectParkingLocationScreenQueryTaskFragment";
};
export type selectParkingLocationScreenQueryTaskFragment$key = {
  readonly " $data"?: selectParkingLocationScreenQueryTaskFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"selectParkingLocationScreenQueryTaskFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "findTasks"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "locationId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "floorName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "floorId",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "bayId",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "bayName",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "taskFilter"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./selectParkingLocationScreenTaskFragmentQuery.graphql')
    }
  },
  "name": "selectParkingLocationScreenQueryTaskFragment",
  "selections": [
    {
      "alias": "findTasks",
      "args": [
        {
          "kind": "Variable",
          "name": "filter",
          "variableName": "taskFilter"
        }
      ],
      "concreteType": "taskConnection",
      "kind": "LinkedField",
      "name": "__selectParkingLocationScreenQueryTaskFragment__findTasks_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "taskEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "task",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "taskVehicle",
                  "kind": "LinkedField",
                  "name": "vehicle",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "taskVehicleRegistration",
                      "kind": "LinkedField",
                      "name": "registration",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plate",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "taskTaskStartLocation",
                  "kind": "LinkedField",
                  "name": "taskStartLocation",
                  "plural": false,
                  "selections": [
                    (v2/*: any*/),
                    (v3/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "taskTaskStartLocationParkingSpaces",
                      "kind": "LinkedField",
                      "name": "parkingSpaces",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "taskTaskStartLocationParkingSpacesFloor",
                          "kind": "LinkedField",
                          "name": "floor",
                          "plural": false,
                          "selections": [
                            (v4/*: any*/),
                            (v5/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "taskTaskStartLocationParkingSpacesFloorBay",
                              "kind": "LinkedField",
                              "name": "bay",
                              "plural": false,
                              "selections": (v6/*: any*/),
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "taskTaskEndLocation",
                  "kind": "LinkedField",
                  "name": "taskEndLocation",
                  "plural": false,
                  "selections": [
                    (v2/*: any*/),
                    (v3/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "taskTaskEndLocationParkingSpaces",
                      "kind": "LinkedField",
                      "name": "parkingSpaces",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "taskTaskEndLocationParkingSpacesFloor",
                          "kind": "LinkedField",
                          "name": "floor",
                          "plural": false,
                          "selections": [
                            (v4/*: any*/),
                            (v5/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "taskTaskEndLocationParkingSpacesFloorBay",
                              "kind": "LinkedField",
                              "name": "bay",
                              "plural": false,
                              "selections": (v6/*: any*/),
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "taskHistory",
                  "kind": "LinkedField",
                  "name": "history",
                  "plural": true,
                  "selections": [
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "action",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "actor",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "event_at",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "taskType",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "campusLocation",
                  "kind": "LinkedField",
                  "name": "campus",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "taskParkingETA",
                  "kind": "LinkedField",
                  "name": "parkingETA",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "taskParkingETAParkingTimePrediction",
                      "kind": "LinkedField",
                      "name": "parkingTimePrediction",
                      "plural": true,
                      "selections": [
                        (v2/*: any*/),
                        (v3/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "parkingTimeThreshold",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "acceptedToCompleteTime",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "startToCompleteTime",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "0d2ece516b2957f9dd3ea89e489fdc16";

export default node;
