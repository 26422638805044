import React from 'react';
import { Platform } from 'react-native';
import {
  BlackMembershipActiveCard,
  BlackMembershipPurchaseCard,
} from '../../../plusMembership';
import { usePayBillStore } from '../hooks/usePayBillStore';
import { paymentOrderTypeEnum } from '../../../../../utilities/helper';
import useUserStore from '../../../../../stores/userStore';
import { View } from '../../../../../components/new';
import { PAYBILL_BS_MODES } from '../helpers';
import payBillAnalytics from '../analytics';

interface BlackMembershipWidgetProps {
  onRemoveMembership: () => void;
  onAddMembership: (membershipId: string) => void;
  showBottomSheet: (bsMode: PAYBILL_BS_MODES) => void;
}

const BlackMembershipWidget = ({
  onAddMembership,
  onRemoveMembership,
  showBottomSheet,
}: BlackMembershipWidgetProps) => {
  const {
    title,
    orderType,
    orderId,
    priceDetails: pd,
    priceDetailsWithPlusMembership: pdPM,
    config,
    blackMembershipAdded,
  } = usePayBillStore((state) => state);
  const isCustomerAlreadyBlackMember = useUserStore(
    (state) => state?.blackMembership?.active,
  );

  if (orderType === paymentOrderTypeEnum.INVESTMENT_LEAD) return null;
  if (isCustomerAlreadyBlackMember) {
    const data =
      Platform.OS !== 'web'
        ? {
            cashback: pd?.cashbackAmount,
            discount: pd?.discountAmount,
          }
        : 'As a member, save upto 50% and get 10% cashback by paying via 32nd Club app!';
    return (
      <View mb="2xl">
        <BlackMembershipActiveCard data={data} />
      </View>
    );
  }

  const membershipData = {
    cashbackAmount: pdPM?.cashbackAmount,
    cashbackPercentage: pdPM?.cashbackPercentage,
    discountAmount: pdPM?.discountAmount,
    discountPercentage: pdPM?.discountPercentage,
  };

  if (!pdPM) return null;

  return (
    <View mb="2xl">
      <BlackMembershipPurchaseCard
        membershipData={membershipData}
        membershipConfig={config?.blackMembershipConfig}
        membershipAdded={blackMembershipAdded}
        onRemoveMembership={() => {
          payBillAnalytics.blackMembershipRemovedFromCart(
            title,
            orderType,
            orderId,
          );
          onRemoveMembership();
        }}
        onAddMembership={() => {
          payBillAnalytics.blackMembershipAddedToCart(
            title,
            orderType,
            orderId,
          );
          onAddMembership(config?.blackMembershipConfig?._id);
        }}
        onViewBenefits={() => {
          payBillAnalytics.memberBenefitsClicked(title, orderType, orderId);
          showBottomSheet(PAYBILL_BS_MODES.BLACK_MEMBER_BENEFITS);
        }}
      />
    </View>
  );
};

export default BlackMembershipWidget;
