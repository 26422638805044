/**
 * @generated SignedSource<<0aba3ff8c89e24070adcb5e6cd77ed85>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EnumtaskHistoryAction = "abort" | "accepted" | "arrived" | "assigned" | "cancelled" | "completed" | "created" | "exited" | "incomplete" | "onboarded" | "started" | "%future added value";
export type EnumtaskTaskType = "park" | "recall" | "repark" | "%future added value";
export type findTaskFilterInput = {
  _id?: any | null;
  assignedTo?: any | null;
  campus: FilterFindManytaskCampusInput;
  created_at?: string | null;
  history?: ReadonlyArray<FilterFindManytaskHistoryInput | null> | null;
  metrics?: FilterFindManytaskMetricsInput | null;
  ownerId?: any | null;
  search?: findTaskFilterInputSearch | null;
  taskEndLocation?: FilterFindManytaskTaskEndLocationInput | null;
  taskStartLocation?: FilterFindManytaskTaskStartLocationInput | null;
  taskType?: EnumtaskTaskType | null;
};
export type FilterFindManytaskHistoryInput = {
  action?: EnumtaskHistoryAction | null;
};
export type FilterFindManytaskTaskStartLocationInput = {
  _id?: any | null;
  locationId: any;
};
export type FilterFindManytaskTaskEndLocationInput = {
  _id?: any | null;
  locationId: any;
};
export type FilterFindManytaskCampusInput = {
  _id: any;
};
export type FilterFindManytaskMetricsInput = {
  _id?: any | null;
  acceptedIn?: number | null;
  assignedIn?: number | null;
  createToArrivedIn?: number | null;
  createdAt?: any | null;
  isAcceptedDelayed?: boolean | null;
  isAssignmentDelayed?: boolean | null;
  isOnbardingDelayed?: boolean | null;
  isParkingDelayed?: boolean | null;
  isWithInCustomerETA?: boolean | null;
  isWithinETA?: boolean | null;
  startToCompleteIn?: number | null;
  taskCompletionIn?: number | null;
  taskOnboardeIn?: number | null;
  updatedAt?: any | null;
  valetTookInCompletion?: number | null;
};
export type findTaskFilterInputSearch = {
  vehicleNo: string;
};
export type taskApiFindTaskQuery$variables = {
  filter: findTaskFilterInput;
};
export type taskApiFindTaskQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"taskApiFindTaskFragment">;
};
export type taskApiFindTaskQuery = {
  response: taskApiFindTaskQuery$data;
  variables: taskApiFindTaskQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "locationId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "floorName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "floorId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "UserName",
  "kind": "LinkedField",
  "name": "name",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "first",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "last",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "taskEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "task",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "taskVehicle",
            "kind": "LinkedField",
            "name": "vehicle",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "taskVehicleBrand",
                "kind": "LinkedField",
                "name": "brand",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "brandLogo",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "taskVehicleRegistration",
                "kind": "LinkedField",
                "name": "registration",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "plate",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "color",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "taskType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "taskTaskStartLocation",
            "kind": "LinkedField",
            "name": "taskStartLocation",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "taskTaskStartLocationParkingSpaces",
                "kind": "LinkedField",
                "name": "parkingSpaces",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "taskTaskStartLocationParkingSpacesFloor",
                    "kind": "LinkedField",
                    "name": "floor",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "taskTaskStartLocationParkingSpacesFloorBay",
                        "kind": "LinkedField",
                        "name": "bay",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "bayName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "bayId",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "taskTaskEndLocation",
            "kind": "LinkedField",
            "name": "taskEndLocation",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "taskTaskEndLocationParkingSpaces",
                "kind": "LinkedField",
                "name": "parkingSpaces",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "taskTaskEndLocationParkingSpacesFloor",
                    "kind": "LinkedField",
                    "name": "floor",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "campusLocation",
            "kind": "LinkedField",
            "name": "campus",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "taskHistory",
            "kind": "LinkedField",
            "name": "history",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "action",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "actor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "event_at",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "assignedTo",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "valet",
            "plural": false,
            "selections": [
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "UserContact",
                "kind": "LinkedField",
                "name": "contact",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserContactPhone",
                    "kind": "LinkedField",
                    "name": "phone",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "prefix",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "number",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "vehicleId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ownerId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "parkingId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "parkingTag",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "PageInfo",
    "kind": "LinkedField",
    "name": "pageInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasNextPage",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endCursor",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v8 = [
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "filter"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "taskApiFindTaskQuery",
    "selections": [
      {
        "kind": "InlineDataFragmentSpread",
        "name": "taskApiFindTaskFragment",
        "selections": [
          {
            "alias": "findTasks",
            "args": [
              {
                "kind": "Variable",
                "name": "filter",
                "variableName": "taskFilter"
              },
              {
                "kind": "Variable",
                "name": "sort",
                "variableName": "sort"
              }
            ],
            "concreteType": "taskConnection",
            "kind": "LinkedField",
            "name": "__taskApiFindTaskFragment__findTasks_connection",
            "plural": false,
            "selections": (v7/*: any*/),
            "storageKey": null
          }
        ],
        "args": [
          {
            "kind": "Literal",
            "name": "count",
            "value": 10
          },
          {
            "kind": "Variable",
            "name": "taskFilter",
            "variableName": "filter"
          }
        ],
        "argumentDefinitions": [
          {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "count"
          },
          {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "cursor"
          },
          {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "sort"
          },
          {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "taskFilter"
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "taskApiFindTaskQuery",
    "selections": [
      {
        "alias": "findTasks",
        "args": (v8/*: any*/),
        "concreteType": "taskConnection",
        "kind": "LinkedField",
        "name": "findTasksV2",
        "plural": false,
        "selections": (v7/*: any*/),
        "storageKey": null
      },
      {
        "alias": "findTasks",
        "args": (v8/*: any*/),
        "filters": [
          "filter",
          "sort"
        ],
        "handle": "connection",
        "key": "taskApiFindTaskFragment__findTasks",
        "kind": "LinkedHandle",
        "name": "findTasksV2"
      }
    ]
  },
  "params": {
    "cacheID": "e4944bb78dc4b642f349e9eca075f0bb",
    "id": null,
    "metadata": {},
    "name": "taskApiFindTaskQuery",
    "operationKind": "query",
    "text": "query taskApiFindTaskQuery(\n  $filter: findTaskFilterInput!\n) {\n  ...taskApiFindTaskFragment_1hgUS5\n}\n\nfragment taskApiFindTaskFragment_1hgUS5 on Query {\n  findTasks: findTasksV2(filter: $filter, first: 10) {\n    edges {\n      node {\n        _id\n        vehicle {\n          brand {\n            name\n            brandLogo\n          }\n          registration {\n            plate\n          }\n          color\n        }\n        taskType\n        taskStartLocation {\n          locationId\n          name\n          parkingSpaces {\n            floor {\n              floorName\n              floorId\n              bay {\n                bayName\n                bayId\n              }\n            }\n          }\n        }\n        taskEndLocation {\n          locationId\n          name\n          parkingSpaces {\n            floor {\n              floorName\n              floorId\n            }\n          }\n        }\n        campus {\n          _id\n          name\n        }\n        history {\n          action\n          actor\n          event_at\n        }\n        assignedTo\n        valet {\n          name {\n            first\n            last\n          }\n        }\n        user {\n          name {\n            first\n            last\n          }\n          contact {\n            phone {\n              prefix\n              number\n            }\n          }\n        }\n        vehicleId\n        ownerId\n        parkingId\n        parkingTag\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c8b4d8efe3c29148c4cead347c799a39";

export default node;
