import React, { Suspense, useCallback, useState } from 'react';
import { ScrollView } from 'react-native';
import { useLazyLoadQuery, readInlineData } from 'react-relay';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import {
  View,
  Layout,
  TopNavigation,
  IconButton,
} from '../../../components/new';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../../utilities/NewErrorBoundary';
import NewErrorView from '../../../utilities/NewErrorView';
import DisconnectedDropover from '../../../components/disconnectedpopover.component';
import Loading from '../../../components/loading.component';
import useUserStore from '../../../stores/userStore';
import { qrCodeScreenQuery } from './API/qrCodeScreenQuery';
import {
  navigateBack,
  taskHistoryActionTypes,
  taskTypes,
} from '../../../utilities/helper';
import fetchQrCodeScreenTaskQuery, {
  qrCodeScreenTaskQueryTaskFragment,
} from './API/qrCodeScreenTaskQuery';
import useCommonStore from '../../../stores/commonStore';
import ValetQrCodeCard from '../../../components/new/custom/ValetQrCodeCard';
import AppConstants from '../../../utilities/AppConstants';
import { unwrapPagedData } from '../../../utilities/paginationUtilities';

const RenderQrCodeCard = (props) => {
  const { queryOptions, variables } = props;
  const navigation = useNavigation<any>();
  const userId = useUserStore((state) => state.id);
  const campusID = useCommonStore((state) => state.campusId);
  const { IMAGEKIT_PROD_URI } = AppConstants;
  const { findUserById } = useLazyLoadQuery(qrCodeScreenQuery, variables, {
    ...queryOptions,
    networkCacheConfig: { force: true },
  });
  const { name, profileImage, _id, identityCode } = findUserById ?? {};
  const fullName = `${name?.first} ${name?.last !== null ? name?.last : ''}`;
  const imageLink = profileImage
    ? IMAGEKIT_PROD_URI + findUserById.profileImage
    : 'https://ik.imagekit.io/32ndhub/assets/nouser.png';

  const fetchRecallTask = () => {
    fetchQrCodeScreenTaskQuery({
      campus: { _id: campusID },
      assignedTo: userId,
      taskType: taskTypes.recall,
      history: [
        { action: taskHistoryActionTypes.assigned },
        { action: taskHistoryActionTypes.accepted },
        { action: taskHistoryActionTypes.started },
      ],
    })
      .then((tasksFragmentRef) => {
        const { findTasks } = readInlineData(
          qrCodeScreenTaskQueryTaskFragment,
          tasksFragmentRef,
        );
        const res = {
          findTasks: unwrapPagedData(findTasks.edges),
        };

        if (res && res?.findTasks && res?.findTasks?.length) {
          if (res?.findTasks[0]?.taskType === taskTypes.recall) {
            const findLastStatus =
              res?.findTasks[0]?.history.slice(-1)[0]?.action;
            const taskID = res?.findTasks[0]?._id;
            if (findLastStatus === taskHistoryActionTypes.started) {
              navigation.navigate('ReachedDropLocationScreen', {
                taskId: taskID,
              });
            } else if (findLastStatus === taskHistoryActionTypes.arrived) {
              navigation.navigate('FinishTaskScreen', {
                taskId: taskID,
              });
            } else {
              navigation.popToTop();
            }
          } else {
            navigation.popToTop();
          }
        }
      })
      .catch((err) => {
        console.log('errrr', err);
      });
  };

  useFocusEffect(
    useCallback(() => {
      const interval = 3000;
      const c = setInterval(() => fetchRecallTask(), interval);
      return () => {
        // Useful for cleanup functions
        clearInterval(c);
      };
    }, []),
  );

  return (
    <ScrollView
      contentContainerStyle={{
        flex: 1,
        justifyContent: 'flex-start',
      }}
    >
      <View px="2xl">
        <ValetQrCodeCard
          fullName={fullName}
          imageLink={imageLink}
          _id={_id}
          identityCode={identityCode}
        />
      </View>
    </ScrollView>
  );
};

const QrCodeScreen = ({ navigation, route }) => {
  const userId = useUserStore((state) => state.id);
  const userRole = useUserStore((state) => state.role);

  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'network-only',
    });
  const [netStatus, setNetStatus] = useState(true);

  const refresh = useCallback(
    () => {
      setRefreshedQueryOptions((prev) => ({
        fetchKey: (prev?.fetchKey ?? 0) + 1,
        fetchPolicy: 'network-only',
      }));
    },
    [
      /* ... */
    ],
  );

  const onPressLeftIcon = () => {
    navigateBack(navigation, userRole);
  };

  return (
    <Layout level={2}>
      <TopNavigation
        IconLeft={
          <IconButton
            name="back-outline-300"
            size="md"
            appearance="ghost"
            iconSize="2xl"
            iconColor="primary.500"
            shape="square"
            onPress={onPressLeftIcon}
          />
        }
        appearance="ghost"
        level="1"
        // title=""
      />
      <NewErrorBoundary
        fetchKey={refreshedQueryOptions.fetchKey}
        fallback={
          <NewErrorView
            errorMsg="Sorry something went wrong"
            reload={refresh}
          />
        }
      >
        <Suspense fallback={<Loading />}>
          <RenderQrCodeCard
            variables={{
              userId,
            }}
            queryOptions={refreshedQueryOptions}
          />
        </Suspense>
      </NewErrorBoundary>
      <DisconnectedDropover
        setNetStatus={setNetStatus}
        text="No Internet Connection"
        icon="wifi-off-outline"
      />
    </Layout>
  );
};

export default QrCodeScreen;
