import React, { useState, useRef, useCallback } from 'react';
import {
  Pressable,
  ScrollView,
  Image,
  ActivityIndicator,
  TouchableWithoutFeedback,
  Keyboard,
} from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { ThemeProvider, ThemeConsumer } from 'styled-components/native';
import { useFocusEffect } from '@react-navigation/native';
import useBoundStore from '../../../stores';
import {
  menuAddButtonAppearanceEnum,
  menuAddButtonSizeEnum,
} from '../../../components/new/primitive/MenuAddButton/varAndEnum';
import {
  Layout,
  Text,
  View,
  TopNavigation,
  IconButton,
  Button,
  MenuAddButton,
  Heading,
  Card,
  Icon,
  Divider,
  Input,
} from '../../../components/new';
import {
  applyEllipsis,
  getOptions,
  navigateBack,
} from '../../../utilities/helper';
import { placeOrder } from '../../../relay/orderApi';
import VariationsBottomSheet from './variationsBottomSheet.component';
import theme, { getTheme, themeModes } from '../../../themes/new/theme';
import useAuthStore from '../../../stores/authStore';
import useLoginModalStore from '../../../stores/loginModalStore';
import useUserStore from '../../../stores/userStore';
import { useSnackbarStore } from '../../../stores/snackbar/snackbarStore';
import useCommonStore from '../../../stores/commonStore';
import { getCount } from '../../../components/new/custom/SelectedCartFooter';
import { InputStates } from '../../../components/new/primitive/Input/helpers';
import { firebaseEventLogger } from '../../../utilities/firbaseAnalytics';

const checkGif = require('../../../../assets/check-black.gif');
const emptyCart = require('../../../../assets/images/empty-cart-1.png');

const ADDITIONAL_NOTES_MAX_CHARS = 100;

const AddButton = (props) => {
  const {
    quantity,
    addToCart,
    removeFromCart,
    menuItem,
    cart,
    clearOrderDetails,
    restaurant,
  } = props;
  return (
    <MenuAddButton
      appearance={menuAddButtonAppearanceEnum.outline}
      count={quantity}
      size={menuAddButtonSizeEnum.md}
      onLeftPress={() => {
        firebaseEventLogger('OrderDetailRemovePress', {
          itemId: menuItem.id,
        });
        removeFromCart({
          id: menuItem.id,
          addonItem: menuItem.addonItem,
          title: menuItem.title,
          itemPrice: menuItem.itemPrice,
          description: menuItem.description,
          options: menuItem.options,
        });
      }}
      onRightPress={() => {
        firebaseEventLogger('OrderDetailAddPress', {
          itemId: menuItem.id,
        });
        addToCart({
          id: menuItem.id,
          addonItem: menuItem.addonItem,
          title: menuItem.title,
          itemPrice: menuItem.itemPrice,
          description: menuItem.description,
          options: menuItem.options,
          defaultPrice: menuItem.defaultPrice,
          timestamp: Date.now(),
          restaurantId: restaurant,
          posId: menuItem.posId,
        });
      }}
    />
  );
};

const OrderScreen = ({ navigation, route }) => {
  const { restaurant, restaurantName } = route.params;
  const cart = useBoundStore((state) => state.cart);
  const addToCart = useBoundStore((state) => state.addToCart);
  const removeFromCart = useBoundStore((state) => state.removeFromCart);
  const clearCart = useBoundStore((state) => state.clearCart);
  const orderDetails = useBoundStore((state) => state.orderDetails);
  const clearOrderDetails = useBoundStore((state) => state.clearOrderDetails);
  // const setOrderDetails = useBoundStore((state) => state.setOrderDetails);
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  const setAuthModalOpen = useLoginModalStore((state) => state.setIsOpen);
  const setRedirectUri = useAuthStore((state) => state.setRedirectUri);
  const [selectedMenuItem, setSelectedMenuItem] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showAnimation, setShowAnimation] = useState(false);
  const userRole = useUserStore((state) => state.role);
  const userId = useUserStore((state) => state.id);
  const campusID = useCommonStore((state) => state.campusId);
  const [additionalNotes, setAdditionalNotes] = useState('');
  const [additionalNotesValidation, setAdditionalNotesValidation] =
    useState('');

  const bottomSheetModalRef = useRef(null);
  const { firstName, lastName } = useUserStore((state) => state);
  const { dispatchSnackbar } = useSnackbarStore((state) => state);

  const insets = useSafeAreaInsets();

  // const getCount = () => {
  //   return cart.reduce((prev, curr) => {
  //     return prev + curr.quantity;
  //   }, 0);
  // };

  const getPrice = (item) => {
    let price = 0;
    if (item?.itemPrice.length) {
      const variationsArr = item.itemPrice.filter(
        (ele) => ele.type === 'variation',
      );
      if (variationsArr.length === 0) {
        price = item.defaultPrice;
      }
    }
    if (item.options.length) {
      price += item.options.reduce((prev, curr) => {
        return prev + Number(curr.label);
      }, 0);
    }
    if (typeof item.itemPrice === 'number') {
      price = item.itemPrice;
    }
    return parseFloat((price * item.quantity).toFixed(2));
  };

  useFocusEffect(
    useCallback(() => {
      firebaseEventLogger('OrderDetailLanding', {
        restaurantId: restaurant,
      });
    }, []),
  );

  const priceBreakdown = () => {
    return cart.reduce(
      (prev, curr) => {
        // console.log('curr', curr);
        // console.log('cart', cart);
        let price = 0;
        if (curr.options.length) {
          price = curr.options.reduce((prevItem, currItem) => {
            return prevItem + Number(currItem.label);
          }, 0);
          // console.log('2', price);
          prev.Total += price * curr.quantity;
          for (let i = 0; i < curr.tax.length; i += 1) {
            const { taxType, value } = curr.tax[i];
            prev[taxType] += (price * curr.quantity * value) / 100;
          }
          // console.log('3', prev.Total);
          // if (curr.tax?.taxType === 'VAT') {
          //   prev.VAT += price * curr.quantity * (curr.tax.value || 0);
          // } else if (curr.tax?.taxType === 'GST') {
          //   prev.GST += price * curr.quantity * (curr.tax.value || 0);
          // }
        }
        if (typeof curr.itemPrice === 'number') {
          prev.Total += curr.itemPrice * curr.quantity;
          for (let i = 0; i < curr.tax.length; i += 1) {
            const { taxType, value } = curr.tax[i];
            // console.log('4', taxType, prev[taxType]);
            // console.log('4.5', prev.Total);

            prev[taxType] += (curr.itemPrice * curr.quantity * value) / 100;
          }
          // if (curr.tax?.taxType === 'VAT') {
          //   prev.VAT += curr.itemPrice * curr.quantity * (curr.tax.value || 0);
          // } else if (curr.tax?.taxType === 'GST') {
          //   prev.GST += curr.itemPrice * curr.quantity * (curr.tax.value || 0);
          // }
        }
        if (curr.itemPrice.length) {
          const variationsArr = curr.itemPrice.filter(
            (ele) => ele.type === 'variation',
          );
          if (variationsArr.length === 0) {
            // prev +=
            //   curr.defaultPrice * curr.quantity * (1 + (curr.tax.value || 0));
            prev.Total += curr.defaultPrice * curr.quantity;
            for (let i = 0; i < curr.tax.length; i += 1) {
              const { taxType, value } = curr.tax[i];
              // console.log('5', taxType, prev[taxType]);
              // console.log('6', price * curr.quantity);
              prev[taxType] +=
                ((curr.defaultPrice + price * curr.quantity) * value) / 100;
            }
            // if (curr.tax?.taxType === 'VAT') {
            //   prev.VAT +=
            //     curr.defaultPrice * curr.quantity * (curr.tax.value || 0);
            // } else if (curr.tax?.taxType === 'GST') {
            //   prev.GST +=
            //     curr.defaultPrice * curr.quantity * (curr.tax.value || 0);
            // }
          }
        }
        // return parseInt(prev.toFixed(2), 10);
        // console.log('8', prev);
        return prev;
      },
      { Total: 0, VAT: 0, CGST: 0, SGST: 0 },
    );
  };

  const orderSuccessHandler = (response) => {
    if (!response.placeOrder?.error) {
      firebaseEventLogger('order__OrderSuccessful', {
        restaurantId: restaurant,
        type: 'restaurant',
      });
      clearCart();
      clearOrderDetails();
      setShowAnimation(true);
      setLoading(false);
      setTimeout(() => {
        setShowAnimation(false);
        navigation.navigate('user');
      }, 1200);
    } else {
      firebaseEventLogger('order__OrderFailed', {
        restaurantId: restaurant,
        type: 'restaurant',
      });
    }
  };

  const orderHandler = () => {
    firebaseEventLogger('order__OrderPlaced', {
      restaurantId: restaurant,
      type: 'restaurant',
    });
    if (!isLoggedIn) {
      setRedirectUri({
        screenName: 'order',
        params: {
          restaurant,
        },
      });
      setAuthModalOpen(true);
      return;
    }
    setLoading(true);
    const cartItems = cart.map((item) => {
      for (let index = 0; index < item.tax.length; index += 1) {
        delete item.tax[index]._id;
      }
      const ItemVarition = [];
      const addonItem = [];
      if (item.options.length) {
        item.options.forEach((ele) => {
          if (ele.type === 'variation') {
            ItemVarition.push({
              name: ele.name,
              category: ele.category,
            });
          } else if (ele.type === 'addon') {
            addonItem.push({
              name: ele.name,
              category: ele.category,
              quantity: 1,
            });
          }
        });
      }
      return {
        itemId: item.id,
        quantity: item.quantity,
        // defaultprice: item.defaultPrice,
        // itemName: item.title,
        variations: ItemVarition,
        addOns: addonItem,
        // posId: item.posId,
        // taxes: item.tax,
      };
    });

    const data = {
      tableNo: +tableNo,
      menuItems: cartItems,
      restaurantId: restaurant,
      campusId: campusID || '',
      additionalNotes: additionalNotes?.trim(),
    };
    placeOrder(data, orderSuccessHandler, (err) => {
      setLoading(false);
    });
  };

  const setBtnState = () => {
    if (additionalNotesValidation) return 'disabled';
    if (cart[0].restaurant?.openStatus?.status === 'closed') return 'disabled';
    if (tableNo && cart[0].posId && cart[0].restaurant.posId) {
      if (loading) {
        return 'disabled';
      }
      return 'active';
    }
    return 'disabled';
  };

  let tableNo;
  // if (orderDetails.restaurantId === restaurant) {
  if (orderDetails.restaurantId === cart[0]?.restaurant._id) {
    tableNo = orderDetails.tableNumber;
  }

  const noOfItems = getCount(cart);
  const scrollviewRef = useRef();

  return (
    <TouchableWithoutFeedback style={{ flex: 1 }} onPress={Keyboard.dismiss}>
      <View flex={1}>
        {!showAnimation ? (
          <Layout level={2}>
            <View height="100%">
              <TopNavigation
                title={applyEllipsis(
                  restaurantName || cart[0]?.restaurant?.displayName,
                  25,
                )}
                appearance="ghost"
                level="1"
                IconLeft={
                  <IconButton
                    name="back-outline-300"
                    size="md"
                    appearance="ghost"
                    iconColor="primary.500"
                    onPress={() => navigateBack(navigation, 'user')}
                    // onPress={() =>
                    //   navigation.navigate('menu', {
                    //     restaurantId: restaurant,
                    //   })
                    // }
                  />
                }
              />
              {cart.length > 0 ? (
                <>
                  <ScrollView ref={scrollviewRef}>
                    <View
                      mt="3xl"
                      px="2xl"
                      flexDirection="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Heading size="2xl" weight="medium" color="primary.300">
                        Order Summary
                      </Heading>
                      {tableNo ? (
                        <Pressable
                          onPress={() => {
                            navigation.navigate('StaffBarcodeScannerScreen', {
                              screenName: 'order',
                              restaurant,
                              restaurantName,
                              hideManual: true,
                            });
                          }}
                        >
                          <Text size="sm" color="info.500">
                            {`Table No. ${tableNo}`}
                          </Text>
                        </Pressable>
                      ) : null}
                    </View>
                    <View px="2xl" py="2xl">
                      <Card>
                        {cart.map((item, index) => {
                          // TODO: change to flashlist
                          return (
                            <View
                              // eslint-disable-next-line react/no-array-index-key
                              key={item.id + index}
                              mb={item.options.length ? '4xl' : '2xl'}
                              flexDirection="row"
                              justifyContent="space-between"
                            >
                              <View flex={3}>
                                <Text
                                  size="md"
                                  color="primary.400"
                                  weight="medium"
                                >
                                  {item.title}
                                </Text>
                                <Text mb="xs" size="md" color="primary.400">
                                  &#8377;{getPrice(item)}
                                </Text>
                                {item.options.length ? (
                                  <Text
                                    size="xs"
                                    color="primary.200"
                                    numberOfLines={2}
                                    ellipsizeMode="tail"
                                  >
                                    {getOptions(item.options)}
                                  </Text>
                                ) : null}
                                {/* <Text numberOfLines={3}>
                                {item.options.length
                                  ? item.options.map((opts, index) => (
                                      <Text size="xs" color="primary.200">
                                        <Text
                                          weight="medium"
                                          color="primary.200"
                                        >
                                          {opts.category}
                                        </Text>{' '}
                                        : {opts.name}
                                        {index < item.options.length - 1
                                          ? ', '
                                          : ''}
                                      </Text>
                                    ))
                                  : null}
                              </Text> */}

                                {item.itemPrice.length ? (
                                  <View alignItems="flex-start">
                                    <Button
                                      iconLeft=""
                                      iconRight="expand-outline-300"
                                      state=""
                                      status="info"
                                      appearance="ghost"
                                      size="sm"
                                      iconSize="md"
                                      onPress={() => {
                                        firebaseEventLogger(
                                          'OrderDetailEditButtonClicked',
                                        );
                                        setSelectedMenuItem({
                                          ...item,
                                          index,
                                          restaurantId: restaurant,
                                          restaurantName,
                                        });
                                        bottomSheetModalRef.current?.show();
                                      }}
                                    >
                                      Edit
                                    </Button>
                                  </View>
                                ) : null}
                              </View>
                              <View flex={2} alignItems="flex-end">
                                <AddButton
                                  menuItem={item}
                                  quantity={item.quantity}
                                  addToCart={addToCart}
                                  removeFromCart={removeFromCart}
                                  cart={cart}
                                  clearOrderDetails={clearOrderDetails}
                                  restaurant={restaurant}
                                />
                              </View>
                            </View>
                          );
                        })}
                        <View mt="xs">
                          <Divider />
                        </View>
                        {/* GO_BACK not available when page refresh */}
                        <Pressable
                          onPress={() => {
                            firebaseEventLogger(
                              'OrderDetailAddMoreItemsCLicked',
                            );
                            navigation.navigate('menu', {
                              restaurantId: restaurant,
                            });
                          }}
                        >
                          <View
                            flexDirection="row"
                            justifyContent="space-between"
                            mt="2xl"
                            mb="lg"
                          >
                            <Text size="sm" weight="medium" color="primary.400">
                              Add more items
                            </Text>
                            <Icon
                              size="xl"
                              name="forward-outline-300"
                              color="primary.500"
                            />
                          </View>
                        </Pressable>
                      </Card>
                    </View>
                    <View px="2xl" mt="2xl" mb="4xl">
                      <View>
                        <Input
                          multiline
                          numberOfLines={3}
                          state={
                            additionalNotesValidation
                              ? InputStates.error
                              : InputStates.default
                          }
                          inputMode="text"
                          label="Add a note"
                          labelFontSize="md"
                          labelFontFamily="body"
                          placeholder="e.g: Note for the order"
                          helperText={additionalNotesValidation}
                          value={additionalNotes}
                          onChangeText={(val) => {
                            setAdditionalNotes(val);
                          }}
                          onFocus={() => {
                            scrollviewRef.current?.scrollToEnd();
                            setAdditionalNotesValidation('');
                          }}
                          onBlur={() => {
                            if (
                              additionalNotes?.length &&
                              additionalNotes?.length < 10
                            ) {
                              setAdditionalNotesValidation(
                                'Please enter note with more than 10 characters',
                              );
                            }
                          }}
                          maxLength={ADDITIONAL_NOTES_MAX_CHARS}
                          allowLeadingSpace={false}
                          style={{
                            height: 87,
                            textAlignVertical: 'top',
                          }}
                        />
                      </View>
                    </View>
                  </ScrollView>
                  {/* {!tableNo && cart[0].posId && cart[0].restaurant.posId ? ( */}
                  {!tableNo ? (
                    <ThemeConsumer>
                      {(newTheme) => (
                        <ThemeProvider
                          theme={
                            newTheme.currentThemeMode === themeModes.light
                              ? getTheme(themeModes.dark)
                              : getTheme(themeModes.light)
                          }
                        >
                          <View
                            px="2xl"
                            py="lg"
                            bg="opacity.95"
                            // flex={1}
                            flexDirection="row"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Text size="md" weight="medium" mb="sm">
                              Add Table Number
                            </Text>
                            <Button
                              size="md"
                              state={
                                cart[0].restaurant?.openStatus?.status ===
                                'closed'
                                  ? 'disabled'
                                  : 'active'
                              }
                              onPress={() => {
                                firebaseEventLogger('OrderDetailScanCodePress');
                                navigation.navigate(
                                  'StaffBarcodeScannerScreen',
                                  {
                                    screenName: 'order',
                                    restaurant,
                                    restaurantName,
                                    hideManual: true,
                                  },
                                );
                              }}
                            >
                              Scan Code
                            </Button>
                          </View>
                        </ThemeProvider>
                      )}
                    </ThemeConsumer>
                  ) : null}
                  <View
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                    pt="2xl"
                    pb={
                      insets.bottom ? insets.bottom + theme.space['2xl'] : '7xl'
                    }
                    bg="background.primary.elevation"
                    borderTopWidth={1}
                    borderColor="grey.50"
                  >
                    <View flex={4} pl="4xl">
                      <Text size="lg" weight="medium" color="primary.500">
                        {cart.length && restaurant === cart[0].restaurant?._id
                          ? noOfItems > 1
                            ? `${noOfItems} Items Selected`
                            : `${noOfItems} Item Selected`
                          : ''}
                      </Text>
                      {/* <Text size="sm" color="primary.200">
                      {getCount() === 1
                        ? `${getCount()} Item`
                        : `${getCount()} Items`}
                    </Text> */}
                      <Text size="xs" color="primary.200">
                        {restaurantName}
                      </Text>
                    </View>
                    <View flex={4} pr="2xl">
                      <Button
                        iconLeft=""
                        iconRight=""
                        state={setBtnState()}
                        appearance="filled"
                        size="lg"
                        rounded="md"
                        onPress={orderHandler}
                      >
                        {loading ? <ActivityIndicator /> : 'Place Order'}
                      </Button>
                    </View>
                  </View>
                </>
              ) : (
                <View px="2xl">
                  <View mt="2xl" alignItems="center">
                    <Image
                      source={emptyCart}
                      style={{ width: 285, height: 285 }} // TODO: dynamic width height
                      resizeMode="contain"
                    />
                  </View>
                  <View alignItems="center">
                    <Text size="xl" weight="medium" color="primary.400">
                      Your Cart is Empty
                    </Text>
                    <Text
                      size="sm"
                      color="primary.100"
                      px="9xl"
                      mx="7xl"
                      textAlign="center"
                      mt="sm"
                    >
                      Looks like you haven’t added anything to your cart yet
                    </Text>
                  </View>
                  <Button
                    mt="9xl"
                    iconLeft=""
                    iconRight=""
                    state=""
                    appearance="filled"
                    size="md"
                    onPress={() => {
                      navigation.replace('menu', { restaurantId: restaurant });
                    }}
                  >
                    Browse Menu
                  </Button>
                </View>
              )}
            </View>
          </Layout>
        ) : (
          <View
            flex={1}
            alignItems="center"
            justifyContent="center"
            bg={theme.colors.background.primary.base}
            px="4xl"
          >
            <Image source={checkGif} style={{ width: 200, height: 200 }} />
            <Text size="2xl" color="primary.500" mt="9xl" weight="medium">
              Order Placed!
            </Text>
            <Text size="md" color="primary.200" mt="lg" textAlign="center">
              Your food is being prepared and will be served shortly
            </Text>
          </View>
        )}
        <VariationsBottomSheet
          ref={bottomSheetModalRef}
          ctaText="Update Item"
          removeItem={false}
          selectedMenuItem={selectedMenuItem}
          setSelectedMenuItem={setSelectedMenuItem}
          AddButton={AddButton}
          setRemoveItem={() => {}}
        />
      </View>
    </TouchableWithoutFeedback>
  );
};

export default OrderScreen;
