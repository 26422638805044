/* eslint-disable import/prefer-default-export */
import { graphql } from 'react-relay';

export const supervisorOverviewTasksFragment = graphql`
  fragment supervisorOverviewTasksFragment on Query
  @refetchable(queryName: "SupervisorOverviewTaskQuery")
  @argumentDefinitions(
    taskFilter: { type: "findTaskFilterInput!" }
    sort: { type: "SortConnectiontaskEnum" }
    count: { type: "Int" }
    cursor: { type: "String" }
  ) {
    findTasks: findTasksV2(
      filter: $taskFilter
      sort: $sort
      first: $count
      after: $cursor
    ) @connection(key: "SupervisorOverviewTaskFragment_findTasks") {
      edges {
        node {
          taskType
          vehicle {
            brand {
              name
            }
            registration {
              plate
            }
          }
          history {
            action
            event_at
          }
          valet {
            identityCode
          }
          metrics {
            isWithinETA
            isParkingDelayed
            isOnbardingDelayed
            isAssignmentDelayed
            assignedIn
            taskOnboardeIn
            taskCompletionIn
            valetTookInCompletion
          }
          taskStartLocation {
            name
          }
          taskEndLocation {
            name
          }
          parkingTag
        }
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`;
