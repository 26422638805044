import React from 'react';
import { Platform } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { BlurView, Button, Text, View } from '../../../../../components/new';
import MembershipBenefitStrip from './MembershipBenefitsStrip';
import { usePayBillStore } from '../hooks/usePayBillStore';
import useUserStore from '../../../../../stores/userStore';
import { InputStates } from '../../../../../components/new/primitive/Input/helpers';
import { toRupees } from '../../../../../utilities/helper';
import { firebaseEventLogger } from '../../../../../utilities/firbaseAnalytics';

interface PayBillFooterProps {
  onPay: () => void;
  payDisabled: boolean;
}

const PayBillFooter = ({ onPay, payDisabled }: PayBillFooterProps) => {
  const insets = useSafeAreaInsets();
  const isCustomerBlackMember = useUserStore(
    (state) => state.blackMembership?.active,
  );
  const { title, orderType, orderId, priceDetails } = usePayBillStore(
    (state) => state,
  );
  const { grossAmount, netAmount, totalCashbackAmount } = priceDetails;

  return (
    <View position="absolute" bottom={0} left={0} right={0}>
      <View>
        <MembershipBenefitStrip
          cashback={totalCashbackAmount} // points cashback + coupon cashback
          fallbackContent={
            isCustomerBlackMember && Platform.OS === 'web'
              ? 'Get 10% cashback if you pay via 32nd Club app!'
              : ''
          }
        />
        <BlurView level="4">
          <View pb={insets.bottom} bg="background.primary.elevation">
            <View>
              <View
                flexDirection="row"
                p="2xl"
                borderTopWidth="xs"
                borderColor="primary.50"
              >
                <View flex={1}>
                  <View flexDirection="row">
                    {!!(netAmount < grossAmount) && (
                      <View justifyContent="center">
                        <Text
                          size="md"
                          weight="medium"
                          color="primary.100"
                          style={{
                            textDecorationLine: 'line-through',
                          }}
                        >
                          {toRupees(grossAmount)}
                        </Text>
                      </View>
                    )}
                    <View ml="sm">
                      <Text size="xl" weight="medium" color="primary.500">
                        {toRupees(netAmount)}
                      </Text>
                    </View>
                  </View>
                  <Text size="xs" color="primary.200">
                    Payable Amount
                  </Text>
                </View>
                <View flex={1}>
                  <Button
                    state={
                      payDisabled ? InputStates.disabled : InputStates.active
                    }
                    size="lg"
                    onPress={() => {
                      firebaseEventLogger(
                        'paymentAmountScreen__payNowClicked_Tap',
                        {
                          orderType,
                          id: orderId,
                          name: title,
                        },
                      );
                      onPay();
                    }}
                  >
                    Pay Now
                  </Button>
                </View>
              </View>
            </View>
          </View>
        </BlurView>
      </View>
    </View>
  );
};

export default PayBillFooter;
