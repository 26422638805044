import { firebaseEventLogger } from '../../../../utilities/firbaseAnalytics';
import { paymentOrderTypeEnum } from '../../../../utilities/helper';

const payBillAnalytics = {
  landed: (
    title: string,
    orderType: paymentOrderTypeEnum | string,
    orderId: string,
  ) => {
    firebaseEventLogger('paymentAmountScreen__land_Landing', {
      orderType,
      id: orderId,
      name: title,
    });
  },
  blackMembershipAddedToCart: (
    title: string,
    orderType: paymentOrderTypeEnum | string,
    orderId: string,
  ) => {
    firebaseEventLogger('paymentAmountScreen__bmAddTocart_Tap', {
      orderType,
      id: orderId,
      name: title,
    });
  },
  memberBenefitsClicked: (
    title: string,
    orderType: paymentOrderTypeEnum | string,
    orderId: string,
  ) => {
    firebaseEventLogger('paymentAmountScreen__viewBmBenefits_Tap', {
      orderType,
      id: orderId,
      name: title,
    });
  },
  payWithPointsToggled: (
    title: string,
    orderType: paymentOrderTypeEnum | string,
    orderId: string,
    applied: boolean,
  ) => {
    firebaseEventLogger('paymentAmountScreen__payWithPoints_Toggle', {
      orderType,
      id: orderId,
      name: title,
      applied,
    });
  },
  // app/play store
  downloadNowClicked: (
    title: string,
    orderType: paymentOrderTypeEnum | string,
    orderId: string,
  ) => {
    firebaseEventLogger('paymentAmountScreen__downloadNow_Tap', {
      orderType,
      id: orderId,
      name: title,
    });
  },
  continueWithoutPointsClicked: (
    title: string,
    orderType: paymentOrderTypeEnum | string,
    orderId: string,
  ) => {
    firebaseEventLogger('paymentAmountScreen__withoutPoints_Tap', {
      orderType,
      id: orderId,
      name: title,
    });
  },
  viewBreakUpClicked: (
    title: string,
    orderType: paymentOrderTypeEnum | string,
    orderId: string,
  ) => {
    firebaseEventLogger('paymentAmountScreen__viewBreakup_Tap', {
      orderType,
      id: orderId,
      name: title,
    });
  },
  editAmountClicked: (
    title: string,
    orderType: paymentOrderTypeEnum | string,
    orderId: string,
  ) => {
    firebaseEventLogger('PaymentScreenEditAmountClicked', {
      orderType,
      id: orderId,
      name: title,
    });
  },
  freeCancellationBannerClicked: (
    title: string,
    orderType: paymentOrderTypeEnum | string,
    orderId: string,
  ) => {
    firebaseEventLogger('paymentAmountScreen__freeCancellation_Tap', {
      orderType,
      id: orderId,
      name: title,
    });
  },
  donationCheckBoxClicked: (
    title: string,
    orderType: paymentOrderTypeEnum | string,
    orderId: string,
    applied: boolean,
  ) => {
    firebaseEventLogger('paymentAmountScreen__donate_checkbox', {
      orderType,
      id: orderId,
      name: title,
      applied,
    });
  },
  couponRemoved: (
    title: string,
    orderType: paymentOrderTypeEnum | string,
    orderId: string,
  ) => {
    firebaseEventLogger('paymentAmountScreen__removeCoupon_Tap', {
      orderType,
      id: orderId,
      name: title,
    });
  },
  allCouponsViewed: (
    title: string,
    orderType: paymentOrderTypeEnum | string,
    orderId: string,
  ) => {
    firebaseEventLogger('paymentAmountScreen__viewAllCoupons_Tap', {
      orderType,
      id: orderId,
      name: title,
    });
  },

  blackMembershipRemovedFromCart: (
    title: string,
    orderType: paymentOrderTypeEnum | string,
    orderId: string,
  ) => {
    firebaseEventLogger('paymentAmountScreen__bmARemoveFromCart_Tap', {
      orderType,
      id: orderId,
      name: title,
    });
  },
  payWithoutMembershipClicked: (
    title: string,
    orderType: paymentOrderTypeEnum | string,
    orderId: string,
  ) => {
    firebaseEventLogger('paymentAmountScreen__payWithoutMembership_Tap', {
      orderType,
      id: orderId,
      name: title,
    });
  },
  blackMembershipBenefitsAwesomeClicked: (
    title: string,
    orderType: paymentOrderTypeEnum | string,
    orderId: string,
  ) => {
    firebaseEventLogger('paymentAmountScreen__benefitsAwesome_Tap', {
      orderType,
      id: orderId,
      name: title,
    });
  },
  tipAdded: (
    title: string,
    orderType: paymentOrderTypeEnum | string,
    orderId: string,
    amount: number,
  ) => {
    firebaseEventLogger('paymentAmountScreen__tipAdded_Tap', {
      orderType,
      id: orderId,
      name: title,
      tipAamount: amount,
    });
  },
  payNowClicked: (
    title: string,
    orderType: paymentOrderTypeEnum | string,
    orderId: string,
  ) => {
    firebaseEventLogger('paymentAmountScreen__payNowClicked_Tap', {
      orderType,
      id: orderId,
      name: title,
    });
  },
};

export default payBillAnalytics;
