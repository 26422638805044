import React, { useMemo, useState } from 'react';
import { FlatList } from 'react-native-gesture-handler';
import { Chip, Text, View } from '../../../../../components/new';
import { usePayBillStore } from '../hooks/usePayBillStore';
import { toRupees } from '../../../../../utilities/helper';
import { Appearance, State } from '../../../../../themes/new/helper';
import { TipWidgetConfig } from '../types';
import { resolveTipWidgetConfig } from '../helpers';
import payBillAnalytics from '../analytics';

const TipItem = ({ item, selected, index, onTipSelect }) => {
  return (
    <Chip
      key={`TIP_REAS0N_${item?.price}`}
      state={selected ? State.SELECTED : State.ACTIVE}
      appearance={Appearance.FILLED}
      onPress={() => onTipSelect(index)}
      size="xs"
      mr="sm+md"
      mb="xl"
      isUpperCase={false}
    >
      {toRupees(item?.price)}
    </Chip>
  );
};

interface TipWidgetProps {
  onSelect: (tipAmount: number) => void;
}

const TipWidget = ({ onSelect }: TipWidgetProps) => {
  const { title, orderType, orderId, config } = usePayBillStore(
    (state) => state,
  );
  const tipWidgetConfig: TipWidgetConfig | undefined = useMemo(
    () => resolveTipWidgetConfig(orderType),
    [orderType],
  );

  const [selectedTipIndex, setSelectedTipIndex] = useState(
    tipWidgetConfig?.defaultIdx ?? 0,
  );

  const handleTipSelection = async (idx: number) => {
    const tipAmount = tipWidgetConfig?.values[idx]?.price;
    payBillAnalytics.tipAdded(title, orderType, orderId, tipAmount);
    setSelectedTipIndex(idx);
    onSelect(tipAmount);
  };

  if (!config?.tipEnabled || !tipWidgetConfig) return null;

  return (
    <View>
      <View mb="3xl">
        <Text size="md" color="primary.200" weight="medium">
          {tipWidgetConfig?.label ?? ''}
        </Text>
        <View flexDirection="row" mt="3xl">
          <FlatList
            horizontal
            showsHorizontalScrollIndicator={false}
            data={tipWidgetConfig?.values ?? []}
            renderItem={({ item, index }) => {
              return (
                <TipItem
                  key={`TIP_REAS0N_${item?.price}`}
                  item={item}
                  index={index}
                  selected={index === selectedTipIndex}
                  onTipSelect={handleTipSelection}
                />
              );
            }}
          />
        </View>
      </View>
    </View>
  );
};

export default TipWidget;
