import React from 'react';
import {
  Placeholder,
  Fade,
  PlaceholderLine,
  PlaceholderMedia,
} from 'rn-placeholder';
import { View } from '../../../../components/new';

const EventsHomePlaceholder = () => {
  return (
    <Placeholder Animation={Fade}>
      <View>
        <View shadow="sm">
          <PlaceholderMedia style={{ width: '100%', height: 400 }} />
        </View>
        <View
          bg="static.white"
          shadow="sm"
          borderRadius="md"
          p="2xl"
          style={{
            flexDirection: 'column',
            top: -40,
          }}
        >
          <PlaceholderLine
            width={80}
            height={30}
            style={{ marginBottom: 20, marginTop: 20 }}
          />
          <PlaceholderLine
            width={100}
            height={10}
            style={{ marginBottom: 12 }}
          />
          <PlaceholderLine
            width={40}
            height={10}
            style={{ marginBottom: 28 }}
          />
          <PlaceholderLine
            width={60}
            height={20}
            style={{ marginBottom: 12, marginTop: 16 }}
          />
          <PlaceholderLine
            width={40}
            height={20}
            style={{ marginBottom: 12, marginTop: 16 }}
          />
          <View
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <PlaceholderLine
              width={60}
              height={20}
              style={{ marginBottom: 12, marginTop: 16 }}
            />
            <PlaceholderLine
              width={20}
              height={30}
              style={{ marginBottom: 12, marginTop: 16 }}
            />
          </View>
          <PlaceholderLine
            width={80}
            height={20}
            style={{ marginBottom: 20, marginTop: 20 }}
          />
          <View flexDirection="row" alignItems="center">
            <PlaceholderLine
              width={60}
              height={120}
              style={{ marginBottom: 12, marginTop: 16 }}
            />
            <PlaceholderLine
              width={60}
              height={120}
              style={{ marginBottom: 12, marginTop: 16, marginLeft: 16 }}
            />
          </View>
        </View>
      </View>
    </Placeholder>
  );
};

export default EventsHomePlaceholder;
