import React, { PropsWithChildren } from 'react';
import View from '../../basic/View/View';

type PlaceholderBaseViewProps = React.ComponentProps<typeof View>;

const PlaceholderBaseView = ({
  children,
  ...restProps
}: PropsWithChildren<PlaceholderBaseViewProps>) => {
  return (
    <View {...restProps} bg="primary.40">
      {children}
    </View>
  );
};

export default PlaceholderBaseView;
