import React from 'react';
import { Platform } from 'react-native';
import { usePayBillStore } from '../hooks/usePayBillStore';
import PayWithPointsComp from '../../PayWithPointsComp';
import { paymentOrderTypeEnum } from '../../../../../utilities/helper';
import { View } from '../../../../../components/new';

const PayBillPointsWidget = ({
  onToggle,
}: {
  onToggle: (usePoints: boolean, toggleDisabled: boolean) => void;
}) => {
  const toggleDisabled = Platform.OS === 'web';
  const { priceDetails, orderType, config, pointsApplied, setPointsApplied } =
    usePayBillStore((state) => state);
  const { usablePointsAmount } = priceDetails;
  const mt =
    orderType === paymentOrderTypeEnum.INVESTMENT_LEAD ? '2xl' : 'none';

  if (!config?.pointsEnabled || usablePointsAmount <= 0) return null;

  return (
    <View mb="4xl" mt={mt}>
      <PayWithPointsComp
        rewardsAmountCanUsed={usablePointsAmount}
        payWithPointsState={pointsApplied}
        setPayWithPointsState={(value: boolean) => {
          if (toggleDisabled) {
            onToggle(false, toggleDisabled);
          } else {
            setPointsApplied(value);
            onToggle(value, toggleDisabled);
          }
        }}
      />
    </View>
  );
};

export default PayBillPointsWidget;
