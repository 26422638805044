import React from 'react';
import PriceRow from './PriceRow';
import { View } from '../../../../components/new';
import { toRupees } from '../../../../utilities/helper';

interface EventBillBreakUpProps {
  slotId: string;
  ticketId: string;
  noOfSlots: number;
  billAmount: number;
  sourceDetails: {
    event: {
      eventTiming: {
        _id: string;
        slot: {
          availableTickets: {
            price: number;
            basePrice: number;
            personAllowed: number;
          }[];
        };
      }[];
    };
  };
}

const getTicketPriceBreakUp = (availableTickets, ticketId, noOfSlots) => {
  const priceBreakups = [];
  const avT = availableTickets?.find((avt) => avt?._id === ticketId);
  if (avT) {
    const total = noOfSlots * avT?.price;
    priceBreakups.push({
      perPrice: avT?.price,
      total,
      count: noOfSlots,
    });
  }

  return priceBreakups;
};

const EventBillBreakUp = ({
  slotId,
  ticketId,
  noOfSlots,
  sourceDetails,
  billAmount,
}: EventBillBreakUpProps) => {
  const timings = sourceDetails?.event?.eventTiming;

  if (!slotId || !timings?.length) return null;

  const slot = timings.find((t) => {
    return t?._id === slotId;
  });
  if (!slot) return null;
  const priceBreakup = getTicketPriceBreakUp(
    slot?.slot?.availableTickets,
    ticketId,
    noOfSlots,
  );

  if (!priceBreakup?.length) return null;

  return (
    <View>
      {priceBreakup.map((pb) => {
        return (
          <View mb="2xl">
            <PriceRow
              leftContent={`${toRupees(pb?.perPrice)} x ${pb?.count} ${
                pb?.count > 1 ? 'tickets' : 'ticket'
              }`}
              rightContent={toRupees(pb?.total)}
            />
          </View>
        );
      })}
      <PriceRow
        leftContent="Bill Amount"
        rightContent={toRupees(billAmount)}
        emphasized
      />
    </View>
  );
};

export default EventBillBreakUp;
