/* eslint-disable import/prefer-default-export */
import { graphql } from 'react-relay';

export const supervisorOverviewParentQuery = graphql`
  query supervisorOverviewParentQuery(
    $startTimeStamp: Int!
    $endTimeStamp: Int!
    $parkingLocationFilter: findParkingLocationFilterInput!
    $taskFilter: findTaskFilterInput!
    $sortTasks: SortConnectiontaskEnum
  ) {
    getTaskMetricsDateWise(
      startTimeStamp: $startTimeStamp
      endTimeStamp: $endTimeStamp
    ) {
      metrics {
        date
        assignmentTime
        assignmentScore
        deliveryTime
        deliveryScore
        parkingTime
        parkingScore
        onboardingTime
        onboardingScore
      }
    }
    findParkingLocation(filter: $parkingLocationFilter) {
      ...searchParkingList_findParkingLocation
    }
    ...supervisorOverviewTasksFragment
      @arguments(taskFilter: $taskFilter, sort: $sortTasks, count: 15)
  }
`;
