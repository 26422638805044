/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFocusEffect } from '@react-navigation/native';
import { usePaginationFragment } from 'react-relay';
import { taskFilter as tFilter, filterComponentConfig } from '../constants';
import { supervisorOverviewTasksFragment } from '../API/supervisorOverviewTasksFragment';
import { buildTasksData, getFilteredTasks } from '../utils';
import { usePagedTasks } from '../../../../hooks/usePagedTasks';

const useTaskFilter = (
  selectedDate,
  tasksFragment,
  defaultTaskType,
  isToday,
) => {
  /** referencing original task filter from parent query fragment */
  const queryFilter = tasksFragment?.__fragmentOwner?.variables?.taskFilter;
  const [taskType, setTaskType] = useState(defaultTaskType);
  const [taskFilter, setTaskFilter] = useState(tFilter.DELAYED);
  const [taskData, setTaskData] = useState(null);

  const { tasks, refechTasks, hasMoreTasks, fetchMoreTasks } = usePagedTasks(
    supervisorOverviewTasksFragment,
    tasksFragment,
  );

  /** we reset the dropdown back to default on tab change, no matter what last value was */
  useFocusEffect(
    useCallback(() => {
      setTaskType(defaultTaskType);
    }, [defaultTaskType]),
  );

  /** handling fetched tasks */
  useFocusEffect(
    useCallback(() => {
      if (tasks) {
        // tasks mapped to Widget view model
        const tasksData = tasks.map((t) => buildTasksData(t));
        setTaskData(tasksData);
      }
    }, [tasks]),
  );

  /**
   * Refetch fragment on date change, taskType change
   */
  useEffect(() => {
    if (selectedDate && queryFilter) {
      refechTasks(
        {
          taskFilter: {
            ...queryFilter,
            taskType,
            created_at: selectedDate,
          },
        },
        {
          /** current days task keep on updating, hence network-only */
          fetchPolicy: isToday ? 'network-only' : 'store-or-network',
        },
      );
    }
  }, [selectedDate, queryFilter, taskType, isToday]);

  /** tasks get filtered everytime `taskFilter` changes */
  const filteredTasks = useMemo(() => {
    if (taskData) {
      return getFilteredTasks(taskFilter, taskData);
    }
    return [];
  }, [taskData, taskFilter]);

  const noTasksMessage =
    taskFilter === tFilter.ALL
      ? 'No tasks are available'
      : taskFilter === tFilter.DELAYED
      ? 'No delayed tasks are available'
      : taskFilter === tFilter.ON_TIME
      ? 'No on-time tasks are available'
      : '';

  const handleTaskfilterSelection = (idx: number) => {
    const filterSelected = filterComponentConfig.find((f) => f.id === idx).name;
    setTaskFilter(filterSelected);
  };

  const handleTaskTypeSelection = (selectedTaskType: string) => {
    setTaskType(selectedTaskType);
  };
  return {
    selectedTaskType: taskType,
    selectedTaskFilter: taskFilter,
    filteredTasks,
    noTasksMessage,
    fetchMoreTasks: (count = 15) => fetchMoreTasks(count),
    hasMoreTasks,
    handleTaskfilterSelection,
    handleTaskTypeSelection,
  };
};

export default useTaskFilter;
