/* eslint-disable no-fallthrough */
import { normalizeToNumberOrZero } from '../../../../../utilities/Utility';
import { InteractionType } from '../../../../../utilities/firbaseAnalytics';
import {
  paymentOrderTypeEnum,
  toRupees,
} from '../../../../../utilities/helper';
import { RestaurantsFilterNames } from '../../../resturants/resturantsData';
import { blackMembershipProfileQueryPaymentStatsFragment$data as StatsData } from './API/__generated__/blackMembershipProfileQueryPaymentStatsFragment.graphql';

export enum iconNameEnum {
  CAR = 'CAR',
  CALENDAR = 'CALENDAR',
  MOBILE = 'MOBILE',
  CUP = 'CUP',
  CONFETTI = 'CONFETTI',
}

const savingsListItemMapping = {
  'DINING_&_SHOPPING': {
    icon: 'dining-outline',
    title: 'Dining & Shopping',
    displayOrder: 1,
  },
  [paymentOrderTypeEnum.PARKING]: {
    icon: 'car-outline-300',
    title: 'Parking',
    displayOrder: 2,
  },
  [paymentOrderTypeEnum.EVENT]: {
    icon: 'calendar-outline-300',
    title: 'Experiences',
    displayOrder: 3,
  },
  OTHERS: {
    icon: 'calendar-outline-300',
    title: 'Others',
    displayOrder: 4,
  },
};

export type SavingsListItemData = {
  title: string;
  icon: string;
  subTitle: string;
  info:
    | string
    | {
        label: string;
        navLink: string;
        navParams?: Record<string, any>;
        eventInfo?: {
          name: string;
          screen: string;
          buttonName: string;
          interactionType: InteractionType;
        };
      };
  sortOrder?: number;
};

export type BenefitsListItemData = Omit<SavingsListItemData, 'sortOrder'>;

export const generateSavingsListData = (
  stats: StatsData,
  eventDiscount: number,
) => {
  const diningAndShoppingStat = {
    invoiceCount: 0,
    saved: 0,
  };
  const othersStats = {
    invoiceCount: 0,
    saved: 0,
  };
  const listData: SavingsListItemData[] = [];

  stats?.breakup?.forEach((stat) => {
    if (
      typeof stat?.invoiceCount === 'number' &&
      typeof stat?.saved === 'number'
    ) {
      switch (stat?.orderType) {
        case paymentOrderTypeEnum.PARKING:
        case paymentOrderTypeEnum.EVENT: {
          listData.push({
            title: savingsListItemMapping[stat?.orderType].title,
            icon: savingsListItemMapping[stat?.orderType].icon,
            subTitle: `Used ${stat?.invoiceCount} ${
              stat?.invoiceCount === 1 ? 'time' : 'times'
            }`,
            info: toRupees(normalizeToNumberOrZero(stat?.saved)),
            sortOrder: savingsListItemMapping[stat?.orderType].displayOrder,
          });
          break;
        }
        case paymentOrderTypeEnum.foorOrder:
        case paymentOrderTypeEnum.store:
        case paymentOrderTypeEnum.restaurant: {
          diningAndShoppingStat.invoiceCount += stat?.invoiceCount ?? 0;
          diningAndShoppingStat.saved += stat?.saved ?? 0;
          break;
        }
        default: {
          othersStats.invoiceCount += stat?.invoiceCount ?? 0;
          othersStats.saved += stat?.saved ?? 0;
        }
      }
    }
  });

  if (
    diningAndShoppingStat.invoiceCount > 0 &&
    diningAndShoppingStat.saved > 0
  ) {
    const config = savingsListItemMapping['DINING_&_SHOPPING'];
    listData.push({
      title: config.title,
      icon: config.icon,
      subTitle: `Used ${diningAndShoppingStat?.invoiceCount} times`,
      info: toRupees(normalizeToNumberOrZero(diningAndShoppingStat?.saved)),
      sortOrder: config.displayOrder,
    });
  }

  if (othersStats.invoiceCount > 0 && othersStats.saved > 0) {
    const config = savingsListItemMapping.OTHERS;
    listData.push({
      title: config.title,
      icon: config.icon,
      subTitle: `Used ${othersStats?.invoiceCount} times`,
      info: toRupees(normalizeToNumberOrZero(othersStats?.saved)),
      sortOrder: config.displayOrder,
    });
  }
  listData.sort((a, b) => a.sortOrder - b.sortOrder);
  const noDiningShoppingSavings = !listData.find(
    (d) => d.title === savingsListItemMapping['DINING_&_SHOPPING'].title,
  );
  const noEventsSavings = !listData.find(
    (d) => d.title === savingsListItemMapping[paymentOrderTypeEnum.EVENT].title,
  );

  // if Dining/Shopping savings are not present, then add item for discovery with CTA
  if (noDiningShoppingSavings) {
    const config = savingsListItemMapping['DINING_&_SHOPPING'];
    listData.push({
      title: config.title,
      icon: config.icon,
      subTitle: 'Upto 50% off',
      info: {
        label: 'Pay Now',
        navLink: 'user',
        navParams: { screen: 'dineIn' },
      },
    });
  }
  // if Events savings are not present, then add item for discovery with CTA
  if (noEventsSavings) {
    const config = savingsListItemMapping[paymentOrderTypeEnum.EVENT];
    listData.push({
      title: config.title,
      icon: config.icon,
      subTitle: `Flat ${eventDiscount}% off`,
      info: {
        label: 'Book Now',
        navLink: 'user',
        navParams: { screen: 'events' },
      },
    });
  }

  return listData;
};

export const generateBenefitsListData = (
  isBlackMember: boolean,
  parkingDiscount: number,
  eventsDiscount: number,
): BenefitsListItemData[] => {
  return [
    {
      title: 'Valet Parking',
      icon: iconNameEnum.CAR,
      subTitle: `Flat ${parkingDiscount ?? 0}% off on parking fee`,
      info: 'When you pay via 32nd Club app',
    },
    {
      title: 'Exclusive Access',
      icon: iconNameEnum.CONFETTI,
      subTitle: 'Member-only events',
      info: 'On occasions like Diwali, Christmas, Restaurant Launches, and more',
    },
    {
      title: 'Experiences',
      icon: iconNameEnum.CALENDAR,
      subTitle: `Flat ${eventsDiscount ?? 0}% off on Experiences`,
      info: isBlackMember
        ? {
            label: 'Book Now',
            navLink: 'user',
            navParams: { screen: 'events' },
            eventInfo: {
              name: 'blackMembershipScreen__bookEvent_Tap',
              screen: 'plusMembershipScreen',
              buttonName: 'bookEvent',
              interactionType: InteractionType.TAP,
            },
          }
        : {
            label: 'Check Out Experiences',
            navLink: 'user',
            navParams: { screen: 'events' },
            eventInfo: {
              name: 'blackMembershipScreen__checkEvents_Tap',
              screen: 'plusMembershipScreen',
              buttonName: 'checkEvents',
              interactionType: InteractionType.TAP,
            },
          },
    },
    {
      title: 'Dining & Shopping',
      icon: iconNameEnum.MOBILE,
      subTitle: 'Flat 10% cashback + Upto 25% off',
      info: isBlackMember
        ? {
            label: 'Pay Now',
            navLink: 'user',
            navParams: { screen: 'dineIn' },
            eventInfo: {
              name: 'blackMembershipScreen__payNow_Tap',
              screen: 'plusMembershipScreen',
              buttonName: 'payNow',
              interactionType: InteractionType.TAP,
            },
          }
        : {
            label: 'Check Out Offers',
            navLink: 'user',
            navParams: {
              screen: 'dineIn',
              params: { filter: RestaurantsFilterNames.CLUB_OFFERS },
            },
            eventInfo: {
              name: 'blackMembershipScreen__checkOffers_Tap',
              screen: 'plusMembershipScreen',
              buttonName: 'checkOffers',
              interactionType: InteractionType.TAP,
            },
          },
    },
    {
      title: 'Other',
      icon: iconNameEnum.CUP,
      subTitle: 'One complimentary coffee monthly',
      info: isBlackMember
        ? {
            label: 'Redeem Now',
            navLink: 'RewardsScreen',
            navParams: { showBackIcon: true },
            eventInfo: {
              name: 'blackMembershipScreen__redeemOffer_Tap',
              screen: 'plusMembershipScreen',
              buttonName: 'redeemOffer',
              interactionType: InteractionType.TAP,
            },
          }
        : 'At The Daily Brew inside The Vault',
    },
  ];
};
