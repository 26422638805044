/**
 * @generated SignedSource<<e81dc6f2f96ed4adf8410ea59f7cdbff>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EnumcollectionsTitle = "CAFES" | "DATE_NIGHT" | "FINE_DINING" | "OUTDOOR_SEATING" | "RECOMMENDED" | "SERVES_ALCOHOL" | "UNIQUE_DINEIN" | "%future added value";
export type Enumrestaurants2Amenities = "Bar_Area" | "Buffet" | "Catering" | "Charging_Point" | "Coat_Check" | "Dance_Floor" | "Delivery" | "Event_Space" | "Family_Play_Area" | "Fine_Dining" | "Karaoke" | "Kids_Menu" | "Late_Night_Dining" | "Live_Cooking_Stations" | "Live_Sports_Screening" | "Meeting_Rooms" | "On_Site_Parking" | "Outdoor_Barbecue" | "Outdoor_Coolers" | "Outdoor_Heaters" | "Outdoor_Seating" | "Patio" | "Photobooth" | "Private_Dining" | "Pure_Veg" | "Reservations" | "Rooftop_Dining" | "Serves_Alcohol" | "Smoking_Area" | "Takeout" | "Valet_Parking" | "Wheelchair_Accessible" | "Wifi" | "%future added value";
export type Enumrestaurants2Occasion = "celebration" | "corporate" | "date_night" | "reunion" | "%future added value";
export type Enumrestaurants2Type = "RESTAURANT" | "STORE" | "%future added value";
export type findCollectionsFilterInput = {
  restaurantId?: ReadonlyArray<any | null> | null;
  title?: ReadonlyArray<EnumcollectionsTitle | null> | null;
};
export type findRestaurantsFilterInput = {
  _id?: any | null;
  amenities?: ReadonlyArray<Enumrestaurants2Amenities | null> | null;
  campus: FilterFindManyrestaurants2CampusInput;
  cusines?: ReadonlyArray<string | null> | null;
  imageSection?: string | null;
  occasion?: ReadonlyArray<Enumrestaurants2Occasion | null> | null;
  openHours?: string | null;
  outdoorAndEventFlag?: boolean | null;
  outdoorSeating?: boolean | null;
  overAllRating?: number | null;
  ownerId?: any | null;
  pureVeg?: boolean | null;
  search?: findRestaurantsFilterInputSearch | null;
  serveAlcohol?: boolean | null;
  type?: Enumrestaurants2Type | null;
  withOffer?: boolean | null;
};
export type FilterFindManyrestaurants2CampusInput = {
  _id: any;
};
export type findRestaurantsFilterInputSearch = {
  restaurantTitle?: string | null;
};
export type homeScreenLogOutQuery$variables = {
  filter: findCollectionsFilterInput;
  restaurantsOfferFilter: findRestaurantsFilterInput;
};
export type homeScreenLogOutQuery$data = {
  readonly findCollections: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"homeScreenLogOutQuery_collectionsFragment">;
  }>;
  readonly getAllHomePageBanners: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"homeScreenLogOutQuery_homeScreenBannerFragment">;
  } | null> | null;
  readonly offerRestaurants: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"homeScreenQuery_findRestaurantsFragment">;
  }>;
};
export type homeScreenLogOutQuery = {
  response: homeScreenLogOutQuery$data;
  variables: homeScreenLogOutQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "restaurantsOfferFilter"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "filter"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "restaurantsOfferFilter"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "restaurants2MediaGallery",
  "kind": "LinkedField",
  "name": "mediaGallery",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sectionTitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "restaurants2MediaGalleryMedia",
      "kind": "LinkedField",
      "name": "media",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "cover",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "mediaType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "mediaURL",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "homeScreenLogOutQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "collections",
        "kind": "LinkedField",
        "name": "findCollections",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "homeScreenLogOutQuery_collectionsFragment"
          }
        ],
        "storageKey": null
      },
      {
        "alias": "offerRestaurants",
        "args": (v2/*: any*/),
        "concreteType": "restaurants2",
        "kind": "LinkedField",
        "name": "findRestaurants2",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "homeScreenQuery_findRestaurantsFragment"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "HomePageBanner",
        "kind": "LinkedField",
        "name": "getAllHomePageBanners",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "homeScreenLogOutQuery_homeScreenBannerFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "homeScreenLogOutQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "collections",
        "kind": "LinkedField",
        "name": "findCollections",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "restaurants2",
            "kind": "LinkedField",
            "name": "restaurants",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "logo",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "overAllRating",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "restaurants2Description",
                "kind": "LinkedField",
                "name": "description",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "restaurants2DescriptionCusines",
                    "kind": "LinkedField",
                    "name": "cusines",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "offerRestaurants",
        "args": (v2/*: any*/),
        "concreteType": "restaurants2",
        "kind": "LinkedField",
        "name": "findRestaurants2",
        "plural": true,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "payBillCashback",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "payBillPercentage",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "HomePageBanner",
        "kind": "LinkedField",
        "name": "getAllHomePageBanners",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "imageLink",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "deepLink",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "59f8210677aebfefd16727c63d4e1fc4",
    "id": null,
    "metadata": {},
    "name": "homeScreenLogOutQuery",
    "operationKind": "query",
    "text": "query homeScreenLogOutQuery(\n  $filter: findCollectionsFilterInput!\n  $restaurantsOfferFilter: findRestaurantsFilterInput!\n) {\n  findCollections(filter: $filter) {\n    ...homeScreenLogOutQuery_collectionsFragment\n  }\n  offerRestaurants: findRestaurants2(filter: $restaurantsOfferFilter) {\n    ...homeScreenQuery_findRestaurantsFragment\n  }\n  getAllHomePageBanners {\n    ...homeScreenLogOutQuery_homeScreenBannerFragment\n  }\n}\n\nfragment homeScreenLogOutQuery_collectionsFragment on collections {\n  title\n  restaurants {\n    _id\n    displayName\n    logo\n    overAllRating\n    description {\n      cusines {\n        name\n      }\n    }\n    mediaGallery {\n      sectionTitle\n      media {\n        cover\n        mediaType\n        mediaURL\n      }\n    }\n  }\n}\n\nfragment homeScreenLogOutQuery_homeScreenBannerFragment on HomePageBanner {\n  imageLink\n  deepLink\n}\n\nfragment homeScreenQuery_findRestaurantsFragment on restaurants2 {\n  _id\n  type\n  displayName\n  mediaGallery {\n    sectionTitle\n    media {\n      cover\n      mediaType\n      mediaURL\n    }\n  }\n  payBillCashback\n  payBillPercentage\n}\n"
  }
};
})();

(node as any).hash = "aa1a591360a923f20ec4e9e8c9b13fa1";

export default node;
