import React from 'react';
import {
  Card,
  CheckBox,
  IconButton,
  Text,
  View,
} from '../../../../../components/new';
import { applyEllipsis, toRupees } from '../../../../../utilities/helper';
import { usePayBillStore } from '../hooks/usePayBillStore';
import { FontWeights, State } from '../../../../../themes/new/helper';
import { normalizeToNumberOrZero } from '../../../../../utilities/Utility';
import { PAYBILL_BS_MODES } from '../helpers';
import payBillAnalytics from '../analytics';

interface CharityWidgetProps {
  onSelect: (isBeingApplied: boolean, charityId: string) => void;
  showBottomSheet: (mode: PAYBILL_BS_MODES) => void;
}

const CharityWidget = ({ onSelect, showBottomSheet }: CharityWidgetProps) => {
  const {
    title,
    orderId,
    orderType,
    config,
    charityApplied,
    setCharityApplied,
  } = usePayBillStore((state) => state);
  const { charityConfig } = config ?? {};
  const { type, value } = charityConfig?.contribution ?? {};
  const charityAmount = type === 'FIXED' ? value : 0;

  const handleCharitySelection = async () => {
    const isBeingApplied = !charityApplied;
    payBillAnalytics.donationCheckBoxClicked(
      title,
      orderType,
      orderId,
      isBeingApplied,
    );
    setCharityApplied(!charityApplied);
    onSelect(isBeingApplied, config?.charityConfig?._id);
  };

  if (!config?.charityEnabled || type !== 'FIXED') return null;

  return (
    <View mb="9xl">
      <Card py="sm">
        <View>
          <View
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <View flexDirection="row" alignItems="center">
              <Text size="sm" weight={FontWeights.MEDIUM} color="primary.400">
                {`Donate to '${applyEllipsis(charityConfig?.title, 18)}'`}
              </Text>
              <IconButton
                pl="lg"
                name="info-outline-300"
                appearance="ghost"
                iconSize="md"
                iconColor="primary.500"
                onPress={() => showBottomSheet(PAYBILL_BS_MODES.CHARITY_INFO)}
              />
            </View>
            <CheckBox
              ml={0}
              flex={1}
              size="sm"
              checked={charityApplied}
              onPress={handleCharitySelection}
              state={State.ACTIVE}
            >
              {toRupees(normalizeToNumberOrZero(charityAmount))}
            </CheckBox>
          </View>
        </View>
      </Card>
    </View>
  );
};

export default CharityWidget;
