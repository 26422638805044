/* eslint-disable import/prefer-default-export */
import { useCallback, useRef, useState } from 'react';
import { ActionSheetRef } from 'react-native-actions-sheet';
import { PAYBILL_BS_MODES } from '../helpers';

export const usePayBillBottomSheet = () => {
  const bsRef = useRef<ActionSheetRef>(null);
  const [bsMode, setBsMode] = useState<PAYBILL_BS_MODES>(PAYBILL_BS_MODES.NONE);
  const bsPostDismissCbRef = useRef<{ onDismiss: () => void | null }>({
    onDismiss: null,
  });

  const hideBottomSheet = useCallback((onDismissCb?: () => void) => {
    if (typeof onDismissCb === 'function') {
      bsPostDismissCbRef.current.onDismiss = onDismissCb;
    }
    bsRef.current.hide();
  }, []);

  const showBottomSheet = useCallback((mode: PAYBILL_BS_MODES) => {
    if (mode) {
      setBsMode(mode);
      bsRef.current.show();
    } else {
      console.warn('No BS mode passed');
    }
  }, []);

  const handleBsClose = useCallback(() => {
    setBsMode(PAYBILL_BS_MODES.NONE);
    const innerFn = bsPostDismissCbRef?.current?.onDismiss;
    bsPostDismissCbRef.current.onDismiss = null;
    if (typeof innerFn === 'function') {
      innerFn();
    }
  }, []);

  return {
    bsRef,
    bsMode,
    hideBottomSheet,
    showBottomSheet,
    handleBsClose,
  };
};
