/**
 * @generated SignedSource<<72620c61540102b1f415edc2fb59e4c9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type findTaskQueryTaskFragment$data = {
  readonly findTasks: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly _id: any;
        readonly user: {
          readonly _id: any;
        } | null;
        readonly vehicle: {
          readonly brand: {
            readonly brandLogo: string | null;
            readonly name: string | null;
          } | null;
          readonly color: string | null;
          readonly registration: {
            readonly plate: string;
          } | null;
        } | null;
      };
    }>;
    readonly pageInfo: {
      readonly hasNextPage: boolean;
    };
  } | null;
  readonly " $fragmentType": "findTaskQueryTaskFragment";
};
export type findTaskQueryTaskFragment$key = {
  readonly " $data"?: findTaskQueryTaskFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"findTaskQueryTaskFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "findTaskQueryTaskFragment"
};

(node as any).hash = "a86194d2456d0f1e14f2ba03419036a9";

export default node;
