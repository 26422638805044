import React, {
  Suspense,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import {
  FlatList,
  Platform,
  Pressable,
  ScrollView,
  useWindowDimensions,
} from 'react-native';
import { useLazyLoadQuery, usePaginationFragment, graphql } from 'react-relay';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import Carousel from 'react-native-reanimated-carousel';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import {
  Gesture,
  GestureDetector,
  PanGesture,
} from 'react-native-gesture-handler';
import {
  Layout,
  View,
  TopNavigation,
  IconButton,
  Text,
  Card,
  Icon,
  Button,
} from '../../../components/new';
import Ticket, {
  TicketVariant,
} from '../../../components/new/composites/Ticket';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../../utilities/NewErrorBoundary';
import NewErrorView from '../../../utilities/NewErrorView';
import {
  Capitalise,
  DateFormatsEnum,
  TicketStatus,
  applyEllipsis,
  getFormatedDate,
  navigateBack,
} from '../../../utilities/helper';
import useUserStore from '../../../stores/userStore';
import Loading from '../../../components/loading.component';
import { useNodeFromPagination } from '../../../hooks/useNodeFromPagination';
import { getTheme } from '../../../themes/new/theme';
import CarouselDots from '../../../components/new/custom/Carousel/CarouselDots';
import LightThemeWrapper from '../wrapperComp/LightThemeWrapper';
import BottomSheetV2 from '../../../components/new/composites/BottomSheet/BottomSheetV2';
import { Appearance, State } from '../../../themes/new/helper';
import { eventAnalytics } from './analytics/eventAnalytics';

type EventVenueType = {
  displayName: string;
  venue: {
    geo: {
      lat: string;
      long: string;
    };
  };
};

const eventTicketScreenQuery = graphql`
  query EventTicketScreenQuery(
    $filter: FilterFindManyticketInput!
    $invoiceFilter: findInvoiceInput!
    $hasInvoiceId: Boolean = false
  ) {
    findInvoiceV2(filter: $invoiceFilter, first: 1, after: null)
      @include(if: $hasInvoiceId) {
      edges {
        node {
          orderId
        }
      }
    }
    ...EventTicketScreenQueryFragment @arguments(filter: $filter)
  }
`;

export const ticketQueryFragment = graphql`
  fragment EventTicketScreenQueryFragment on Query
  @refetchable(queryName: "EventTicketScreenTicketFragmentQuery")
  @argumentDefinitions(
    filter: { type: "FilterFindManyticketInput!" }
    count: { type: "Int" }
    cursor: { type: "String" }
  ) {
    tickets: findTickets(filter: $filter, first: $count, after: $cursor)
      @connection(key: "EventTicketScreenQueryFragment__tickets") {
      edges {
        node {
          _id
          ticketType
          coverImg
          thumbnailImg
          uId
          expires_on
          title
          issuedBy {
            refType
            _id
          }
          user {
            name {
              first
              last
            }
          }
          finePrint {
            summary
            details
          }
          status
          additionalFields {
            event {
              startTime
              endTime
              spaces
            }
          }
          canBeClaimedBy {
            venue {
              geo {
                lat
                long
              }
              addressLine1
            }
            displayName
          }
          rewardId
        }
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`;

const mapEventLocations = (venues: EventVenueType[]) => {
  return venues.map((v) => ({
    location: applyEllipsis(v.displayName, 20),
    lat: v?.venue?.geo?.lat,
    long: v?.venue?.geo?.long,
  }));
};

const mapTicketStatus = (status: string) => {
  switch (status) {
    case 'CANCELLED':
      return 'CANCELLED';
    case 'EXPIRED':
      return 'EXPIRED';
    case 'CLAIMED':
      return 'ALREADY_REDEEMED';
    default:
      return undefined;
  }
};

const EventTicketScreen = forwardRef(
  ({ variables, queryOptions, route, setEventId }, ref) => {
    const { invoiceId } = route?.params ?? {};
    const navigation = useNavigation();
    const bsRef = useRef(null);
    const { width, height } = useWindowDimensions();
    const [carouselHeight, setCarouselHeight] = useState(0);
    const [activeIndex, setActiveIndex] = useState(0);
    const { bottom } = useSafeAreaInsets();

    const { findInvoiceV2, ...ticketRef } = useLazyLoadQuery(
      eventTicketScreenQuery,
      variables,
      queryOptions,
    );

    const eventId = findInvoiceV2?.edges[0]?.node?.orderId;

    useEffect(() => {
      if (eventId) {
        setEventId(eventId);
      }
    }, [eventId, setEventId]);

    const { data } = usePaginationFragment(ticketQueryFragment, ticketRef);
    const Status = {
      ACTIVE: 1,
      CLAIMED: 2,
      CANCELLED: 3,
      EXPIRED: 4,
    };
    const sortByStatus = (a, b) => Status[a.status] - Status[b.status];
    const tickets = useNodeFromPagination(data.tickets).sort(sortByStatus);
    const ticket = tickets[0];

    let eventStartDate;
    let eventTime;
    let eventEndDate;
    let eventLocations;

    if (ticket) {
      eventStartDate = getFormatedDate(
        new Date(ticket?.additionalFields?.event?.startTime),
        DateFormatsEnum.DateMonthYear,
      );
      eventTime = getFormatedDate(
        new Date(ticket?.additionalFields?.event?.startTime),
        DateFormatsEnum.TIME,
      );
      eventEndDate = getFormatedDate(
        new Date(ticket?.expires_on),
        DateFormatsEnum.DateMonthYear,
      );
      eventLocations = mapEventLocations(ticket?.canBeClaimedBy);
    }
    const noOfPeople = parseFloat(ticket?.additionalFields?.event?.spaces);
    const { first, last } = ticket?.user?.name ?? {};
    let customerName = '';
    if (first?.length) {
      customerName = Capitalise(first.trim());
    }
    if (last?.length) {
      customerName = `${customerName} ${Capitalise(last.trim())}`;
    }

    let partnerTitle = '';
    if (eventLocations instanceof Array) {
      if (eventLocations?.length > 1) partnerTitle = 'Multiple Partners';
      if (eventLocations?.length === 1)
        partnerTitle = eventLocations[0]?.location;
    }

    useImperativeHandle(
      ref,
      () => {
        return {
          navigateToTncScreenFromParent: navigateToTnCScreen,
        };
      },
      [],
    );

    const navigateToTnCScreen = () => {
      const tncList = ticket?.finePrint?.details ?? [];
      if (tncList?.length <= 0) {
        return;
      }
      navigation.navigate('ticketTnC', { details: tncList });
    };

    const navigateToCancelBookingScreen = () => {
      eventAnalytics.ticketScreen.cancelBookingClicked(eventId);
      bsRef.current?.hide();
      navigation.navigate('eventCancellation', { invoiceId });
    };

    const handleScroll = (e) => {
      const { contentOffset } = e?.nativeEvent;
      const viewSize = e?.nativeEvent?.layoutMeasurement;
      // Divide the horizontal offset by the width of the view to see which page is visible
      const pageNum = Math.floor(contentOffset.x / viewSize.width);
      if (activeIndex !== pageNum) {
        setActiveIndex(pageNum);
      }
    };

    return (
      <>
        <View flex={1}>
          <ScrollView
            contentContainerStyle={{
              // flex: 1,
              paddingTop: getTheme().space['2xl'],
            }}
            showsVerticalScrollIndicator={false}
          >
            {Platform.OS !== 'web' ? (
              <View
                flex={1}
                minHeight={600}
                maxHeight={700}
                onLayout={(e) => {
                  const { height: h } = e?.nativeEvent?.layout;
                  setCarouselHeight(h);
                }}
              >
                <Carousel
                  width={width}
                  height={carouselHeight}
                  data={tickets}
                  scrollAnimationDuration={100}
                  enabled={tickets?.length > 1}
                  onSnapToItem={(index) => {
                    setActiveIndex(index);
                  }}
                  pagingEnabled
                  panGestureHandlerProps={{
                    activeOffsetX: [-10, 10],
                  }}
                  onConfigurePanGesture={(panGesture: PanGesture) => {
                    // within a ScrollView
                    panGesture.config.touchAction = 'pan-y'; // for web

                    // for iOS and Android
                    panGesture.activeOffsetX([-5, 5]);
                    panGesture.failOffsetY([-5, 5]);
                  }}
                  renderItem={({ index, item }) => (
                    <Ticket
                      cutoutCircleClr={
                        getTheme().colors.background.primary.base
                      }
                      variant={TicketVariant.EVENT}
                      customerName={
                        typeof noOfPeople === 'number' && noOfPeople > 1
                          ? `${applyEllipsis(customerName, 25)} +${
                              noOfPeople - 1
                            }`
                          : applyEllipsis(customerName, 25)
                      }
                      headerImage={item?.thumbnailImg}
                      headerTitle={item?.title}
                      ticketId={item?.uId.toString()}
                      eventDate={eventStartDate as string}
                      eventStartTime={eventTime as string}
                      expiryDate={eventEndDate as string}
                      redeemableAt={eventLocations}
                      state={mapTicketStatus(item?.status)}
                      baseWidth="100%"
                    />
                  )}
                />
              </View>
            ) : (
              <FlatList
                data={tickets}
                pagingEnabled
                scrollEnabled
                onScroll={handleScroll}
                renderItem={({ index, item }) => (
                  <Ticket
                    cutoutCircleClr={getTheme().colors.background.primary.base}
                    variant={TicketVariant.EVENT}
                    customerName={
                      typeof noOfPeople === 'number' && noOfPeople > 1
                        ? `${applyEllipsis(customerName, 25)} +${
                            noOfPeople - 1
                          }`
                        : applyEllipsis(customerName, 25)
                    }
                    headerImage={item?.thumbnailImg}
                    headerTitle={item?.title}
                    ticketId={item?.uId.toString()}
                    eventDate={eventStartDate as string}
                    eventStartTime={eventTime as string}
                    expiryDate={eventEndDate as string}
                    redeemableAt={eventLocations}
                    state={mapTicketStatus(item?.status)}
                    baseWidth={width}
                  />
                )}
                keyExtractor={(item) => item?._id}
                horizontal
                showsHorizontalScrollIndicator={false}
              />
            )}
            {tickets?.length > 1 && (
              <View
                m="2xl"
                mb="6xl+5xl"
                flexDirection=" row"
                justifyContent="center"
              >
                <CarouselDots
                  numberOfDots={tickets?.length}
                  activeIndex={activeIndex}
                />
              </View>
            )}
            <View height={100} />
          </ScrollView>
        </View>
        <View
          position="absolute"
          bottom={0}
          width="100%"
          bg="background.primary.base"
        >
          <View p="2xl">
            <Button
              size="md"
              state={State.ACTIVE}
              appearance={Appearance.FILLED}
              onPress={() => {
                eventAnalytics.ticketScreen.manageBookingClicked(eventId);
                bsRef?.current?.show();
              }}
              mb="2xl"
            >
              Manage Booking
            </Button>
          </View>
        </View>
        <BottomSheetV2
          indicatorStyle={{
            margin: 0,
            padding: 0,
            width: 0,
            height: 0,
          }}
          insets={false}
          ref={bsRef}
        >
          <View px="2xl">
            <View
              mb="4xl"
              pb="2xl"
              pt={Platform.OS === 'web' ? '2xl' : 'lg'}
              borderBottomWidth="xs"
              borderColor="primary.50"
              alignItems="center"
            >
              <Pressable onPress={navigateToCancelBookingScreen}>
                <Text size="lg" color="error.500">
                  Cancel Booking
                </Text>
              </Pressable>
            </View>
            <View mb="lg">
              <Button
                onPress={() => bsRef.current?.hide()}
                size="lg"
                appearance={Appearance.OUTLINE}
              >
                Go Back
              </Button>
            </View>
          </View>
        </BottomSheetV2>
      </>
    );
  },
);

const EventTicketScreenWrapper = ({ navigation, route }) => {
  const ref = useRef();
  const isFocused = useIsFocused();
  const { ticketId, backToHome, invoiceId, backToScreen } = route.params;
  const [eventId, setEventId] = useState('');
  const { userRole } = useUserStore((state) => ({
    userRole: state.role,
  }));

  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'network-only',
    });

  const refresh = useCallback(() => {
    setRefreshedQueryOptions((prev) => ({
      ...prev,
      fetchKey: (prev?.fetchKey || 0) + 1,
    }));
  }, [setRefreshedQueryOptions]);

  useEffect(() => {
    if (isFocused) {
      refresh();
    }
  }, [isFocused, refresh]);

  const variables = invoiceId
    ? {
        filter: { invoiceId },
        invoiceFilter: { _id: invoiceId },
        hasInvoiceId: true,
      }
    : { filter: { uId: parseInt(ticketId, 10) } };
  return (
    <LightThemeWrapper>
      <Layout level={2}>
        <TopNavigation
          title="Event Ticket"
          IconLeft={
            <IconButton
              name="back-outline-300"
              size="md"
              appearance="ghost"
              iconColor="primary.500"
              onPress={() => {
                eventAnalytics.ticketScreen.backClicked(eventId);
                if (typeof backToScreen === 'string' && backToScreen?.length) {
                  navigation.navigate('user', {
                    screen: backToScreen,
                  });
                } else if (backToHome) {
                  navigation.popToTop();
                } else {
                  navigateBack(navigation, userRole);
                }
              }}
            />
          }
          IconRight={
            <IconButton
              name="menu-dots-400"
              size="md"
              appearance="ghost"
              onPress={() => {
                if (
                  typeof ref.current?.navigateToTncScreenFromParent ===
                  'function'
                ) {
                  ref.current?.navigateToTncScreenFromParent();
                }
              }}
            />
          }
        />

        <NewErrorBoundary
          // fetchKey={refreshedQueryOptions.fetchKey}
          fallback={
            <NewErrorView
              errorMsg="Sorry something went wrong"
              // reload={refresh}
            />
          }
        >
          <Suspense
            fallback={
              <Loading />
              // <ShimmerPlaceholder height={height - (tabBarHeight + 70)} />
            }
          >
            <EventTicketScreen
              ref={ref}
              variables={variables}
              queryOptions={refreshedQueryOptions}
              route={route}
              setEventId={setEventId}
            />
          </Suspense>
        </NewErrorBoundary>
      </Layout>
    </LightThemeWrapper>
  );
};

export default EventTicketScreenWrapper;
