/**
 * @generated SignedSource<<b07ae26f8d23d5fb8cbfb4c355a20387>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EnumtaskHistoryAction = "abort" | "accepted" | "arrived" | "assigned" | "cancelled" | "completed" | "created" | "exited" | "incomplete" | "onboarded" | "started" | "%future added value";
export type EnumtaskTaskType = "park" | "recall" | "repark" | "%future added value";
export type findTaskFilterInput = {
  _id?: any | null;
  assignedTo?: any | null;
  campus: FilterFindManytaskCampusInput;
  created_at?: string | null;
  history?: ReadonlyArray<FilterFindManytaskHistoryInput | null> | null;
  metrics?: FilterFindManytaskMetricsInput | null;
  ownerId?: any | null;
  search?: findTaskFilterInputSearch | null;
  taskEndLocation?: FilterFindManytaskTaskEndLocationInput | null;
  taskStartLocation?: FilterFindManytaskTaskStartLocationInput | null;
  taskType?: EnumtaskTaskType | null;
};
export type FilterFindManytaskHistoryInput = {
  action?: EnumtaskHistoryAction | null;
};
export type FilterFindManytaskTaskStartLocationInput = {
  _id?: any | null;
  locationId: any;
};
export type FilterFindManytaskTaskEndLocationInput = {
  _id?: any | null;
  locationId: any;
};
export type FilterFindManytaskCampusInput = {
  _id: any;
};
export type FilterFindManytaskMetricsInput = {
  _id?: any | null;
  acceptedIn?: number | null;
  assignedIn?: number | null;
  createToArrivedIn?: number | null;
  createdAt?: any | null;
  isAcceptedDelayed?: boolean | null;
  isAssignmentDelayed?: boolean | null;
  isOnbardingDelayed?: boolean | null;
  isParkingDelayed?: boolean | null;
  isWithInCustomerETA?: boolean | null;
  isWithinETA?: boolean | null;
  startToCompleteIn?: number | null;
  taskCompletionIn?: number | null;
  taskOnboardeIn?: number | null;
  updatedAt?: any | null;
  valetTookInCompletion?: number | null;
};
export type findTaskFilterInputSearch = {
  vehicleNo: string;
};
export type editAmountScreenQuery$variables = {
  taskFilter: findTaskFilterInput;
};
export type editAmountScreenQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"editAmountScreenQueryTaskFragment">;
};
export type editAmountScreenQuery = {
  response: editAmountScreenQuery$data;
  variables: editAmountScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "taskFilter"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "taskFilter"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 1
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "editAmountScreenQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Literal",
            "name": "count",
            "value": 1
          },
          {
            "kind": "Variable",
            "name": "taskFilter",
            "variableName": "taskFilter"
          }
        ],
        "kind": "FragmentSpread",
        "name": "editAmountScreenQueryTaskFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "editAmountScreenQuery",
    "selections": [
      {
        "alias": "findTasks",
        "args": (v1/*: any*/),
        "concreteType": "taskConnection",
        "kind": "LinkedField",
        "name": "findTasksV2",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "taskEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "task",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Parking",
                    "kind": "LinkedField",
                    "name": "parking",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "status",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "parkingTag",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ParkingParkingInvoice",
                        "kind": "LinkedField",
                        "name": "parkingInvoice",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ParkingParkingInvoiceTimings",
                            "kind": "LinkedField",
                            "name": "timings",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "entryTime",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "exitTime",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "chargeTime",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ParkingParkingInvoicePaymentDetails",
                            "kind": "LinkedField",
                            "name": "paymentDetails",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "isPaid",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "mode",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "focReason",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "amount",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ParkingParkingInvoiceCharges",
                            "kind": "LinkedField",
                            "name": "charges",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ParkingParkingInvoiceChargesChargesBreakup",
                                "kind": "LinkedField",
                                "name": "chargesBreakup",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "duration",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "payable",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "discountAmount",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "totalPayable",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "findTasks",
        "args": (v1/*: any*/),
        "filters": [
          "filter",
          "sort"
        ],
        "handle": "connection",
        "key": "editAmountScreenQueryTaskFragment__findTasks",
        "kind": "LinkedHandle",
        "name": "findTasksV2"
      }
    ]
  },
  "params": {
    "cacheID": "134c7eeeec45b1558b435c2876ec507e",
    "id": null,
    "metadata": {},
    "name": "editAmountScreenQuery",
    "operationKind": "query",
    "text": "query editAmountScreenQuery(\n  $taskFilter: findTaskFilterInput!\n) {\n  ...editAmountScreenQueryTaskFragment_3CvMC1\n}\n\nfragment editAmountScreenQueryTaskFragment_3CvMC1 on Query {\n  findTasks: findTasksV2(filter: $taskFilter, first: 1) {\n    edges {\n      node {\n        _id\n        parking {\n          _id\n          status\n          parkingTag\n          parkingInvoice {\n            timings {\n              entryTime\n              exitTime\n              chargeTime\n            }\n            paymentDetails {\n              isPaid\n              mode\n              focReason\n              amount\n            }\n            charges {\n              chargesBreakup {\n                duration\n                payable\n              }\n              discountAmount\n              totalPayable\n            }\n          }\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "91eb630989bcc02bf4713877475060fc";

export default node;
