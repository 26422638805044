import React from 'react';
import { useWindowDimensions } from 'react-native';
import { View } from '../../../../components/new';
import { DetailCardPlaceholder } from '../../../../components/new/custom/DetailCard';
import { getTheme } from '../../../../themes/new/theme';
import Placeholder from '../../../../components/new/custom/Placeholder/Placeholder';

const PADDING = getTheme().space['2xl'];
const RestaurantScreenPlaceholder = () => {
  const { height, width } = useWindowDimensions();
  const cardWidth = width - 2 * PADDING;

  return (
    <Placeholder>
      <View top={50} mt="2xl" height={height}>
        <View px="2xl" pt="2xl">
          <View mb="xl">
            <DetailCardPlaceholder width={cardWidth} withRating />
          </View>
          <View mb="xl">
            <DetailCardPlaceholder width={cardWidth} withRating />
          </View>
          <View mb="xl">
            <DetailCardPlaceholder width={cardWidth} withRating />
          </View>
        </View>
      </View>
    </Placeholder>
  );
};

export default RestaurantScreenPlaceholder;
