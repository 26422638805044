import moment from 'moment';
import { isBoolean } from 'validate.js';
import { BottomSheetFilterDataType } from '../../resturants/resturantsData';

export enum FilterKeyEvents {
  TIME = 'eventTiming',
  CATEGORY = 'category',
  UPCOMING = 'upcomingEvent',
}

export interface EventsFilterDataType {
  name: string;
  active: boolean;
  isMultiSelect: boolean;
  filterKey?: FilterKeyEvents;
  itemsSelected?: number;
}

export interface EventsFilterBottomSheetDataType {
  sheetTitle: string;
  referenceFilterName: string;
  dataArr: { name: string; value: string; isChecked: boolean }[];
}

export const EventsFilterData: EventsFilterDataType[] = [
  {
    name: 'All',
    active: true,
    isMultiSelect: false,
  },
  {
    name: 'Category',
    active: false,
    isMultiSelect: true,
    itemsSelected: 0,
    filterKey: FilterKeyEvents.CATEGORY,
  },
  {
    name: 'Today',
    active: false,
    isMultiSelect: false,
    filterKey: FilterKeyEvents.TIME,
  },
  {
    name: 'Tomorrow',
    active: false,
    isMultiSelect: false,
    filterKey: FilterKeyEvents.TIME,
  },
  {
    name: 'This Weekend',
    active: false,
    isMultiSelect: false,
    filterKey: FilterKeyEvents.TIME,
  },
  {
    name: 'Upcoming',
    active: false,
    isMultiSelect: false,
    filterKey: FilterKeyEvents.UPCOMING,
  },
];

type filterRangeInput = 'All' | 'Today' | 'Tomorrow' | 'This Weekend';

function getDateRange(input: filterRangeInput): {
  startDate: Date | null;
  endDate: Date | null;
} {
  const today = moment().startOf('day').add(330, 'm').utc();
  const endOfDay = moment().add(1, 'day').startOf('day').add(330, 'm').utc();

  switch (input) {
    case 'All':
      return { startDate: null, endDate: null };
    case 'Today':
      return { startDate: today.toDate(), endDate: endOfDay.toDate() };
    case 'Tomorrow':
      const tomorrow = today.clone().add(1, 'day');
      const endOfTomorrow = endOfDay.clone().add(1, 'day');
      return {
        startDate: tomorrow.toDate(),
        endDate: endOfTomorrow.toDate(),
      };
    case 'This Weekend':
      const saturday = moment()
        .isoWeekday(5)
        .startOf('day')
        .add(330, 'm')
        .utc();
      const sunday = moment()
        .isoWeekday(7)
        .add(1, 'day')
        .startOf('day')
        .add(330, 'm')
        .utc();
      return { startDate: saturday.toDate(), endDate: sunday.toDate() };
    default:
      return { startDate: null, endDate: null };
  }
}

export const EventsFilterBottomSheetData: Record<
  string,
  BottomSheetFilterDataType
> = {
  category: {
    sheetTitle: 'Select Category',
    referenceFilterName: 'category',
    dataArr: [
      { isChecked: false, name: 'Art', value: 'ART' },
      { isChecked: false, name: 'Crafts', value: 'CRAFTS' },
      { isChecked: false, name: 'Health & Wellness', value: 'HEALTH_WELLNESS' },
      { isChecked: false, name: 'Sensory', value: 'SENSORY' },
      { isChecked: false, name: 'Food & Drinks', value: 'FOOD_DRINKS' },
      { isChecked: false, name: 'Dance & Music', value: 'DANCE_MUSIC' },
    ],
  },
};

export interface FilterPayloadEvents {
  eventTiming?: {
    slot: {
      startTime: string;
      endTime: string;
      isActive: boolean;
    };
  };
  upcomingEvent?: boolean;
  category?: string[];
}

export const constructPayloadForFilter = (
  filters: EventsFilterDataType[],
  bottomSheetFilterData: Record<string, EventsFilterBottomSheetDataType>,
): FilterPayloadEvents => {
  let payload: FilterPayloadEvents = {};
  const activeFilters = filters.filter((ele) => ele.active);
  activeFilters.forEach((ele) => {
    switch (ele.filterKey) {
      case FilterKeyEvents.TIME:
        const timings = getDateRange(ele.name as filterRangeInput);
        payload = {
          ...payload,
          eventTiming: {
            slot: {
              startTime: timings?.startDate?.toString(),
              endTime: timings?.endDate?.toString(),
              isActive: true,
            },
          },
        };
        delete payload.upcomingEvent;
        break;
      case FilterKeyEvents.UPCOMING:
        payload = {
          ...payload,
          upcomingEvent: true,
        };
        delete payload.eventTiming;
        break;
      case FilterKeyEvents.CATEGORY:
        payload = {
          ...payload,
          category: bottomSheetFilterData[ele.filterKey].dataArr
            .filter((ele1) => ele1.isChecked)
            .map((ele2) => ele2.value),
        };
        break;
      default:
        break;
    }
  });
  return payload;
};

interface URL_FILTER_TYPE {
  category?: string[];
  upcoming?: 'true' | 'false';
  time?: 'TODAY' | 'TOMORROW' | 'THIS_WEEKEND';
}

function getDateRangeForInitialState(
  input: 'TODAY' | 'TOMORROW' | 'THIS_WEEKEND',
): {
  startDate: Date | null;
  endDate: Date | null;
} {
  const today = moment().startOf('day').add(330, 'm').utc();
  const endOfDay = moment().add(1, 'day').startOf('day').add(330, 'm').utc();

  switch (input) {
    case 'TODAY':
      return { startDate: today.toDate(), endDate: endOfDay.toDate() };
    case 'TOMORROW':
      const tomorrow = today.clone().add(1, 'day');
      const endOfTomorrow = endOfDay.clone().add(1, 'day');
      return {
        startDate: tomorrow.toDate(),
        endDate: endOfTomorrow.toDate(),
      };
    case 'THIS_WEEKEND':
      const saturday = moment()
        .isoWeekday(5)
        .startOf('day')
        .add(330, 'm')
        .utc();
      const sunday = moment()
        .isoWeekday(7)
        .add(1, 'day')
        .startOf('day')
        .add(330, 'm')
        .utc();
      return { startDate: saturday.toDate(), endDate: sunday.toDate() };
    default:
      return { startDate: null, endDate: null };
  }
}

export const constructPayloadForInitialState = (
  filterData: URL_FILTER_TYPE,
): FilterPayloadEvents => {
  let payload: FilterPayloadEvents = {};
  Object.keys(filterData).forEach((key) => {
    switch (key) {
      case 'category':
        if (Array.isArray(filterData[key]) && filterData[key]?.length > 0) {
          payload = {
            ...payload,
            category: filterData[key],
          };
        }
        break;
      case 'upcoming':
        if (filterData[key] === 'true') {
          delete payload.eventTiming;
          payload = {
            ...payload,
            upcomingEvent: true,
          };
        }
        break;
      case 'time':
        if (filterData[key]) {
          const timings = getDateRangeForInitialState(filterData[key]);
          delete payload.upcomingEvent;
          payload = {
            ...payload,
            eventTiming: {
              slot: {
                startTime: timings?.startDate?.toString(),
                endTime: timings?.endDate?.toString(),
                isActive: true,
              },
            },
          };
        }
        break;
      default:
        break;
    }
  });
  return payload;
};

export const constructFiltersForInitialState = (
  filterData: URL_FILTER_TYPE,
): EventsFilterDataType[] => {
  const defaultFilters = EventsFilterData;
  let filters: EventsFilterDataType[] = defaultFilters.map((ele) => {
    switch (ele.name) {
      case 'Category':
        if (
          filterData?.category &&
          Array.isArray(filterData.category) &&
          filterData.category.length > 0
        ) {
          return {
            ...ele,
            active: true,
            itemsSelected: filterData.category.length,
          };
        }
        return ele;
      case 'Today':
        if (filterData?.time === 'TODAY') {
          return {
            ...ele,
            active: true,
          };
        }
        return ele;
      case 'Tomorrow':
        if (filterData?.time === 'TOMORROW') {
          return {
            ...ele,
            active: true,
          };
        }
        return ele;
      case 'This Weekend':
        if (filterData?.time === 'THIS_WEEKEND') {
          return {
            ...ele,
            active: true,
          };
        }
        return ele;
      case 'Upcoming':
        if (filterData.upcoming === 'true') {
          return {
            ...ele,
            active: true,
          };
        }
        return ele;
      default:
        return ele;
    }
  });

  const isAnyFilterActive =
    [...filters].slice(1).filter((ele) => ele.active).length > 0;

  if (isAnyFilterActive) {
    filters = filters.map((ele, index) => {
      if (index === 0) {
        return {
          ...ele,
          active: false,
        };
      }
      return ele;
    });
  } else {
    filters = filters.map((ele, index) => {
      if (index === 0) {
        return {
          ...ele,
          active: true,
        };
      }
      return ele;
    });
  }

  return filters;
};

export const constructBottomSheetDataForInitialState = (
  filterData: URL_FILTER_TYPE,
): Record<string, BottomSheetFilterDataType> => {
  let sheetData = { ...EventsFilterBottomSheetData };
  Object.keys(filterData).forEach((ele) => {
    switch (ele) {
      case 'category':
        if (
          filterData?.category &&
          Array.isArray(filterData.category) &&
          filterData.category.length > 0
        ) {
          sheetData = {
            ...sheetData,
            category: {
              ...sheetData.category,
              dataArr: sheetData.category.dataArr.map((eleCheck) => {
                if (filterData.category.includes(eleCheck.value)) {
                  return {
                    ...eleCheck,
                    isChecked: true,
                  };
                }
                return eleCheck;
              }),
            },
          };
        }
        break;
      default:
        break;
    }
  });
  return sheetData;
};
