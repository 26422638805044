import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { Platform } from 'react-native';
import BillingCard from '../../BillingCard';
import { usePayBillStore } from '../hooks/usePayBillStore';
import { paymentOrderTypeEnum } from '../../../../../utilities/helper';
import { normalizeToNumberOrZero } from '../../../../../utilities/Utility';
import { PAYBILL_BS_MODES } from '../helpers';
import useLoginModalStore from '../../../../../stores/loginModalStore';
import useUserStore from '../../../../../stores/userStore';
import { Text, View } from '../../../../../components/new';
import payBillAnalytics from '../analytics';

/** View Break Up is applicable on these */
const viewBreakUpWhitelist = [
  paymentOrderTypeEnum.EVENT,
  paymentOrderTypeEnum.PARKING,
];

/** Edit Bill link is visible on these */
const onEditWhileList = [paymentOrderTypeEnum.restaurant];

interface BillSummaryWidgetProps {
  showBottomSheet: (mode: PAYBILL_BS_MODES) => void;
}

const BillSummaryWidget = ({ showBottomSheet }: BillSummaryWidgetProps) => {
  const navigation = useNavigation();
  const appliedCoupon = useLoginModalStore((state) => state?.appliedCoupon);
  const isCustomerBlackMember = useUserStore(
    (state) => state.blackMembership?.active,
  );
  const {
    title,
    orderId,
    orderType,
    blackMembershipAdded,
    priceDetails,
    config,
  } = usePayBillStore((state) => state);
  const showDiscountPromo =
    isCustomerBlackMember &&
    Platform.OS === 'web' &&
    orderType !== paymentOrderTypeEnum.INVESTMENT_LEAD;
  const { blackMembershipConfig } = config ?? {};
  const {
    billAmount,
    netAmount,
    discountAmount,
    tipAmount,
    usedPointsAmount,
    charityAmount,
  } = priceDetails ?? {};

  const navigateToEditAmountScreen = () => {
    payBillAnalytics.editAmountClicked(title, orderType, orderId);
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.replace('paymentScreen', {
        orderId,
        orderType,
      });
    }
  };

  return (
    <View pb="4xl">
      <Text color="primary.300" mb="2xl" size="md" weight="medium">
        Bill Summary
      </Text>
      <BillingCard
        orderType={orderType as paymentOrderTypeEnum}
        priceDetails={{
          billAmount,
          netAmount,
          discountAmount,
          tipAmount,
          usedPointsAmount,
          charityAmount,
          blackMembershipAmount: blackMembershipAdded
            ? normalizeToNumberOrZero(blackMembershipConfig?.price)
            : 0,
        }}
        onViewBreakUp={
          viewBreakUpWhitelist.includes(orderType as paymentOrderTypeEnum)
            ? () => {
                payBillAnalytics.viewBreakUpClicked(
                  title,
                  orderType as paymentOrderTypeEnum,
                  orderId,
                );
                showBottomSheet(PAYBILL_BS_MODES.BREAK_UP);
              }
            : null
        }
        onEdit={
          onEditWhileList.includes(orderType as paymentOrderTypeEnum)
            ? navigateToEditAmountScreen
            : null
        }
        appliedCoupon={appliedCoupon}
        discountPromo={
          showDiscountPromo
            ? {
                text: 'Get Upto 50% Off',
                cb: () => {
                  showBottomSheet(
                    PAYBILL_BS_MODES.DOWNLOAD_APP_PROMT_IF_BLACK_MEMBER_PAYS_ON_WEB,
                  );
                },
              }
            : null
        }
      />
    </View>
  );
};

export default BillSummaryWidget;
