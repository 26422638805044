/**
 * @generated SignedSource<<e18aba6eb497b8bbeb8f632e33cbe5e9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EnumtaskHistoryAction = "abort" | "accepted" | "arrived" | "assigned" | "cancelled" | "completed" | "created" | "exited" | "incomplete" | "onboarded" | "started" | "%future added value";
export type EnumtaskTaskType = "park" | "recall" | "repark" | "%future added value";
export type findTaskFilterInput = {
  _id?: any | null;
  assignedTo?: any | null;
  campus: FilterFindManytaskCampusInput;
  created_at?: string | null;
  history?: ReadonlyArray<FilterFindManytaskHistoryInput | null> | null;
  metrics?: FilterFindManytaskMetricsInput | null;
  ownerId?: any | null;
  search?: findTaskFilterInputSearch | null;
  taskEndLocation?: FilterFindManytaskTaskEndLocationInput | null;
  taskStartLocation?: FilterFindManytaskTaskStartLocationInput | null;
  taskType?: EnumtaskTaskType | null;
};
export type FilterFindManytaskHistoryInput = {
  action?: EnumtaskHistoryAction | null;
};
export type FilterFindManytaskTaskStartLocationInput = {
  _id?: any | null;
  locationId: any;
};
export type FilterFindManytaskTaskEndLocationInput = {
  _id?: any | null;
  locationId: any;
};
export type FilterFindManytaskCampusInput = {
  _id: any;
};
export type FilterFindManytaskMetricsInput = {
  _id?: any | null;
  acceptedIn?: number | null;
  assignedIn?: number | null;
  createToArrivedIn?: number | null;
  createdAt?: any | null;
  isAcceptedDelayed?: boolean | null;
  isAssignmentDelayed?: boolean | null;
  isOnbardingDelayed?: boolean | null;
  isParkingDelayed?: boolean | null;
  isWithInCustomerETA?: boolean | null;
  isWithinETA?: boolean | null;
  startToCompleteIn?: number | null;
  taskCompletionIn?: number | null;
  taskOnboardeIn?: number | null;
  updatedAt?: any | null;
  valetTookInCompletion?: number | null;
};
export type findTaskFilterInputSearch = {
  vehicleNo: string;
};
export type findTaskQuery$variables = {
  filter: findTaskFilterInput;
};
export type findTaskQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"findTaskQueryTaskFragment">;
};
export type findTaskQuery = {
  response: findTaskQuery$data;
  variables: findTaskQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "taskEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "task",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "taskVehicle",
            "kind": "LinkedField",
            "name": "vehicle",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "taskVehicleBrand",
                "kind": "LinkedField",
                "name": "brand",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "brandLogo",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "taskVehicleRegistration",
                "kind": "LinkedField",
                "name": "registration",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "plate",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "color",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "PageInfo",
    "kind": "LinkedField",
    "name": "pageInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasNextPage",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endCursor",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v3 = [
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "filter"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 1
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "findTaskQuery",
    "selections": [
      {
        "kind": "InlineDataFragmentSpread",
        "name": "findTaskQueryTaskFragment",
        "selections": [
          {
            "alias": "findTasks",
            "args": [
              {
                "kind": "Variable",
                "name": "filter",
                "variableName": "taskFilter"
              }
            ],
            "concreteType": "taskConnection",
            "kind": "LinkedField",
            "name": "__findTaskQueryTaskFragment__findTasks_connection",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          }
        ],
        "args": [
          {
            "kind": "Literal",
            "name": "count",
            "value": 1
          },
          {
            "kind": "Variable",
            "name": "taskFilter",
            "variableName": "filter"
          }
        ],
        "argumentDefinitions": [
          {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "count"
          },
          {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "cursor"
          },
          {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "taskFilter"
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "findTaskQuery",
    "selections": [
      {
        "alias": "findTasks",
        "args": (v3/*: any*/),
        "concreteType": "taskConnection",
        "kind": "LinkedField",
        "name": "findTasksV2",
        "plural": false,
        "selections": (v2/*: any*/),
        "storageKey": null
      },
      {
        "alias": "findTasks",
        "args": (v3/*: any*/),
        "filters": [
          "filter"
        ],
        "handle": "connection",
        "key": "findTaskQueryTaskFragment__findTasks",
        "kind": "LinkedHandle",
        "name": "findTasksV2"
      }
    ]
  },
  "params": {
    "cacheID": "90fa570b6690afc2894534c6fd237814",
    "id": null,
    "metadata": {},
    "name": "findTaskQuery",
    "operationKind": "query",
    "text": "query findTaskQuery(\n  $filter: findTaskFilterInput!\n) {\n  ...findTaskQueryTaskFragment_4G9oKb\n}\n\nfragment findTaskQueryTaskFragment_4G9oKb on Query {\n  findTasks: findTasksV2(filter: $filter, first: 1) {\n    edges {\n      node {\n        _id\n        vehicle {\n          brand {\n            name\n            brandLogo\n          }\n          registration {\n            plate\n          }\n          color\n        }\n        user {\n          _id\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "bf5a8651f9bc2362b7f770661ce196e5";

export default node;
