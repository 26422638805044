import { graphql } from 'react-relay';

export const homeScreenLogOutQuery = graphql`
  query homeScreenLogOutQuery(
    $filter: findCollectionsFilterInput!
    $restaurantsOfferFilter: findRestaurantsFilterInput!
  ) {
    findCollections(filter: $filter) {
      ...homeScreenLogOutQuery_collectionsFragment
    }
    offerRestaurants: findRestaurants2(filter: $restaurantsOfferFilter) {
      ...homeScreenQuery_findRestaurantsFragment
    }
    getAllHomePageBanners: getAllHomePageBanners {
      ...homeScreenLogOutQuery_homeScreenBannerFragment
    }
  }
`;

export const homeScreenLogoutCollectionsFragment = graphql`
  fragment homeScreenLogOutQuery_collectionsFragment on collections
  @relay(plural: true) {
    title
    restaurants {
      _id
      displayName
      logo
      overAllRating
      description {
        cusines {
          name
        }
      }
      mediaGallery {
        sectionTitle
        media {
          cover
          mediaType
          mediaURL
        }
      }
    }
  }
`;

export const homeScreenBannerFragment = graphql`
  fragment homeScreenLogOutQuery_homeScreenBannerFragment on HomePageBanner
  @relay(plural: true) {
    imageLink
    deepLink
  }
`;
