import React from 'react';
import { Text, View } from '../../../../../components/new';
import BlackMembershipCard from '../../../../../components/new/custom/BlackMembershipCard';
import Header from './Header';
import Footer from './Footer';
import { toRupees } from '../../../../../utilities/helper';
import DarkThemeWrapper from '../../../wrapperComp/DarkThemeWrapper';
import { blackMembership } from '../../../../../utilities/Constant';

const { BASE_PRICE } = blackMembership;

type Prices = {
  discount: number;
  cashback: number;
  membershipPrice: number;
};

const getBodyText = (
  membershipAdded: boolean,
  discountPerc: number,
  cashbackPerc: number,
  prices: Prices,
): string => {
  const savings = prices?.discount + prices?.cashback;
  const maxSavings = savings < prices?.membershipPrice ? toRupees(500) : '50%';
  if (membershipAdded) {
    if (prices?.discount) {
      if (prices?.cashback) {
        return `Saved ${toRupees(prices?.discount)} and earned ${toRupees(
          prices?.cashback,
        )} cashback.`;
      }
      return `Saved ${toRupees(prices?.discount)}`;
    }
    return `Earned ${toRupees(prices?.cashback)} cashback.`;
  }
  return `Save ${toRupees(
    savings,
  )} now and upto ${maxSavings} on every 32ND visit.`;
};

interface BlackMembershipPurchaseCardProps {
  membershipData: {
    cashbackAmount: number;
    cashbackPercentage: number;
    discountAmount: number;
    discountPercentage: number;
  };
  membershipConfig: {
    price: number;
    expiryDuration: {
      days: number;
    };
  };
  membershipAdded: boolean;
  onRemoveMembership: () => void;
  onAddMembership: () => void;
  onViewBenefits: () => void;
}

const BlackMembershipPurchaseCard = (
  props: BlackMembershipPurchaseCardProps,
) => {
  const {
    membershipData,
    membershipConfig,
    membershipAdded,
    onRemoveMembership,
    onAddMembership,
    onViewBenefits,
  } = props;

  const bodyText = getBodyText(
    membershipAdded,
    membershipData?.discountPercentage,
    membershipData?.cashbackPercentage,
    {
      discount: membershipData?.discountAmount,
      cashback: membershipData?.cashbackAmount,
      membershipPrice: membershipConfig?.price,
    },
  );

  return (
    <DarkThemeWrapper>
      <BlackMembershipCard>
        <View justifyContent="space-between">
          <Header onViewBenefits={onViewBenefits} />
          <View px="2xl" pt="lg" pb="3xl+xs">
            <Text size="lg" color="primary.500">
              {bodyText}
            </Text>
          </View>
          <Footer
            membershipAdded={membershipAdded}
            duration={membershipConfig?.expiryDuration?.days}
            price={BASE_PRICE}
            priceSlashed={membershipConfig?.price}
            onRemoveMembership={onRemoveMembership}
            onAddMembership={onAddMembership}
          />
        </View>
      </BlackMembershipCard>
    </DarkThemeWrapper>
  );
};

export default BlackMembershipPurchaseCard;
