import { useCallback, useReducer } from 'react';
import moment from 'moment';
import { useLazyLoadQuery } from 'react-relay';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { supervisorOverviewParentQuery } from '../API/supervisorOverviewParentQuery';
import {
  parkingOverviewReducer,
  initialState,
  actionTypes,
} from '../parkingOveriewReducer';
import {
  getPickerTimeStamps,
  buildPickerData,
  buildMetricsData,
  dateFormat,
} from '../utils';
import useCommonStore from '../../../../stores/commonStore';
import { taskType } from '../constants';

const useParkingOverview = (queryOptions, defaultTaskType) => {
  const navigation = useNavigation();
  const [state, dispatch] = useReducer(parkingOverviewReducer, initialState);
  const campusID = useCommonStore((s) => s.campusId);

  /** --------------- Setting up queries ---------- */
  const today = moment().format(dateFormat);
  const { sTS, eTS } = getPickerTimeStamps();
  const queryData = {
    /** for metrics query */
    startTimeStamp: sTS,
    endTimeStamp: eTS,
    /** for parking location query */
    parkingLocationFilter: { campus: { _id: campusID } },
    /** initial query data for task list */
    sortTasks: 'DELAY_AT_DESC',
    taskFilter: {
      taskType: defaultTaskType,
      campus: { _id: campusID },
      created_at: today,
      history: [{ action: 'completed' }, { action: 'exited' }],
    } as Record<string, any>,
  };

  if (defaultTaskType === taskType.RECALL) {
    queryData.taskFilter.metrics = {
      isAssignmentDelayed: true,
      isWithinETA: false,
    };
  } else {
    queryData.taskFilter.metrics = {
      isParkingDelayed: true,
      isOnbardingDelayed: true,
    };
  }

  /** Main query fetching metrices and parking location */
  const {
    getTaskMetricsDateWise: taskMetricsDateWise,
    findParkingLocation,
    ...tasksFragment
  } = useLazyLoadQuery(supervisorOverviewParentQuery, queryData, {
    fetchKey: queryOptions.fetchKey,
    fetchPolicy: 'store-and-network',
    networkCacheConfig: { force: true },
  });

  useFocusEffect(
    useCallback(() => {
      (async () => {
        if (taskMetricsDateWise?.metrics) {
          const fetchedMetrics = taskMetricsDateWise?.metrics;
          const allMetricsData = fetchedMetrics.map((m) => buildMetricsData(m));
          const lastMetricItemData = allMetricsData[allMetricsData.length - 1];
          const pickerData = buildPickerData(fetchedMetrics);
          dispatch({ type: actionTypes.SET_SELECTED_DATE, payload: today });
          dispatch({
            type: actionTypes.SET_DAYPICKER_DATA,
            payload: pickerData,
          });
          dispatch({
            type: actionTypes.SET_METRICS_WIDGET_DATA,
            payload: lastMetricItemData,
          });
          dispatch({
            type: actionTypes.SET_METRICS_DATA,
            payload: allMetricsData,
          });
        }
      })();
    }, [taskMetricsDateWise]),
  );

  const handleDaySelection = (selectedDate: string) => {
    const isSameDateSelected = moment(selectedDate, dateFormat).isSame(
      moment(state.selectedDate, dateFormat),
      'day',
    );
    if (isSameDateSelected) return;
    const selectedDayMetrics = state.metricsData.filter(
      (m) => m.date === selectedDate,
    )[0];

    if (selectedDayMetrics) {
      dispatch({
        type: actionTypes.SET_METRICS_WIDGET_DATA,
        payload: selectedDayMetrics,
      });
    }
    dispatch({ type: actionTypes.SET_SELECTED_DATE, payload: selectedDate });
  };

  const navigateToParkingOverviewScreen = useCallback(() => {
    navigation.navigate('parkingOverview', {
      campusId: campusID,
    });
  }, [navigation, campusID]);

  const isToday =
    state.selectedDate &&
    moment(state.selectedDate, dateFormat).isSame(new Date(), 'day');

  return {
    state,
    isToday,
    tasksQueryFragment: tasksFragment,
    parkingLocationQueryFragment: findParkingLocation,
    handleDaySelection,
    navigateToParkingOverviewScreen,
  };
};

export default useParkingOverview;
