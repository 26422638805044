import React from 'react';
import { Platform, useWindowDimensions } from 'react-native';
import { View } from '../../../../components/new';
import { DetailCardPlaceholder } from '../../../../components/new/custom/DetailCard';
import { getTheme } from '../../../../themes/new/theme';
import Placeholder from '../../../../components/new/custom/Placeholder/Placeholder';

const PADDING = getTheme().space['2xl'];

const StoresScreenPlaceholder = () => {
  const { height, width } = useWindowDimensions();
  const cardWidth = width - 2 * PADDING;

  return (
    <Placeholder>
      <View height={height}>
        <View px="2xl" pt={Platform.OS === 'android' ? 'xl' : '2xl'}>
          <View mb="xl">
            <DetailCardPlaceholder width={cardWidth} />
          </View>
        </View>
      </View>
    </Placeholder>
  );
};

export default StoresScreenPlaceholder;
