/* eslint-disable import/prefer-default-export */
import { useMemo } from 'react';
import { usePaginationFragment } from 'react-relay';

export const usePagedTasks = (fragment, tasks) => {
  const {
    data,
    refetch: refechTasks,
    loadNext,
    hasNext: hasMoreTasks,
  } = usePaginationFragment(fragment, tasks);

  const { findTasks } = data;

  /** using `useMemo` as `taskNodes` is mapped to new array on every render of this hook despite `findTasks` being same */
  const taskNodes = useMemo(
    () => findTasks?.edges.map((edge) => edge.node),
    [findTasks],
  );

  return {
    tasks: taskNodes,
    refechTasks,
    hasMoreTasks,
    fetchMoreTasks: (count?: number) => loadNext(count),
  };
};
