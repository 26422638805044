import { useNavigation } from '@react-navigation/native';
import {
  useLazyLoadQuery,
  useFragment,
  usePaginationFragment,
} from 'react-relay';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Platform, RefreshControl, ScrollView } from 'react-native';
import { View } from '../../../../components/new';
import { RestaurantCollectionCategory } from '../../../../utilities/helper';
import {
  homeScreenCollectionsFragment,
  homeScreenQuery,
  homeScreenQueryFindTicketsFragment,
  homeScreenQueryWithPhotoMomentQuery,
  homeScreenQueryFindInvestmentLeadFragment,
} from '../API/homeScreenQuery';
import RestaurantCollectionView from './RestaurantCollectionView';
import { buildRestaurantCollectionViewData } from '../utils';
import useUserStore from '../../../../stores/userStore';
import EventBus, { EventBusEventsEnum } from '../../../../utilities/EventBus';
import Header from '../../../../components/new/custom/Header';
import TrackingWidgetComponent from '../../trackYourCar/TrackingWidgetComponent';
import FeatureTiles from './FeatureTiles';
import DownloadCard from '../../trackMyVehicle/DownloadCard';
import TakeSurvey from './TakeSurvey';
import PointsWidget from './PointsWidget';
import SpinAndWinWidget from './SpinAndWinWidget';
import PhotoMomentWidget from '../../../../components/PhotoMomentWidget';
import RestaurantsWithOffers from './RestaurantsWithOffers';
import useVideoMomentsStore from '../../../../stores/VideoMomentsStore';
import HomePageBannerCarousel from '../../../../components/new/custom/Carousel/HomePageBannerCarousel';
import DidYouKnowCarouselNew from '../../../../components/new/custom/Carousel/DidYouKnowCarouselNew';
import useCommonStore from '../../../../stores/commonStore';
import usePaymentModalStore from '../../../../stores/PaymentModalStore';
import AppConstants from '../../../../utilities/AppConstants';
import { unwrapPagedData } from '../../../../utilities/paginationUtilities';
import InvestmentWidget from '../../investHome/InvestmentWidget';

const filterCollection = (
  collection: any[],
  category: RestaurantCollectionCategory,
) => {
  return collection
    .filter((c) => c.title === category && c?.restaurants?.length)
    .map((cafe) => cafe.restaurants)[0];
};

const HomePageSections = ({ queryOptions, refresh }) => {
  const navigation = useNavigation();
  const userId = useUserStore((state) => state.id);
  const campusId = useCommonStore((state) => state.campusId);
  const [refreshing, setRefreshing] = useState(false);
  const { scannedS3Imagekey } = useVideoMomentsStore((state) => state);
  const taskTypeRecall = usePaymentModalStore((state) => state?.taskTypeRecall);
  const { TYPEFORM_PRODUCT } = AppConstants;

  const photoDataPayload = {
    assetId: scannedS3Imagekey,
    rescanning: false,
  };

  const variables = {
    filter: {
      title: [
        RestaurantCollectionCategory.OUTDOOR_SEATING,
        RestaurantCollectionCategory.FINE_DINING,
        RestaurantCollectionCategory.CAFES,
        RestaurantCollectionCategory.SERVES_ALCOHOL,
      ],
    },
    restaurantsOfferFilter: {
      campus: {
        _id: campusId,
      },
      type: 'RESTAURANT',
      withOffer: true,
    },
    _id: userId,
    sort: ['DESC'],
    photoData: photoDataPayload,
    typeFormfilter: {
      formId: TYPEFORM_PRODUCT,
    },
    typeFormLimit: 1,
    typeFormSort: 'submittedAt_DESC',
    ticketsFilters: {
      ticketType: 'REWARD_TICKET',
      displayType: 'QR',
      status: 'ACTIVE',
    },
    first: 11,
    investmentSort: '_ID_DESC',
  };

  const {
    findCollections: findCollectionsRef,
    offerRestaurants: offerRestaurantsRef,
    findUserById,
    getSpinsCount,
    getMatchingPhotosForPhotoMomentsV3,
    findTypeFormResponses,
    getAllHomePageBanners: getAllHomePageBannersRef,
    findInvestmentLead,
    ...ticketsRef
  } = useLazyLoadQuery(
    scannedS3Imagekey ? homeScreenQueryWithPhotoMomentQuery : homeScreenQuery,
    variables,
    {
      fetchKey: queryOptions.fetchKey,
      fetchPolicy: 'store-and-network',
    },
  );

  /* ========== TICKETS LIST PAGINATION ========== */
  const {
    data: ticketsNodes,
    loadNext: fetchMoreTickets,
    hasNext: hasMoreTickets,
  } = usePaginationFragment(homeScreenQueryFindTicketsFragment, ticketsRef);

  // extracting node out of edges
  const tickets = unwrapPagedData(ticketsNodes?.findTickets?.edges);

  const collection = useFragment(
    homeScreenCollectionsFragment,
    findCollectionsRef,
  );

  const investmentLead = useFragment(
    homeScreenQueryFindInvestmentLeadFragment,
    findInvestmentLead,
  );

  const onRefresh = useCallback(() => {
    refresh();
  }, []);

  const {
    alcoholCollection,
    outDoorCollection,
    fineDiningCollection,
    cafesCollection,
  } = useMemo(() => {
    const outdoor = filterCollection(
      collection,
      RestaurantCollectionCategory.OUTDOOR_SEATING,
    );
    const alcohol = filterCollection(
      collection,
      RestaurantCollectionCategory.SERVES_ALCOHOL,
    );
    const fineDining = filterCollection(
      collection,
      RestaurantCollectionCategory.FINE_DINING,
    );
    const cafes = filterCollection(
      collection,
      RestaurantCollectionCategory.CAFES,
    );

    return {
      alcoholCollection: buildRestaurantCollectionViewData(alcohol),
      outDoorCollection: buildRestaurantCollectionViewData(outdoor),
      fineDiningCollection: buildRestaurantCollectionViewData(fineDining),
      cafesCollection: buildRestaurantCollectionViewData(cafes),
    };
  }, [collection]);

  const scrollViewRef = useRef(null);

  useEffect(() => {
    EventBus.getInstance().on(EventBusEventsEnum.SCROLL_TO_TOP_TAB_1, () => {
      scrollViewRef?.current?.scrollTo({ x: 0, y: 0, animated: true });
    });
  }, []);

  return (
    <View flex={1}>
      <Header navigation={navigation} />
      <ScrollView
        showsVerticalScrollIndicator={false}
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
        }
        ref={scrollViewRef}
      >
        {taskTypeRecall ? (
          <TrackingWidgetComponent
            parentFetchKey={queryOptions.fetchKey}
            taskNotAvailable={() => null}
            reloadComp={() => {
              refresh();
            }}
          />
        ) : null}
        <View px="2xl" mt="4xl">
          <HomePageBannerCarousel fragmentRef={getAllHomePageBannersRef} />
        </View>
        {taskTypeRecall ? null : (
          <TrackingWidgetComponent
            parentFetchKey={queryOptions.fetchKey}
            taskNotAvailable={() => null}
            reloadComp={() => {
              refresh();
            }}
          />
        )}
        <InvestmentWidget />
        <View px="2xl">
          <RestaurantsWithOffers fragmentRef={offerRestaurantsRef} />
        </View>
        <View mt="4xl">
          <PhotoMomentWidget fragmentRef={getMatchingPhotosForPhotoMomentsV3} />
        </View>
        <InvestmentWidget
          isCarousalWidget
          investmentLead={investmentLead}
          reloadComp={() => {
            refresh();
          }}
        />
        <FeatureTiles tickets={tickets} />
        <View
          mt="4xl"
          px="2xl"
          mb={Platform.OS === 'web' ? '' : '4xl'}
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <PointsWidget userDataFragmentRef={findUserById} />
          <SpinAndWinWidget fragmentRef={getSpinsCount} />
        </View>
        <InvestmentWidget
          isCarousalWidget
          investmentLead={investmentLead}
          reloadComp={() => {
            refresh();
          }}
        />
        {Platform.OS === 'web' ? (
          <View my="2xl" px="2xl">
            <DownloadCard />
          </View>
        ) : (
          <View my="2xl" />
        )}
        <RestaurantCollectionView
          title="Places to party at 32ND"
          category={RestaurantCollectionCategory.SERVES_ALCOHOL}
          collection={alcoholCollection}
        />
        <RestaurantCollectionView
          title="Cafes With Outdoor Seating"
          category={RestaurantCollectionCategory.OUTDOOR_SEATING}
          collection={outDoorCollection}
        />
        <RestaurantCollectionView
          title="Fine Dining at 32ND"
          category={RestaurantCollectionCategory.FINE_DINING}
          collection={fineDiningCollection}
        />
        <RestaurantCollectionView
          title="Cafes at 32ND"
          category={RestaurantCollectionCategory.CAFES}
          collection={cafesCollection}
        />
        <View px="2xl">
          <DidYouKnowCarouselNew />
        </View>
        <View mb="4xl" px="2xl">
          <TakeSurvey fragmentRef={findTypeFormResponses} />
        </View>
        {/* <InstagramGridComponent fragmentRef={getInstagramBasicData} /> */}
      </ScrollView>
    </View>
  );
};

export default HomePageSections;
