import React, { Suspense, useCallback, useState } from 'react';
import { StatusBar } from 'expo-status-bar';
import { Pressable } from 'react-native';
import DisconnectedDropover from '../../../../../components/disconnectedpopover.component';
import {
  IconButton,
  Layout,
  Text,
  TopNavigation,
  View,
} from '../../../../../components/new';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../../../../utilities/NewErrorBoundary';
import NewErrorView from '../../../../../utilities/NewErrorView';
import { Loading } from '../../../../../components';
import DarkThemeWrapper from '../../../wrapperComp/DarkThemeWrapper';
import PaymentsImage from '../../../../../../assets/images/payments.webp';
import { navigateBack, toRupees } from '../../../../../utilities/helper';
import PlusMembershipProfileScreenInner from './PlusMembershipProfileScreenInner';
import CachedImage from '../../../../../components/new/custom/CachedImage';
import useAuthStore from '../../../../../stores/authStore';

const PlusMembershipProfileScreen = ({ navigation }) => {
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  const [earnedPoints, setEarnedPoints] = useState(0);
  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'network-only',
    });
  const [_, setNetStatus] = useState(true);

  const refresh = useCallback(() => {
    setRefreshedQueryOptions((prev) => ({
      fetchKey: (prev?.fetchKey ?? 0) + 1,
      fetchPolicy: 'network-only',
    }));
  }, []);

  const navigateToPointsScreen = () => {
    navigation.navigate('pointsScreen');
  };

  return (
    <DarkThemeWrapper>
      {/* eslint-disable-next-line react/style-prop-object */}
      <StatusBar style="light" />
      <Layout level={2}>
        <View>
          <TopNavigation
            textSize="md"
            appearance="ghost"
            level="none"
            textColor="primary.10"
            IconLeft={
              <IconButton
                name="back-outline-300"
                size="md"
                appearance="ghost"
                iconColor="primary.500"
                ml="lg"
                onPress={() => navigateBack(navigation, 'user')}
              />
            }
            IconRight={
              isLoggedIn && typeof earnedPoints === 'number' ? (
                <Pressable onPress={navigateToPointsScreen}>
                  <EarnedPoints earnedPoints={earnedPoints} />
                </Pressable>
              ) : (
                <></>
              )
            }
          />
        </View>
        <NewErrorBoundary
          fetchKey={refreshedQueryOptions.fetchKey}
          fallback={
            <NewErrorView
              errorMsg="Sorry something went wrong"
              reload={refresh}
            />
          }
        >
          <Suspense fallback={<Loading />}>
            <PlusMembershipProfileScreenInner
              setEarnedPoints={setEarnedPoints}
              queryOptions={refreshedQueryOptions}
            />
          </Suspense>
        </NewErrorBoundary>
        <DisconnectedDropover
          setNetStatus={setNetStatus}
          text="No Internet Connection"
          icon="wifi-off-outline"
        />
      </Layout>
    </DarkThemeWrapper>
  );
};

export default PlusMembershipProfileScreen;

const EarnedPoints = ({ earnedPoints }: { earnedPoints: number }) => {
  return (
    <View
      borderRadius="sm"
      px="xl"
      py="lg"
      flexDirection="row"
      mr="2xl"
      bg="background.primary.elevation"
      justifyContent="center"
      alignItems="center"
    >
      <Text mr="sm" textAlign="center" size="sm" color="gold.500">
        Earned {toRupees(earnedPoints)}
      </Text>
      <CachedImage source={PaymentsImage} style={{ height: 16, width: 16 }} />
    </View>
  );
};
