import React, {
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { Dimensions, Platform, useWindowDimensions } from 'react-native';
import { useLazyLoadQuery, usePaginationFragment } from 'react-relay';
import Animated, {
  useAnimatedScrollHandler,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import _ from 'lodash';
import { ActionSheetRef, FlatList } from 'react-native-actions-sheet';
import useAuthStore from '../../../stores/authStore';
import useCommonStore from '../../../stores/commonStore';
import useUserStore from '../../../stores/userStore';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../../utilities/NewErrorBoundary';
import {
  Avatar,
  Button,
  Layout,
  Pressable,
  Text,
  View,
} from '../../../components/new';
import AppConstants from '../../../utilities/AppConstants';
import ListingFilters from '../../../components/new/custom/ListingFilters';
import NewErrorView from '../../../utilities/NewErrorView';
import { Loading } from '../../../components';
import EventsScreenPlacehodler from '../shimmerPlaceholders/screens/EventsScreenPlaceholder';
import EventBus, { EventBusEventsEnum } from '../../../utilities/EventBus';
import {
  eventsListingQueryfindEventsV2Fragment,
  eventsListingScreenQuery,
} from './eventsListingQuery';
import theme, { getTheme } from '../../../themes/new/theme';
import EventsListingEmptyPlaceholder from './EventsListingEmptyPlaceholder';
import { firebaseEventLogger } from '../../../utilities/firbaseAnalytics';
import { unwrapPagedData } from '../../../utilities/paginationUtilities';
import PaginationLoader from '../../../components/new/custom/PaginationLoader';
import DetailCard from '../../../components/new/custom/DetailCard';
import DarkThemeWrapper from '../wrapperComp/DarkThemeWrapper';
import {
  getEventCashbackPercentage,
  getEventDiscountPercentage,
} from '../plusMembership/helpers';
import { normalizeToNumberOrZero } from '../../../utilities/Utility';
import {
  getDateText,
  getEventPrice,
  isPassedEvent,
  isSoldOutEvent,
} from './helpers/helpers';
import { mediaTypes, MembershipTypes } from '../../../utilities/helper';
import {
  constructBottomSheetDataForInitialState,
  constructFiltersForInitialState,
  constructPayloadForFilter,
  constructPayloadForInitialState,
  EventsFilterBottomSheetData,
  EventsFilterBottomSheetDataType,
  EventsFilterData,
  EventsFilterDataType,
  FilterKeyEvents,
} from './helpers/EventsFilterData';
import Divider from '../../../components/new/primitive/Divider/Divider';
import RenderCheckbox from '../resturants/renderCheckbox';
import BottomSheetV2 from '../../../components/new/composites/BottomSheet/BottomSheetV2';
import { getExtraFilterState } from '../resturants/resturantsData';

const EVENTS_PAGE_SIZE = 5;

const AccountScreenIcon = ({
  imageLink,
  firstName,
  lastName,
  isBlackMember,
}) => {
  const navigation = useNavigation<any>();
  return (
    <View flexDirection="row" alignItems="center">
      <Pressable
        onPress={() => {
          navigation.navigate('account');
        }}
        hitSlop={{
          top: 10,
          bottom: 10,
          left: 10,
          right: 10,
        }}
      >
        <View
          ml="lg"
          borderWidth="sm"
          borderColor={isBlackMember ? 'static.gold' : 'transparent'}
          borderRadius="full"
        >
          <Avatar
            level={1}
            source={imageLink}
            resizeMode="cover"
            name={`${firstName ?? ''} ${lastName ?? ''}`}
          />
        </View>
      </Pressable>
    </View>
  );
};

const Listing = ({
  refreshedQueryOptions,
  queryFilters,
  onScroll,
  setIsListEmpty,
}) => {
  const ref = useRef<any>();
  const navigation = useNavigation<any>();
  const [viewableItem, setViewableItem] = useState('');
  const [endReached, setEndReached] = useState(false);
  const viewabilityConfigRef = useRef({
    itemVisiblePercentThreshold: 100,
    minimumViewTime: 50,
    waitForInteraction: false,
  });

  const onViewableItemsChangedCallback = useCallback(({ viewableItems }) => {
    const itemIndex =
      endReached && !hasMoreEvents ? viewableItems?.length - 1 : 0;
    if (viewableItems[itemIndex]?.isViewable) {
      setViewableItem(viewableItems[itemIndex]?.key);
    }
  }, []);

  const walletBalance = useUserStore((state) => state.wallet.currentBalance);

  const { findAddons, ...eventsRef } = useLazyLoadQuery(
    eventsListingScreenQuery,
    queryFilters,
    refreshedQueryOptions,
  );
  //  from plus membership config
  const eventDiscountPercentage = getEventDiscountPercentage(
    findAddons[0] as any,
  );
  const eventCashbackPercentage = getEventCashbackPercentage(
    findAddons[0] as any,
  );

  /* ========== EVENT LIST VIA PAGINATION ========== */
  const {
    data: eventsNodes,
    loadNext: fetchMoreEvents,
    hasNext: hasMoreEvents,
  } = usePaginationFragment(eventsListingQueryfindEventsV2Fragment, eventsRef);

  // extracting node out of edges
  const events = unwrapPagedData(eventsNodes?.findEventsV2?.edges);

  useEffect(() => {
    if (Array.isArray(events) && events?.length === 0) {
      setIsListEmpty(true);
    } else {
      setIsListEmpty(false);
    }
  }, [eventsNodes]);

  useEffect(() => {
    EventBus.getInstance().on(EventBusEventsEnum.SCROLL_TO_TOP_TAB_4, () => {
      ref?.current?.scrollToOffset({ animated: true, offset: 0 });
    });
  }, []);

  const handleCardPress = (id: string, eventName: string) => {
    firebaseEventLogger('events__eventCard__Tap', {
      buttonName: 'eventCard',
      screenName: 'events',
      userType: 'user',
      interactionType: 'tap',
      id,
      name: eventName,
    });
    navigation.navigate('eventsHome', { id });
  };

  return (
    <View px="2xl" flex={1}>
      <Animated.FlatList
        ref={ref}
        data={events}
        onScroll={onScroll}
        scrollEventThrottle={Platform.OS === 'web' ? 500 : 24}
        contentContainerStyle={{
          paddingBottom: getTheme().space['8xl'],
        }}
        // @ts-ignore
        keyExtractor={(item) => item?._id}
        renderItem={({ item, index }) => {
          const isPastEvent = isPassedEvent(item);
          let eventPrice;
          if (isPastEvent) {
            eventPrice = item?.basePrice;
          } else {
            eventPrice = getEventPrice(item);
          }
          const isPastOrSoldOutevent =
            isSoldOutEvent(item) || isPassedEvent(item);
          return (
            <DarkThemeWrapper>
              <View mt={index === 0 ? '72' : 'xl'}>
                <DetailCard
                  id={item?._id}
                  displayName={item?.title}
                  discountPercentage={normalizeToNumberOrZero(
                    eventDiscountPercentage,
                  )}
                  currentBalance={walletBalance ?? 0}
                  tagLine={
                    <View flexDirection="row" justifyContent="space-between">
                      {isPastOrSoldOutevent ? (
                        <Text size="sm" color="primary.400">
                          Coming soon!
                        </Text>
                      ) : (
                        <Text size="sm" color="primary.400">
                          {
                            getDateText({
                              data: item?.eventTiming ?? [],
                            }) as string
                          }
                        </Text>
                      )}
                      {Boolean(eventPrice) && (
                        <Text size="sm" color="primary.400">{`₹${Math.ceil(
                          eventPrice,
                        )} onwards`}</Text>
                      )}
                    </View>
                  }
                  statusTag={
                    isPastOrSoldOutevent ? (
                      <EventStatusTag
                        statusTag={{ status: 'upcoming', label: 'Upcoming' }}
                      />
                    ) : null
                  }
                  onPress={() => handleCardPress(item?._id, item?.title)}
                  coverImages={item?.media?.cover?.filter(
                    (img) => img?.mediaType === 'image',
                  )}
                  coverVideo={
                    item?.media?.cover?.filter(
                      (ele) =>
                        ele?.mediaType === mediaTypes.video && ele?.cover,
                    )[0]
                  }
                  payBillCashback={normalizeToNumberOrZero(
                    eventCashbackPercentage,
                  )}
                  pointsEnabled={{ redeem: true, reward: true }}
                  viewableItem={viewableItem}
                />
              </View>
            </DarkThemeWrapper>
          );
        }}
        removeClippedSubviews
        viewabilityConfig={viewabilityConfigRef.current}
        onViewableItemsChanged={onViewableItemsChangedCallback}
        onEndReached={({ distanceFromEnd }) => {
          if (hasMoreEvents) {
            fetchMoreEvents(EVENTS_PAGE_SIZE);
          }
          if (distanceFromEnd <= 0) {
            setEndReached(true);
          }
        }}
        initialNumToRender={5}
        windowSize={10}
        onEndReachedThreshold={0.8}
        showsVerticalScrollIndicator={false}
        ListEmptyComponent={
          events?.length ? null : <EventsListingEmptyPlaceholder />
        }
        ListFooterComponent={
          hasMoreEvents ? (
            <View px="9xl" height={200} alignItems="center">
              <PaginationLoader />
            </View>
          ) : null
        }
      />
    </View>
  );
};

const EventsListing = ({ route }) => {
  const eventId = route?.params?.eventId;
  const filtersAuto = route?.params?.filter;
  const preAppliedFilters = JSON.parse(filtersAuto || '{}');
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  const campusID = useCommonStore((state) => state.campusId);
  const appTheme = useCommonStore((state) => state.theme);
  const { id, profileImage, firstName, lastName, blackMembership } =
    useUserStore((state) => state);
  const { active: isBlackMember } = blackMembership;
  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: Platform.OS === 'web' ? 'network-only' : 'store-and-network',
    });

  const refresh = useCallback(() => {
    setRefreshedQueryOptions((prev) => ({
      ...prev,
      fetchKey: (prev?.fetchKey || 0) + 1,
    }));
  }, []);

  const { IMAGEKIT_PROD_URI } = AppConstants;
  const screenHeight = Dimensions.get('window').height;
  const maxHeightPercentage = 0.9;

  const imageLink = profileImage ? IMAGEKIT_PROD_URI + profileImage : '';

  const [filters, setFilters] = useState<EventsFilterDataType[]>(
    constructFiltersForInitialState(preAppliedFilters),
  );

  const [queryFiltersInitialState] = useState({
    filter: {
      campus: { _id: campusID },
      isActive: true,
      priorityEventId: eventId ?? null,
      ...constructPayloadForInitialState(preAppliedFilters),
    },
    sort: 'SLOT_START_TIME_ASC',
    includeFindUser: isLoggedIn,
    id,
    count: EVENTS_PAGE_SIZE,
    addonsFilter: {
      type: MembershipTypes.PLUS_MEMBERSHIP,
    },
  });

  const [queryFilters, setQueryFilters] = useState<any>(
    queryFiltersInitialState,
  );
  const [isListEmpty, setIsListEmpty] = useState(false);
  const [isAllFilterSelected, setIsAllFilterSelected] = useState(
    filters[0].active,
  );
  const { width } = useWindowDimensions();
  const [showFilters, setShowFilters] = useState<boolean>(true);

  const bottomSheetModalRef = useRef<ActionSheetRef>(null);

  const [bottomSheetFilterData, setBottomSheetFilterData] = useState<
    Record<string, EventsFilterBottomSheetDataType>
  >(constructBottomSheetDataForInitialState(preAppliedFilters));

  const [extraFilterCurrentState, setExtraFilterCurrentState] =
    useState<string>('');
  const [extraFilterPrevState, setExtraFilterPrevState] = useState<string>('');

  const [activeBottomSheetFilterData, setActiveBottomSheetFilterData] =
    useState<EventsFilterBottomSheetDataType>({
      dataArr: [],
      referenceFilterName: '',
      sheetTitle: '',
    });

  useEffect(() => {
    if (isListEmpty && isAllFilterSelected) {
      setShowFilters(false);
    } else {
      setShowFilters(true);
    }
  }, [isListEmpty, isAllFilterSelected]);

  useEffect(() => {
    refresh();
  }, [queryFilters]);

  const handleApplyExtraFilters = () => {
    firebaseEventLogger('EVENTS_FILTER_BOTTOM_SHEET_APPLY');
    const filterName = activeBottomSheetFilterData.referenceFilterName;
    const updatedBottomSheetData = {
      ...bottomSheetFilterData,
    };
    updatedBottomSheetData[filterName] = {
      ...activeBottomSheetFilterData,
    };
    setBottomSheetFilterData(updatedBottomSheetData);

    const selectedChoices = activeBottomSheetFilterData.dataArr.filter(
      (ele) => ele.isChecked,
    );

    let newFilters: EventsFilterDataType[] = filters.map((filter) => {
      if (filter.filterKey === filterName) {
        return {
          ...filter,
          itemsSelected: selectedChoices.length,
          active: selectedChoices.length > 0,
        };
      }
      return filter;
    });

    const isAnyFilterActive =
      [...newFilters].slice(1).filter((ele) => ele.active).length > 0;

    if (isAnyFilterActive) {
      newFilters = newFilters.map((ele, index) => {
        if (index === 0) {
          return {
            ...ele,
            active: false,
          };
        }
        return ele;
      });
      setIsAllFilterSelected(false);
    } else {
      newFilters = newFilters.map((ele, index) => {
        if (index === 0) {
          return {
            ...ele,
            active: true,
          };
        }
        return ele;
      });
      setIsAllFilterSelected(true);
    }

    setFilters(newFilters);

    const newPayload = constructPayloadForFilter(
      newFilters,
      updatedBottomSheetData,
    );

    setQueryFilters({
      ...queryFilters,
      filter: {
        campus: { _id: campusID },
        isActive: true,
        priorityEventId: eventId ?? null,
        ...newPayload,
      },
    });

    bottomSheetModalRef?.current?.hide();
  };

  const handleOnDismiss = () => {
    setExtraFilterCurrentState('');
    setExtraFilterPrevState('');
  };

  useFocusEffect(
    useCallback(() => {
      firebaseEventLogger('eventsListingPageLanding');
    }, []),
  );

  const onFilterPress = (data: EventsFilterDataType) => {
    firebaseEventLogger('EVENT_FILTER_CLICKED', {
      name: data.name,
    });
    let newFilters: EventsFilterDataType[] = [];
    switch (data.filterKey) {
      case FilterKeyEvents.TIME:
        newFilters = filters.map((filter) => {
          if (filter.filterKey === FilterKeyEvents.TIME) {
            if (filter.name === data.name) {
              return {
                ...filter,
                active: !filter.active,
              };
            }
            return {
              ...filter,
              active: false,
            };
          }
          return filter;
        });
        newFilters = newFilters.map((filter) => {
          if (filter.filterKey === FilterKeyEvents.UPCOMING) {
            return {
              ...filter,
              active: false,
            };
          }
          return filter;
        });
        break;
      case FilterKeyEvents.UPCOMING:
        newFilters = filters.map((filter) => {
          if (filter.filterKey === FilterKeyEvents.UPCOMING) {
            if (filter.name === data.name) {
              return {
                ...filter,
                active: !filter.active,
              };
            }
            return {
              ...filter,
              active: false,
            };
          }
          return filter;
        });
        newFilters = newFilters.map((filter) => {
          if (filter.filterKey === FilterKeyEvents.TIME) {
            return {
              ...filter,
              active: false,
            };
          }
          return filter;
        });
        break;
      case FilterKeyEvents.CATEGORY:
        const extraFilterState = getExtraFilterState(
          bottomSheetFilterData[data.filterKey].dataArr,
        );
        setExtraFilterPrevState(extraFilterState);
        setExtraFilterCurrentState(extraFilterState);
        setActiveBottomSheetFilterData(bottomSheetFilterData[data.filterKey]);
        bottomSheetModalRef.current.show();
        return;
      default:
        newFilters = _.cloneDeep(EventsFilterData);
        setIsAllFilterSelected(true);
        setBottomSheetFilterData(_.cloneDeep(EventsFilterBottomSheetData));
        break;
    }

    const isAnyFilterActive =
      [...newFilters].slice(1).filter((ele) => ele.active).length > 0;

    if (isAnyFilterActive) {
      newFilters = newFilters.map((ele, index) => {
        if (index === 0) {
          return {
            ...ele,
            active: false,
          };
        }
        return ele;
      });
      setIsAllFilterSelected(false);
    } else {
      newFilters = newFilters.map((ele, index) => {
        if (index === 0) {
          return {
            ...ele,
            active: true,
          };
        }
        return ele;
      });
      setIsAllFilterSelected(true);
    }
    setFilters(newFilters);

    const newPayload = constructPayloadForFilter(
      newFilters,
      bottomSheetFilterData,
    );

    setQueryFilters({
      ...queryFilters,
      filter: {
        campus: { _id: campusID },
        isActive: true,
        priorityEventId: eventId ?? null,
        ...newPayload,
      },
    });
  };

  const handleExtraFilterClicked = (value: string) => {
    firebaseEventLogger('EVENTS_FILTER_BOTTOM_SHEET_CHECKBOX', {
      value,
    });
    const newBottomSheetFilterDataArr = activeBottomSheetFilterData.dataArr.map(
      (filter) => {
        if (value === filter.value) {
          return {
            ...filter,
            isChecked: !filter.isChecked,
          };
        }
        return filter;
      },
    );
    const extraFilterState = getExtraFilterState(newBottomSheetFilterDataArr);
    setExtraFilterCurrentState(extraFilterState);
    setActiveBottomSheetFilterData((prevState) => ({
      ...prevState,
      dataArr: newBottomSheetFilterDataArr,
    }));
  };

  const handleClearExtraFilterClicked = () => {
    firebaseEventLogger('EVENTS_FILTER_BOTTOM_SHEET_CLEAR');
    const newBottomSheetFilterDataArr = activeBottomSheetFilterData.dataArr.map(
      (filter) => {
        return { ...filter, isChecked: false };
      },
    );
    const extraFilterState = getExtraFilterState(newBottomSheetFilterDataArr);
    setExtraFilterCurrentState(extraFilterState);
    setActiveBottomSheetFilterData((prevState) => ({
      ...prevState,
      dataArr: newBottomSheetFilterDataArr,
    }));
  };

  const calculateMaxHeight = () => {
    return Math.round(screenHeight * maxHeightPercentage);
  };

  const opacity = useSharedValue(1);
  const height = useSharedValue(56);

  const scrollThreshold = 120;
  const lastScrollPosition = useSharedValue(0);

  const scrollHandler = useAnimatedScrollHandler({
    onScroll: (event) => {
      try {
        if (event.contentOffset.y <= 0) {
          height.value = withTiming(56);
          opacity.value = withTiming(1);
          return;
        }

        const deltaY = event?.contentOffset?.y - lastScrollPosition?.value;

        if (Math.abs(deltaY) > scrollThreshold) {
          if (deltaY < 0) {
            height.value = withTiming(56);
            opacity.value = withTiming(1);
          } else {
            height.value = withTiming(1);
            opacity.value = withTiming(1);
          }
          lastScrollPosition.value = event?.contentOffset?.y;
        }
      } catch (e) {
        console.log(e);
      }
    },
  });

  const [showHideBg, setShowHideBg] = useState(
    getTheme(appTheme).colors.background.primary.base,
  );
  const [showHideBorderColor, setShowHideBorderColor] = useState(
    getTheme(appTheme).colors.grey[50],
  );
  const [showHideBorderWidth, setShowHideBorderWidth] = useState(
    getTheme(appTheme).borderWidths.xs,
  );

  useEffect(() => {
    setShowHideBg(getTheme(appTheme).colors.background.primary.base);
    setShowHideBorderColor(getTheme(appTheme).colors.grey[50]);
    setShowHideBorderWidth(getTheme(appTheme).borderWidths.xs);
  }, [appTheme]);

  const showHideStyle = useAnimatedStyle(() => {
    return {
      height: height.value,
      opacity: opacity.value,
      marginBottom: (12 * height.value) / 40,
      position: 'absolute',
      maxWidth: width,
      backgroundColor: showHideBg,
      zIndex: 20,
      borderBottomWidth: showHideBorderWidth,
      borderColor: showHideBorderColor,
    };
  });

  const insets = useSafeAreaInsets();

  return (
    <Layout level={2}>
      <View mt={insets.top + getTheme().space.lg}>
        <View flexDirection="row" px="2xl" pb="lg">
          <View style={{ height: 38, width: 38 }} />
          <View
            flexDirection="row"
            flex={1}
            justifyContent="center"
            alignItems="center"
          >
            <Text
              size="md"
              color="primary.400"
              numberOfLines={1}
              ellipsizemode="tail"
            >
              Experiences
            </Text>
          </View>
          <AccountScreenIcon
            imageLink={imageLink}
            firstName={firstName}
            lastName={lastName}
            isBlackMember={isBlackMember}
          />
        </View>
      </View>
      <View style={{ position: 'relative', flex: 1 }}>
        {showFilters && (
          <Animated.View style={showHideStyle}>
            <ListingFilters
              isVisible={showFilters}
              data={filters}
              onPress={onFilterPress}
            />
          </Animated.View>
        )}
        <NewErrorBoundary
          fetchKey={refreshedQueryOptions.fetchKey}
          fallback={
            <NewErrorView
              errorMsg="Sorry something went wrong"
              reload={refresh}
            />
          }
        >
          <Suspense
            fallback={
              Platform.OS === 'web' ? <Loading /> : <EventsScreenPlacehodler />
            }
          >
            <Listing
              refreshedQueryOptions={refreshedQueryOptions}
              queryFilters={queryFilters}
              onScroll={scrollHandler}
              setIsListEmpty={(isEmpty: boolean) => {
                setIsListEmpty(isEmpty);
              }}
            />
          </Suspense>
        </NewErrorBoundary>
        <BottomSheetV2 ref={bottomSheetModalRef} onClose={handleOnDismiss}>
          <View style={{ maxHeight: calculateMaxHeight() }}>
            <View flexDirection="column">
              <View
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                px="2xl"
              >
                <Text size="xl" color="primary.200">
                  {activeBottomSheetFilterData?.sheetTitle}
                </Text>
                <Button
                  appearance="ghost"
                  status="info"
                  size="md"
                  onPress={handleClearExtraFilterClicked}
                >
                  Clear All
                </Button>
              </View>
              <View px="2xl" my="lg">
                <Divider level={1} />
              </View>
              <FlatList
                style={{
                  maxHeight: Dimensions.get('screen').height * 0.65,
                }}
                contentContainerStyle={{
                  paddingHorizontal: theme.space['2xl'],
                  paddingTop: theme.space['2xl'],
                }}
                data={activeBottomSheetFilterData.dataArr}
                renderItem={(item) => {
                  return (
                    <RenderCheckbox
                      name={item.item.name}
                      value={item.item.value}
                      isChecked={item.item.isChecked}
                      onPress={handleExtraFilterClicked}
                    />
                  );
                }}
              />
              <View px="2xl" mt="xl">
                <Button
                  size="lg"
                  appearance="filled"
                  state={
                    extraFilterCurrentState === extraFilterPrevState
                      ? 'disabled'
                      : 'active'
                  }
                  onPress={handleApplyExtraFilters}
                >
                  Apply
                </Button>
              </View>
            </View>
          </View>
        </BottomSheetV2>
      </View>
    </Layout>
  );
};

export default EventsListing;

const EventStatusTag = ({ statusTag }) => {
  return (
    <View position="absolute" top={0} zIndex={20} overflow="hidden">
      <View
        bg="primary.10"
        borderBottomWidth="xs"
        borderRightWidth="xs"
        borderColor="warning.100"
        borderBottomRightRadius="xl"
        px="lg"
        py="sm"
      >
        <View
          px="2xl"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
        >
          {/* @ts-ignore */}
          <View
            width={8}
            height={8}
            borderRadius="full"
            bg={getStatusColor(statusTag?.status)}
          />
          <Text ml="sm" size="xs" color={getStatusColor(statusTag?.status)}>
            {statusTag?.label ?? ''}
          </Text>
        </View>
      </View>
    </View>
  );
};

const getStatusColor = (status: string) => {
  switch (status) {
    case 'upcoming':
      return 'warning.500';
    default:
      return 'info.500';
  }
};
