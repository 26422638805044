import { graphql } from 'react-relay';

export const homeScreenQuery = graphql`
  query homeScreenQuery(
    $filter: findCollectionsFilterInput!
    $restaurantsOfferFilter: findRestaurantsFilterInput!
    $_id: MongoID!
    $typeFormfilter: findTypeFormResponseFilterInput
    $typeFormLimit: Int
    $typeFormSort: SortFindManytypeformResponseInput
    $ticketsFilters: FilterFindManyticketInput!
    $first: Int
    $cursor: String
    $investmentSort: SortFindManyInvestmentPropertyLeadsGenerateModelInput
  ) {
    getAllHomePageBanners: getAllHomePageBanners {
      ...homeScreenLogOutQuery_homeScreenBannerFragment
    }
    findCollections(filter: $filter) {
      ...homeScreenQuery_collectionsFragment
    }
    findUserById(_id: $_id) {
      ...homeScreenQuery_findUserFragment
    }
    getSpinsCount {
      ...homeScreenQuery_spinCountFragment
    }
    findTypeFormResponses(
      filter: $typeFormfilter
      sort: $typeFormSort
      limit: $typeFormLimit
    ) {
      ...homeScreenQuery_findTypeFormResponsesFragment
    }
    findInvestmentLead(sort: $investmentSort) {
      ...homeScreenQueryFindInvestmentLeadFragment
    }
    offerRestaurants: findRestaurants2(filter: $restaurantsOfferFilter) {
      ...homeScreenQuery_findRestaurantsFragment
    }
    ...homeScreenQueryFindTicketsFragment
      @arguments(filter: $ticketsFilters, first: $first, cursor: $cursor)
  }
`;

export const homeScreenQueryWithPhotoMomentQuery = graphql`
  query homeScreenQueryWithPhotoMomentQuery(
    $filter: findCollectionsFilterInput!
    $restaurantsOfferFilter: findRestaurantsFilterInput!
    $_id: MongoID!
    $typeFormfilter: findTypeFormResponseFilterInput
    $typeFormLimit: Int
    $typeFormSort: SortFindManytypeformResponseInput
    $ticketsFilters: FilterFindManyticketInput!
    $first: Int
    $cursor: String
    $photoData: PhotoMomentsInputV3
    $investmentSort: SortFindManyInvestmentPropertyLeadsGenerateModelInput
  ) {
    getAllHomePageBanners: getAllHomePageBanners {
      ...homeScreenLogOutQuery_homeScreenBannerFragment
    }
    findCollections(filter: $filter) {
      ...homeScreenQuery_collectionsFragment
    }
    findUserById(_id: $_id) {
      ...homeScreenQuery_findUserFragment
    }
    getSpinsCount {
      ...homeScreenQuery_spinCountFragment
    }
    findTypeFormResponses(
      filter: $typeFormfilter
      sort: $typeFormSort
      limit: $typeFormLimit
    ) {
      ...homeScreenQuery_findTypeFormResponsesFragment
    }
    findInvestmentLead(sort: $investmentSort) {
      ...homeScreenQueryFindInvestmentLeadFragment
    }
    getMatchingPhotosForPhotoMomentsV3(data: $photoData) {
      ...homeScreenQuery_photoMomentWidgetFragment
    }
    offerRestaurants: findRestaurants2(filter: $restaurantsOfferFilter) {
      ...homeScreenQuery_findRestaurantsFragment
    }
    ...homeScreenQueryFindTicketsFragment
      @arguments(filter: $ticketsFilters, first: $first, cursor: $cursor)
  }
`;

export const homeScreenCollectionsFragment = graphql`
  fragment homeScreenQuery_collectionsFragment on collections
  @relay(plural: true) {
    title
    restaurants {
      _id
      displayName
      logo
      overAllRating
      description {
        cusines {
          name
        }
      }
      mediaGallery {
        sectionTitle
        media {
          cover
          mediaType
          mediaURL
        }
      }
    }
  }
`;

export const homeScreenInstagramFragment = graphql`
  fragment homeScreenQuery_instagramFragment on SocialMedia {
    platform
    data {
      profile_picture_url
      username
      followers_count
      media {
        data {
          media_url
          media_type
        }
      }
    }
  }
`;

export const homeScreenPhotoMomentWidgetFragment = graphql`
  fragment homeScreenQuery_photoMomentWidgetFragment on PhotoMomentsV3
  @relay(plural: true) {
    assetType
    assetId
    assetLink
    clickedOn
    similarity
    thumbnail
    thumbnailId
  }
`;

export const homeScreenFindUserFragment = graphql`
  fragment homeScreenQuery_findUserFragment on User {
    contact {
      phone {
        number
      }
    }
    wallet {
      currentBalance
      earnedAmount
    }
  }
`;

export const homeScreenSpinCountFragment = graphql`
  fragment homeScreenQuery_spinCountFragment on spinSlotsLeftTC {
    spinsLeft
    freezedTill
  }
`;

export const homeScreenfindTypeFormResponsesFragment = graphql`
  fragment homeScreenQuery_findTypeFormResponsesFragment on typeformResponse
  @relay(plural: true) {
    eventId
    formId
    hidden
    landedAt
    submittedAt
  }
`;

export const homeScrenOffersRestaurantsFragment = graphql`
  fragment homeScreenQuery_findRestaurantsFragment on restaurants2
  @relay(plural: true) {
    _id
    type
    displayName
    mediaGallery {
      sectionTitle
      media {
        cover
        mediaType
        mediaURL
      }
    }
    payBillCashback
    payBillPercentage
  }
`;

export const homeScreenQueryFindTicketsFragment = graphql`
  fragment homeScreenQueryFindTicketsFragment on Query
  @refetchable(queryName: "homeScreenFindTicketQueryRefechable")
  @argumentDefinitions(
    filter: { type: "FilterFindManyticketInput!" }
    first: { type: "Int" }
    cursor: { type: "String" }
  ) {
    findTickets(filter: $filter, first: $first, after: $cursor)
      @connection(key: "homeScreenFindTicketsFragment_findTickets") {
      edges {
        node {
          _id
        }
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`;

export const homeScreenQueryFindInvestmentLeadFragment = graphql`
  fragment homeScreenQueryFindInvestmentLeadFragment on InvestmentPropertyLeadsGenerateModel
  @relay(plural: true) {
    status
    investmentPropertyId
  }
`;
