import { useLazyLoadQuery, useFragment } from 'react-relay';
import { useEffect, useMemo, useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import {
  blackMembershipProfileQuery,
  findUserFragment,
} from './API/blackMembershipProfileQuery';
import { blackMembershipProfileQuery$variables as QueryVariables } from './API/__generated__/blackMembershipProfileQuery.graphql';
import { blackMembershipProfileQueryPaymentStatsFragment$key as PaymentStatsRef } from './API/__generated__/blackMembershipProfileQueryPaymentStatsFragment.graphql';
import { blackMembershipProfileQueryUserFragment$data as UserData } from './API/__generated__/blackMembershipProfileQueryUserFragment.graphql';
import useUserStore from '../../../../../stores/userStore';
import {
  getActiveBlackMembershipFromUser,
  getEventDiscountPercentage,
  getParkingDiscountPercentage,
  isCustomerBlackMember,
} from '../../helpers';
import {
  DateFormatsEnum,
  MembershipTypes,
  getFormatedDate,
  paymentOrderTypeEnum,
  toRupees,
} from '../../../../../utilities/helper';
import { isProperNumber } from '../../../../../utilities/Utility';
import analytics from './analytics';
import useCommonStore, { AppTheme } from '../../../../../stores/commonStore';
import { getTheme, themeModes } from '../../../../../themes/new/theme';
import { useThemedSystemNav } from '../../../../../hooks/useThemedSystemNav';
import useAuthStore from '../../../../../stores/authStore';
import useLoginModalStore from '../../../../../stores/loginModalStore';

const darkTheme = getTheme(themeModes.dark);

interface PlusMemberProfileHookOutput {
  isBlackMember: boolean;
  membershipId: string;
  membershipPrice: number;
  membershipDays: number;
  headerStripText: string;
  paymentStatsRef: PaymentStatsRef;
  eventDiscountPercentage: number;
  parkingDiscountPercentage: number;
  handleBuyMembership: () => void;
}

const usePlusMembershipProfile = (
  setEarnedPoints,
  queryOptions,
): PlusMemberProfileHookOutput => {
  const navigation = useNavigation();
  const userId = useUserStore((state) => state.id);
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  const setAuthModalOpen = useLoginModalStore((state) => state.setIsOpen);
  const [buyMembershipClicked, setBuyMembershipClicked] = useState(false);

  const queryVariables: QueryVariables = {
    userId,
    addonFilter: {
      type: MembershipTypes.PLUS_MEMBERSHIP,
    },
    isLoggedIn,
  };
  const {
    findUserById: findUserByIdRef,
    findAddons,
    getUserPaymentStats: paymentStatsRef,
  } = useLazyLoadQuery(
    blackMembershipProfileQuery,
    queryVariables,
    queryOptions,
  );
  const user = useFragment(findUserFragment, findUserByIdRef);
  const { earnedAmount } = user?.wallet ?? {};

  const blackMembershipAddon = findAddons[0];
  const isBlackMember = useMemo(
    () => isCustomerBlackMember(user, blackMembershipAddon),
    [user, blackMembershipAddon],
  );
  const headerStripText = resolveHeaderLabel(
    isBlackMember,
    user,
    blackMembershipAddon?._id,
  );

  useThemedSystemNav(
    AppTheme.DARK,
    !isBlackMember ? darkTheme.colors.background.secondary.base : null,
  );

  useEffect(() => {
    analytics.landed();
  }, []);

  useEffect(() => {
    // if user coming after login flow and had previously clicked "buy membership CTA"
    if (isLoggedIn && buyMembershipClicked) {
      if (!isBlackMember) {
        setBuyMembershipClicked(false);
        navigation.navigate('paymentAmountScreen', {
          orderId: blackMembershipAddon?._id,
          orderType: paymentOrderTypeEnum.ADDONS,
        });
      }
    }
  }, [isLoggedIn, buyMembershipClicked, isBlackMember]);

  useEffect(() => {
    const v = isProperNumber(earnedAmount) ? Math.trunc(earnedAmount) : 0;
    setEarnedPoints(v);
  }, [earnedAmount]);

  const handleBuyMembership = () => {
    analytics.buy32ndBlackClicked();
    if (!isLoggedIn) {
      setBuyMembershipClicked(true);
      setAuthModalOpen(true);
      return;
    }
    navigation.navigate('paymentAmountScreen', {
      orderId: blackMembershipAddon?._id,
      orderType: paymentOrderTypeEnum.ADDONS,
    });
  };

  return {
    isBlackMember,
    membershipId: blackMembershipAddon?._id, // orderId
    membershipPrice: blackMembershipAddon?.price,
    membershipDays: blackMembershipAddon?.expiryDuration?.days,
    headerStripText,
    paymentStatsRef,
    eventDiscountPercentage:
      getEventDiscountPercentage(blackMembershipAddon) ?? 0,
    parkingDiscountPercentage:
      getParkingDiscountPercentage(blackMembershipAddon) ?? 0,
    handleBuyMembership,
  };
};

const resolveHeaderLabel = (
  isBlackMember: boolean,
  user: UserData,
  membershipId: string,
): string => {
  try {
    if (!isBlackMember)
      return `You can save upto ${toRupees(500)} on every visit`;
    const { expiryDate } =
      getActiveBlackMembershipFromUser(user, membershipId) ?? {};
    if (!expiryDate) return '';
    const d = new Date(expiryDate);
    return `Valid till ${getFormatedDate(d, DateFormatsEnum.DateMonthYear)}`;
  } catch (e) {
    return '';
  }
};

export default usePlusMembershipProfile;
