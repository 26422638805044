import { graphql } from 'react-relay';

/* eslint-disable import/prefer-default-export */

export const submitKeyScreenQuery = graphql`
  query submitKeyScreenQuery($filter: findTaskFilterInput!) {
    ...submitKeyScreenQueryTaskFragment
      @arguments(taskFilter: $filter, count: 1)
  }
`;

export const submitKeyScreenTaskFragment = graphql`
  fragment submitKeyScreenQueryTaskFragment on Query
  @refetchable(queryName: "submitKeyScreenTaskFragmentQuery")
  @argumentDefinitions(
    taskFilter: { type: "findTaskFilterInput!" }
    count: { type: "Int" }
    cursor: { type: "String" }
  ) {
    findTasks: findTasksV2(filter: $taskFilter, first: $count, after: $cursor)
      @connection(key: "submitKeyScreenQueryTaskFragment__findTasks") {
      edges {
        node {
          _id
          vehicle {
            registration {
              plate
            }
          }
          history {
            _id
            action
            actor
            event_at
          }
          valet {
            _id
            name {
              first
              last
            }
            identityCode
          }
          taskType
          campus {
            _id
          }
          parkingETA {
            parkingTimePrediction {
              locationId
              name
              parkingTimeThreshold
              acceptedToCompleteTime
              startToCompleteTime
            }
          }
          taskEndLocation {
            locationId
          }
        }
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`;
