import { graphql, fetchQuery } from 'react-relay';
import environment from '../../../../../relay/relayEnvironment';
import {
  configQuery$variables as QueryVariables,
  configQuery$data as QueryResponse,
} from './__generated__/configQuery.graphql';

export const configQuery = graphql`
  query fetchConfigQuery(
    $addonsFilter: FilterFindManyaddonInput
    $userId: MongoID!
    $isLoggedIn: Boolean!
  ) {
    findConfig(filter: {}) {
      payBillConfig {
        tipStatus {
          refType
          isTipEnabled
        }
      }
    }
    findCharities(filter: {}) {
      _id
      title
      description
      isActive
      finePrint {
        details
        summary
      }
      contribution {
        type
        value
      }
    }
    findAddons(filter: $addonsFilter) {
      _id
      name
      price
      type
      additionalCashback {
        refType
        cashback {
          value
          type
        }
      }
      additionalDiscount {
        refType
        discount {
          value
          type
        }
      }
      expiryDuration {
        days
      }
    }
    findUserById(_id: $userId) @include(if: $isLoggedIn) {
      memberships {
        membershipAddonId
        status
        expiryDate
      }
    }
  }
`;

const fetchConfig = (
  queryVariables: QueryVariables,
): Promise<QueryResponse> => {
  return fetchQuery(environment, configQuery, queryVariables, {
    force: true,
  }).toPromise();
};

export default fetchConfig;
