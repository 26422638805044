/**
 * @generated SignedSource<<ef7006797d0590e71608d13d5f313df9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EnuminvoiceOrderType = "ADDONS" | "EVENT" | "FOOD_ORDER" | "INVESTMENT_LEAD" | "PARKING" | "POPUP_ORDER" | "POPUP_STORE" | "RESTAURANT" | "STORE" | "%future added value";
export type EnuminvoicePaymentType = "BILL_UPLOAD" | "CASH" | "JUSPAY" | "PAYTMQR" | "RAZORPAY" | "%future added value";
export type EnuminvoicePopUpStoreDetailsDeliveryStatus = "DELIVERED" | "UNDELIVERED" | "%future added value";
export type EnuminvoiceStatus = "FAILED" | "PENDING" | "REFUNDED" | "SUCCESSFUL" | "%future added value";
export type Periodicity = "DAILY" | "MONTHLY" | "%future added value";
export type SortFindManyinvoiceInput = "CREATED_AT_ASC" | "CREATED_AT_DESC" | "ORDERTYPE_ASC" | "ORDERTYPE_DESC" | "ORDERTYPE__ORDERID_ASC" | "ORDERTYPE__ORDERID_DESC" | "ORDERTYPE__ORDERID__STATUS_ASC" | "ORDERTYPE__ORDERID__STATUS_DESC" | "ORDERTYPE__ORDERID__STATUS__WORKSHOPDETAILS__SLOTID_ASC" | "ORDERTYPE__ORDERID__STATUS__WORKSHOPDETAILS__SLOTID_DESC" | "SLOT_CLOSE_TIME_ASC" | "SLOT_CLOSE_TIME_DESC" | "SLOT_START_TIME_ASC" | "SLOT_START_TIME_DESC" | "STATUS_ASC" | "STATUS_DESC" | "_ID_ASC" | "_ID_DESC" | "%future added value";
export type findInvoiceInput = {
  AND?: ReadonlyArray<FilterFindManyinvoiceInput> | null;
  OR?: ReadonlyArray<FilterFindManyinvoiceInput> | null;
  _id?: any | null;
  _operators?: FilterFindManyinvoiceOperatorsInput | null;
  addons?: FilterFindManyinvoiceAddonsInput | null;
  couponCode?: string | null;
  created_at?: any | null;
  csrContributionId?: any | null;
  datePeriod?: DatePeriod | null;
  displayName?: string | null;
  orderId?: any | null;
  orderType?: EnuminvoiceOrderType | null;
  paymentTxnId?: any | null;
  paymentType?: EnuminvoicePaymentType | null;
  platform?: string | null;
  popUpStoreDetails?: FilterFindManyinvoicePopUpStoreDetailsInput | null;
  priceDetails?: FilterFindManyinvoicePriceDetailsInput | null;
  rewardTxnId?: any | null;
  search?: findInvoiceInputSearch | null;
  status?: EnuminvoiceStatus | null;
  uId?: string | null;
  userId?: any | null;
  workshopDetails?: FilterFindManyinvoiceWorkshopDetailsInput | null;
};
export type FilterFindManyinvoicePriceDetailsInput = {
  _id?: any | null;
  addonAmount?: number | null;
  cashbackAmount?: number | null;
  cashbackPercentage?: number | null;
  couponAmount?: number | null;
  couponCashbackAmount?: number | null;
  couponId?: any | null;
  csrAmount?: number | null;
  discountGiven?: number | null;
  discountId?: any | null;
  discountPercentage?: number | null;
  grossAmount?: number | null;
  netAmount?: number | null;
  rewardsUsed?: number | null;
  tipAmount?: number | null;
};
export type FilterFindManyinvoiceWorkshopDetailsInput = {
  slotId?: any | null;
  tickets?: ReadonlyArray<FilterFindManyinvoiceWorkshopDetailsTicketsInput | null> | null;
};
export type FilterFindManyinvoiceWorkshopDetailsTicketsInput = {
  spaces?: number | null;
  ticketId?: any | null;
};
export type FilterFindManyinvoicePopUpStoreDetailsInput = {
  _id?: any | null;
  deliveryStatus?: EnuminvoicePopUpStoreDetailsDeliveryStatus | null;
  displayName?: string | null;
};
export type FilterFindManyinvoiceAddonsInput = {
  orderId?: any | null;
};
export type FilterFindManyinvoiceOperatorsInput = {
  _id?: FilterFindManyinvoice_idOperatorsInput | null;
  orderType?: FilterFindManyinvoiceOrderTypeOperatorsInput | null;
  status?: FilterFindManyinvoiceStatusOperatorsInput | null;
};
export type FilterFindManyinvoiceStatusOperatorsInput = {
  exists?: boolean | null;
  gt?: EnuminvoiceStatus | null;
  gte?: EnuminvoiceStatus | null;
  in?: ReadonlyArray<EnuminvoiceStatus | null> | null;
  lt?: EnuminvoiceStatus | null;
  lte?: EnuminvoiceStatus | null;
  ne?: EnuminvoiceStatus | null;
  nin?: ReadonlyArray<EnuminvoiceStatus | null> | null;
};
export type FilterFindManyinvoiceOrderTypeOperatorsInput = {
  exists?: boolean | null;
  gt?: EnuminvoiceOrderType | null;
  gte?: EnuminvoiceOrderType | null;
  in?: ReadonlyArray<EnuminvoiceOrderType | null> | null;
  lt?: EnuminvoiceOrderType | null;
  lte?: EnuminvoiceOrderType | null;
  ne?: EnuminvoiceOrderType | null;
  nin?: ReadonlyArray<EnuminvoiceOrderType | null> | null;
};
export type FilterFindManyinvoice_idOperatorsInput = {
  exists?: boolean | null;
  gt?: any | null;
  gte?: any | null;
  in?: ReadonlyArray<any | null> | null;
  lt?: any | null;
  lte?: any | null;
  ne?: any | null;
  nin?: ReadonlyArray<any | null> | null;
};
export type FilterFindManyinvoiceInput = {
  AND?: ReadonlyArray<FilterFindManyinvoiceInput> | null;
  OR?: ReadonlyArray<FilterFindManyinvoiceInput> | null;
  _id?: any | null;
  _operators?: FilterFindManyinvoiceOperatorsInput | null;
  addons?: FilterFindManyinvoiceAddonsInput | null;
  couponCode?: string | null;
  created_at?: any | null;
  csrContributionId?: any | null;
  displayName?: string | null;
  orderId?: any | null;
  orderType?: EnuminvoiceOrderType | null;
  paymentTxnId?: any | null;
  paymentType?: EnuminvoicePaymentType | null;
  platform?: string | null;
  popUpStoreDetails?: FilterFindManyinvoicePopUpStoreDetailsInput | null;
  priceDetails?: FilterFindManyinvoicePriceDetailsInput | null;
  rewardTxnId?: any | null;
  status?: EnuminvoiceStatus | null;
  uId?: string | null;
  userId?: any | null;
  workshopDetails?: FilterFindManyinvoiceWorkshopDetailsInput | null;
};
export type DatePeriod = {
  from: string;
  period: Periodicity;
};
export type findInvoiceInputSearch = {
  uId?: string | null;
};
export type eventsApiFindInvoiceQuery$variables = {
  filter: findInvoiceInput;
  sort?: SortFindManyinvoiceInput | null;
};
export type eventsApiFindInvoiceQuery$data = {
  readonly findInvoice: ReadonlyArray<{
    readonly _id: any;
    readonly displayName: string;
    readonly sourceDetails: {
      readonly event: {
        readonly host: {
          readonly media: ReadonlyArray<{
            readonly mediaURL: string;
          } | null> | null;
        } | null;
      } | null;
    } | null;
    readonly workshopDetails: {
      readonly endTime: any;
      readonly startTime: any;
    } | null;
  }>;
};
export type eventsApiFindInvoiceQuery = {
  response: eventsApiFindInvoiceQuery$data;
  variables: eventsApiFindInvoiceQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sort"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "filter"
      },
      {
        "kind": "Variable",
        "name": "sort",
        "variableName": "sort"
      }
    ],
    "concreteType": "invoice",
    "kind": "LinkedField",
    "name": "findInvoice",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "displayName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "invoiceWorkshopDetails",
        "kind": "LinkedField",
        "name": "workshopDetails",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "endTime",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "InvoiceSourceDetailsTC",
        "kind": "LinkedField",
        "name": "sourceDetails",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "event",
            "kind": "LinkedField",
            "name": "event",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "eventHost",
                "kind": "LinkedField",
                "name": "host",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "eventMediaCover",
                    "kind": "LinkedField",
                    "name": "media",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "mediaURL",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "eventsApiFindInvoiceQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "eventsApiFindInvoiceQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "31af239312ddf02769e2c79a23d1aa19",
    "id": null,
    "metadata": {},
    "name": "eventsApiFindInvoiceQuery",
    "operationKind": "query",
    "text": "query eventsApiFindInvoiceQuery(\n  $filter: findInvoiceInput!\n  $sort: SortFindManyinvoiceInput\n) {\n  findInvoice(filter: $filter, sort: $sort) {\n    _id\n    displayName\n    workshopDetails {\n      startTime\n      endTime\n    }\n    sourceDetails {\n      event {\n        host {\n          media {\n            mediaURL\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "011dabc312ecc42484c28278e2224f1f";

export default node;
