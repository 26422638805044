/**
 * @generated SignedSource<<dd3fde3be851aa4ea218692994cf30c7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type EnumtaskHistoryAction = "abort" | "accepted" | "arrived" | "assigned" | "cancelled" | "completed" | "created" | "exited" | "incomplete" | "onboarded" | "started" | "%future added value";
export type EnumtaskTaskType = "park" | "recall" | "repark" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type taskApiFindTaskFragment$data = {
  readonly findTasks: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly _id: any;
        readonly assignedTo: any | null;
        readonly campus: {
          readonly _id: any;
          readonly name: string;
        } | null;
        readonly history: ReadonlyArray<{
          readonly action: EnumtaskHistoryAction;
          readonly actor: any | null;
          readonly event_at: any | null;
        } | null>;
        readonly ownerId: any | null;
        readonly parkingId: any | null;
        readonly parkingTag: string | null;
        readonly taskEndLocation: {
          readonly locationId: any;
          readonly name: string | null;
          readonly parkingSpaces: {
            readonly floor: {
              readonly floorId: any;
              readonly floorName: string | null;
            } | null;
          } | null;
        } | null;
        readonly taskStartLocation: {
          readonly locationId: any;
          readonly name: string | null;
          readonly parkingSpaces: {
            readonly floor: {
              readonly bay: {
                readonly bayId: any;
                readonly bayName: string | null;
              } | null;
              readonly floorId: any;
              readonly floorName: string | null;
            } | null;
          } | null;
        } | null;
        readonly taskType: EnumtaskTaskType;
        readonly user: {
          readonly contact: {
            readonly phone: {
              readonly number: string;
              readonly prefix: string;
            };
          };
          readonly name: {
            readonly first: string;
            readonly last: string | null;
          };
        } | null;
        readonly valet: {
          readonly name: {
            readonly first: string;
            readonly last: string | null;
          };
        } | null;
        readonly vehicle: {
          readonly brand: {
            readonly brandLogo: string | null;
            readonly name: string | null;
          } | null;
          readonly color: string | null;
          readonly registration: {
            readonly plate: string;
          } | null;
        } | null;
        readonly vehicleId: any | null;
      };
    }>;
    readonly pageInfo: {
      readonly hasNextPage: boolean;
    };
  } | null;
  readonly " $fragmentType": "taskApiFindTaskFragment";
};
export type taskApiFindTaskFragment$key = {
  readonly " $data"?: taskApiFindTaskFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"taskApiFindTaskFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "taskApiFindTaskFragment"
};

(node as any).hash = "2c2bf7b17bc486bd8b68392fc563951a";

export default node;
