import React from 'react';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { ScrollView } from 'react-native-gesture-handler';
import { NewErrorBoundaryParentState } from '../../../../utilities/NewErrorBoundary';
import { usePayBill } from './hooks/usePayBill';
import { Text, View } from '../../../../components/new';
import TipWidget from './components/TipWidget';
import { getTheme } from '../../../../themes/new/theme';
import PointsWidget from './components/PayBillPointsWidget';
import SavingsStrip from './components/SavingsStrip';
import BottomSheetV2 from '../../../../components/new/composites/BottomSheet/BottomSheetV2';
import { PAYBILL_BS_MODES, getPayBillBottomSheetStyles } from './helpers';
import {
  BlackMemberInvitationOnPayBs,
  CharityInfoBs,
  DownloadAppIfPointsUsedOnWebBs,
  DownloadAppPromtIfBlackMemberPaysOnWebBs,
  EventCancellationInfoBs,
  ViewBreakupBs,
} from './components/PayBillBottomSheets';
import BlackMembershipBenefitsBs from './components/BlackMembershipBenefitsBs';
import BlackMembershipWidget from './components/BlackMembershipWidget';
import EventCancelltationWidget from './components/EventCancellationWidget';
import CharityWidget from './components/CharityWidget';
import BillSummaryWidget from './components/BillSummaryWidget';
import CouponWidget from './components/CouponWidget';
import PayBillFooter from './components/PayBillFooter';

interface PayBillScreenInnerProps {
  configQueryRef: any;
  queryOptions: NewErrorBoundaryParentState;
}

const theme = getTheme();
const { space } = theme;

const PayBillScreenInner = ({
  configQueryRef,
  queryOptions,
}: PayBillScreenInnerProps) => {
  const {
    bsRef,
    bsMode,
    queryVariables,
    payDisabled,
    isBillPayEnabled,
    showBottomSheet,
    hideBottomSheet,
    addBlackMembershipToCart,
    removeBlackMembershipFromCart,
    handleBsClose,
    handleTipSelection,
    handlePointsWidgetToggle,
    handleCharitySelection,
    handleCouponApplication,
    handlePayNow,
  } = usePayBill(configQueryRef, queryOptions);
  const { bottom } = useSafeAreaInsets();

  if (!isBillPayEnabled)
    return (
      <View flex={1} justifyContent="center" alignItems="center">
        <Text>Access Denied</Text>
      </View>
    );

  return (
    <View flex={1} pb="9xl" alignItems="center">
      <SavingsStrip />
      <ScrollView
        scrollIndicatorInsets={{ right: 1 }}
        style={{
          width: '100%',
        }}
        contentContainerStyle={{
          paddingBottom: space['9xl+9xl'] + bottom,
        }}
      >
        <View px="2xl" mt="xl">
          <BlackMembershipWidget
            onAddMembership={addBlackMembershipToCart}
            onRemoveMembership={removeBlackMembershipFromCart}
            showBottomSheet={showBottomSheet}
          />
          <TipWidget onSelect={handleTipSelection} />
          <PointsWidget onToggle={handlePointsWidgetToggle} />
          <CouponWidget
            queryVariables={queryVariables}
            onApplyCoupon={handleCouponApplication}
          />
          <EventCancelltationWidget showBottomSheet={showBottomSheet} />
          <BillSummaryWidget showBottomSheet={showBottomSheet} />
          <CharityWidget
            onSelect={handleCharitySelection}
            showBottomSheet={showBottomSheet}
          />
        </View>
      </ScrollView>
      <PayBillFooter payDisabled={payDisabled} onPay={handlePayNow} />
      <BottomSheetV2
        ref={bsRef}
        onClose={handleBsClose}
        {...getPayBillBottomSheetStyles(bsMode)}
      >
        {bsMode === PAYBILL_BS_MODES.BREAK_UP && <ViewBreakupBs />}
        {bsMode === PAYBILL_BS_MODES.CHARITY_INFO && <CharityInfoBs />}
        {bsMode ===
          PAYBILL_BS_MODES.DOWNLOAD_APP_PROMT_IF_POINTS_USED_ON_WEB && (
          <DownloadAppIfPointsUsedOnWebBs hideBottomSheet={hideBottomSheet} />
        )}
        {bsMode === PAYBILL_BS_MODES.BLACK_MEMBER_BENEFITS && (
          <BlackMembershipBenefitsBs
            hideBottomSheet={hideBottomSheet}
            onAddToCart={addBlackMembershipToCart}
          />
        )}
        {bsMode === PAYBILL_BS_MODES.EVENT_CANCELLATION_INFO && (
          <EventCancellationInfoBs />
        )}
        {bsMode === PAYBILL_BS_MODES.BLACK_MEMBER_INVITATION_ON_PAY && (
          <BlackMemberInvitationOnPayBs
            onAddBMembership={addBlackMembershipToCart}
            onPayWithoutMembership={handlePayNow}
            hideBottomSheet={hideBottomSheet}
          />
        )}
        {bsMode ===
          PAYBILL_BS_MODES.DOWNLOAD_APP_PROMT_IF_BLACK_MEMBER_PAYS_ON_WEB && (
          <DownloadAppPromtIfBlackMemberPaysOnWebBs
            onPayWithoutApp={handlePayNow}
            hideBottomSheet={hideBottomSheet}
          />
        )}
      </BottomSheetV2>
    </View>
  );
};

export default PayBillScreenInner;
