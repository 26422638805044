/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback, Suspense } from 'react';
import { useLazyLoadQuery, readInlineData } from 'react-relay';
import { ScrollView } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import {
  Layout,
  View,
  Text,
  IconButton,
  TopNavigation,
  Icon,
  Button,
  ButtonGroup,
} from '../../../components/new';
import Loading from '../../../components/loading.component';
import DisconnectedDropover from '../../../components/disconnectedpopover.component';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../../utilities/NewErrorBoundary';
import NewErrorView from '../../../utilities/NewErrorView';
import useCommonStore from '../../../stores/commonStore';
import {
  reachedDropLocationScreenQuery,
  reachedDropLocationScreenQueryTaskFragment,
} from './API/reachedDropLocationScreenQuery';
import useUserStore from '../../../stores/userStore';
import {
  formatPlateNo,
  navigateBack,
  taskHistoryActionTypes,
} from '../../../utilities/helper';
import TaskCard from '../../../components/new/primitive/TaskCard/TaskCard';
import { handlePhone } from '../../../utilities/handleLinking';
import { useSnackbarStore } from '../../../stores/snackbar/snackbarStore';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../../../components/new/primitive/snackbar/helpers/helpers';
import { Appearance, State, Status } from '../../../themes/new/helper';
import { findTask, taskApiFindTaskFragment } from '../../../relay/taskApi';
import { usePagedTasks } from '../../../hooks/usePagedTasks';
import { unwrapPagedData } from '../../../utilities/paginationUtilities';

const ReachedDropLocationScreen = (props) => {
  const { queryOptions, variables, refresh, navigation } = props;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { findParkingLocation, ...findTasksFragment } = useLazyLoadQuery(
    reachedDropLocationScreenQuery,
    variables,
    {
      ...queryOptions,
      networkCacheConfig: { force: true },
    },
  );

  const { tasks: findTasks } = usePagedTasks(
    reachedDropLocationScreenQueryTaskFragment,
    findTasksFragment,
  );

  const campusID = useCommonStore((state) => state.campusId);
  const { dispatchSnackbar } = useSnackbarStore((state) => state);

  const { taskStartLocation, vehicle, parkingTag, ETA, user, _id, history } =
    findTasks[0] ?? {};

  const delayAt = new Date(ETA?.acceptedToArrivedTime).valueOf(); // delay time
  const currentTime = new Date().valueOf(); // current time
  const isDelayed = currentTime > delayAt; // check if current time breached delay time

  // ------ difference between current time and delayAt -------
  const delayedDifferenceInMins = (delayAt - currentTime) / 60 / 1000;

  // ------ finding started status from task history ------
  const startedStatus =
    history &&
    history?.length &&
    history?.filter((ele) => ele.action === taskHistoryActionTypes.started);

  // ------ difference between started status time and current time in seconds ------
  const startedWithCurrentDiff =
    startedStatus &&
    startedStatus?.length &&
    (new Date().valueOf() - new Date(startedStatus[0]?.event_at).valueOf()) /
      1000;

  const [timerValue, setTimerValue] = useState(
    startedWithCurrentDiff <= 30 ? 30 - startedWithCurrentDiff : 0,
  ); // 30 seconds timer after ride started

  const [delayedTimer, setDelayedTimer] = useState(
    Math.floor(delayedDifferenceInMins),
  ); // 1 min timer for for waiting timer in task card

  const insets = useSafeAreaInsets();

  const formattedVehicleNumber = formatPlateNo(vehicle?.registration?.plate);
  const heading = formattedVehicleNumber;
  const subHeading = `#${parkingTag}`;

  // ------ updating timer after every 1 sec for reached location button ------
  useEffect(() => {
    const interval = 1000;
    const timer = setInterval(() => {
      if (timerValue > 0) {
        setTimerValue(timerValue - 1);
      }
    }, interval);
    return () => {
      // Useful for cleanup functions
      clearInterval(timer);
    };
  }, [timerValue]);

  // ------ updating timer after every 1 min for timer in task card ------
  useEffect(() => {
    const delayedInterval = 1000 * 60;
    const delayedTimerInterval = setInterval(() => {
      if (delayedTimer > 0) {
        setDelayedTimer(delayedTimer - 1);
      }
    }, delayedInterval);
    return () => {
      // Useful for cleanup functions
      clearInterval(delayedTimerInterval);
    };
  }, [delayedTimer]);

  const topRightCornerComponent = () => {
    return (
      <View
        flexDirection="row"
        borderRadius="md"
        borderWidth="xs"
        borderColor="primary.50"
        bg="primary.10"
        p="sm+md"
        alignItems="center"
      >
        <Icon
          name="timer-outline-300"
          size="xl"
          color={isDelayed && delayedTimer < 1 ? 'warning.500' : 'success.500'}
        />
        <Text ml="lg" size="md" weight="medium" color="primary.300">
          {isDelayed && delayedTimer < 1 ? 'Delayed' : `${delayedTimer} min`}
        </Text>
      </View>
    );
  };

  const bottomLeftCTAComponent = () => {
    // ------ function to handle call ------
    const onPressCallCustomer = () => {
      handlePhone(
        // eslint-disable-next-line no-unsafe-optional-chaining
        user?.contact?.phone?.prefix +
          // eslint-disable-next-line no-unsafe-optional-chaining
          user?.contact?.phone?.number,
      );
    };

    // ------ function to check if status has been marked arrived from controller ------
    const onPressReachedLocation = () => {
      findTask({
        _id,
        campus: { _id: campusID },
      })
        .then((findTasksFragmentRef) => {
          const { findTasks: pagedFindTasks } = readInlineData(
            taskApiFindTaskFragment,
            findTasksFragmentRef,
          );
          const res = {
            findTasks: unwrapPagedData(pagedFindTasks.edges),
          };
          const arrivedStatus =
            res.findTasks[0].history &&
            res.findTasks[0].history?.length &&
            res.findTasks[0].history?.filter(
              (ele) => ele?.action === taskHistoryActionTypes.arrived,
            );
          if (arrivedStatus && arrivedStatus?.length) {
            navigation.navigate('FinishTaskScreen', {
              taskId: _id,
            });
          } else {
            dispatchSnackbar({
              msg: 'Controller has to mark car arrived first',
              status: SnackbarStatus.warning,
              version: SnackbarVersion.v2,
            });
          }
        })
        .catch((err) => {
          throw err;
        });
    };

    return (
      <View flexDirection="row" pb={insets.bottom}>
        <IconButton
          name="call-filled-300"
          size="md"
          iconSize="2xl"
          onPress={onPressCallCustomer}
        />
        <View ml="lg" flex={1}>
          <Button
            size="lg"
            appearance={Appearance.FILLED}
            status={Status.PRIMARY}
            state={timerValue > 0 ? State.DISABLED : State.ACTIVE}
            onPress={onPressReachedLocation}
            shadow="sm"
          >
            {timerValue <= 0
              ? 'Reached Location'
              : `Reached Location (${Math.floor(timerValue)}s)`}
          </Button>
        </View>
      </View>
    );
  };

  // ------ sort the list by not disable -> disabled ------
  const sortByDisabled = (a, b) => {
    return a.disabled - b.disabled;
  };

  /* ------ finding matched parking location from findParkingLocation
  with taskStartLocation ------  */
  const parkingLocation =
    findParkingLocation &&
    findParkingLocation?.length &&
    findParkingLocation
      ?.map((item) => {
        if (item?._id !== taskStartLocation?.locationId) {
          return { ...item, disabled: true };
        }
        return { ...item, status: 'success', disabled: false };
      })
      .sort(sortByDisabled);

  /* ------ finding matched parking bay from parkingLocation
  found above with taskStartLocation ------ */
  const parkingBay =
    parkingLocation &&
    parkingLocation?.length &&
    parkingLocation[0]?.parkingSpaces?.floor
      ?.map((item) => {
        if (item?._id !== taskStartLocation?.parkingSpaces?.floor.floorId) {
          return { ...item, disabled: true, name: item?.floorName };
        }
        return {
          ...item,
          status: 'success',
          disabled: false,
          name: item?.floorName,
        };
      })
      .sort(sortByDisabled);

  /* ------ finding matched parking spot from parkingBay
  found above with taskStartLocation ------ */
  const parkingSpot =
    parkingBay &&
    parkingBay?.length &&
    parkingBay[0]?.bay
      ?.map((item) => {
        if (item?._id !== taskStartLocation?.parkingSpaces?.floor?.bay?.bayId) {
          return { ...item, disabled: true, name: item?.bayName };
        }
        return {
          ...item,
          status: 'success',
          disabled: false,
          name: item?.bayName,
        };
      })
      .sort(sortByDisabled);

  return (
    <View flex={1}>
      <ScrollView>
        <View px="2xl" mt="4xl">
          <View>
            <ButtonGroup
              buttonData={parkingLocation}
              title="Parking Location"
              size="lg"
            />
          </View>
          <View>
            <ButtonGroup
              buttonData={parkingBay}
              title="Parking Bay"
              size="lg"
            />
          </View>
          <View>
            <ButtonGroup
              buttonData={parkingSpot}
              title="Parking Spot"
              size="lg"
            />
          </View>
          <View height={200} />
        </View>
      </ScrollView>
      <View position="absolute" bottom={0} width="100%">
        <TaskCard
          heading={heading}
          subHeading={subHeading}
          topRightCorner={topRightCornerComponent()}
          shadow="lg"
          bottomLeftCTA={bottomLeftCTAComponent()}
          alwaysOpened
        />
      </View>
    </View>
  );
};

const ReachedDropLocationScreenWrapper = ({ navigation, route }) => {
  const { taskId } = route?.params ?? {};
  const campusID = useCommonStore((state) => state.campusId);
  const { role } = useUserStore((state) => state);

  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'network-only',
    });
  const [netStatus, setNetStatus] = useState(true);

  const refresh = useCallback(
    () => {
      // Trigger a re-render of useLazyLoadQuery with the same variables,
      // but an updated fetchKey and fetchPolicy.
      // The new fetchKey will ensure that the query is fully
      // re-evaluated and refetched.
      // The fetchPolicy ensures that we always fetch from the network
      // and skip the local data cache.
      setRefreshedQueryOptions((prev) => ({
        fetchKey: (prev?.fetchKey ?? 0) + 1,
        fetchPolicy: 'network-only',
      }));
    },
    [
      /* ... */
    ],
  );

  const filter = {
    campus: { _id: campusID },
    _id: taskId,
  };

  const locationFilter = {
    campus: { _id: campusID },
  };

  return (
    <Layout level={2}>
      <TopNavigation
        appearance="ghost"
        level="1"
        IconLeft={
          <IconButton
            name="back-outline-300"
            size="md"
            appearance="ghost"
            iconColor="primary.500"
            onPress={() => navigateBack(navigation, role)}
          />
        }
      />
      <NewErrorBoundary
        fetchKey={refreshedQueryOptions.fetchKey}
        fallback={
          <NewErrorView
            errorMsg="Sorry something went wrong"
            reload={refresh}
          />
        }
      >
        <Suspense fallback={<Loading />}>
          <ReachedDropLocationScreen
            variables={{ filter, locationFilter }}
            fetchKey={refreshedQueryOptions?.fetchKey}
            queryOptions={refreshedQueryOptions}
            refresh={refresh}
            navigation={navigation}
          />
        </Suspense>
      </NewErrorBoundary>
      <DisconnectedDropover
        setNetStatus={setNetStatus}
        text="No Internet Connection"
        icon="wifi-off-outline"
      />
    </Layout>
  );
};

export default ReachedDropLocationScreenWrapper;
