import React, { Suspense, useEffect, useRef, useState } from 'react';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { useLazyLoadQuery } from 'react-relay';
import moment from 'moment';
import {
  KeyboardAvoidingView,
  Platform,
  useWindowDimensions,
} from 'react-native';
import { ActionSheetRef } from 'react-native-actions-sheet';
import LottieView from 'lottie-react-native';
import {
  Button,
  Chip,
  Icon,
  IconButton,
  Layout,
  Text,
  TopNavigation,
  View,
} from '../../../components/new';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../../utilities/NewErrorBoundary';
import NewErrorView from '../../../utilities/NewErrorView';
import DateTimeSlots from './DateTimeSlots';
import {
  MembershipTypes,
  navigateBack,
  paymentOrderTypeEnum,
} from '../../../utilities/helper';
import useUserStore from '../../../stores/userStore';
import useCommonStore from '../../../stores/commonStore';
import { reservationScreenQuery } from './API/reservationScreen';
import { useSnackbarStore } from '../../../stores/snackbar/snackbarStore';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../../../components/new/primitive/snackbar/helpers/helpers';
import { Loading } from '../../../components';
import { firebaseEventLogger } from '../../../utilities/firbaseAnalytics';
import useAuthStore from '../../../stores/authStore';
import useLoginModalStore from '../../../stores/loginModalStore';
import { space } from '../../../themes/new/theme';
import { Appearance, FontWeights, State } from '../../../themes/new/helper';
import BottomSheetV2 from '../../../components/new/composites/BottomSheet/BottomSheetV2';
import CustomScrollView from '../../../components/new/custom/CustomScrollView';
import CustomFlatList from '../../../components/new/custom/CustomFlatList';
import CachedImageBackground from '../../../components/new/custom/CachedImageBackground';
import { getEventCancellationInfo } from './helpers/helpers';

const GOLD_METAL_BG = require('../../../../assets/images/black_membership/golden-metal-texture.png');
const PercentLottie = require('../../../../assets/images/black_membership/lottie/percent-dark.json');

enum slotTagColorEnum {
  SUCCESS = 'SUCCESS',
  INFO = 'INFO',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
  DISABLED = 'DISABLED',
  NONE = 'NONE',
}

const slotsArray = [1, 2, 3, 4, 5, 6, 7, 8];

const getColorTag = (c) => {
  let colorToken = 'primary.400';
  if (c === slotTagColorEnum.DISABLED) {
    colorToken = 'primary.50';
    return colorToken;
  }
  return colorToken;
};

const getHelperColorTag = (c) => {
  let colorToken = 'primary.100';
  if (c === slotTagColorEnum.SUCCESS) {
    colorToken = 'success.500';
    return colorToken;
  }
  if (c === slotTagColorEnum.INFO) {
    colorToken = 'info.500';
    return colorToken;
  }
  if (c === slotTagColorEnum.WARNING) {
    colorToken = 'warning.500';
    return colorToken;
  }
  if (c === slotTagColorEnum.ERROR) {
    colorToken = 'error.500';
    return colorToken;
  }
  if (c === slotTagColorEnum.DISABLED) {
    colorToken = 'primary.100';
    return colorToken;
  }
  if (c === slotTagColorEnum.NONE) {
    colorToken = 'primary.100';
    return colorToken;
  }
  return colorToken;
};

const addExtraObjectFields = (item) => {
  const name = moment(item?.slot?.startTime).format('hh:mm A');
  const disabled = item?.slot?.slotsLeft <= 0;
  const selected = false;
  const helperText = item?.slot?.slotTag || '';
  const helperTextColor = getHelperColorTag(item?.slot?.slotTagColor) || '';
  const textColor = getColorTag(item?.slot?.slotTagColor) || '';
  return {
    name,
    disabled,
    selected,
    helperText,
    helperTextColor,
    textColor,
  };
};

const dataMaker = (eventTimings) => {
  let newEventTimings = [];
  try {
    newEventTimings = JSON.parse(JSON.stringify(eventTimings));
  } catch (error) {
    console.log(error);
  }

  const modifiedEventTimings = newEventTimings?.map((item) => ({
    ...item,
    ...addExtraObjectFields(item),
  }));

  // -----------------
  modifiedEventTimings.sort((a, b) => {
    const dateA = new Date(a?.date);
    const dateB = new Date(b?.date);
    return dateA - dateB;
  });

  const groupedData = [];

  let i = 0;

  modifiedEventTimings?.forEach((item, index) => {
    const d = new Date(item?.date);
    const year = d.getFullYear();
    const month = d.getMonth();
    const date = d.getDate();

    if (index === 0) {
      groupedData[i] = [item];
    } else {
      const previousD = new Date(modifiedEventTimings[index - 1]?.date);
      const pYear = previousD.getFullYear();
      const pMonth = previousD.getMonth();
      const pDate = previousD.getDate();

      if (pYear === year && pMonth === month && pDate === date) {
        groupedData[i].push(item);
      } else {
        i += 1;
        groupedData[i] = [item];
      }
    }
  });
  return JSON.parse(JSON.stringify(groupedData.splice(0, 8)));
};

const ReservationsScreen = ({ events, eventId, addon, isMember }) => {
  const { dispatchSnackbar } = useSnackbarStore((state) => state);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [selectedSlot, setSelectedSlot] = useState(1);
  const navigation = useNavigation<any>();
  const [selectedDateIndex, setSelectedDateIndex] = useState(0);
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  const { width } = useWindowDimensions();
  const bottomSheetModalRef = useRef<ActionSheetRef>(null);
  const [regularTickets, setRegularTickets] = useState(null);
  const [slotsArrayRes, setSlotsArrayRes] = useState([]);
  const [enabledLogin, setEnabledLogin] = useState(false);
  const setAuthModalOpen = useLoginModalStore((state) => state.setIsOpen);
  const setWhatsappLoginEnabled = useLoginModalStore(
    (state) => state.setWhatsappLoginEnabled,
  );
  const isOpen = useLoginModalStore((state) => state.isOpen);
  const eventTimings = events[0]?.eventTiming;
  const data = dataMaker(eventTimings);
  const blackMemberDiscountPercentage =
    addon?.discount?.type === 'PERCENTAGE' ? addon?.discount?.value : 0;
  const [numColumns, setNumColumns] = useState(width >= 384 ? 4 : 3);
  const selectedEventDate = data[selectedDateIndex]?.[0]?.date;
  const [eventCancellationInfo, setEventCancellationInfo] = useState<{
    isRefundableIfCancelled: boolean;
    refundLastDate: {
      date: Date;
      formatOne: string;
      formatTwo: string;
    };
  } | null>(null);
  useEffect(() => {
    if (typeof selectedEventDate === 'string') {
      setEventCancellationInfo(
        getEventCancellationInfo(new Date(selectedEventDate)),
      );
    }
  }, [selectedEventDate]);

  useEffect(() => {
    if (isLoggedIn && enabledLogin) {
      const amount = regularTickets?.price * selectedSlot;
      const slotId = data[selectedDateIndex]?.[selectedIndex]?._id;
      const tickets = [
        {
          ticketId: regularTickets?._id,
          spaces: selectedSlot,
        },
      ];
      setTimeout(() => {
        navigation.navigate('paymentAmountScreen', {
          orderId: eventId,
          orderType: paymentOrderTypeEnum.EVENT,
          amount,
          eventSlotId: slotId,
          eventTicketId: regularTickets?._id,
          eventDate: data[selectedDateIndex]?.[0]?.date,
          noOfSlots: selectedSlot,
        });
      }, 300);
      firebaseEventLogger('eventReservation__proceedToPay_Tap', {
        id: eventId,
        name: events[0]?.title,
        date: data[selectedDateIndex]?.[selectedIndex]?.date,
        slotTime: data[selectedDateIndex]?.[selectedIndex]?.name,
        slots: selectedSlot,
      });
      setTimeout(() => {
        setRegularTickets(null);
        setSlotsArrayRes([]);
        setSelectedIndex(-1);
        setSelectedSlot(1);
        setEnabledLogin(false);
      }, 500);
    }
    if (!isOpen) {
      setTimeout(() => {
        setRegularTickets(null);
        setSlotsArrayRes([]);
        setSelectedIndex(-1);
        setSelectedSlot(1);
      }, 500);
    }
  }, [isLoggedIn, isOpen]);

  useEffect(() => {
    if (selectedIndex >= 0) {
      const availableTickets =
        data[selectedDateIndex]?.[selectedIndex]?.slot?.availableTickets;
      const regularTicketsFind = availableTickets?.find(
        (item) => item?.name === 'Regular',
      );
      if (regularTicketsFind?.slotsLeft > 0) {
        setRegularTickets(regularTicketsFind);
        const newArr = [...slotsArray];
        const slots = newArr.splice(0, regularTicketsFind?.slotsLeft);
        setSlotsArrayRes(slots);
        bottomSheetModalRef?.current?.show();
      } else {
        dispatchSnackbar({
          msg: `Slots not available`,
          status: SnackbarStatus.error,
          version: SnackbarVersion.v2,
        });
        setSelectedIndex(-1);
        setRegularTickets(null);
        setSlotsArrayRes([]);
      }
    } else {
      setRegularTickets(null);
      setSlotsArrayRes([]);
    }
  }, [selectedIndex]);

  useFocusEffect(
    React.useCallback(() => {
      firebaseEventLogger('event__reservation__landing', {
        id: eventId,
        name: events[0]?.title,
      });
    }, []),
  );

  useEffect(() => {
    firebaseEventLogger('eventReservation__selectDate_Tap', {
      screenName: 'eventReservation',
      userType: 'user',
      interactionType: 'selectDate',
      id: eventId,
      name: events[0]?.title,
      date: data?.[selectedDateIndex]?.[0]?.date,
    });
  }, []);

  const renderTimeChip = ({ item, index }) => {
    return (
      <View
        alignItems="center"
        mb="xl"
        ml={index % numColumns === 0 ? 0 : 'lg'}
      >
        <Chip
          state={
            item?.disabled
              ? State.DISABLED
              : index === selectedIndex
              ? State.SELECTED
              : State.ACTIVE
          }
          onPress={() => {
            setSelectedIndex(index);
            setEnabledLogin(false);
            firebaseEventLogger('eventReservation__selectTime_Tap', {
              id: eventId,
              name: events[0].title,
            });
            firebaseEventLogger('eventReservation__selectSlot_Tap', {
              id: eventId,
              name: events[0].title,
              date: item?.date,
              slotTime: item?.name,
              slots: 1,
            });
          }}
          helperText={item?.helperText}
          helperTextColor={item?.helperTextColor}
          helperTextSize="2xs"
          textColor={item?.textColor}
          size="xs"
          iconLeft=""
          iconRight=""
          status="primary"
          style={{ minWidth: (width - 32) / 4 - 8 }}
          isUpperCase={false}
          appearance={Appearance.FILLED}
        >
          {item?.name}
        </Chip>
      </View>
    );
  };
  const renderDateAndTimeCard = () => {
    const TimeDiff =
      moment(data[selectedDateIndex]?.[0]?.slot?.endTime).unix() -
      moment(data[selectedDateIndex]?.[0]?.slot?.startTime).unix();
    const duration = TimeDiff / 60;
    return (
      <View mt="4xl">
        {!!data?.length && (
          <View>
            <CustomScrollView
              horizontal
              showsHorizontalScrollIndicator={false}
              contentContainerStyle={{
                paddingLeft: space['2xl'],
              }}
            >
              {data.map((item, index) => {
                return (
                  <DateTimeSlots
                    key={index}
                    curIndex={index}
                    data={item}
                    selectedDateIndex={selectedDateIndex}
                    setSelectedDateIndex={() => {
                      setSelectedDateIndex(index);
                      setSelectedIndex(-1);
                      firebaseEventLogger('eventReservation__selectDate_Tap', {
                        screenName: 'eventReservation',
                        userType: 'user',
                        interactionType: 'selectDate',
                        eventId,
                        date: item?.[0]?.date,
                      });
                    }}
                  />
                );
              })}
            </CustomScrollView>
            <View mt="9xl" px="2xl">
              <View
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Text size="lg" color="primary.300" weight="medium">
                  Select Slot
                </Text>
                <Text size="xs" color="primary.200" weight="medium">
                  Duration -{' '}
                  {duration && duration > 0
                    ? `${duration.toFixed(0)} min`
                    : 'Flexible'}
                </Text>
              </View>

              <CustomFlatList
                numColumns={numColumns}
                scrollEnabled={false}
                key="_"
                keyExtractor={(item) => `_${item?._id}`}
                data={data[selectedDateIndex]}
                renderItem={renderTimeChip}
                contentContainerStyle={{
                  marginTop: space['2xl'],
                  flexWrap: 'wrap',
                  flex: 1,
                }}
              />
            </View>
          </View>
        )}
      </View>
    );
  };

  if (!data.length) {
    return (
      <View flex={1}>
        <View flex={1} justifyContent="center" alignItems="center">
          <Text>no data found</Text>
        </View>
      </View>
    );
  }

  const handleOnDismiss = () => {
    if (isLoggedIn || !enabledLogin) {
      setRegularTickets(null);
      setSlotsArrayRes([]);
      setSelectedIndex(-1);
      setSelectedSlot(1);
    }
  };

  const handleProceedToPay = () => {
    if (!isLoggedIn) {
      firebaseEventLogger('eventReservation__ProceedToLogin_Tap', {
        screenName: 'eventReservation',
        userType: 'user',
        interactionType: 'ProceedToLogin',
        eventId,
      });
      setEnabledLogin(true);
      setTimeout(() => {
        setAuthModalOpen(true);
      }, 500);
    } else {
      const amount = regularTickets?.price * selectedSlot;
      const slotId = data[selectedDateIndex]?.[selectedIndex]?._id;
      const tickets = [
        {
          ticketId: regularTickets?._id,
          spaces: selectedSlot,
        },
      ];
      navigation.navigate('paymentAmountScreen', {
        orderId: eventId,
        orderType: paymentOrderTypeEnum.EVENT,
        amount,
        eventSlotId: slotId,
        eventTicketId: regularTickets?._id,
        eventDate: data[selectedDateIndex]?.[0]?.date,
        noOfSlots: selectedSlot,
      });
      firebaseEventLogger('eventReservation__proceedToPay_Tap', {
        id: eventId,
        name: events[0]?.title,
        date: data[selectedDateIndex]?.[selectedIndex]?.date,
        slotTime: data[selectedDateIndex]?.[selectedIndex]?.name,
        slots: selectedSlot,
      });
    }
    setTimeout(() => {
      bottomSheetModalRef?.current?.hide();
    }, 200);
  };

  const renderSlots = ({ item, index }) => {
    return (
      <View alignItems="center" width={(width - 32) / 4} mb="3xl">
        <Chip
          key={`PERSON_SLOT_${item}`}
          state={index === selectedSlot - 1 ? State.SELECTED : State.ACTIVE}
          onPress={() => {
            setSelectedSlot(item);
            firebaseEventLogger('eventReservation__selectSlot_Tap', {
              name: events[0].title,
              eventId,
              date: data[selectedDateIndex]?.[selectedIndex]?.date,
              slotTime: data[selectedDateIndex]?.[selectedIndex]?.name,
              slots: item,
            });
          }}
          helperText={item?.helperText}
          helperTextColor={item?.helperTextColor}
          helperTextSize="2xs"
          textColor="primary.400"
          size="md"
          iconLeft=""
          iconRight=""
          px="3xl"
          isUpperCase={false}
          appearance={Appearance.FILLED}
        >
          {item}
        </Chip>
      </View>
    );
  };

  return (
    <KeyboardAvoidingView
      style={{ flex: 1 }}
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
    >
      <CustomScrollView
        contentContainerStyle={{ flexGrow: 1 }}
        keyboardShouldPersistTaps="handled"
      >
        {renderDateAndTimeCard()}
      </CustomScrollView>
      <BottomSheetV2 ref={bottomSheetModalRef} onClose={handleOnDismiss} insets>
        <View
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          borderBottomWidth="xs"
          borderColor="grey.100"
          pb="2xl"
          pt="2xl"
          mx="2xl"
        >
          <Text size="lg" weight={FontWeights.MEDIUM} color="primary.300">
            How many tickets?
          </Text>
          {eventCancellationInfo?.isRefundableIfCancelled && (
            <View flexDirection="row" alignItems="center">
              <Icon name="doubletick-400" color="success.500" size="lg" />
              <Text ml="sm" size="xs" color="success.500">
                Free Cancellation
              </Text>
            </View>
          )}
        </View>
        <View px="2xl">
          <CustomFlatList
            numColumns={4}
            scrollEnabled={false}
            data={slotsArrayRes}
            keyExtractor={(item) => `RENDER_SLOT${item}`}
            renderItem={renderSlots}
            contentContainerStyle={{
              marginTop: space['4xl'],
            }}
          />
        </View>
        <View
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          borderTopWidth="xs"
          borderColor="grey.100"
          pb="4xl"
          pt="lg"
          mx="2xl"
        >
          <Text size="xs" weight={FontWeights.MEDIUM} color="primary.300">
            {`₹${regularTickets?.price}`} per ticket
          </Text>
          <View flexDirection="row" alignItems="center">
            <Icon name="timer-outline-300" color="primary.300" size="md" />
            <Text size="xs" color="primary.300" mr="lg" ml="sm" weight="medium">
              {moment(
                data[selectedDateIndex]?.[selectedIndex]?.slot?.startTime,
              ).format('ddd, DD MMM')}
            </Text>
            <Icon name="calendar-outline-300" color="primary.300" size="md" />
            <Text size="xs" color="primary.300" ml="sm" weight="medium">
              {moment(
                data[selectedDateIndex]?.[selectedIndex]?.slot?.startTime,
              ).format('hh:mm A')}
            </Text>
          </View>
        </View>
        {blackMemberDiscountPercentage > 0 && (
          <View width={width}>
            <CachedImageBackground
              source={GOLD_METAL_BG}
              style={{
                justifyContent: 'center',
                width: '100%',
                height: 40,
              }}
              imageStyle={{
                resizeMode: 'cover',
              }}
            >
              <View py="sm+md" px="2xl" flexDirection="row" alignItems="center">
                <View mr="lg" width={24} height={24}>
                  <LottieView
                    autoPlay
                    loop
                    style={{
                      height: 24,
                      width: 24,
                    }}
                    source={PercentLottie}
                  />
                </View>
                <Text
                  size="xs"
                  color="static.black"
                  weight={FontWeights.MEDIUM}
                >
                  {isMember ? (
                    <>
                      As a 32nd Plus member, get {blackMemberDiscountPercentage}
                      % off now!
                    </>
                  ) : (
                    <>
                      Become a 32nd Plus member to get{' '}
                      {blackMemberDiscountPercentage}% off now!
                    </>
                  )}
                </Text>
              </View>
            </CachedImageBackground>
          </View>
        )}
        <View
          px="2xl"
          pt="2xl"
          borderTopWidth={blackMemberDiscountPercentage < 0 ? 0 : 'xs'}
          borderColor="grey.100"
        >
          <Button state={State.ACTIVE} size="lg" onPress={handleProceedToPay}>
            Proceed
          </Button>
        </View>
      </BottomSheetV2>
    </KeyboardAvoidingView>
  );
};

const ReservationsScreenWrapper = ({ navigation, route }) => {
  const { eventId } = route.params;
  const campusID = useCommonStore((state) => state.campusId);
  const isAlreadyBlackMember = useUserStore(
    (state) => state.blackMembership?.active,
  );
  const variables = {
    filter: {
      campus: { _id: campusID },
      _id: eventId,
      isActive: true,
    },
    addonsFilter: {
      type: MembershipTypes.PLUS_MEMBERSHIP,
    },
    isMember: isAlreadyBlackMember,
  };

  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'network-only',
    });

  const { findEvents, findAddons } = useLazyLoadQuery(
    reservationScreenQuery,
    variables,
    refreshedQueryOptions,
  );
  const eventBlackMemberDiscount = Array.isArray(findAddons)
    ? findAddons[0]?.additionalDiscount?.find((d) => d?.refType === 'EVENT')
    : {};

  const { userRole } = useUserStore((state) => ({
    userRole: state.role,
  }));

  const refresh = () => {
    setRefreshedQueryOptions((prev) => ({
      ...prev,
      fetchKey: (prev?.fetchKey || 0) + 1,
    }));
  };

  return (
    <Layout level={2}>
      <TopNavigation
        title={
          findEvents?.[0]?.title?.length > 24
            ? `${findEvents?.[0]?.title?.substring(0, 24)}...`
            : findEvents?.[0]?.title?.substring(0, 24)
        }
        IconLeft={
          <IconButton
            name="back-outline-300"
            size="md"
            appearance="ghost"
            iconColor="primary.500"
            onPress={() => {
              navigateBack(navigation, userRole);
            }}
          />
        }
      />
      <NewErrorBoundary
        fetchKey={refreshedQueryOptions.fetchKey}
        fallback={
          <NewErrorView
            errorMsg="Sorry something went wrong"
            reload={refresh}
          />
        }
      >
        <Suspense fallback={<Loading />}>
          <ReservationsScreen
            events={findEvents}
            eventId={eventId}
            isMember={isAlreadyBlackMember}
            addon={eventBlackMemberDiscount}
          />
        </Suspense>
      </NewErrorBoundary>
    </Layout>
  );
};

export default ReservationsScreenWrapper;
