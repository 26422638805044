import { graphql } from 'react-relay';

export const payBillScreenQuery = graphql`
  query payBillScreenQuery(
    $amount: Float
    $tipAmount: Float
    $csrContributionId: ID
    $useRewardPoints: Boolean!
    $orderId: ID!
    $orderType: Referer!
    $workshopDetails: invoiceWorkshopDetailsInput
    $couponCode: String
    $addons: addonsDetailsInput
    $preAppliedAddon: addonsDetailsInput
    $fetchPreAppliedAddon: Boolean!
  ) {
    getNetPrice(
      amount: $amount
      tipAmount: $tipAmount
      useRewardPoints: $useRewardPoints
      csrContributionId: $csrContributionId
      orderId: $orderId
      orderType: $orderType
      workshopDetails: $workshopDetails
      couponCode: $couponCode
      addons: $addons
    ) {
      ...payBillScreenQuery_getNetPriceFragment
    }
    getNetPriceWithMembership: getNetPrice(
      amount: $amount
      tipAmount: $tipAmount
      useRewardPoints: $useRewardPoints
      csrContributionId: $csrContributionId
      orderId: $orderId
      orderType: $orderType
      workshopDetails: $workshopDetails
      couponCode: $couponCode
      addons: $preAppliedAddon
    ) @include(if: $fetchPreAppliedAddon) {
      ...payBillScreenQuery_getNetPriceFragment
    }
  }
`;

export const getNetPriceFragment = graphql`
  fragment payBillScreenQuery_getNetPriceFragment on invoiceModelPriceExtendedTC {
    priceDetails {
      grossAmount
      discountGiven
      rewardsUsed
      netAmount
      tipAmount
      csrAmount
      rewardsUsable
      discountPercentage
      couponId
      couponAmount
      couponCashbackAmount
      billSummary {
        grossAmount
        deductions
        additions
        netAmount
      }
      addonAmount
    }
    discountPercentage
    cashbackPercentage
    cashbackAmount
    sourceDetails {
      restaurant {
        displayName
        payBill {
          payEnabled
          pointsEnabled {
            redeem
          }
        }
        _id
      }
      event {
        title
        _id
        eventTiming {
          _id
          slot {
            availableTickets {
              _id
              price
              basePrice
              personAllowed
            }
          }
        }
      }
      store {
        displayName
        _id
      }
      popUpStore {
        displayName
        _id
      }
      parking {
        _id
        parkingInvoice {
          charges {
            chargesBreakup {
              duration
              payable
            }
          }
        }
      }
    }
    extras {
      coupons {
        isApplied
        message
      }
    }
  }
`;
